import { ISimpleSelectItem } from '../../components/select/SimpleSelect';
import { QuotationType } from '../../generated/graphql';

export const QUOTATION_TYPE_OPTIONS: ISimpleSelectItem[] = [
  {
    key: QuotationType.Spot,
    name: 'Spot',
  },
  {
    key: QuotationType.Contract,
    name: 'Contract',
  },
];
