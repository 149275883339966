export interface IInvoiceLine {
  amount: number;
  unitPrice: number;
  vatRatePercentage: number;
  vatRateId: string;
}

export interface ITotalAmounts {
  totalExclVat: number;
  totalInclVat: number;
  totalVat: number;
  vatRateId: string;
  vatRatePercentage: number;
}

export interface IInvoiceTotalOutput {
  perVatRate: Record<number, ITotalAmounts>;
  totalExclVat: number;
  totalInclVat: number;
  totalVat: number;
}

export function calculateInvoiceTotals(lines: IInvoiceLine[]): IInvoiceTotalOutput {
  const totalsPerVatRate: Record<number, ITotalAmounts> = {};

  for (const line of lines) {
    if (!totalsPerVatRate[line.vatRatePercentage]) {
      totalsPerVatRate[line.vatRatePercentage] = {
        vatRateId: line.vatRateId,
        vatRatePercentage: line.vatRatePercentage,
        totalExclVat: 0,
        totalInclVat: 0,
        totalVat: 0,
      };
    }

    const totalWithoutVat = (line.amount * line.unitPrice) / 100;
    const totalVat = (totalWithoutVat / 10000) * line.vatRatePercentage;

    totalsPerVatRate[line.vatRatePercentage].totalExclVat += totalWithoutVat;
    totalsPerVatRate[line.vatRatePercentage].totalInclVat += totalWithoutVat + totalVat;
    totalsPerVatRate[line.vatRatePercentage].totalVat += totalVat;
  }

  const values = Object.values(totalsPerVatRate);
  return {
    totalExclVat: values.reduce((acc, val) => acc + val.totalExclVat, 0),
    totalInclVat: values.reduce((acc, val) => acc + val.totalInclVat, 0),
    totalVat: values.reduce((acc, val) => acc + val.totalVat, 0),
    perVatRate: totalsPerVatRate,
  };
}
