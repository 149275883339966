import { Plus, Trash } from '@phosphor-icons/react';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';

import { PageHeader } from '../../../../components/PageHeader';
import { Pagination } from '../../../../components/pagination/Pagination';
import {
  TrailerCostsDocument,
  TrailerCostsQuery,
  TrailerCostsQueryVariables,
  useDeleteTrailerCostMutation,
} from '../../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../../hooks/usePagination';
import { LinkButton } from '../../../../components/button/ButtonLink';
import { Table } from 'components/table/Table';
import { formatDate } from '@utils/date';
import { useTrailer } from '../trailerContext';
import { formatNumber } from '@utils/number';
import { PAID_BY_OPTIONS } from './constants';
import { ConfirmDialog } from 'components/dialog/ConfirmDialog';
import toast from 'react-hot-toast';
import { getDisplayError } from '@utils/get-display-error';

const TABLE_HEADERS = [
  {
    id: 'id',
    name: 'ID',
  },
  {
    id: 'cost',
    name: 'Kost',
  },
  {
    id: 'description',
    name: 'Beschrijving',
  },
  {
    id: 'date',
    name: 'Datum',
  },
  {
    id: 'paidBy',
    name: 'Betaald door',
  },
];

export const TrailerCostsPage = () => {
  const { trailer } = useTrailer();
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<TrailerCostsQuery, TrailerCostsQueryVariables>(
          TrailerCostsDocument,
          {
            trailerId: trailer.id,
            id: variables.cursor,
            take: variables.take,
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.trailerCosts ?? [];
    },
    [client, trailer],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 20,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });
  const [, deleteCost] = useDeleteTrailerCostMutation();

  return (
    <>
      <PageHeader title="Trailers" />

      <div>
        <div className="flex justify-end mb-4">
          <div>
            <LinkButton to="new" color="primary" iconLeft={<Plus className="button-icon" />}>
              Nieuwe kost
            </LinkButton>
          </div>
        </div>

        <Table
          idKey="id"
          headers={TABLE_HEADERS}
          data={page.data}
          mapData={(cost) => {
            return [
              <Link to={`${cost.id}`} className="link-text">
                {cost.id}
              </Link>,
              <div className="whitespace-nowrap">{`${formatNumber(cost.amount, 2)} EUR`}</div>,
              cost.description,
              formatDate(cost.date),
              PAID_BY_OPTIONS.find((v) => v.key === cost.paidBy)?.name ?? cost.paidBy,
              <ConfirmDialog
                triggerColor="danger"
                triggerText={<Trash className="button-icon" />}
                title="Trailer kost verwijderen?"
                submitText="Verwijder"
                description={<div>Ben je zeker dat je deze trailer kost wilt verwijderen?</div>}
                onSubmit={async () => {
                  try {
                    const result = await deleteCost({
                      id: cost.id,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                    toast.success('Trailerkost verwijderd');
                  } catch (err) {
                    toast.error(`Kon trailer kost niet verwijderen: ${getDisplayError(err)}`);
                  }
                }}
              />,
            ];
          }}
        />

        <div className="my-4">
          <Pagination
            hasPrevious={page.hasPrevious}
            previous={page.previous}
            hasNext={page.hasNext}
            next={page.next}
            isFetching={page.isFetching}
          />
        </div>
      </div>
    </>
  );
};
