import createContext from 'contexts/create-context';
import * as React from 'react';

import { CreateCreditNoteAction, ICreateCreditNoteState, createCreditNoteReducer } from './reducer';

interface ProviderValue {
  state: ICreateCreditNoteState;
  dispatch: React.Dispatch<CreateCreditNoteAction>;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

export const CreateCreditNoteProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
  const { children } = props;
  const [state, dispatch] = React.useReducer(createCreditNoteReducer, {
    activeWindow: 'SELECT_CUSTOMER',
    selectedCustomer: null,
    selectedInvoice: null,
    orders: [],
  });

  return <ReactProvider value={{ state, dispatch }}>{children}</ReactProvider>;
};

export const useCreateCreditNoteCtx = useContext;
export const Consumer = ReactConsumer;
