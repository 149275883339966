import { CalendarDots, CurrencyEur, MagnifyingGlass } from '@phosphor-icons/react';
import { Suspense, useState } from 'react';

import classNames from '@utils/classnames';
import { formatDate } from '@utils/date';
import { formatNumber } from '@utils/number';
import { Button } from 'components/button/Button';
import { SimpleSelect } from 'components/select/SimpleSelect';
import {
  GetPaymentReminderInvoicesQuery,
  InvoiceStatus,
  PaymentReminderType,
  useGetPaymentReminderInvoicesQuery,
  useCreatePaymentReminderMutation,
} from 'generated/graphql';
import { REMINDER_TYPE_SELECT_OPTIONS } from '../../constants.client';
import toast from 'react-hot-toast';
import { getDisplayError } from '@utils/get-display-error';
import { useNavigate } from 'react-router-dom';
import { Input } from 'components/input/Input';

type Invoice = GetPaymentReminderInvoicesQuery['invoices'][0];

const InvoiceCard: React.FC<{ invoice: Invoice; isSelected?: boolean; onClick?: () => void }> = (props) => {
  const { invoice, isSelected, onClick } = props;

  return (
    <div
      className={classNames('card p-4 grid grid-cols-3', {
        'selected-card': isSelected,
      })}
      data-clickable={onClick ? 'true' : undefined}
      key={invoice.id}
      onClick={onClick}
    >
      <div className="font-medium">{`#${invoice.invoiceNumber}`}</div>
      <div className="flex gap-2 items-center">
        <CalendarDots className="h-4 w-4" />
        {formatDate(invoice.expiresAt)}
      </div>
      <div className="flex gap-2 items-center">
        <CurrencyEur className="h-4 w-4" />
        {formatNumber(invoice.totalInclVat, 2, {
          decimalSeperator: ',',
        })}
      </div>
    </div>
  );
};

interface IInvoicesListProps {
  searchValue: string;
  customerId: number | undefined;
  selectedInvoicesSet: Set<string>;
  selectedInvoices: Invoice[];
  setSelectedInvoices: (invoices: Invoice[]) => void;
}

const InvoicesList: React.FC<IInvoicesListProps> = (props) => {
  const { searchValue, customerId, selectedInvoicesSet, selectedInvoices, setSelectedInvoices } = props;
  const [{ data: invoicesData }] = useGetPaymentReminderInvoicesQuery({
    variables: {
      take: 150,
      filters: {
        search: searchValue,
        customerId,
        status: [InvoiceStatus.Created, InvoiceStatus.Sent],
      },
    },
    requestPolicy: 'cache-and-network',
  });

  const invoices = invoicesData?.invoices ?? [];
  const filteredInvoices = invoices.filter((v) => !selectedInvoicesSet.has(v.id));
  return (
    <div className="grid grid-cols-2 gap-2">
      {filteredInvoices.length > 0 ? (
        filteredInvoices.map((invoice) => {
          const isSelected = selectedInvoicesSet.has(invoice.id);

          return (
            <InvoiceCard
              invoice={invoice}
              isSelected={!!isSelected}
              onClick={() => {
                if (isSelected) {
                  setSelectedInvoices(selectedInvoices.filter((i) => i.id !== invoice.id));
                } else if (customerId == null || invoice.customer.id === customerId) {
                  setSelectedInvoices([...selectedInvoices, invoice]);
                }
              }}
            />
          );
        })
      ) : (
        <div>Geen te betalen facturen gevonden</div>
      )}
    </div>
  );
};

export const PaymentReminderInvoiceSelect = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [selectedInvoices, setSelectedInvoices] = useState<Invoice[]>([]);
  const [reminderType, setReminderType] = useState(PaymentReminderType.FirstReminder);
  const customerId = selectedInvoices.length ? selectedInvoices[0].customer.id : undefined;

  const [, createPaymentReminder] = useCreatePaymentReminderMutation();

  const selectedInvoicesSet = new Set(selectedInvoices.map((i) => i.id));
  return (
    <div>
      <div className="heading-two mb-4">Type herinnering</div>
      <div>
        <SimpleSelect
          selectedItem={REMINDER_TYPE_SELECT_OPTIONS.find((v) => v.key === reminderType)!}
          items={REMINDER_TYPE_SELECT_OPTIONS}
          onSelect={(v) => {
            if (v) {
              setReminderType(v.key as PaymentReminderType);
            }
          }}
        />
      </div>
      <div className="heading-two my-4">Geselecteerde facturen</div>
      <div className="grid grid-cols-2 gap-2">
        {selectedInvoices.length > 0 ? (
          selectedInvoices.map((v) => {
            return (
              <InvoiceCard
                invoice={v}
                onClick={() => {
                  setSelectedInvoices(selectedInvoices.filter((i) => i.id !== v.id));
                }}
              />
            );
          })
        ) : (
          <div>Nog geen facturen geselecteerd</div>
        )}
      </div>
      <div className="heading-two my-4">Selecteer facturen</div>
      <div className="mb-4">
        <Input
          type="text"
          placeholder="Zoek een factuur..."
          value={searchValue}
          onChange={setSearchValue}
          iconLeft={<MagnifyingGlass className="input-icon" />}
        />
      </div>

      <Suspense>
        <InvoicesList
          searchValue={searchValue}
          customerId={customerId}
          selectedInvoicesSet={selectedInvoicesSet}
          selectedInvoices={selectedInvoices}
          setSelectedInvoices={setSelectedInvoices}
        />
      </Suspense>

      <div className="my-8 flex justify-end">
        <Button
          color="primary"
          isDisabled={!selectedInvoices.length}
          onTrigger={async () => {
            try {
              const result = await createPaymentReminder({
                customerId: customerId!,
                reminderType,
                invoiceIds: selectedInvoices.map((i) => i.id),
              });
              if (result.error) {
                throw result.error;
              }
              toast.success('Betaalherinnering aangemaakt');
              navigate(`../${result.data?.createPaymentReminder.id}`);
            } catch (err) {
              console.error(err);
              toast.error(`Kon betaalherinnering niet aanmaken: ${getDisplayError(err)}`);
            }
          }}
        >
          Maak herinnering aan
        </Button>
      </div>
    </div>
  );
};
