export function downloadTextFile(content: string, filename: string, mimetype: string): void {
  const blob = new Blob([content], { type: mimetype });
  // @ts-ignore
  const url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
  return downloadUrl(url, { filename });
}

export function downloadUrl(
  url: string,
  opts: {
    filename?: string | null;
    openInNewTab?: boolean;
  } = {},
): void {
  const { filename, openInNewTab } = opts;
  const elem = window.document.createElement('a');
  elem.style.display = 'none';
  elem.href = url;
  if (filename) {
    elem.download = filename;
  }
  if (openInNewTab) {
    elem.target = '_blank';
  }
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
}
