import classNames from '@utils/classnames';
import React, { useRef, useState } from 'react';

export interface InputProps {
  id?: string;
  value: string;
  onBlur?: (e: React.FocusEvent<any>) => void;
  onFocus?: (e: React.FocusEvent<any>) => void;
  onChange: (newValue: string) => void;
  type: 'text' | 'email' | 'password' | 'number' | 'date' | 'time' | 'tel';
  step?: string;
  isInvalid?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  autoComplete?: string;
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  spellCheck?: boolean;
}

export const Input: React.FC<InputProps> = (props) => {
  const {
    id,
    value,
    onBlur: onBlurProp,
    onFocus: onFocusProp,
    isInvalid,
    isDisabled,
    placeholder,
    step,
    autoComplete,
    iconLeft,
    iconRight,
    onKeyDown,
    spellCheck,
  } = props;
  let type = props.type;
  let onChange = props.onChange;
  if (type === 'number') {
    type = 'text';
    onChange = (newValue) => {
      let cleanedValue = newValue.replace(/[^0-9,.]+/g, '').replace(/,+/g, '.');
      if (cleanedValue.length > 1 && cleanedValue[0] === '0') {
        cleanedValue = cleanedValue.substring(1);
      }
      props.onChange(cleanedValue);
    };
  }
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<null | HTMLInputElement>(null);

  const onBlur = (evt: React.FocusEvent<any>) => {
    setIsFocused(false);
    onBlurProp?.(evt);
  };

  const onFocus = (evt: React.FocusEvent<any>) => {
    setIsFocused(true);
    onFocusProp?.(evt);
  };

  return (
    <div
      className={classNames('w-full flex bg-offwhite text-dark-04 border-dark-04 border rounded', {
        'border-feedback-negative-04': isInvalid,
        'border-dark-01': !isInvalid && isFocused,
        'border-dark-04': !isInvalid && !isFocused,
      })}
      onClick={() => inputRef.current?.focus()}
    >
      {iconLeft ? <div className="flex justify-center items-center w-auto mx-2">{iconLeft}</div> : null}
      <input
        id={id}
        ref={inputRef}
        type={type}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        onChange={(evt) => onChange(evt.target.value)}
        className={classNames('py-2 block w-full focus:outline-none bg-transparent text-dark-01', {
          'pl-2': !iconLeft,
          'pr-2': !iconRight,
        })}
        disabled={isDisabled}
        autoComplete={autoComplete ?? 'off'}
        step={step}
        onKeyDown={onKeyDown}
        spellCheck={spellCheck}
      />
      {iconRight ? <div className="flex justify-center items-center w-auto ml-4">{iconRight}</div> : null}
    </div>
  );
};
