import React from 'react';

export interface IAvatarProps {
  url?: string | null;
  name: string;
}

export const Avatar: React.FC<IAvatarProps> = (props) => {
  const { url, name } = props;

  const firstLetter = name.length > 0 ? name[0].toUpperCase() : 'T';
  return url ? (
    <img className="h-8 w-8 rounded-full" src={url ?? '/static/fallback-avatar.svg'} alt={name} title={name} />
  ) : (
    <div className="h-8 w-8 rounded-full bg-dark-orange-03 text-md text-white font-medium flex justify-center items-center">
      {firstLetter}
    </div>
  );
};
