import toast from 'react-hot-toast';

import { getDisplayError } from '../../../utils/get-display-error';
import { ConfirmDialog } from '../../../components/dialog/ConfirmDialog';
import { useSendDieselSurchargeNewsletterMutation } from '../../../generated/graphql';
import { useAllGeneralSettings } from '../all-settings-context';
import { formatDateTime } from '../../../utils/date';

export const GeneralActionsPage = () => {
  const { settings } = useAllGeneralSettings();
  const [, sendNewsletter] = useSendDieselSurchargeNewsletterMutation();

  return (
    <div>
      <ConfirmDialog
        triggerText="Verzend diesel toeslag nieuwsbrief"
        triggerColor="default"
        title="Verzend diesel toeslag nieuwsbrief"
        submitText="Verzend"
        description={`Ben je zeker dat je de diesel toeslag nieuwsbrief wilt verzenden? Laatste verzonden op ${
          settings.dieselSurchargeEmailSentAt ? formatDateTime(settings.dieselSurchargeEmailSentAt) : 'n/a'
        }`}
        onSubmit={async () => {
          try {
            const res = await sendNewsletter({});
            if (res.error) {
              throw res.error;
            }
            toast.success('Diesel toeslag nieuwsbrief verzonden!');
          } catch (err: any) {
            toast.error('Kon diesel toeslag nieuwsbrief niet versturen: ' + getDisplayError(err));
            throw err;
          }
        }}
      />
    </div>
  );
};
