import { PaperPlaneTilt } from '@phosphor-icons/react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Button } from '../../../../components/button/Button';
import { useCustomer } from '../Customer/customerContext';
import { InputField } from '../../../../components/input/InputField';
import { getDisplayError } from '../../../../utils/get-display-error';
import { useInviteCustomerUserMutation } from '../../../../generated/graphql';

const inviteCustomerUserSchema = Yup.object().shape({
  email: Yup.string().email().required('Vereist'),
});

export const InviteCustomerUserPage = () => {
  const navigate = useNavigate();
  const { customer } = useCustomer();
  const [, inviteCustomerUser] = useInviteCustomerUserMutation();

  return (
    <div>
      <h2 className="heading-two mb-4">Nodig gebruiker uit</h2>

      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={inviteCustomerUserSchema}
        onSubmit={async (values) => {
          try {
            const result = await inviteCustomerUser({
              email: values.email,
              customerId: customer.id,
            });
            if (result.error) {
              throw result.error;
            }
            toast.success('Gebruiker uitgenodigd');
            navigate('..');
          } catch (err: any) {
            toast.error('Kon gebruiker niet uitnodigen: ' + getDisplayError(err));
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <InputField labelText="Email" type="email" name="email" />

            <Button
              type="submit"
              color="primary"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              iconLeft={<PaperPlaneTilt className="button-icon" />}
            >
              Nodig gebruiker uit
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};
