import { Plus } from '@phosphor-icons/react';
import { Formik } from 'formik';
import { useCreateSupplierTruckMutation } from 'generated/graphql';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import dayjs from 'dayjs';

import { CheckboxField } from '../../../../components/checkbox/CheckboxField';
import { TextAreaField } from '../../../../components/textarea/TextAreaField';
import { getDisplayError } from '../../../../utils/get-display-error';
import { sleep } from '../../../../utils/sleep';
import { useSupplier } from '../Supplier/supplierContext';
import { formatInputDate } from '../../../../utils/date';
import { Button } from 'components/button/Button';
import { InputField } from 'components/input/InputField';
import { UserComboboxField, InternalUser } from '../../../user/components/UserCombobox';
import { nullthrows } from '../../../../utils/invariant';

const createTruckSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Minimum 1 karakter').required('Vereist'),
  licensePlate: Yup.string().required('Vereist'),
  isActive: Yup.boolean(),
  insuranceExpiresAt: Yup.string().required('Vereist'),
  examinationExpiresAt: Yup.string().required('Vereist'),
  internalNotes: Yup.string(),
});

export const CreateSupplierTruckPage = () => {
  const navigate = useNavigate();
  const { supplier, refreshData: refetchSupplier } = useSupplier();
  const [, createTruckMutation] = useCreateSupplierTruckMutation();

  return (
    <div>
      <h1 className="heading-one mb-4">Nieuwe trekker</h1>

      <Formik
        initialValues={{
          name: '',
          licensePlate: '',
          insuranceExpiresAt: formatInputDate(dayjs().add(1, 'year').toDate()),
          examinationExpiresAt: formatInputDate(dayjs().add(1, 'year').toDate()),
          isActive: false,
          internalNotes: '',
          planner: null as null | InternalUser,
        }}
        validationSchema={createTruckSchema}
        onSubmit={async (values) => {
          try {
            const { planner: _planner, ...rest } = values;

            const planner = nullthrows(_planner, 'Geen planner geselecteerd');

            const result = await createTruckMutation({
              data: {
                ...rest,
                supplierId: supplier.id,
                plannerId: planner.id,
              },
            });
            if (result.error) {
              throw result.error;
            }
            if (result.data) {
              refetchSupplier();
              await sleep(100);
              navigate('..');
            }
            toast.success('Trekker aangemaakt');
          } catch (err: any) {
            toast.error('Kon trekker niet aanmaken: ' + getDisplayError(err));
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <InputField labelText="Codenaam" type="text" name="name" />
            <InputField labelText="Nummerplaat" type="text" name="licensePlate" />
            <InputField labelText="Verzekering vervaldag" type="date" name="insuranceExpiresAt" />
            <InputField labelText="Keuring vervaldag" type="date" name="examinationExpiresAt" />
            <TextAreaField labelText="Interne notities" name="internalNotes" spellCheck={true} />
            <CheckboxField labelText="Rijdt actief voor ons" name="isActive" />
            <UserComboboxField labelText="Verantwoordelijke planner" name="planner" />
            <Button
              type="submit"
              color="primary"
              iconLeft={<Plus className="button-icon" />}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Maak trekker aan
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};
