import { CaretLeft } from '@phosphor-icons/react';
import { useTranslation } from '../../contexts/translation-context';
import { Button } from '../button/Button';
import { CaretRight } from '@phosphor-icons/react/dist/ssr';

export interface IPaginationProps {
  hasPrevious: boolean;
  previous: () => void;
  hasNext: boolean;
  next: () => void;
  isFetching: boolean;
}

export const Pagination: React.FC<IPaginationProps> = (props) => {
  const { hasPrevious, previous, hasNext, next, isFetching } = props;
  const { i18n } = useTranslation();

  return (
    <div className="flex justify-between">
      <div>
        <Button
          iconLeft={<CaretLeft className="button-icon" />}
          isDisabled={isFetching || !hasPrevious}
          onTrigger={previous}
          isLoading={isFetching}
          color="subtle"
        >
          {i18n('previous')}
        </Button>
      </div>
      <div>
        <Button
          iconRight={<CaretRight className="button-icon" />}
          isDisabled={isFetching || !hasNext}
          onTrigger={next}
          isLoading={isFetching}
          color="subtle"
        >
          {i18n('next')}
        </Button>
      </div>
    </div>
  );
};
