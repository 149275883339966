import createContext from 'contexts/create-context';
import * as React from 'react';

import {
  MinimalGeneralSettingsQuery,
  useMinimalGeneralSettingsQuery,
  usePendingIncomingDocumentsCountQuery,
} from '../generated/graphql';
import { nullthrows } from '../utils/invariant';

export type MinimalGeneralSettings = NonNullable<MinimalGeneralSettingsQuery['generalSettings']>;

interface ProviderValue {
  settings: MinimalGeneralSettings;
  refreshData: () => void;
  incomingDocCount: number;
  refreshIncomingDocCount: () => void;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface IMinimalGeneralSettingsProviderProps {
  children?: React.ReactNode;
}

export const MinimalGeneralSettingsProvider: React.FC<IMinimalGeneralSettingsProviderProps> = (props) => {
  const { children } = props;
  const [data, refreshData] = useMinimalGeneralSettingsQuery();
  const [incomingDocCount, refreshIncomingDocCount] = usePendingIncomingDocumentsCountQuery();

  React.useEffect(() => {
    // Refresh incoming doc count every 30 seconds
    const intervalRef = setInterval(() => {
      refreshIncomingDocCount({
        requestPolicy: 'network-only',
      });
    }, 30_000);
    return () => {
      clearInterval(intervalRef);
    };
  }, [true]);

  return (
    <ReactProvider
      value={{
        settings: nullthrows(data.data?.generalSettings, 'General settings not found'),
        refreshData: () => {
          refreshData({
            requestPolicy: 'network-only',
          });
        },
        incomingDocCount: incomingDocCount.data?.pendingIncomingDocumentsCount ?? 0,
        refreshIncomingDocCount: () => {
          refreshIncomingDocCount({
            requestPolicy: 'network-only',
          });
        },
      }}
    >
      {children}
    </ReactProvider>
  );
};

export const useMinimalGeneralSettings = useContext;
export const MinimalGeneralSettingsConsumer = ReactConsumer;
