// Never import any server-only code here, this is also used on the client side...

// Dutch version of the translation file
export const translations = {
  emails: {
    footer: {
      kindRegards: 'Met vriendelijke groeten,',
      questionsOrProblems: 'Vragen of problemen?',
      contactUs: 'Contacteer ons',
    },
    transportOrder: {
      confirm: 'Bevestig',
      refuse: 'Weiger',
    },
    forgotPassword: {
      resetLink: 'Stel nieuw wachtwoord in',
    },
    userInvite: {
      view: 'Bekijk uitnodiging',
    },
    attachments: {
      quotation: 'Offerte {{reference}}',
    },
    customerComplaintAlert: {
      order: 'Bekijk order',
    },
  },
  trailerTypes: {
    BoxTrailer: 'Box Trailer',
    FlatBed: 'Flat bed',
    Mega: 'Mega oplegger',
    Refrigerated: 'Koelwagen',
    RoadTrain: 'Road Train',
    Tautliner: 'Tautliner',
    Joloda: 'Tautliner / Joloda',
  },
  truckDocumentType: {
    Insurance: 'Verzekering',
    Examination: 'Keuring',
  },
  documents: {
    note: {
      extraInformation: 'Extra informatie',
    },
    letter: {
      letter: 'Brief',
    },
    paymentReminder: {
      invoiceNumber: 'Factuur nummer',
      invoiceDate: 'Factuur datum',
      dueDate: 'Vervaldatum',
      amount: 'Bedrag',
      expiredAmount: 'Vervallen bedrag',
      currency: 'Munteenheid',
    },
    transportOrder: {
      trailerTypes: 'Trailer Types',
      transportOrder: 'Transport Opdracht',
      date: 'Datum',
      orderRef: 'Order Referentie',
      load: 'Laden',
      unload: 'Lossen',
      reference: 'Referentie',
      goods: 'Goederen',
      description: 'Omschrijving',
      palletCount: 'Aantal pallets',
      packageType: 'Pallettype',
      weight: 'Gewicht',
      LoadingMeter: 'Laadmeter',
      freightRate: 'Vrachttarief',
      price: 'Prijs',
      vat: 'BTW',
      questionsOrComments: 'Vragen of opmerkingen?',
      contactFileManager: 'Neem gerust contact op met uw dossierverantwoordelijke',
      anonymousCmr: 'ANONIEME CMR MOET AANGEMAAKT WORDEN VOOR DEZE ORDER.',
      phone: 'Tel.',
      email: 'E-mail',
    },
    planning: {
      planning: 'Planning',
      licensePlate: 'Nummerplaat',
      codeName: 'Codenaam',
    },
    orderConfirmation: {
      orderConfirmation: 'Bestelbevestiging',
      dearCustomer: 'Beste klant,',
      herebyWeConfirmYourOrder: 'Bij deze bevestigen wij uw bestelling van onderstaand transport:',
      date: 'Datum',
      ourReference: 'Onze referentie',
      yourReference: 'Uw referentie',
      load: 'Laden',
      unload: 'Lossen',
      reference: 'Referentie',
      goods: 'Goederen',
      description: 'Omschrijving',
      packageType: 'Pallettype',
      palletCount: 'Pallets',
      weight: 'Gewicht',
      LoadingMeter: 'Laadmeter',
      freightRate: 'Vrachttarief',
      price: 'Prijs',
      vat: 'BTW',
      questionsOrComments: 'Vragen of opmerkingen?',
      contactFileManager: 'Neem gerust contact op met uw dossierverantwoordelijke',
      phone: 'Tel.',
      email: 'E-mail',
    },
    orderCancellation: {
      orderCancellation: 'Bestelanulatie',
      dearCustomer: 'Beste klant,',
      orderCancelledText: 'Uw bestelling van onderstaand transport is bij deze geannuleerd:',
      date: 'Datum',
      ourReference: 'Onze referentie',
      yourReference: 'Uw referentie',
      load: 'Laden',
      unload: 'Lossen',
      reference: 'Referentie',
      goods: 'Goederen',
      description: 'Omschrijving',
      packageType: 'Pallettype',
      palletCount: 'Pallets',
      weight: 'Gewicht',
      LoadingMeter: 'Laadmeter',
      freightRate: 'Vrachttarief',
      price: 'Prijs',
      vat: 'BTW',
      questionsOrComments: 'Vragen of opmerkingen?',
      contactFileManager: 'Neem gerust contact op met uw dossierverantwoordelijke',
      phone: 'Tel.',
      email: 'E-mail',
    },
    invoice: {
      invoice: 'Factuur',
      customerNumber: 'Klantnummer',
      invoiceNumber: 'Factuurnummer',
      invoiceDate: 'Factuurdatum',
      dueDate: 'Vervaldatum',
      load: 'Laden',
      unload: 'Lossen',
      ourReference: 'Onze referentie',
      yourReference: 'Uw referentie',
      description: 'Omschrijving',
      quantity: 'Aantal',
      unitPrice: 'Eenheidsprijs',
      total: 'Totaal',
      vat: 'BTW',
      paymentInformation: 'Betaalinformatie',
      amountToPay: 'Te betalen bedrag',
      toBePaidBefore: 'Te betalen voor',
      beneficiary: 'Begunstigde',
      message: 'Mededeling',
      generalTermsAndConditions: 'Algemene verkoopsvoorwaarden in bijlage',
      questionsOrComments: 'Voor vragen contacteer de boekhouding op {{email}}',
    },
    quotation: {
      quotation: 'Offerte',
      customerNumber: 'Klantnummer',
      reference: 'Referentie',
      date: 'Offertedatum',
      dueDate: 'Vervaldatum',
      from: 'Van',
      to: 'Naar',
      volume: 'Volume',
      price: 'Prijs',
      generalTermsAndConditions: 'Algemene verkoopsvoorwaarden in bijlage',
      questionsOrComments: 'Voor vragen contacteer ons op {{email}}',
      trailerTypes: 'Trailer types',
    },
    sustainabilityReport: {
      sustainabilityReport: 'Duurzaamheidsrapport',
      date: 'Datum',
      ourReference: 'Onze referentie',
      yourReference: 'Uw referentie',
      truck: 'Vrachtwagen',
      distance: 'Afstand',
      weight: 'Gewicht',
      emissions: 'Emissies',
      questionsOrComments: 'Vragen of opmerkingen?',
      contactFileManager: 'Neem gerust contact op met uw dossierverantwoordelijke',
      phone: 'Tel.',
      email: 'E-mail',
    },
    selfBill: {
      title: 'Selfbilling factuur',
      cashDiscount: 'Korting voor contant 2,5%',
      taxableAmount: 'Maatstaf van heffing',
      totalWithDiscount: 'Totaal met korting-contant',
      totalWithoutDiscount: 'Totaal zonder korting-contant',
      totalTitleWithDiscount: 'Contante betaling op 7 dagen (2,5%)',
      totalTitleWithoutDiscount: 'Betaling op 60 dagen',
      or: 'OF',
    },
    creditNote: {
      creditNote: 'Creditnota',
      customerNumber: 'Klantnummer',
      creditNoteNumber: 'Creditnota nummer',
      date: 'Datum',
      dueDate: 'Vervaldatum',
      load: 'Laden',
      unload: 'Lossen',
      ourReference: 'Onze referentie',
      yourReference: 'Uw referentie',
      description: 'Omschrijving',
      quantity: 'Aantal',
      unitPrice: 'Eenheidsprijs',
      total: 'Totaal',
      vat: 'BTW',
      paymentInformation: 'Betaalinformatie',
      amountToPay: 'Te crediteren bedrag',
      toBePaidBefore: 'Wordt gecrediteerd voor',
      payer: 'Opdrachtgever',
      message: 'Mededeling',
      generalTermsAndConditions: 'Algemene verkoopsvoorwaarden in bijlage',
      questionsOrComments: 'Voor vragen contacteer de boekhouding op {{email}}',
    },
    totals: {
      totalExclVat: 'Totaal excl. BTW',
      totalVat: 'Totaal BTW',
      totalInclVat: 'Totaal incl. BTW',
      vatReverseCharged: 'BTW verlegd, artikel 44 BTW richtlijn',
    },
  },
  pages: {
    confirmOrderStates: {
      headingRefused: 'Order is geweigerd',
      headingCancelled: 'Order is geannuleerd',
      headingConfirmed: 'Order is bevestigd',
      headingUnknownStatus: 'Order is al uitgevoerd of status onbekend',
    },
    confirmOrderPlanningStates: {
      headingRefused: 'Planning is geweigerd',
      headingCancelled: 'Planning is geannuleerd',
      headingConfirmed: 'Planning is bevestigd',
      headingUnknownStatus: 'Planning is al uitgevoerd of status onbekend',
    },
    confirmOrder: {
      heading: 'Bevestig transport order {{order.orderNumber}}',
      orderConfirmed: 'Order bevestigd',
      orderConfirmationError: 'Kon order niet bevestigen: {{error}}',
      confirmButton: 'Bevestig transport order',
    },
    confirmPlanning: {
      heading: 'Bevestig planning {{planningDate}}',
      planningConfirmed: 'Planning bevestigd',
      confirmButton: `Bevestig planning`,
    },
    refuseOrder: {
      heading: 'Weiger transport order {{order.orderNumber}}',
      orderRefused: 'Order geweigerd',
      orderRefusalError: 'Kon order niet weigeren: {{error}}',
      refuseButton: 'Weiger transport order',
    },
    refusePlanning: {
      heading: 'Weiger planning {{planningDate}}',
      planningRefused: 'Planning geweigerd',
      refuseButton: 'Weiger planning',
    },
    orderSummary: {
      loadAndUnloadInformation: 'Laad- en los informatie',
      load: 'Laden',
      unload: 'Lossen',
      freightRate: 'Vrachttarief',
      total: 'Totaal',
    },
  },
  customerPortal: {
    requestAccount: {
      title: 'Vraag een account aan',
      success: 'Aanvraag is verzonden, we zullen u contacteren voor verdere stappen.',
      failed: 'Kon account niet aanvragen: {{error}}',
      name: 'Naam',
      email: 'Email',
      companyName: 'Bedrijfsnaam',
      companyNumber: 'Ondernemingsnummer',
      request: 'Vraag aan',
      dontHaveAnAccount: 'Heeft u nog geen account?',
    },
    invite: {
      title: 'Uitgenodigd tot klantenportaal',
      details:
        'Je bent uitgenodigd voor het klantportaal van TransDirect, maak je account aan door je wachtwoord in te voeren.',
      accepted: 'Uitnodiging is geaccepteerd, je wordt zo doorgestuurd naar de homepagina.',
      acceptError: 'Kon uitnodiging niet accepteren: {{error}}',
      acceptButtonText: 'Uitnodiging accepteren',
      password: 'Wachtwoord',
      repeatPassword: 'Herhaal wachtwoord',
    },
    home: {
      customerPortal: 'Klantenportaal',
      title: 'Klantenportaal',
      hello: 'Hallo,',
      welcome: 'Welkom bij het klantenportaal.',
      manageOrders: 'Beheer al je transport opdrachten.',
      viewInvoices: 'Bekijk en betaal facturen eenvoudig.',
      createQuotes: 'Maak en volg offertes.',
      viewLocations: 'Bekijk alle transport locaties.',
    },
    locations: {
      locations: 'Locaties',
      name: 'Naam',
      street: 'Straat',
      number: 'Nr',
      countryCode: 'Landcode',
      postalCode: 'Postcode',
      city: 'Gemeente',
      country: 'Land',
      language: 'Taal',
      notes: 'Notities',
      createLocation: 'Maak locatie aan',
      newLocation: 'Nieuwe locatie',
      locationCreated: 'Locatie aangemaakt',
      couldNotCreateLocation: 'Kon locatie niet aanmaken: {{error}}',
      findLocation: 'Zoek een locatie...',
      address: 'Adres',
      locationNotFound: 'Locatie niet gevonden',
      couldNotLoadLocation: 'Kon locatie niet laden: {{error}}',
    },
    order: {
      duplicate: 'Dupliceer',
      overview: 'Overzicht',
    },
    orders: {
      orders: 'Orders',
      placeOrder: 'Plaats een order',
      invoiceRef: 'Factuur referentie',
      orderRef: 'Order referentie',
      loadLocation: 'Laadplaats',
      unloadLocation: 'Losplaats',
      loadDate: 'Laaddatum',
      loadTime: 'Laadtijd',
      unloadDate: 'Losdatum',
      unloadTime: 'Lostijd',
      invoiceTotal: 'Facturatietotaal',
      status: 'Status',
      invoiceStatus: 'Facturatiestatus',
      hasCMR: 'Heeft CMR',
      creationDate: 'Ingave datum',
      executionDate: 'Uitvoeringsdatum',
      fileManager: 'Dossier beheerder',
      cancellationReason: 'Annuleringsreden',
      stops: 'Stops',
      deleteStop: 'Verwijder stop',
      purchases: 'Aankopen',
      total: 'Totaal',
      trailerTypes: 'Trailer types',
      extraNotes: 'Extra notities',
      noContent: 'Geen inhoud',
      packaging: 'Verpakking',
      coli: 'Coli',
      content: 'Inhoud',
      addContent: 'Voeg inhoud toe',
      deleteContent: 'Verwijder inhoud',
      noStops: 'Geen stops',
      noStopsError: 'Voeg minstens een laad en los stop toe',
      reference: 'Referentie',
      complaints: 'Klachten',
      fileComplaint: 'Klacht rapporteren',
      fileComplaintDescription: 'Vul dit formulier in om een klacht te rapporteren',
      send: 'Verzend',
      reason: 'Reden',
      details: 'Details',
      complaintFiled: 'Klacht verzonden',
      couldNotFileComplaint: 'Kon klacht niet verzenden: {{error}}',
      placeAnOrder: 'Plaats een order',
      assignment: 'Opdracht',
      addStop: 'Voeg stop toe',
      location: 'Locatie',
      date: 'Datum',
      start: 'Start',
      stop: 'Stop',
      weight: 'Gewicht (kg)',
      volume: 'Volume (m3)',
      loadMeter: 'Laadmeter',
      stopsRequired: 'Minstens 1 stop is vereist',
      contentRequired: 'Minstens 1 inhoudsitem is vereist',
      truck: 'Truck',
      distance: 'Afstand',
      emissions: 'Emissies',
      sustainability: 'Duurzaamheid',
    },
    quotes: {
      quotes: 'Offertes',
      quote: 'Offerte',
      quoteReference: 'Offerte referentie',
      quoteDate: 'Offerte datum',
      expirationDate: 'Vervaldatum',
      total: 'Totaal',
      search: 'Zoek een offerte...',
      quotation: 'Offerte {{reference}}',
      notFound: 'Offerte niet gevonden',
      departurePlace: 'Vertrekplaats',
      destination: 'Bestemming',
      distance: 'Afstand',
      price: 'Prijs',
      type: 'Offerte Type',
      requests: 'Offerte aanvragen',
      request: 'Vraag offerte aan',
      notes: 'Notities',
      lines: 'Offerte lijnen',
      addLine: 'Voeg lijn toe',
      noLines: 'Geen offerte lijnen',
      requestTitle: 'Offerte aanvraag {{reference}}',
      requestSuccess: 'Offerte aangevraagd',
      requestFailed: 'Kon offerte niet aanvragen: {{error}}',
      quotationRequests: 'Offerte aanvragen',
      requestedAt: 'Aangevraagd op',
      hasQuote: 'Heeft offerte',
      generalInformation: 'Algemene informatie',
      removeLine: 'Verwijder lijn',
    },
    quoteLine: {
      from: 'Van',
      to: 'Naar',
    },
    invoices: {
      invoices: 'Facturen',
      invoiceNumber: 'Factuurnummer',
      invoiceDate: 'Factuurdatum',
      dueDate: 'Vervaldatum',
      total: 'Totaal',
      search: 'Zoek een factuur...',
      notFound: 'Factuur niet gevonden',
      invoice: 'Factuur {{invoiceNumber}}',
      structuredComment: 'Gestructureerde mededeling',
      reference: 'Referentie',
      sentAt: 'Verzonden op',
      load: 'Laden',
      unload: 'Lossen',
      description: 'Omschrijving',
      amount: 'Aantal',
      unitPrice: 'Eenheidsprijs',
      vat: 'BTW',
      extraInformation: 'Extra informatie: {{extraInformation}}',
      orderTotal: 'Order totaal: €{{total}}',
      totalExclVat: 'Totaal excl btw:',
      totalVat: 'Totaal btw:',
      totalInclVat: 'Totaal incl btw:',
      generalInformation: 'Algemene informatie',
    },
    businessInformation: {
      businessInformation: 'Bedrijfsgegevens',
      updateBusinessInformation: 'Werk bedrijfsgegevens bij',
      name: 'Naam',
      vatNumber: 'BTW-nummer',
      businessNumber: 'Ondernemingsnummer',
      saveChanges: 'Wijzigingen opslaan',
      informationChanged: 'Informatie is bijgewerkt',
      informationNotChanged: 'Kon informatie niet bijwerken: {{error}}',
      requestChanges: 'Om bedrijfsnummer, btw nummer of naam van bedrijf aan te passen, gelieve ons te contacteren',
    },
    contacts: {
      contacts: 'Contacten',
      name: 'Naam',
      email: 'Email',
      phone: 'Telefoon',
      function: 'Functie',
      language: 'Taal',
      notifications: 'Contact geschikt voor',
      newContact: 'Nieuw contact',
      contactCreated: 'Contact aangemaakt',
      couldNotCreateContact: 'Kon contact niet aanmaken: {{error}}',
      createContact: 'Maak contact aan',
      notFound: 'Contact niet gevonden',
      updatedContact: 'Contact bijgewerkt',
      couldNotUpdateContact: 'Kon contact niet bijwerken: {{error}}',
      updateContact: 'Werk contact bij',
      delete: 'Verwijder',
      deleteContact: 'Verwijder contact',
      deleteContactConfirmation: 'Ben je zeker dat je contact {{name}} wilt wissen?',
      deleteContactError: 'Kon contact niet verwijderen: {{error}}',
      deleteContactSuccess: 'Contact verwijderd',
    },
    users: {
      users: 'Gebruikers',
      inviteUser: 'Nodig gebruiker uit',
      invitedUsers: 'Uitgenodigde gebruikers',
      noInvitedUsers: 'Geen uitgenodigde gebruikers',
      removeInvite: 'Verwijder uitnodiging',
      confirmRemoveInvite: 'Ben je zeker dat je de uitnodiging voor {{email}} wilt verwijderen?',
      inviteRemoved: 'Uitnodiging verwijderd',
      couldNotRemoveInvite: 'Kon uitnodiging niet verwijderen: {{error}}',
      noUsersFound: 'Geen gebruikers gevonden',
      removeUser: 'Verwijder gebruiker',
      confirmRemoveUser: 'Ben je zeker dat je de gebruiker {{name}} wilt verwijderen?',
      userRemoved: 'Gebruiker verwijderd',
      couldNotRemoveUser: 'Kon gebruiker niet verwijderen: {{error}}',
      active: 'Actief',
      inactive: 'Inactief',
      userInvited: 'Gebruiker uitgenodigd',
      couldNotInviteUser: 'Kon gebruiker niet uitnodigen: {{error}}',
      name: 'Naam',
      email: 'E-Mail',
      isActive: 'Is actief?',
    },
    myProfile: {
      myProfile: 'Mijn profiel',
      logout: 'Log uit',
      name: 'Naam',
      role: 'Rol',
      email: 'Email',
      language: 'Taal',
      languageChanged: 'Taal is bijgewerkt',
      languageNotChanged: 'Kon taal niet bijwerken: {{error}}',
      joinedAt: 'Lid geworden op',
      nameChanged: 'Naam is bijgewerkt',
      nameNotChanged: 'Kon naam niet bijwerken: {{error}}',
    },
    cmrReminder: {
      cmrReminder: 'CMR Herinnering',
      sendReminder: 'Verzend',
      confirmText: 'Ben je zeker dat je een CMR herinnering wilt versturen?',
      confirmation: 'CMR Herinnering verstuurd',
      error: 'Kon cmr herinnering niet versturen: {{error}}',
    },
    loading: 'Laden...',
  },
  orderStatus: {
    Draft: 'In Opmaak',
    Created: 'Aangemaakt',
    Placed: 'Geplaatst',
    Confirmed: 'Bevestigd',
    Refused: 'Geweigerd',
    Executed: 'Uitgevoerd',
    Cancelled: 'Geannuleerd',
  },
  orderSaleStatus: {
    Created: 'Aangemaakt',
    Credited: 'Gecrediteerd',
    Incomplete: 'Onvolledig',
    Invoiced: 'Gefactureerd',
  },
  customerContactNotifications: {
    invoice: 'Facturatie',
    orderConfirmation: 'Orderbevestiging',
    cmr: 'CMR',
    quotation: 'Offerte',
    dieselSurcharge: 'Diesel toeslag',
  },
  errors: {
    required: 'Vereist',
    invalidEmail: 'Ongeldig e-mailadres',
    dateShouldBeInTheFuture: 'Datum moet in de toekomst liggen',
    startTimeShouldBeBeforeEndTime: 'Starttijd moet voor eindtijd liggen',
    passwordsDontMatch: 'Wachtwoord en herhaal wachtwoord zijn niet gelijk.',
    passwordTooShort: 'Wachtwoord is te kort.',
  },
  orderComplaintType: {
    Aggression: 'Fysieke/verbale agressie',
    DamagedGoods: 'Beschadigde goederen',
    IncorrectDelivery: 'Foutieve levering',
    IncorrectInvoice: 'Foutieve factuur',
    LateDelivery: 'Laattijdige levering',
    Other: 'Andere',
  },
  auth: {
    forgotPassword: {
      title: 'Wachtwoord vergeten',
      requestNewPassword: 'Vraag nieuw wachtwoord aan',
      instructions:
        'Vul uw email in, als de gebruiker in het systeem bestaat zult u spoedig een email ontvangen om een nieuw wachtwoord in te stellen.',
      requestFailed: 'Kon geen nieuw wachtwoord aanvragen',
      instructionsSent: 'Instructies om een nieuw wachtwoord in te stellen zijn verzonden naar uw email adres.',
    },
    configNewPassword: {
      title: 'Stel nieuw wachtwoord in',
      newPassword: 'Nieuw wachtwoord',
      repeatNewPassword: 'Herhaal nieuw wachtwoord',
      confirmButtonText: 'Stel nieuw wachtwoord in',
      passwordDontMatch: 'Wachtwoord en herhaal wachtwoord moeten gelijk zijn',
      tokenNotFound: 'Token niet gevonden',
      invalidToken: 'Token is niet meer geldig',
      configFailed: 'Kon wachtwoord niet instellen: {{error}}',
    },
    login: {
      login: 'Login',
      email: 'Email',
      password: 'Wachtwoord',
      forgotPassword: 'Wachtwoord vergeten',
      customerLoginTitle: 'Klanten login',
      loginWithGoogle: 'Login met Google',
      incorrectCredentials: 'Email of wachtwoord is incorrect',
      loginFailed: 'Kon niet inloggen: {{error}}',
    },
    userNotFound: {
      title: 'Gebruiker niet gevonden',
      description: 'Kon niet inloggen er is een error gebeurd of gebruiker bestaat niet meer.',
      goToLoginPage: 'Ga naar login pagina',
    },
  },
  cancel: 'Annuleer',
  close: 'Sluit',
  moreInfo: 'Meer info',
  previous: 'Vorige',
  next: 'Volgende',
  delete: 'Verwijder',
  or: 'of',
};
