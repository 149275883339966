import { DownloadSimple, Plus, X } from '@phosphor-icons/react';
import * as Dialog from '@radix-ui/react-dialog';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

import { BaseButton } from '../../../components/button/BaseButton';
import { Button } from '../../../components/button/Button';
import { useDownloadDocument } from '../hooks/useDownloadDocument';
import UploadForm from './UploadForm';
import { getDisplayError } from '../../../utils/get-display-error';
import type { Document } from '../types';
import { ConfirmDialog } from '../../../components/dialog/ConfirmDialog';

interface IMinimalFile {
  id: string;
  name: string;
}

interface IFileUploaderButtonProps {
  buttonText: string;
  title: string;
  file?: IMinimalFile | null;
  initialName?: string;
  disableUpload?: boolean;
  generateDocument?: () => Promise<void>;
  unlinkDocument?: () => Promise<void>;
  onSubmit?: (document: Document) => Promise<void>;
}

export const FileUploaderButton: React.FC<IFileUploaderButtonProps> = (props) => {
  const { buttonText, title, file, disableUpload, generateDocument, initialName, onSubmit, unlinkDocument } = props;
  const [open, setOpen] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const { downloadFile, downloadingFiles } = useDownloadDocument();

  const handleSubmit = async (document: Document) => {
    if (onSubmit) {
      try {
        await onSubmit(document);
        setOpen(false);
        toast.success('Bestand opgeladen');
      } catch (err) {
        console.error(err);
        toast.error('Kon bestand niet opladen: ' + getDisplayError(err));
      }
    }
  };

  const isLoading = Boolean(file && disableUpload && downloadingFiles.includes(file.id)) || isGenerating;
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild disabled={isLoading}>
        <BaseButton
          color={file ? 'primary' : 'default-outline'}
          iconLeft={
            file || disableUpload ? <DownloadSimple className="button-icon" /> : <Plus className="button-icon" />
          }
          isLoading={isLoading}
        >
          {buttonText}
        </BaseButton>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay className="dialog-overlay" />
        <Dialog.Content className="dialog-content">
          <Dialog.Title className="heading-one mb-4">{title}</Dialog.Title>

          <div>
            {!!file && (
              <div className="flex gap-2 mb-6">
                <Button
                  color="default"
                  onTrigger={() => downloadFile(file)}
                  isLoading={downloadingFiles.includes(file.id)}
                  iconLeft={<DownloadSimple className="button-icon" />}
                  width="w-full"
                >
                  <div>{file.name}</div>
                </Button>
                {unlinkDocument && (
                  <ConfirmDialog
                    triggerText={<X className="button-icon" />}
                    title="Ben je zeker dat je dit document wilt verwijderen?"
                    submitText="Verwijder document"
                    description="Ben je zeker dat je dit document wilt verwijderen?"
                    onSubmit={async () => {
                      await unlinkDocument();
                    }}
                  />
                )}
              </div>
            )}
          </div>

          {!file && disableUpload && <div>Document nog niet aangemaakt</div>}

          {generateDocument && (
            <Button
              onTrigger={async () => {
                setIsGenerating(true);
                try {
                  await generateDocument();
                  toast.success('Document gegenereerd');
                } catch (err) {
                  console.error(err);
                  toast.error('Kon document niet genereren: ' + getDisplayError(err));
                }
                setIsGenerating(false);
              }}
              color={!file ? 'primary' : 'default'}
              isLoading={isLoading}
            >
              Genereer document
            </Button>
          )}

          {disableUpload ? (
            <div className="flex justify-end">
              <Dialog.Close asChild disabled={isLoading}>
                <BaseButton isLoading={isLoading}>Sluit</BaseButton>
              </Dialog.Close>
            </div>
          ) : (
            <div>
              <h2 className="heading-two mb-2">Upload</h2>

              <UploadForm
                onSubmit={handleSubmit}
                initialName={initialName}
                onCancel={() => {
                  setOpen(false);
                }}
              />
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
