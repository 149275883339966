import { CheckboxWithLabel } from '../checkbox/CheckboxWithLabel';

export interface IInlineSelectItem {
  key: string;
  name: string;
}

export interface IInlineSelectProps {
  items: IInlineSelectItem[];
  selectedItems: IInlineSelectItem[];
  onSelect: (selectedItems: IInlineSelectItem[]) => void;
  isDisabled?: boolean;
  isInvalid?: boolean;
  isOptional?: boolean;
  onBlur?: () => void;
}

export const InlineSelect: React.FC<IInlineSelectProps> = (props) => {
  const { items, selectedItems, onSelect, isDisabled, isInvalid, isOptional } = props;

  return (
    <div>
      {items.map((item) => {
        return (
          <CheckboxWithLabel
            key={item.key}
            labelText={item.name}
            isDisabled={isDisabled}
            isChecked={!!selectedItems.find((v) => v.key === item.key)}
            onChange={(isChecked) => {
              const hasValue = selectedItems.find((v) => v.key === item.key);
              if (isChecked && !hasValue) {
                onSelect([...selectedItems, item]);
              } else if (!isChecked) {
                onSelect(selectedItems.filter((v) => v.key !== item.key));
              }
            }}
          />
        );
      })}
    </div>
  );
};
