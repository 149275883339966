import { StatusColor } from '../../../components/StatusBadge';
import { CreditNoteStatus } from 'generated/graphql';

export interface IMinimalCreditNote {
  paidAt?: Date | string | null;
  sentAt?: Date | string | null;
}

export const CreditNoteStatusToText: Record<CreditNoteStatus, string> = {
  [CreditNoteStatus.Draft]: 'Opmaak',
  [CreditNoteStatus.Created]: 'Aangemaakt',
  [CreditNoteStatus.Sent]: 'Verstuurd',
};

export const CreditNoteStatusToColor: Record<CreditNoteStatus, StatusColor> = {
  [CreditNoteStatus.Draft]: 'black',
  [CreditNoteStatus.Created]: 'black',
  [CreditNoteStatus.Sent]: 'green',
};
