import { Plus } from '@phosphor-icons/react';
import { Formik } from 'formik';
import diff from 'object-diff';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { COUNTRY_VALUES } from '@utils/address';
import * as Yup from 'yup';

import { Button } from '../../../../components/button/Button';
import { InputField } from '../../../../components/input/InputField';
import { SimpleSelectField } from '../../../../components/select/SimpleSelectField';
import { TextAreaField } from '../../../../components/textarea/TextAreaField';
import { Country, Language, useUpdateSupplierMutation } from '../../../../generated/graphql';
import { getDisplayError } from '../../../../utils/get-display-error';
import { LANGUAGE_VALUES } from '../../../../utils/language';
import { formatVatNumber } from '../../../../utils/vat-number';
import { useSupplier } from './supplierContext';
import { AutocompletePostalcode } from '../../../location/components/AutocompletePostalcode';
import { VatRateComboboxField, vatRateToComboboxItem } from '../../../order/vatRate/VatRateComboboxField';
import { CheckboxField } from '../../../../components/checkbox/CheckboxField';
import { Card } from 'components/Card';

const updateSupplierSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Vereist').required('Vereist'),
  vatNumber: Yup.string().vatNumber().required('Vereist'),
  companyNumber: Yup.string().min(1, 'Vereist').required('Vereist'),
  street: Yup.string().min(1, 'Vereist').required('Vereist'),
  streetNumber: Yup.string(),
  city: Yup.string().min(1, 'Vereist').required('Vereist'),
  postalCode: Yup.string().min(1, 'Vereist').required('Vereist'),
  country: Yup.mixed().nullable().required('Vereist'),
  language: Yup.mixed().nullable().required('Vereist'),
  paymentTerm: Yup.string().required('Vereist'),
  selfBillingEnabled: Yup.bool().required('Vereist'),
  regularCarrier: Yup.bool().required('Vereist'),
  disableCmrReminders: Yup.bool().required('Vereist'),
});

export const UpdateSupplierForm = () => {
  const { supplier } = useSupplier();
  const [, updateSupplierMutation] = useUpdateSupplierMutation();

  const initialValues = useMemo(() => {
    return {
      name: supplier.name,
      vatNumber: formatVatNumber(supplier.vatNumber),
      companyNumber: supplier.companyNumber,
      street: supplier.street,
      streetNumber: supplier.streetNumber,
      city: supplier.city,
      postalCode: supplier.postalCode,
      internalNotes: supplier.internalNotes,
      country: COUNTRY_VALUES.find((v) => v.key === supplier.country)!,
      language: LANGUAGE_VALUES.find((v) => v.key === supplier.language)!,
      paymentTerm: `${supplier.paymentTerm}`,
      truckAmount: `${supplier.truckAmount}`,
      tautlinerAmount: `${supplier.tautlinerAmount}`,
      boxTrailerAmount: `${supplier.boxTrailerAmount}`,
      openTrailerAmount: `${supplier.openTrailerAmount}`,
      defaultVatRate: supplier.defaultVatRate ? vatRateToComboboxItem(supplier.defaultVatRate) : null,
      selfBillingEnabled: supplier.selfBillingEnabled,
      regularCarrier: supplier.regularCarrier,
      disableCmrReminders: supplier.disableCmrReminders,
    };
  }, [supplier]);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={updateSupplierSchema}
        onSubmit={async (newValues) => {
          try {
            const patch: Partial<typeof newValues> = diff(initialValues, newValues);
            const defaultVatRateId = patch.defaultVatRate?.key;
            delete patch.defaultVatRate;
            const updateData = {
              ...patch,
              country: patch.country ? (patch.country.key as Country) : undefined,
              paymentTerm: patch.paymentTerm ? +patch.paymentTerm : undefined,
              language: patch.language ? (patch.language.key as Language) : undefined,
              truckAmount: patch.truckAmount ? +patch.truckAmount : undefined,
              tautlinerAmount: patch.tautlinerAmount ? +patch.tautlinerAmount : undefined,
              boxTrailerAmount: patch.boxTrailerAmount ? +patch.boxTrailerAmount : undefined,
              openTrailerAmount: patch.openTrailerAmount ? +patch.openTrailerAmount : undefined,
              defaultVatRateId,
            };
            if (Object.values(updateData).length) {
              const result = await updateSupplierMutation({
                id: supplier.id,
                data: updateData,
              });
              if (result.error) {
                throw result.error;
              }
            }
            toast.success('Vervoerder aangepast');
          } catch (err: any) {
            toast.error('Kon vervoerder niet aanpassen: ' + getDisplayError(err));
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit} className="grid gap-4">
              <Card title="Bedrijfsgegevens">
                <InputField labelText="Naam" type="text" name="name" />
                <InputField labelText="BTW Nummer" type="text" name="vatNumber" />
                <InputField labelText="Ondernemingsnummer" type="text" name="companyNumber" />
                <div className="flex gap-4">
                  <div style={{ flex: 4 }}>
                    <InputField labelText="Straat" type="text" name="street" />
                  </div>
                  <div style={{ flex: 1 }}>
                    <InputField labelText="Nr" type="text" name="streetNumber" />
                  </div>
                </div>
                <AutocompletePostalcode />
                <SimpleSelectField labelText="Taal" items={LANGUAGE_VALUES} name="language" />
              </Card>

              <Card title="Facturatie">
                <InputField labelText="Betaaltermijn" type="number" name="paymentTerm" />
                <VatRateComboboxField labelText="Standaard BTW Tarief" name="defaultVatRate" />
                <div className="grid grid-cols-2 gap-4">
                  <CheckboxField labelText="Self billing" name="selfBillingEnabled" />
                  <CheckboxField labelText="Vaste vervoerder" name="regularCarrier" />
                  <CheckboxField labelText="CMR herinneringen uitschakelen" name="disableCmrReminders" />
                </div>
              </Card>

              <Card title="Vloot">
                <div className="grid grid-cols-2 gap-4">
                  <InputField labelText="Aantal trekkers" type="number" name="truckAmount" />
                  <InputField labelText="Aantal tautliners" type="number" name="tautlinerAmount" />
                  <InputField labelText="Aantal box/frigo trailers" type="number" name="boxTrailerAmount" />
                  <InputField labelText="Aantal open trailers" type="number" name="openTrailerAmount" />
                </div>
              </Card>

              <Card title="Notities">
                <TextAreaField labelText="Interne notities" name="internalNotes" spellCheck={true} />
              </Card>

              <div>
                <Button
                  type="submit"
                  color="primary"
                  iconLeft={<Plus className="button-icon" />}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Bewaar aanpassingen
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
