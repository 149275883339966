export const HorizontalRule = () => {
  return (
    <div
      className="mx-auto bg-dark-05"
      style={{
        width: '95%',
        height: 1,
      }}
    ></div>
  );
};
