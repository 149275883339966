import { StatusColor } from '../../../components/StatusBadge';
import { InvoiceStatus } from '../../../generated/graphql';

export interface IMinimalInvoice {
  paidAt?: Date | string | null;
  sentAt?: Date | string | null;
}

export const InvoiceStatusToText: Record<InvoiceStatus, string> = {
  [InvoiceStatus.Draft]: 'Ontwerp',
  [InvoiceStatus.Created]: 'Aangemaakt',
  [InvoiceStatus.Sent]: 'Verstuurd',
  [InvoiceStatus.Paid]: 'Betaald',
};

export const InvoiceStatusToColor: Record<InvoiceStatus, StatusColor> = {
  [InvoiceStatus.Draft]: 'black',
  [InvoiceStatus.Created]: 'black',
  [InvoiceStatus.Sent]: 'green',
  [InvoiceStatus.Paid]: 'green',
};
