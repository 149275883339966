import { Button } from 'components/button/Button';
import { InputField } from 'components/input/InputField';
import { Formik } from 'formik';
import { useCargoTypesQuery, useUpdateCargoTypeMutation } from 'generated/graphql';
import diff from 'object-diff';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { getDisplayError } from '../../../utils/get-display-error';
import { nullthrows } from '../../../utils/invariant';

const createCargoTypeSchema = Yup.object().shape({
  id: Yup.string().required('Vereist'),
  nameNl: Yup.string().required('Vereist'),
  nameFr: Yup.string().required('Vereist'),
  nameEn: Yup.string().required('Vereist'),
  nameDe: Yup.string().required('Vereist'),
});

interface ICargoTypeValues {
  id: string;
  nameNl: string;
  nameFr: string;
  nameEn: string;
  nameDe: string;
}

interface IUpdateCargoTypeFormProps {
  id: string;
  onComplete?: (values: ICargoTypeValues) => void;
  onCancel?: () => void;
}

export const UpdateCargoTypeForm: React.FC<IUpdateCargoTypeFormProps> = (props) => {
  const { id, onComplete, onCancel } = props;
  const [{ data }] = useCargoTypesQuery({
    requestPolicy: 'cache-and-network',
  });
  const [, updateCargoTypeMutation] = useUpdateCargoTypeMutation();

  const cargoType = nullthrows(
    data?.cargoTypes.find((v) => v.id === id),
    'Package type not found',
  );
  const initialValues: ICargoTypeValues = useMemo(() => {
    return {
      id: cargoType.id,
      nameNl: cargoType.nameNl,
      nameFr: cargoType.nameFr,
      nameEn: cargoType.nameEn,
      nameDe: cargoType.nameDe,
    };
  }, [cargoType]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createCargoTypeSchema}
      onSubmit={async (values) => {
        try {
          const patch: Partial<typeof values> = diff(initialValues, values);
          const result = await updateCargoTypeMutation({
            id: cargoType.id,
            data: patch,
          });
          if (result.error) {
            throw result.error;
          }
          if (result.data) {
            onComplete?.(values);
          }
          toast.success('Inhoud type aangepast');
        } catch (err: any) {
          toast.error('Kon inhoud type niet aanpassen: ' + getDisplayError(err));
        }
      }}
    >
      {({ handleSubmit, isSubmitting, submitForm }) => {
        return (
          <form onSubmit={handleSubmit}>
            <InputField labelText="Code" type="text" name="id" />
            <InputField labelText="Naam NL" type="text" name="nameNl" />
            <InputField labelText="Naam EN" type="text" name="nameEn" />
            <InputField labelText="Naam FR" type="text" name="nameFr" />
            <InputField labelText="Naam DE" type="text" name="nameDe" />

            <div className="flex justify-between">
              {onCancel && (
                <div>
                  <Button isDisabled={isSubmitting} isLoading={isSubmitting} onTrigger={onCancel}>
                    Annuleer
                  </Button>
                </div>
              )}
              <Button
                type="submit"
                color="primary"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                onTrigger={submitForm}
              >
                Pas inhoudstype aan
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
