import dayjs from 'dayjs';
import { useEffect, useReducer } from 'react';

import { formatGraphQLDate, formatInputDate } from '../../utils/date';
import { Input } from '../input/Input';
import { SimpleSelect } from '../select/SimpleSelect';
import { IDateRange, TimeFrameAction, TimeFrameState, TimeFrameType } from './types';

function calculateDateRange(type: TimeFrameType): IDateRange {
  switch (type) {
    case TimeFrameType.Today: {
      const date = dayjs();
      return {
        startDate: formatGraphQLDate(date),
        endDate: formatGraphQLDate(date),
      };
    }
    case TimeFrameType.Week: {
      const monday = dayjs().subtract(1, 'day').set('day', 1);
      const sunday = monday.add(6, 'day');
      return {
        startDate: formatGraphQLDate(monday),
        endDate: formatGraphQLDate(sunday),
      };
    }
    case TimeFrameType.Month: {
      const firstDayOfMonth = dayjs().set('date', 1);
      const lastDayOfMonth = firstDayOfMonth.add(1, 'month').subtract(1, 'day');
      return {
        startDate: formatGraphQLDate(firstDayOfMonth),
        endDate: formatGraphQLDate(lastDayOfMonth),
      };
    }
    case TimeFrameType.Year: {
      const firstDayOfYear = dayjs().set('date', 1).set('month', 0);
      const lastDayOfYear = firstDayOfYear.add(1, 'year').subtract(1, 'day');
      return {
        startDate: formatGraphQLDate(firstDayOfYear),
        endDate: formatGraphQLDate(lastDayOfYear),
      };
    }
    default: {
      return {
        startDate: formatGraphQLDate(dayjs()),
        endDate: formatGraphQLDate(dayjs()),
      };
    }
  }
}

function timeFrameReducer(state: TimeFrameState, action: TimeFrameAction): TimeFrameState {
  switch (action.action) {
    case 'select:type': {
      let newState: TimeFrameState = { ...state, type: action.newType };
      if (newState.type !== TimeFrameType.Custom) {
        const range = calculateDateRange(action.newType);
        newState = { ...newState, ...range };
      }
      return newState;
    }
    case 'set:start-date': {
      return {
        ...state,
        type: TimeFrameType.Custom,
        startDate: formatGraphQLDate(dayjs(action.value)),
      };
    }
    case 'set:end-date': {
      return {
        ...state,
        type: TimeFrameType.Custom,
        endDate: formatGraphQLDate(dayjs(action.value)),
      };
    }
    default:
      return state;
  }
}

export interface TimeFrameProps {
  onChange: (val: TimeFrameState) => void;
}

const ITEMS = [
  {
    key: TimeFrameType.Today,
    name: 'Vandaag',
  },
  {
    key: TimeFrameType.Week,
    name: 'Deze week',
  },
  {
    key: TimeFrameType.Month,
    name: 'Deze maand',
  },
  {
    key: TimeFrameType.Year,
    name: 'Dit jaar',
  },
  {
    key: TimeFrameType.Custom,
    name: 'Kies een periode',
  },
];

export const TimeFrame: React.FC<TimeFrameProps> = (props) => {
  const { onChange } = props;
  const [state, dispatch] = useReducer(timeFrameReducer, {
    type: TimeFrameType.Today,
    startDate: formatGraphQLDate(new Date()),
    endDate: formatGraphQLDate(new Date()),
  });

  useEffect(() => {
    onChange(state);
  }, [state]);

  const items = [...ITEMS];
  return (
    <div className="flex gap-2">
      <div>
        <label>
          <div className="label-text py-1 w-full">Van</div>
          <div>
            <Input
              type="date"
              value={formatInputDate(state.startDate)}
              onChange={(value) => {
                dispatch({
                  action: 'set:start-date',
                  value,
                });
              }}
            />
          </div>
        </label>
      </div>

      <div>
        <label>
          <div className="label-text py-1 w-full">Tot</div>
          <div>
            <Input
              type="date"
              value={formatInputDate(state.endDate)}
              onChange={(value) => {
                dispatch({
                  action: 'set:end-date',
                  value,
                });
              }}
            />
          </div>
        </label>
      </div>

      <div className="w-64 self-end">
        <SimpleSelect
          items={items}
          selectedItem={items.find((v) => v.key === state.type) ?? null}
          onSelect={(v) => {
            if (v) {
              dispatch({
                action: 'select:type',
                newType: v.key as TimeFrameType,
              });
            }
          }}
        />
      </div>
    </div>
  );
};
