import React from 'react';
import { Link } from 'react-router-dom';

export interface ISidebarProps {
  homeLink: string;
  topItems: React.ReactNode;
  bottomItems: React.ReactNode;
}

export const Sidebar: React.FC<ISidebarProps> = (props) => {
  const { homeLink, topItems, bottomItems } = props;

  return (
    <div className="min-h-screen w-64">
      <div className="fixed h-screen top-0 left-0 w-64 z-dialog bg-white">
        <div className="flex flex-col gap-16 justify-between h-screen w-full">
          <div className="flex-1 flex-shrink-0 overflow-y-auto">
            <div className="flex items-center my-4">
              <Link to={homeLink}>
                <img style={{ width: '11rem', height: '5rem' }} className="ml-5" src="/static/logo-dark.svg" />
              </Link>
            </div>

            <div>{topItems}</div>
          </div>

          <div className="flex-shrink-0">{bottomItems}</div>
        </div>
      </div>
    </div>
  );
};
