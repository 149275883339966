import createContext from 'contexts/create-context';
import * as React from 'react';

import { Order } from './Order';

interface ProviderValue {
  order: Order;
  refreshData: () => void;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface IOrderProviderProps {
  order: Order;
  refreshData: () => void;
  children?: React.ReactNode;
}

export const OrderProvider: React.FC<IOrderProviderProps> = (props) => {
  const { order, children, refreshData } = props;

  return <ReactProvider value={{ order, refreshData }}>{children}</ReactProvider>;
};

export const useOrder = useContext;
export const Consumer = ReactConsumer;
