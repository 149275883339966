import { Plus, Trash } from '@phosphor-icons/react';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import dayjs from 'dayjs';

import { Breadcrumb } from 'components/Breadcrumb';
import { Button } from 'components/button/Button';
import { PageHeader } from 'components/PageHeader';
import {
  Country,
  DocumentType,
  QuotationType,
  useCreateQuotationMutation,
  useQuotationRequestQuery,
} from 'generated/graphql';
import { invariant } from '@utils/invariant';

import { InputField } from '../../../components/input/InputField';
import { getDisplayError } from '../../../utils/get-display-error';
import { Customer, CustomerComboboxField } from '../../customer/CustomerComboboxField';
import { QUOTATION_TYPE_OPTIONS } from '../constants';
import { SimpleComboboxField } from '../../../components/combobox/SimpleComboboxField';
import { ISimpleSelectItem } from '../../../components/select/SimpleSelect';
import { formatInputDate } from '../../../utils/date';
import { TextAreaField } from '../../../components/textarea/TextAreaField';
import { AutocompletePostalcode } from '../../location/components/AutocompletePostalcode';
import { TrailerTypes } from '../../order/pages/order/TrailerTypes';
import { parseNumberInput } from '../../../utils/number';
import { createRoute } from '../../../utils/mapbox-api.client';
import { serializeCoordinates } from '../../../utils/mapbox-api';
import { useMinimalGeneralSettings } from '../../../contexts/minimal-settings-context';
import { ILineState, IQuotationLineValues, initialLineValues } from './types';
import { QuotationPriceInput } from '../components/QuotationPriceInput';
import { COUNTRY_VALUES } from '../../../utils/address';
import { PageHeading } from 'components/PageHeading';
import { Card } from 'components/Card';

const createQuotationLineSchema = Yup.object().shape({
  departurePostalCode: Yup.mixed().nullable().required('Vereist'),
  arrivalPostalCode: Yup.mixed().nullable().required('Vereist'),
  distance: Yup.string().required('Vereist'),
  price: Yup.string().required('Vereist'),
  trailerTypes: Yup.array().required('Vereist'),
});

const createQuotationSchema = Yup.object().shape({
  customer: Yup.mixed().nullable().required('Vereist'),
  reference: Yup.string().required('Vereist'),
  notes: Yup.string(),
  quotationDate: Yup.string().required('Vereist'),
  expiresAt: Yup.string().required('Vereist'),
  lines: Yup.array(createQuotationLineSchema).required('Vereist'),
});

interface IQuotationValues {
  type: ISimpleSelectItem;
  customer: Customer | null;
  reference: string;
  quotationDate: string;
  expiresAt: string;
  notes: string;
  lines: IQuotationLineValues[];
}

const NewQuotationPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [, createQuotationMutation] = useCreateQuotationMutation();
  const generalSettings = useMinimalGeneralSettings();
  const [_lines, setLines] = useState<ILineState[]>([
    {
      departure: undefined,
      arrival: undefined,
      fetchKey: Date.now().toString(),
    },
  ]);

  const quotationRequestId = searchParams.get('quotation-req-id');
  const [{ data: quotationReqData }] = useQuotationRequestQuery({
    variables: {
      id: quotationRequestId!,
    },
    pause: !quotationRequestId,
  });
  const quotationRequest = quotationReqData?.quotationRequest;

  const initialValues: IQuotationValues = useMemo(() => {
    return {
      type: quotationRequest
        ? (QUOTATION_TYPE_OPTIONS.find((v) => v.key === quotationRequest.type) ?? QUOTATION_TYPE_OPTIONS[0])
        : QUOTATION_TYPE_OPTIONS[0],
      customer: quotationRequest?.customer || null,
      reference: quotationRequest?.reference || '',
      quotationDate: formatInputDate(new Date()),
      expiresAt: formatInputDate(new Date()),
      notes: quotationRequest?.notes || '',
      lines: quotationRequest
        ? quotationRequest.lines.map((line) => {
            return {
              departurePostalCode: line.departurePostalCode,
              departureCountry: COUNTRY_VALUES.find((v) => v.key === line.departureCountry)!,
              departureCity: line.departureCity,
              arrivalPostalCode: line.arrivalPostalCode,
              arrivalCountry: COUNTRY_VALUES.find((v) => v.key === line.arrivalCountry)!,
              arrivalCity: line.arrivalCity,
              distance: '0.00',
              price: '0.00',
              trailerTypes: line.trailerTypes,
            };
          })
        : [
            {
              ...initialLineValues,
            },
          ],
    };
  }, []);

  const formikbag = useFormik({
    initialValues: initialValues,
    validationSchema: createQuotationSchema,
    onSubmit: async (values) => {
      try {
        const { type, customer, reference, notes, quotationDate, expiresAt, lines } = values;
        const customerId = customer?.id;
        invariant(customerId);
        const result = await createQuotationMutation({
          data: {
            type: type.key as QuotationType,
            customerId,
            reference,
            notes,
            quotationDate,
            expiresAt,
            quotationRequestId: quotationRequestId || null,
            lines: lines.map((l) => {
              return {
                departurePostalCode: l.departurePostalCode,
                departureCountry: l.departureCountry.key as Country,
                departureCity: l.departureCity,
                arrivalPostalCode: l.arrivalPostalCode,
                arrivalCountry: l.arrivalCountry.key as Country,
                arrivalCity: l.arrivalCity,
                distance: parseNumberInput(l.distance, 2),
                price: parseNumberInput(l.price, 2),
                trailerTypes: l.trailerTypes,
              };
            }),
          },
        });
        if (result.error) {
          throw result.error;
        }
        if (result.data) {
          navigate(`../${result.data.createQuotation.id}`);
        }
        toast.success('Offerte aangemaakt');
      } catch (err: any) {
        toast.error('Kon offerte niet aanmaken: ' + getDisplayError(err));
      }
    },
  });

  const fetchRoute = async (state: ILineState, idx: number) => {
    if (!state.departure || !state.arrival) {
      return;
    }

    const fetchKey = `${serializeCoordinates(state.departure)}-${serializeCoordinates(state.arrival)}`;
    if (state.fetchKey === fetchKey) {
      return;
    }

    setLines((prev) => {
      prev[idx].fetchKey = fetchKey;
      return prev;
    });

    const response = await createRoute(state.departure, state.arrival);
    if (response) {
      setLines((prev) => {
        prev.forEach((l, idx) => {
          if (l.fetchKey === fetchKey) {
            setFieldValue(`lines[${idx}].distance`, (response.distance / 1000).toFixed(2));
          }
        });

        return prev;
      });
    }
  };

  useEffect(() => {
    if (!quotationRequest) {
      return;
    }

    for (let idx = 0; idx < quotationRequest.lines.length; idx++) {
      const departureLatitude = quotationRequest.lines[idx].departureLatitude;
      const departureLongitude = quotationRequest.lines[idx].departureLongitude;
      const arrivalLatitude = quotationRequest.lines[idx].arrivalLatitude;
      const arrivalLongitude = quotationRequest.lines[idx].arrivalLongitude;

      if (departureLatitude && departureLongitude && arrivalLatitude && arrivalLongitude) {
        setLines((prev) => {
          prev[idx].departure = [departureLongitude, departureLatitude];
          prev[idx].arrival = [arrivalLongitude, arrivalLatitude];
          fetchRoute(prev[idx], idx).catch(console.error);
          return prev;
        });
      }
    }
  }, [!!quotationRequest]);

  const quotationType = formikbag.values['type'];
  useEffect(() => {
    const getEndDate = () => {
      if (!formikbag.values['quotationDate']) {
        return new Date();
      }

      const date = dayjs(formikbag.values['quotationDate']);
      if (quotationType.key === QuotationType.Spot) {
        return date.add(14, 'day');
      } else {
        // Get last day of the year
        return date.endOf('year');
      }
    };
    formikbag.setFieldValue('expiresAt', formatInputDate(getEndDate()));
  }, [quotationType]);

  const customer = formikbag.values['customer'];
  useEffect(() => {
    if (!customer) {
      return;
    }

    const note = generalSettings.settings.documentNotes.find(
      (v) => v.language === customer.language && v.documentType === DocumentType.Quotation,
    );
    if (note) {
      formikbag.setFieldValue('notes', note.content);
    }
  }, [customer]);

  const { values, isSubmitting, handleSubmit, setFieldValue } = formikbag;
  return (
    <>
      <PageHeader title="Nieuwe offerte" />

      <PageHeading
        leftSide={
          <Breadcrumb
            parentItem={{
              name: 'Offertes',
              to: '/internal/finance/quotations',
            }}
            currentItem="Nieuwe Offerte"
          />
        }
      />

      <div className="px-4">
        <FormikProvider value={formikbag}>
          <form onSubmit={handleSubmit}>
            <div className="mb-8">
              <Card title="Algemene informatie">
                <SimpleComboboxField items={QUOTATION_TYPE_OPTIONS} name="type" labelText="Offerte type" />

                <CustomerComboboxField name="customer" isDisabled={isSubmitting} />

                <InputField type="text" labelText="Referentie klant" name="reference" isDisabled={isSubmitting} />

                <div className="grid grid-cols-2 gap-4">
                  <InputField labelText="Offertedatum" type="date" name="quotationDate" />
                  <InputField labelText="Vervalt op" type="date" name="expiresAt" />
                </div>

                <TextAreaField labelText="Notities" name="notes" isDisabled={isSubmitting} spellCheck={true} />
              </Card>
            </div>

            <FieldArray
              name="lines"
              render={(arrayHelpers) => (
                <div className="my-4">
                  <div className="flex justify-between items-end mb-4">
                    <h2 className="heading-two">Lijnen</h2>
                    <Button
                      onTrigger={() => {
                        const itemToAdd = { ...initialLineValues };
                        arrayHelpers.push(itemToAdd);
                        setLines((prev) => {
                          return [
                            ...prev,
                            {
                              departure: undefined,
                              arrival: undefined,
                              fetchKey: Date.now().toString(),
                            },
                          ];
                        });
                      }}
                      iconLeft={<Plus className="button-icon" />}
                    >
                      Voeg lijn toe
                    </Button>
                  </div>

                  {values['lines'].length > 0 ? (
                    <div className="grid gap-4">
                      {values['lines'].map((_line, idx) => {
                        return (
                          <div className="card p-4" key={`quotation-line-${idx}`}>
                            <div className="flex items-center justify-between mb-4">
                              <h3 className="heading-three">Lijn {idx + 1}</h3>
                              <div>
                                <Button
                                  color="danger-outline"
                                  onTrigger={() => {
                                    arrayHelpers.remove(idx);
                                    setLines((prev) => {
                                      return prev.filter((_, i) => i !== idx);
                                    });
                                  }}
                                  iconLeft={<Trash className="button-icon" />}
                                >
                                  Verwijder
                                </Button>
                              </div>
                            </div>

                            <div className="flex w-full gap-4">
                              <div className="grid quotation-line-form-columns gap-4 w-full">
                                <div>
                                  <div className="font-medium">Van</div>
                                  <div>
                                    <AutocompletePostalcode
                                      postalCodeName={`lines[${idx}].departurePostalCode`}
                                      countryName={`lines[${idx}].departureCountry`}
                                      cityName={`lines[${idx}].departureCity`}
                                      onAutocomplete={(_postalCode, _city, longitude, latitude) => {
                                        setLines((prev) => {
                                          prev[idx].departure = [longitude, latitude];
                                          fetchRoute(prev[idx], idx).catch(console.error);
                                          return [...prev];
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <div className="font-medium">Naar</div>
                                  <div>
                                    <AutocompletePostalcode
                                      postalCodeName={`lines[${idx}].arrivalPostalCode`}
                                      countryName={`lines[${idx}].arrivalCountry`}
                                      cityName={`lines[${idx}].arrivalCity`}
                                      onAutocomplete={(_postalCode, _city, longitude, latitude) => {
                                        setLines((prev) => {
                                          prev[idx].arrival = [longitude, latitude];
                                          fetchRoute(prev[idx], idx).catch(console.error);
                                          return [...prev];
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="2xl:pt-6">
                                  <InputField
                                    labelText="Afstand (in km)"
                                    type="number"
                                    name={`lines[${idx}].distance`}
                                    isDisabled={isSubmitting}
                                  />
                                </div>
                                <div className="2xl:pt-6">
                                  <QuotationPriceInput
                                    name={`lines[${idx}].price`}
                                    quotationType={values['type'].key as QuotationType}
                                    departurePostalCode={values.lines[idx].departurePostalCode}
                                    departureCountry={values.lines[idx].departureCountry.key}
                                    arrivalPostalCode={values.lines[idx].arrivalPostalCode}
                                    arrivalCountry={values.lines[idx].arrivalCountry.key}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="max-w-6xl">
                                <TrailerTypes
                                  value={values.lines[idx].trailerTypes}
                                  onChange={(newTrailerTypes) => {
                                    setFieldValue(`lines[${idx}].trailerTypes`, newTrailerTypes);
                                  }}
                                  isMultiSelect={true}
                                  isRequired={true}
                                  variant="small"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div>Geen lijnen</div>
                  )}
                </div>
              )}
            />

            <div className="mt-8">
              <Button
                type="submit"
                color="primary"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                iconLeft={<Plus className="button-icon" />}
              >
                Maak offerte aan
              </Button>
            </div>
          </form>
        </FormikProvider>
      </div>
    </>
  );
};

export default NewQuotationPage;
