import { TrailerCostPayee } from 'generated/graphql';

export const PAID_BY_OPTIONS: Array<{
  key: TrailerCostPayee;
  name: string;
}> = [
  {
    key: TrailerCostPayee.Customer,
    name: 'Klant',
  },
  {
    key: TrailerCostPayee.Supplier,
    name: 'Vervoerder',
  },
  {
    key: TrailerCostPayee.Internal,
    name: 'Transdirect',
  },
];
