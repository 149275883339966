import { requestPolicyExchange } from '@urql/exchange-request-policy';
import { captureException } from '@sentry/react';
import * as React from 'react';
import { Provider as URQLProvider, cacheExchange, createClient, fetchExchange, errorExchange } from 'urql';

import { API_ROOT } from '../constants';
import { urlJoin } from '../utils/url';

const getClient = () => {
  return createClient({
    url: urlJoin(API_ROOT, '/graphql'),
    suspense: true,
    exchanges: [
      requestPolicyExchange({
        // Upgrade cache-first and cache-only to cache-and-network after given ttl in ms
        ttl: 5 * 60_000,
      }),
      cacheExchange,
      fetchExchange,
      errorExchange({
        onError: (error) => {
          captureException(error);
        },
      }),
    ],
  });
};

interface AuthProviderProps {
  children?: React.ReactNode;
}

export const GraphQLProvider: React.FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [client] = React.useState(getClient);

  return <URQLProvider value={client}>{children}</URQLProvider>;
};
