import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

type SetStateAction<S> = S | ((prevState: S) => S);
type Dispatch<A> = (value: A) => void;
type UpdateFn<T> = Dispatch<SetStateAction<T>>;

function getParsedValue(
  key: string,
  searchParams: URLSearchParams,
  defaultValue: any,
  deserialize: (val: string) => any,
) {
  const value = searchParams.get(key);
  if (value) {
    try {
      return deserialize(value);
    } catch (err) {
      return defaultValue;
    }
  } else {
    return defaultValue;
  }
}

export function useSearchParam<T>(
  key: string,
  defaultValue: T,
  serialize: (val: any) => string = JSON.stringify,
  deserialize: (val: string) => any = JSON.parse,
): [T, UpdateFn<T>] {
  const [searchParams, setSearchParams] = useSearchParams();
  const setSearchParam = useCallback(
    (key: string, _value: any) => {
      const value =
        typeof _value === 'function' ? _value(getParsedValue(key, searchParams, defaultValue, deserialize)) : _value;
      setSearchParams(
        (prev) => {
          prev.set(key, serialize(value));
          return prev;
        },
        {
          replace: true,
        },
      );
    },
    [setSearchParams],
  );
  const foundValue = searchParams.get(key);
  const parsedValue = useMemo(() => {
    return getParsedValue(key, searchParams, defaultValue, deserialize);
  }, [foundValue]);
  return [parsedValue, (val: any) => setSearchParam(key, val)];
}
