import { translations as translationsNL } from './languages/nl';
import { translations as translationsEN } from './languages/en';
import { translations as translationsFR } from './languages/fr';
import { translations as translationsDE } from './languages/de';
import { TranslationKey } from './types';

export type FlattenedObject = Record<string, string>;

const languages: Record<string, FlattenedObject> = {
  nl: flattenObject(translationsNL),
  en: flattenObject(translationsEN),
  fr: flattenObject(translationsFR),
  de: flattenObject(translationsDE),
};

export function flattenObject(value: any, keyParts = [], flattenedObject: any = {}): FlattenedObject {
  if (typeof value === 'object') {
    for (let key of Object.keys(value)) {
      let subValue = value[key];
      let newKeyParts: string[] = [...keyParts, key];
      if (typeof subValue !== 'object') {
        flattenedObject[newKeyParts.join('.')] = subValue;
      } else {
        // @ts-ignore
        flattenObject(subValue, newKeyParts, flattenedObject);
      }
    }
  }

  return flattenedObject;
}

const TEMPLATE_REGEX = /{{([0-9a-zA-Z_.]+)}}/g;

export function format(template: string, values: any = {}) {
  let flattenedValues = flattenObject(values);

  if (!template) {
    return '';
  }

  return template.replace(TEMPLATE_REGEX, (match, key, index) => {
    if (template[index - 1] === '{' && template[index + match.length] === '}') {
      return key;
    } else {
      let cleanedKey = key.trim();
      let result = flattenedValues.hasOwnProperty(cleanedKey) ? flattenedValues[cleanedKey] : null;
      if (result === null || result === undefined) {
        return '';
      } else {
        return result;
      }
    }
  });
}

export function t(language: string, key: TranslationKey, values: FlattenedObject = {}): string {
  let registry: FlattenedObject = languages[language.toLowerCase()] ?? languages.en;

  if (registry && typeof registry[key] === 'string') {
    return format(registry[key], values);
  } else {
    return key ?? '';
  }
}
