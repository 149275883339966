export function multiplyPrecision(value: number, precision = 0): number {
  // Faster than log?
  const precisionMultiplier = +('1' + '0'.repeat(precision));
  return Math.round(value * precisionMultiplier);
}

export function parseNumberInput(value: string, precision = 0): number {
  const strValue = `${value}`.replace(/,+/g, '.');
  const parts = strValue.split('.');
  let inputValue = strValue;
  if (parts.length > 1) {
    const pointValue = parts.pop();
    inputValue = parts.join('') + '.' + pointValue;
  }
  const parsed = parseFloat(inputValue);
  const multiplied = multiplyPrecision(parsed, precision);
  if (isNaN(multiplied)) {
    throw new Error('String is not a number');
  }
  return multiplied;
}

export function formatNumber(
  value: number,
  precision = 0,
  opts: {
    decimalSeperator?: string;
    removeTrailingZeros?: boolean;
  } = {},
): string {
  const { decimalSeperator = '.', removeTrailingZeros = false } = opts;
  const isNegative = value < 0;
  const asString = `${Math.abs(Math.round(value))}`;
  if (precision > 0) {
    const firstNumbers = asString.slice(0, -precision);
    let lastNumber = asString.slice(-precision).padStart(precision, '0');
    if (removeTrailingZeros) {
      while (lastNumber.endsWith('0')) {
        lastNumber = lastNumber.substring(0, lastNumber.length - 1);
      }
    }
    let result = `${isNegative ? '-' : ''}${firstNumbers || '0'}`;
    if (lastNumber.length > 0) {
      result += `${decimalSeperator}${lastNumber}`;
    }
    return result;
  } else {
    return isNegative ? '-' + asString : asString;
  }
}

export function NaNToZero(value: number): number {
  if (isNaN(value)) {
    return 0;
  } else {
    return value;
  }
}

export function parseIntOrNull(value: string): number | null {
  const parsed = parseInt(value, 10);
  if (isNaN(parsed)) {
    return null;
  } else {
    return parsed;
  }
}

export function extractIntFromText(value: string): number {
  const parsed = parseInt(value.replace(/[^0-9]/g, ''), 10);
  if (isNaN(parsed)) {
    return 0;
  } else {
    return parsed;
  }
}
