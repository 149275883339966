import { Pencil, Plus } from '@phosphor-icons/react';
import { useField } from 'formik';
import { useCallback, useMemo } from 'react';

import { ISimpleComboboxItem } from '../../../components/combobox/SimpleCombobox';
import { SimpleComboboxField } from '../../../components/combobox/SimpleComboboxField';
import { usePackageTypesQuery } from '../../../generated/graphql';
import { CreateProductTypeDialog } from '../productType/CreateProductTypeDialog';
import { productTypeToComboboxItem } from '../productType/ProductTypeComboboxField';
import { CreatePackageTypeDialog } from './CreatePackageTypeDialog';
import { UpdatePackageTypeDialog } from './UpdatePackageTypeDialog';

export interface IMinimalPackageType {
  id: string;
  nameNl: string;
}

export interface IPackageTypeComboboxProps {
  name: string;
  labelText?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  onBlur?: () => void;
  isNullable?: boolean;
  onlySelect?: boolean;
}

export const packageTypeToComboboxItem = (val: IMinimalPackageType) => {
  return {
    key: val.id,
    name: `${val.id} - ${val.nameNl}`,
  };
};

export const PackageTypeComboboxField: React.FC<IPackageTypeComboboxProps> = (props) => {
  const { labelText, name, onlySelect, ...otherProps } = props;
  const [{ data }, reexecuteQuery] = usePackageTypesQuery();
  const [field, _meta, helpers] = useField({ name });

  const packageTypes = data?.packageTypes ?? [];

  const items: ISimpleComboboxItem[] = useMemo(() => {
    return data ? data.packageTypes.map(packageTypeToComboboxItem) : [];
  }, [packageTypes]);

  const refetch = useCallback(() => {
    reexecuteQuery({
      requestPolicy: 'network-only',
    });
  }, [reexecuteQuery]);

  const value: ISimpleComboboxItem | null = field.value;
  return (
    <div>
      <div className="flex items-center">
        <SimpleComboboxField labelText={labelText ?? 'Aard'} items={items} name={name} {...otherProps} />
        {!onlySelect && (
          <div className="flex-end flex justify-end gap-2 ml-2">
            {value && (
              <UpdatePackageTypeDialog
                id={value.key}
                onSubmit={(data) => {
                  helpers.setValue(packageTypeToComboboxItem(data));
                  refetch();
                }}
                triggerText={<Pencil className="button-icon" />}
              />
            )}

            <CreatePackageTypeDialog
              onSubmit={(data) => {
                helpers.setValue(productTypeToComboboxItem(data));
                refetch();
              }}
              triggerText={<Plus className="button-icon" />}
            />
          </div>
        )}
      </div>
    </div>
  );
};
