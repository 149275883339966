import { MagnifyingGlass, Plus } from '@phosphor-icons/react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IterableElement } from 'type-fest';
import { useClient } from 'urql';
import toast from 'react-hot-toast';
import ExcelJS from 'exceljs';

import { Input } from '../../../components/input/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import {
  CustomerDocumentType,
  GetCustomersDocument,
  GetCustomersForExportDocument,
  GetCustomersForExportQuery,
  GetCustomersForExportQueryVariables,
  GetCustomersQuery,
  GetCustomersQueryVariables,
  UserRole,
} from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { CUSTOMER_TYPE_VALUES } from '../select-values';
import { LinkButton } from '../../../components/button/ButtonLink';
import { Button } from 'components/button/Button';
import { roleToNumber } from 'src/app/auth/roles.client';
import { useAuth } from 'contexts/auth-context';
import { Table } from 'components/table/Table';
import { PageHeading } from 'components/PageHeading';
import { Breadcrumb } from 'components/Breadcrumb';

export type Customer = NonNullable<IterableElement<GetCustomersQuery['customers']>>;

const TABLE_HEADERS = [
  {
    id: 'name',
    name: 'Naam',
  },
  {
    id: 'type',
    name: 'Type',
  },
  {
    id: 'vatNumber',
    name: 'BTW nummer',
  },
  {
    id: 'address',
    name: 'Adres',
  },
  {
    id: 'postalCode',
    name: 'Postcode',
  },
  {
    id: 'country',
    name: 'Land',
  },
];

const CustomersPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const [isExporting, setIsExporting] = useState(false);
  const { me } = useAuth();
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetCustomersQuery, GetCustomersQueryVariables>(
          GetCustomersDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters: {
              search: searchValue,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.customers ?? [];
    },
    [client, searchValue],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 20,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  useEffect(() => {
    page.reset();
  }, [searchValue]);

  const isAdmin = roleToNumber(me.role) >= roleToNumber(UserRole.Admin);
  return (
    <>
      <PageHeader title="Klanten" />

      <div>
        <PageHeading
          leftSide={<Breadcrumb currentItem="Klanten" />}
          rightSide={
            <div className="flex gap-4">
              {isAdmin && (
                <div>
                  <Button
                    isLoading={isExporting}
                    isDisabled={isExporting}
                    onTrigger={async () => {
                      if (isExporting) {
                        return;
                      }

                      setIsExporting(true);
                      try {
                        const customersDict: Record<
                          string,
                          NonNullable<GetCustomersForExportQuery['customers'][0]>
                        > = {};

                        let cursor = null;
                        while (true) {
                          const result = await client
                            .query<GetCustomersForExportQuery, GetCustomersForExportQueryVariables>(
                              GetCustomersForExportDocument,
                              {
                                id: cursor,
                                take: 100,
                                filters: {
                                  isActive: true,
                                },
                              },
                              {
                                requestPolicy: 'cache-and-network',
                              },
                            )
                            .toPromise();

                          if (result.error) {
                            throw result.error;
                          }

                          if (!result.data) {
                            throw new Error('No data');
                          }

                          let hasNewData = false;
                          for (const customer of result.data.customers) {
                            if (!customersDict[customer.id]) {
                              customersDict[customer.id] = customer;
                              hasNewData = true;
                            }

                            cursor = customer.id;
                          }

                          if (!hasNewData) {
                            break;
                          }
                        }

                        const customers = Object.values(customersDict);
                        const workbook = new ExcelJS.Workbook();
                        workbook.creator = 'TDMS';
                        const worksheet = workbook.addWorksheet('Customers');

                        worksheet.columns = [
                          { header: 'Referentie', key: 'ref', width: 10 },
                          { header: 'Naam', key: 'name', width: 32 },
                          { header: 'Bedrijfsnummer', key: 'companyNumber', width: 32 },
                          { header: 'BTW Nummer', key: 'vatNumber', width: 32 },
                          { header: 'Type', key: 'type', width: 16 },
                          { header: 'Straat', key: 'street', width: 16 },
                          { header: 'Huisnummer', key: 'streetNumber', width: 16 },
                          { header: 'Land', key: 'country', width: 16 },
                          { header: 'Postcode', key: 'postalCode', width: 32 },
                          { header: 'Stad', key: 'city', width: 32 },
                          { header: 'Betaaltermijn', key: 'paymentTerm', width: 32 },
                          { header: 'Factuur frequentie', key: 'invoiceFrequency', width: 32 },
                          { header: 'Factuurlevering', key: 'invoiceDelivery', width: 32 },
                          { header: 'Diesel toeslag', key: 'dieselSurcharge', width: 16 },
                          { header: 'Type goederen', key: 'cargoType', width: 32 },
                          { header: 'Klantenfiche', key: 'customerFileDoc', width: 32 },
                          { header: 'Akkoord e-facturatie', key: 'eInvoicingDoc', width: 32 },
                          { header: 'Transport instructies', key: 'transportInstructionsDoc', width: 32 },
                          { header: 'Factuur e-mail', key: 'invoiceEmail', width: 32 },
                          { header: 'Factuur telefoon', key: 'invoicePhone', width: 32 },
                          { header: 'Offert e-mail', key: 'quotationsEmail', width: 32 },
                          { header: 'Offert telefoon', key: 'quotationsPhone', width: 32 },
                          { header: 'CMR e-mail', key: 'cmrEmail', width: 32 },
                          { header: 'CMR telefoon', key: 'cmrPhone', width: 32 },
                          { header: 'Order bevestiging e-mail', key: 'orderConfirmationEmail', width: 32 },
                          { header: 'Order bevestiging telefoon', key: 'orderConfirmationPhone', width: 32 },
                        ];

                        for (const customer of customers) {
                          worksheet.addRow({
                            ref: customer.id,
                            name: customer.name,
                            companyNumber: customer.companyNumber,
                            vatNumber: customer.vatNumber,
                            type: CUSTOMER_TYPE_VALUES.find((v) => v.key === customer.type)?.name,
                            street: customer.street,
                            streetNumber: customer.streetNumber,
                            country: customer.country,
                            postalCode: customer.postalCode,
                            city: customer.city,
                            paymentTerm: customer.paymentTerm,
                            invoiceFrequency: customer.invoiceFrequency,
                            invoiceDelivery: customer.invoiceDeliveryMethod,
                            dieselSurcharge: (customer.dieselSurchargePercentage || 0) / 100,
                            cargoType: customer.defaultCargoType?.id || '-',
                            customerFileDoc: customer.documents.find(
                              (v) => v.type === CustomerDocumentType.CustomerFile,
                            )
                              ? 'Yes'
                              : 'No',
                            eInvoicingDoc: customer.documents.find(
                              (v) => v.type === CustomerDocumentType.EInvoiceAgreement,
                            )
                              ? 'Yes'
                              : 'No',
                            transportInstructionsDoc: customer.documents.find(
                              (v) => v.type === CustomerDocumentType.TransportInstructions,
                            )
                              ? 'Yes'
                              : 'No',
                            invoiceEmail: customer.contacts.find((v) => v.shouldReceiveInvoices)?.email || '',
                            invoicePhone: customer.contacts.find((v) => v.shouldReceiveInvoices)?.phone || '',
                            quotationsEmail: customer.contacts.find((v) => v.shouldReceiveQuotations)?.email || '',
                            quotationsPhone: customer.contacts.find((v) => v.shouldReceiveQuotations)?.phone || '',
                            cmrEmail: customer.contacts.find((v) => v.shouldReceiveCMR)?.email || '',
                            cmrPhone: customer.contacts.find((v) => v.shouldReceiveCMR)?.phone || '',
                            orderConfirmationEmail:
                              customer.contacts.find((v) => v.shouldReceiveOrderConfirmations)?.email || '',
                            orderConfirmationPhone:
                              customer.contacts.find((v) => v.shouldReceiveOrderConfirmations)?.phone || '',
                          });
                        }

                        const arr = (await workbook.xlsx.writeBuffer()) as Buffer;
                        const blob = new Blob([arr], {
                          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        });
                        const url = URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'klanten.xlsx';
                        a.click();
                        URL.revokeObjectURL(url);

                        toast.success('Export voltooid');
                      } catch (err) {
                        console.error(err);
                        toast.error('Klanten export mislukt');
                      }
                      setIsExporting(false);
                    }}
                  >
                    Exporteer klanten
                  </Button>
                </div>
              )}
              <div>
                <LinkButton to="new" color="primary" iconLeft={<Plus className="button-icon" />}>
                  Nieuwe klant
                </LinkButton>
              </div>
            </div>
          }
        />

        <div className="flex w-full mb-8 px-4">
          <Input
            type="text"
            placeholder="Zoek een klant..."
            value={searchValue}
            onChange={setSearchValue}
            iconLeft={<MagnifyingGlass className="input-icon" />}
          />
        </div>

        <Table
          idKey="id"
          headers={TABLE_HEADERS}
          data={page.data}
          mapData={(customer) => {
            return [
              <Link to={`${customer.id}/general`} className="link-text">
                {customer.name}
                {!!customer.deactivationReason && ` - gedeactiveerd`}
              </Link>,
              CUSTOMER_TYPE_VALUES.find((v) => v.key === customer.type)?.name,
              customer.vatNumber,
              `${customer.street} ${customer.streetNumber}`,
              customer.postalCode,
              customer.country,
            ];
          }}
        />

        <div className="my-4">
          <Pagination
            hasPrevious={page.hasPrevious}
            previous={page.previous}
            hasNext={page.hasNext}
            next={page.next}
            isFetching={page.isFetching}
          />
        </div>
      </div>
    </>
  );
};

export default CustomersPage;
