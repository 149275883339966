import { Plus } from '@phosphor-icons/react';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Breadcrumb } from '../../../../components/Breadcrumb';
import { Button } from '../../../../components/button/Button';
import { InputField } from '../../../../components/input/InputField';
import { PageHeader } from '../../../../components/PageHeader';
import { SimpleSelectField } from '../../../../components/select/SimpleSelectField';
import { TextAreaField } from '../../../../components/textarea/TextAreaField';
import { Country, Language, useCreateSupplierMutation } from '../../../../generated/graphql';
import { COUNTRY_VALUES } from '../../../../utils/address';
import { getDisplayError } from '../../../../utils/get-display-error';
import { LANGUAGE_VALUES } from '../../../../utils/language';
import { AutocompletePostalcode } from '../../../location/components/AutocompletePostalcode';
import { VatRateComboboxField, vatRateToComboboxItem } from '../../../order/vatRate/VatRateComboboxField';
import { useMinimalGeneralSettings } from '../../../../contexts/minimal-settings-context';
import { nullthrows } from '../../../../utils/invariant';
import { CheckboxField } from '../../../../components/checkbox/CheckboxField';
import { Supplier } from '../../SupplierComboboxField';
import { PageHeading } from 'components/PageHeading';
import { Card } from 'components/Card';

const createSupplierSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Vereist').required('Vereist'),
  vatNumber: Yup.string().vatNumber().required('Vereist'),
  companyNumber: Yup.string().min(1, 'Vereist').required('Vereist'),
  street: Yup.string().min(1, 'Vereist').required('Vereist'),
  streetNumber: Yup.string(),
  city: Yup.string().min(1, 'Vereist').required('Vereist'),
  postalCode: Yup.string().min(1, 'Vereist').required('Vereist'),
  country: Yup.mixed().nullable().required('Vereist'),
  language: Yup.mixed().nullable().required('Vereist'),
  paymentTerm: Yup.string().required('Vereist'),
  defaultVatRate: Yup.mixed().nullable().required('Vereist'),
  selfBillingEnabled: Yup.bool().required('Vereist'),
  regularCarrier: Yup.bool().required('Vereist'),
  disableCmrReminders: Yup.bool().required('Vereist'),
});

const CreateSupplierPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [params] = useSearchParams();
  const [, createSupplierMutation] = useCreateSupplierMutation();
  const { settings } = useMinimalGeneralSettings();

  return (
    <>
      <PageHeader title="Nieuwe vervoerder" />

      <PageHeading
        leftSide={
          <Breadcrumb
            parentItem={{
              name: 'Vervoerders',
              to: '/internal/suppliers',
            }}
            currentItem={'Nieuwe vervoerder'}
          />
        }
      />

      <div className="px-4">
        <Formik
          initialValues={{
            name: '',
            vatNumber: '',
            companyNumber: '',
            street: '',
            streetNumber: '',
            city: '',
            postalCode: '',
            country: COUNTRY_VALUES.find((v) => v.key === Country.Be)!,
            language: LANGUAGE_VALUES.find((v) => v.key === Language.Nl)!,
            paymentTerm: '60',
            internalNotes: '',
            truckAmount: '0',
            tautlinerAmount: '0',
            boxTrailerAmount: '0',
            openTrailerAmount: '0',
            defaultVatRate: settings.defaultVatRate ? vatRateToComboboxItem(settings.defaultVatRate) : null,
            selfBillingEnabled: false,
            regularCarrier: false,
            disableCmrReminders: false,
          }}
          validationSchema={createSupplierSchema}
          onSubmit={async (values) => {
            try {
              const defaultVatRateId = nullthrows(values.defaultVatRate?.key, 'btw type niet ingevuld');
              // @ts-ignore this is fine, blabla
              delete values.defaultVatRate;
              const inputData = {
                ...values,
                country: values.country.key as Country,
                paymentTerm: +values.paymentTerm,
                language: values.language.key as Language,
                truckAmount: +values.truckAmount,
                tautlinerAmount: +values.tautlinerAmount,
                boxTrailerAmount: +values.boxTrailerAmount,
                openTrailerAmount: +values.openTrailerAmount,
                defaultVatRateId,
              };
              const result = await createSupplierMutation({
                data: inputData,
              });
              if (result.error) {
                throw result.error;
              }
              if (result.data) {
                const popupId = params.get('popup-id');
                if (popupId) {
                  const data: Supplier = result.data.createSupplier;
                  window.opener?.postMessage(
                    {
                      id: popupId,
                      type: 'created-supplier',
                      data,
                    },
                    '*',
                  );
                }
                navigate(`../${result.data.createSupplier.id}/general${search}`);
              }
              toast.success('Vervoerder aangemaakt');
            } catch (err: any) {
              toast.error('Kon vervoerder niet aanmaken: ' + getDisplayError(err));
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className="grid gap-4">
              <Card title="Bedrijfsgegevens">
                <InputField labelText="Naam" type="text" name="name" />
                <InputField labelText="BTW Nummer" type="text" name="vatNumber" />
                <InputField labelText="Ondernemingsnummer" type="text" name="companyNumber" />
                <div className="flex gap-4">
                  <div style={{ flex: 4 }}>
                    <InputField labelText="Straat" type="text" name="street" />
                  </div>
                  <div style={{ flex: 1 }}>
                    <InputField labelText="Nr" type="text" name="streetNumber" />
                  </div>
                </div>
                <AutocompletePostalcode />
                <SimpleSelectField labelText="Taal" items={LANGUAGE_VALUES} name="language" />
              </Card>

              <Card title="Facturatie">
                <InputField labelText="Betaaltermijn" type="number" name="paymentTerm" />
                <VatRateComboboxField labelText="Standaard BTW Tarief" name="defaultVatRate" />
                <div className="grid gap-4 grid-cols-2">
                  <CheckboxField labelText="Self billing" name="selfBillingEnabled" />
                  <CheckboxField labelText="Vaste vervoerder" name="regularCarrier" />
                  <CheckboxField labelText="CMR herinneringen uitschakelen" name="disableCmrReminders" />
                </div>
              </Card>

              <Card title="Vloot">
                <div className="grid grid-cols-2 gap-4">
                  <InputField labelText="Aantal trekkers" type="number" name="truckAmount" />
                  <InputField labelText="Aantal tautliners" type="number" name="tautlinerAmount" />
                  <InputField labelText="Aantal box/frigo trailers" type="number" name="boxTrailerAmount" />
                  <InputField labelText="Aantal open trailers" type="number" name="openTrailerAmount" />
                </div>
              </Card>

              <Card title="Notities">
                <TextAreaField labelText="Interne notities" name="internalNotes" spellCheck={true} />
              </Card>

              <div>
                <Button
                  type="submit"
                  color="primary"
                  iconLeft={<Plus className="button-icon" />}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Maak vervoerder aan
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CreateSupplierPage;
