import Idbkv from 'idb-kv';

import { IRoute, serializeCoordinates, createRoute as originalCreateRoute } from './mapbox-api';

// @ts-ignore
const MAPBOX_TOKEN: string = import.meta.env.VITE_MAPBOX_TOKEN;

const cacheStore = new Idbkv('mapbox-cache-v1');

export async function createRoute(
  startPoint: [number, number],
  destinationPoint: [number, number],
): Promise<IRoute | null> {
  const points = serializeCoordinates(startPoint) + ';' + serializeCoordinates(destinationPoint);

  const cacheKey = `MB_ROUTE_${points}`;
  const existingItem = await cacheStore.get(cacheKey).catch(() => null);
  if (existingItem) {
    return existingItem as any;
  }

  const route = await originalCreateRoute(MAPBOX_TOKEN, [startPoint, destinationPoint]);
  await cacheStore.set(cacheKey, route);
  return route;
}
