import { useMemo } from 'react';
import { useParams, useResolvedPath } from 'react-router-dom';
import { invariant } from '@utils/invariant';

import { Delay } from '../../../../components/Delay';
import { SpinnerBlock } from '../../../../components/Spinner';
import { ITabItem, Tabs } from '../../../../components/tabs/Tabs';
import { useOrder } from '../order/orderContext';
import { OrderLineStops } from '../orderLineStop/OrderLineStops';
import { OrderLineProvider } from './orderLineContext';
import { OrderLine } from './OrderLines';

const OrderLineDetails = () => {
  const { orderLineId: _orderLineId } = useParams<{ orderLineId: string }>();
  invariant(_orderLineId);
  const orderLineId = +_orderLineId;
  const { order, refreshData } = useOrder();
  const orderLine: OrderLine | undefined = useMemo(() => {
    return order.lines.find((l) => l.id === orderLineId);
  }, [order, orderLineId]);

  const contentsRoute = useResolvedPath('contents');
  const salesRoute = useResolvedPath('sales');
  const purchasesRoute = useResolvedPath('purchases');
  const tabItems = useMemo<ITabItem[]>(() => {
    const items = [
      {
        title: 'Inhoud',
        path: contentsRoute.pathname,
      },
    ];

    if (!order.noPurchase) {
      items.push({
        title: 'Aankopen',
        path: purchasesRoute.pathname,
      });
    }

    items.push({
      title: 'Verkopen',
      path: salesRoute.pathname,
    });

    return items;
  }, [order]);

  if (!orderLine) {
    return (
      <Delay
        ms={1500}
        before={<SpinnerBlock message="Order word geladen..." />}
        after={<div>Opdracht niet gevonden</div>}
      />
    );
  }

  return (
    <OrderLineProvider orderLine={orderLine} refreshData={refreshData}>
      <div className="grid grid-cols-3 gap-4">
        <div>
          <OrderLineStops />
        </div>

        <div className="col-span-2">
          <Tabs items={tabItems} />
        </div>
      </div>
    </OrderLineProvider>
  );
};

export default OrderLineDetails;
