import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { IterableElement } from 'type-fest';
import { Plus, Trash } from '@phosphor-icons/react';

import { ConfirmDialog } from '../../../../components/dialog/ConfirmDialog';
import { ITableHeader, Table } from '../../../../components/table/Table';
import { useDeleteCustomerContactsMutation } from '../../../../generated/graphql';
import { getDisplayError } from '../../../../utils/get-display-error';
import { formatPhoneNumber } from '../../../../utils/phonenumber';
import { Customer } from '../Customer';
import { useCustomer } from '../Customer/customerContext';
import { LinkButton } from '../../../../components/button/ButtonLink';

export type CustomerContact = IterableElement<Customer['contacts']>;

const CONTACT_HEADERS: ITableHeader[] = [
  {
    id: 'name',
    name: 'Naam',
  },
  {
    id: 'email',
    name: 'Email',
  },
  {
    id: 'phone',
    name: 'Telefoon',
  },
  {
    id: 'function',
    name: 'Functie',
  },
  {
    id: 'language',
    name: 'Taal',
  },
  {
    id: 'notifications',
    name: 'Contact geschikt voor',
  },
  {
    id: 'delete',
    name: 'Verwijder',
  },
];

export const CustomerContacts = () => {
  const { customer, refreshData } = useCustomer();
  const [, executeDelete] = useDeleteCustomerContactsMutation();

  const refresh = useCallback(() => {
    refreshData();
  }, [refreshData]);

  return (
    <div>
      <div className="flex justify-end mb-4">
        <LinkButton to="new" color="primary" iconLeft={<Plus className="button-icon" />}>
          Nieuw contact
        </LinkButton>
      </div>

      <Table
        idKey="id"
        headers={CONTACT_HEADERS}
        data={customer.contacts}
        mapData={(data) => {
          const notifications = [];
          if (data.shouldReceiveInvoices) {
            notifications.push('Facturatie');
          }
          if (data.shouldReceiveOrderConfirmations) {
            notifications.push('Order bevestiging');
          }
          if (data.shouldReceiveCMR) {
            notifications.push('CMR');
          }
          if (data.shouldReceiveQuotations) {
            notifications.push('Offertes');
          }
          if (data.shouldReceiveDieselSurcharge) {
            notifications.push('Dieseltoeslag');
          }
          return [
            <Link to={`${data.id}`} className="link-text">
              {data.name}
            </Link>,
            data.email,
            formatPhoneNumber(data.phone, true),
            data.function,
            data.language,
            notifications.join(', '),
            <ConfirmDialog
              triggerColor="danger"
              triggerText={<Trash className="button-icon" />}
              title="Verwijder contacten"
              submitText="Verwijder"
              description={`Ben je zeker dat je contact ${data.name} wilt wissen?`}
              onSubmit={async () => {
                try {
                  const res = await executeDelete({
                    customerId: customer.id,
                    ids: [data.id],
                  });
                  refresh();
                  if (res.error) {
                    throw res.error;
                  }
                } catch (err) {
                  toast.error('Kon contact niet verwijderen: ' + getDisplayError(err));
                }
              }}
            />,
          ];
        }}
      />
    </div>
  );
};
