import classNames from '@utils/classnames';
import React from 'react';

export interface ICheckboxProps {
  id?: string;
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  isDisabled?: boolean;
  isInvalid?: boolean;
  className?: string;
}

export const Checkbox: React.FC<ICheckboxProps> = (props) => {
  const { id, onChange, isChecked, isDisabled, className } = props;

  return (
    <input
      id={id}
      type="checkbox"
      className={classNames('cursor-pointer', className)}
      onChange={(evt) => onChange(evt.target.checked)}
      checked={isChecked}
      disabled={isDisabled}
    ></input>
  );
};
