import * as Yup from 'yup';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useState } from 'react';

import { Button } from '../../../components/button/Button';
import { PageHeader } from '../../../components/PageHeader';
import { useCustomerPortalAccountRequestMutation } from '../../../generated/graphql';
import { getDisplayError } from '../../../utils/get-display-error';
import { InputField } from '../../../components/input/InputField';
import { useTranslation } from '../../../contexts/translation-context';

const customerPortalRequestAccountSchema = Yup.object().shape({
  name: Yup.string().required('errors.required'),
  email: Yup.string().email('errors.invalidEmail').required('errors.required'),
  companyName: Yup.string().required('errors.required'),
  companyNumber: Yup.string().required('errors.required'),
});

export const CustomerPortalRequestAccountPage = () => {
  const [, requestAccount] = useCustomerPortalAccountRequestMutation();
  const { i18n } = useTranslation();
  const [hasRequested, setHasRequested] = useState(false);
  const [error, setError] = useState<string | null>(null);

  return (
    <div className="h-screen">
      <PageHeader title={i18n('customerPortal.requestAccount.title')} />

      <main
        className="p-8 min-h-screen w-full"
        style={{
          background: "url('/static/graphic-elements/element-one.svg') no-repeat center right fixed",
        }}
      >
        <div className="flex flex-col justify-center h-full items-center max-w-md mx-auto">
          <h1 className="heading-one mb-8 mt-32">{i18n('customerPortal.requestAccount.title')}</h1>

          <div className="w-full">
            <Formik
              initialValues={{
                name: '',
                email: '',
                companyName: '',
                companyNumber: '',
              }}
              validationSchema={customerPortalRequestAccountSchema}
              onSubmit={async (values) => {
                try {
                  const { name, email, companyName, companyNumber } = values;

                  const result = await requestAccount({
                    data: {
                      name,
                      email,
                      companyName,
                      companyNumber,
                    },
                  });
                  if (result.error) {
                    throw result.error;
                  }

                  const succeeded = result.data?.customerPortalAccountRequest;
                  if (!succeeded) {
                    throw new Error('Account request failed');
                  }

                  setHasRequested(true);

                  toast.success(i18n('customerPortal.requestAccount.success'));
                } catch (err: any) {
                  setError(getDisplayError(err));

                  toast.error(
                    i18n('customerPortal.requestAccount.failed', {
                      error: getDisplayError(err),
                    }),
                  );
                }
              }}
            >
              {({ handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                  <InputField labelText={i18n('customerPortal.requestAccount.name')} type="text" name="name" />
                  <InputField labelText={i18n('customerPortal.requestAccount.email')} type="email" name="email" />
                  <InputField
                    labelText={i18n('customerPortal.requestAccount.companyName')}
                    type="text"
                    name="companyName"
                  />
                  <InputField
                    labelText={i18n('customerPortal.requestAccount.companyNumber')}
                    type="text"
                    name="companyNumber"
                  />

                  <div className="my-4">
                    {hasRequested && <p>{i18n('customerPortal.requestAccount.success')}</p>}
                    {error && <p className="text-red-500">{error}</p>}
                  </div>

                  <div className="flex justify-end">
                    <Button type="submit" color="default" isDisabled={isSubmitting} isLoading={isSubmitting}>
                      {i18n('customerPortal.requestAccount.request')}
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CustomerPortalRequestAccountPage;
