import { ISimpleSelectItem } from '../../components/select/SimpleSelect';
import { StatusColor } from '../../components/StatusBadge';
import { OrderStatus, PurchaseStatus, SaleStatus, OrderOrigin } from '../../generated/graphql';

export const PURCHASE_STATUS_ORDER = {
  [PurchaseStatus.Created]: 100,
  [PurchaseStatus.Confirmed]: 200,
  [PurchaseStatus.Invoiced]: 300,
};

export const ORDER_ORIGINS: Record<OrderOrigin, string> = {
  [OrderOrigin.AiEntry]: 'AI Entry',
  [OrderOrigin.Manual]: 'Intern manueel',
  [OrderOrigin.CustomerPortal]: 'Klantenportaal',
  [OrderOrigin.Transporeon]: 'Transporeon',
  [OrderOrigin.Api]: 'API',
};

export const PURCHASE_STATUS_OPTIONS: ISimpleSelectItem[] = [
  {
    key: PurchaseStatus.Created,
    name: 'Aangemaakt',
  },
  {
    key: PurchaseStatus.Confirmed,
    name: 'Bevestigd',
  },
  {
    key: PurchaseStatus.SelfBillCreated,
    name: 'Self-bill aangemaakt',
  },
  {
    key: PurchaseStatus.Invoiced,
    name: 'Gefactureerd',
  },
];

export const PURCHASE_STATUS_COLOR: Record<PurchaseStatus, StatusColor> = {
  [PurchaseStatus.Created]: 'black',
  [PurchaseStatus.Confirmed]: 'green',
  [PurchaseStatus.SelfBillCreated]: 'green',
  [PurchaseStatus.Invoiced]: 'green',
};

export const SALE_STATUS_OPTIONS: ISimpleSelectItem[] = [
  {
    key: SaleStatus.Created,
    name: 'Aangemaakt',
  },
  {
    key: SaleStatus.Incomplete,
    name: 'Onvolledig',
  },
  {
    key: SaleStatus.Invoiced,
    name: 'Gefactureerd',
  },
  {
    key: SaleStatus.Credited,
    name: 'Gecrediteerd',
  },
];

export const SALE_STATUS_COLOR: Record<SaleStatus, StatusColor> = {
  [SaleStatus.Created]: 'black',
  [SaleStatus.Incomplete]: 'orange',
  [SaleStatus.Invoiced]: 'green',
  [SaleStatus.Credited]: 'red', // should this be red or black?
};

export const ORDER_STATUS_OPTIONS: ISimpleSelectItem[] = [
  {
    key: OrderStatus.Draft,
    name: 'In Opmaak',
  },
  {
    key: OrderStatus.Created,
    name: 'Aangemaakt',
  },
  {
    key: OrderStatus.Placed,
    name: 'Geplaatst',
  },
  {
    key: OrderStatus.Confirmed,
    name: 'Bevestigd',
  },
  {
    key: OrderStatus.Refused,
    name: 'Geweigerd',
  },
  {
    key: OrderStatus.Executed,
    name: 'Uitgevoerd',
  },
  {
    key: OrderStatus.Cancelled,
    name: 'Geannuleerd',
  },
];

export const ORDER_STATUS_COLOR: Record<OrderStatus, StatusColor> = {
  [OrderStatus.Draft]: 'black',
  [OrderStatus.Created]: 'black',
  [OrderStatus.Placed]: 'green',
  [OrderStatus.Confirmed]: 'green',
  [OrderStatus.Refused]: 'red',
  [OrderStatus.Executed]: 'green',
  [OrderStatus.Cancelled]: 'red',
};
