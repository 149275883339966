import { useCreateInvoiceCtx } from './context';
import { nullthrows } from '../../../../../utils/invariant';
import { OrderSelect as OrderSelectComponent } from '../../../components/OrderSelect';

interface IOrderSelectProps {
  searchValue: string;
}

export const OrderSelect: React.FC<IOrderSelectProps> = (props) => {
  const { searchValue } = props;
  const { state: invoiceState, dispatch } = useCreateInvoiceCtx();
  const customer = nullthrows(invoiceState.selectedCustomer, 'No customer selected');

  return (
    <OrderSelectComponent
      customerId={customer.customer.id}
      searchValue={searchValue}
      selectedOrderIds={invoiceState.orders.map((o) => o.id)}
      onSelectOrder={(o) => {
        dispatch({
          type: 'SELECT_ORDER',
          order: o,
        });
      }}
    />
  );
};
