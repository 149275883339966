import { useClient } from 'urql';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { IterableElement } from 'type-fest';

import { Pagination } from '../../../components/pagination/Pagination';
import { ITableHeader, Table } from '../../../components/table/Table';
import { GetAuditLogsDocument, GetAuditLogsQuery, GetAuditLogsQueryVariables } from '../../../generated/graphql';
import { formatDateTime } from '../../../utils/date';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';

export type AuditLog = NonNullable<IterableElement<GetAuditLogsQuery['auditLogs']>>;

const TABLE_HEADERS: ITableHeader[] = [
  {
    id: 'description',
    name: 'Beschrijving',
  },
  {
    id: 'actionType',
    name: 'Actie',
  },
  {
    id: 'actorName',
    name: 'Auteur',
  },
  {
    id: 'createdAt',
    name: 'Aangemaakt op',
  },
];

export interface IAuditLogsListProps {
  filter: GetAuditLogsQueryVariables['filter'];
}

export const AuditLogsList: React.FC<IAuditLogsListProps> = (props) => {
  const { filter } = props;
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetAuditLogsQuery, GetAuditLogsQueryVariables>(
          GetAuditLogsDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filter,
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.auditLogs ?? [];
    },
    [client],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 20,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  return (
    <div>
      <div className="mb-8">
        {page.data.length ? (
          <Table
            idKey="id"
            headers={TABLE_HEADERS}
            data={page.data}
            mapData={(auditLog) => {
              return [
                <Link to={`/internal/audit-logs/${auditLog.id}`} className="link-text">
                  {auditLog.description}
                </Link>,
                auditLog.actionType,
                auditLog.actorName,
                formatDateTime(auditLog.createdAt),
              ];
            }}
          />
        ) : (
          <div>Geen audit logs gevonden</div>
        )}
      </div>

      <div className="my-4">
        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </div>
  );
};
