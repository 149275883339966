import React from 'react';

export const GoogleIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.8 10.208C18.8 9.55801 18.7417 8.93301 18.6333 8.33301H10V11.883H14.9333C14.7167 13.0247 14.0667 13.9913 13.0917 14.6413V16.9497H16.0667C17.8 15.3497 18.8 12.9997 18.8 10.208Z"
        fill="#4285F4"
      />
      <path
        d="M9.99998 19.1667C12.475 19.1667 14.55 18.35 16.0667 16.95L13.0917 14.6417C12.275 15.1917 11.2333 15.525 9.99998 15.525C7.61665 15.525 5.59165 13.9167 4.86665 11.75H1.81665V14.1167C3.32498 17.1083 6.41665 19.1667 9.99998 19.1667Z"
        fill="#34A853"
      />
      <path
        d="M4.86671 11.7416C4.68337 11.1916 4.57504 10.6083 4.57504 9.99993C4.57504 9.3916 4.68337 8.80827 4.86671 8.25827V5.8916H1.81671C1.19171 7.12493 0.833374 8.5166 0.833374 9.99993C0.833374 11.4833 1.19171 12.8749 1.81671 14.1083L4.19171 12.2583L4.86671 11.7416Z"
        fill="#FBBC05"
      />
      <path
        d="M9.99998 4.48301C11.35 4.48301 12.55 4.94967 13.5083 5.84967L16.1333 3.22467C14.5417 1.74134 12.475 0.833008 9.99998 0.833008C6.41665 0.833008 3.32498 2.89134 1.81665 5.89134L4.86665 8.25801C5.59165 6.09134 7.61665 4.48301 9.99998 4.48301Z"
        fill="#EA4335"
      />
    </svg>
  );
};
