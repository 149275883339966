import createContext from 'contexts/create-context';
import * as React from 'react';

import { Customer } from '.';

interface ProviderValue {
  customer: Customer;
  refreshData: () => void;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface ICustomerProviderProps {
  customer: Customer;
  refreshData: () => void;
  children?: React.ReactNode;
}

export const CustomerProvider: React.FC<ICustomerProviderProps> = (props) => {
  const { customer, children, refreshData } = props;

  return <ReactProvider value={{ customer, refreshData }}>{children}</ReactProvider>;
};

export const useCustomer = useContext;
export const Consumer = ReactConsumer;
