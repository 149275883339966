import { Plus } from '@phosphor-icons/react';
import { Button } from 'components/button/Button';
import { FormikProvider, useFormik } from 'formik';
import { OrderLineStopType, useCreateOrderLineMutation } from 'generated/graphql';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { invariant, nullthrows } from '@utils/invariant';
import * as Yup from 'yup';

import { parseInputTime } from '../../../../utils/date';
import { getDisplayError } from '../../../../utils/get-display-error';
import { parseNumberInput } from '../../../../utils/number';
import { useOrder } from '../order/orderContext';
import { createOrderLineContentSchema } from '../orderLineContent/CreateOrderLineContent';
import { createOrderLineSaleSchema } from '../orderLineSale/CreateOrderLineSale';
import { STOP_TYPE_ITEMS } from '../orderLineStop/constants';
import { createOrderLineStopSchema, initialValues as initialStopValues } from '../orderLineStop/CreateOrderLineStop';
import { createOrderPurchaseSchema } from '../orderPurchase/CreateOrderPurchase';
import { CreateOrderLineFields, ICreateOrderLineValues } from './CreateOrderLineFields';

const createOrderLineSchema = Yup.object().shape({
  stops: Yup.array(createOrderLineStopSchema).required('Vereist'),
  contents: Yup.array(createOrderLineContentSchema).required('Vereist'),
  purchases: Yup.array(createOrderPurchaseSchema).required('Vereist'),
  sales: Yup.array(createOrderLineSaleSchema).required('Vereist'),
});

interface IOrderLineValues extends ICreateOrderLineValues {}

const CreateOrderLinePage = () => {
  const { order, refreshData: refetchOrder } = useOrder();
  const navigate = useNavigate();
  const [, createOrderLineMutation] = useCreateOrderLineMutation();

  const initialValues: IOrderLineValues = useMemo(() => {
    return {
      stops: [
        {
          ...initialStopValues,
          type: STOP_TYPE_ITEMS.find((item) => item.key === OrderLineStopType.Load)!,
        },
      ],
      contents: [],
      purchases: [],
      sales: [],
    };
  }, []);

  const formikbag = useFormik({
    initialValues,
    validationSchema: createOrderLineSchema,
    onSubmit: async (values) => {
      try {
        const { stops, contents, purchases, sales } = values;
        const result = await createOrderLineMutation({
          orderId: order?.id,
          data: {
            stops: stops.map((stop, i) => {
              const { type: selectedType, location, date, timeStart, timeEnd, reference, notes } = stop;
              const locationId = location?.id;
              invariant(locationId);
              const type = nullthrows(selectedType?.key, 'Stop type is required');

              return {
                sequenceIndex: i * 10,
                type: type as OrderLineStopType,
                locationId,
                date,
                timeStart: parseInputTime(timeStart),
                timeEnd: parseInputTime(timeEnd),
                reference,
                notes,
              };
            }),

            contents: contents.map((content) => {
              const { cargoType, packageType, packages, weight, volume, loadingMeters } = content;
              const cargoTypeId = cargoType?.key;
              const packageTypeId = packageType?.key;
              invariant(cargoTypeId);
              invariant(packageTypeId);

              return {
                cargoTypeId,
                packageTypeId,
                packages: parseNumberInput(packages, 2),
                weight: parseNumberInput(weight, 2),
                volume: parseNumberInput(volume, 2),
                loadingMeters: parseNumberInput(loadingMeters, 2),
              };
            }),
            purchases: purchases.map((purchase) => {
              const { productType, vatRate, amount, unitPrice, externalNote } = purchase;
              const productTypeId = nullthrows(productType?.key, 'Product type is vereist');
              const vatRateId = nullthrows(vatRate?.key, 'btw type is vereist');
              return {
                productTypeId,
                vatRateId,
                amount: parseNumberInput(amount, 2),
                unitPrice: parseNumberInput(unitPrice, 2),
                externalNote: externalNote ?? '',
              };
            }),
            sales: sales.map((sale) => {
              const { productType, vatRate, amount, unitPrice, externalNote } = sale;
              const productTypeId = nullthrows(productType?.key, 'Product type is vereist');
              const vatRateId = nullthrows(vatRate?.key, 'btw type is vereist');
              return {
                productTypeId,
                vatRateId,
                amount: parseNumberInput(amount, 2),
                unitPrice: parseNumberInput(unitPrice, 2),
                externalNote: externalNote ?? '',
              };
            }),
          },
        });
        if (result.error) {
          throw result.error;
        }
        if (result.data) {
          refetchOrder();
          navigate(`../${result.data.createOrderLine.id}`);
        }
        toast.success('Opdracht aangemaakt');
      } catch (err: any) {
        toast.error('Kon opdracht niet aanmaken: ' + getDisplayError(err));
      }
    },
  });

  const { handleSubmit, isSubmitting } = formikbag;
  return (
    <>
      <h1 className="heading-one mb-4">Nieuwe opdracht</h1>

      <FormikProvider value={formikbag}>
        <form onSubmit={handleSubmit}>
          <CreateOrderLineFields
            customer={order.customer}
            supplier={order.supplier}
            noPurchase={order.noPurchase}
            values={formikbag.values}
            isSubmitting={isSubmitting}
          />

          <Button
            type="submit"
            color="primary"
            iconLeft={<Plus className="button-icon" />}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Maak opdracht aan
          </Button>
        </form>
      </FormikProvider>
    </>
  );
};

export default CreateOrderLinePage;
