import * as React from 'react';

import createContext from './create-context';
import { t, FlattenedObject } from '../translation/utils';
import { TranslationKey } from '../translation/types';

interface ProviderValue {
  lang: string;
  setLanguage: (newLang: string) => void;
  i18n: (key: TranslationKey, values?: FlattenedObject) => string;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface TranslationProviderProps {
  children?: React.ReactNode;
}

const VALID_LANGUAGES = new Set(['en', 'nl', 'fr', 'de']);

const getSearchParams = () => {
  return new URLSearchParams(window.location.search);
};

const FALLBACK_LANG = 'en';

const parseLangCode = (langCode: string): string | null => {
  const code = langCode.toLowerCase().trim();
  if (!VALID_LANGUAGES.has(code)) {
    return null;
  } else {
    return code;
  }
};

const INITIAL_LANG =
  parseLangCode(
    getSearchParams().get('lang') ?? localStorage.getItem('lang') ?? (navigator.language || 'en').substring(0, 2),
  ) ?? FALLBACK_LANG;

export const TranslationProvider: React.FC<TranslationProviderProps> = (props) => {
  const { children } = props;
  const [lang, setLang] = React.useState(INITIAL_LANG);

  const setLanguage = React.useCallback(
    (_lang: string) => {
      const langCode = parseLangCode(_lang);
      if (!langCode) {
        console.warn(`Tried to set invalid language code: ${_lang}`);
        return;
      }

      localStorage.setItem('lang', langCode);
      setLang(langCode);
    },
    [setLang],
  );

  React.useEffect(() => {
    const searchParamsLang = getSearchParams().get('lang');
    if (searchParamsLang) {
      setLanguage(searchParamsLang);
    }
  }, [true]);

  const i18n = React.useCallback(
    (key: TranslationKey, values: FlattenedObject = {}) => {
      return t(lang, key, values);
    },
    [lang],
  );

  return <ReactProvider value={{ lang, setLanguage, i18n }}>{children}</ReactProvider>;
};

export const useTranslation = useContext;
export const Consumer = ReactConsumer;
