import * as Dialog from '@radix-ui/react-dialog';
import React, { Suspense, useState } from 'react';

import { BaseButton } from '../../../components/button/BaseButton';
import { CreatePackageTypeForm } from './CreatePackageTypeForm';
import { IMinimalPackageType } from './PackageTypeComboboxField';

export interface ICreatePackageTypeDialogProps {
  triggerText: React.ReactElement;
  onSubmit: (data: IMinimalPackageType) => void | Promise<void>;
}

export const CreatePackageTypeDialog: React.FC<ICreatePackageTypeDialogProps> = (props) => {
  const { triggerText, onSubmit } = props;
  const [open, setOpen] = useState(false);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <BaseButton>{triggerText}</BaseButton>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay className="dialog-overlay" />
        <Dialog.Content className="dialog-content">
          <Dialog.Title className="heading-one mb-4">Maak verpakking type aan</Dialog.Title>

          <div>
            <Suspense fallback="Loading...">
              <CreatePackageTypeForm
                onCancel={() => setOpen(false)}
                onComplete={(data) => {
                  onSubmit(data);
                  setOpen(false);
                }}
              />
            </Suspense>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
