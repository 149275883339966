import * as Dialog from '@radix-ui/react-dialog';
import React from 'react';

import { OrderStatus, useUpdateOrderStatusManuallyMutation } from '../../../generated/graphql';
import { Button } from '../../../components/button/Button';
import { Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { getDisplayError } from '../../../utils/get-display-error';
import { SimpleSelectField } from '../../../components/select/SimpleSelectField';
import { ORDER_STATUS_OPTIONS } from '../constants';
import { nullthrows } from '../../../utils/invariant';

export interface IOrderStatusDialogProps {
  isOpen: boolean;
  setOpen: (newOpen: boolean) => void;
  orderId: string;
  initialStatus: OrderStatus;
}

export const OPTIONS = [
  OrderStatus.Created,
  OrderStatus.Placed,
  OrderStatus.Confirmed,
  OrderStatus.Refused,
  OrderStatus.Executed,
].map((v) => {
  return nullthrows(
    ORDER_STATUS_OPTIONS.find((i) => i.key === v),
    `Order status ${v} does not exist`,
  );
});

export const OrderStatusDialog: React.FC<IOrderStatusDialogProps> = (props) => {
  const { isOpen, setOpen, orderId, initialStatus } = props;
  const [_orderStatusChangeState, updateOrderStatus] = useUpdateOrderStatusManuallyMutation();

  return (
    <Dialog.Root open={isOpen} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="dialog-overlay" />
        <Dialog.Content className="dialog-content">
          <Dialog.Title className="heading-one mb-4">Verander order status manueel</Dialog.Title>

          <Formik
            initialValues={{
              status: ORDER_STATUS_OPTIONS.find((s) => s.key === initialStatus) ?? OPTIONS[0],
            }}
            onSubmit={async (values) => {
              try {
                const result = await updateOrderStatus({
                  orderId,
                  newStatus: values.status.key as OrderStatus,
                });
                if (result.error) {
                  throw result.error;
                }
                toast.success('Order status aangepast');
                setOpen(false);
              } catch (err: any) {
                toast.error('Kon order status niet aanpassen: ' + getDisplayError(err));
              }
            }}
          >
            {({ handleSubmit, isSubmitting, submitForm }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <SimpleSelectField labelText="Nieuwe status" items={OPTIONS} name="status" />

                  <div className="flex justify-between">
                    <Button isDisabled={isSubmitting} isLoading={isSubmitting} onTrigger={() => setOpen(false)}>
                      Annuleer
                    </Button>

                    <Button
                      type="submit"
                      color="primary"
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                      onTrigger={submitForm}
                    >
                      Pas status aan
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
