export enum TimeFrameType {
  Today = 'today',
  Week = 'week',
  Month = 'month',
  Year = 'year',
  Custom = 'custom',
}

export interface TimeFrameState {
  type: TimeFrameType;
  startDate: string;
  endDate: string;
}

export type TimeFrameAction =
  | {
      action: 'select:type';
      newType: TimeFrameType;
    }
  | {
      action: 'set:start-date';
      value: string;
    }
  | {
      action: 'set:end-date';
      value: string;
    };

export interface IDateRange {
  startDate: string;
  endDate: string;
}
