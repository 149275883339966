import { Breadcrumb } from 'components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { AuditLogsList } from '../components/AuditLogsList';
import { PageHeading } from 'components/PageHeading';

export const AuditLogsPage = () => {
  return (
    <>
      <PageHeader title="Audit Logs" />

      <div>
        <PageHeading leftSide={<Breadcrumb currentItem="Audit Logs" />} />

        <AuditLogsList filter={{}} />
      </div>
    </>
  );
};
