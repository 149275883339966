import type { ISimpleSelectItem } from '../../components/select/SimpleSelect';
import { CustomerType, InvoiceDeliveryMethod, InvoiceFrequency } from '../../generated/graphql';

export const INVOICE_FREQUENCY_VALUES: ISimpleSelectItem[] = [
  {
    key: InvoiceFrequency.Monthly,
    name: 'Maandelijks',
  },
  {
    key: InvoiceFrequency.Weekly,
    name: 'Wekelijks',
  },
  {
    key: InvoiceFrequency.Transport,
    name: 'Per transport',
  },
];

export const INVOICE_DELIVERY_METHOD_VALUES: ISimpleSelectItem[] = [
  {
    key: InvoiceDeliveryMethod.Email,
    name: 'Email',
  },
  {
    key: InvoiceDeliveryMethod.Post,
    name: 'Post',
  },
];

export const CUSTOMER_TYPE_VALUES: ISimpleSelectItem[] = [
  {
    key: CustomerType.Direct,
    name: 'Directe klant',
  },
  {
    key: CustomerType.Transport,
    name: 'Transportbedrijf',
  },
];
