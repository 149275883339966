import * as React from 'react';
import { createId } from '@paralleldrive/cuid2';
import { toast } from 'react-hot-toast';
import createContext from 'contexts/create-context';

import { INotificationsState, notificationsReducer } from './reducer';
import { useNotificationsQuery } from '../../../generated/graphql';
import { useWebsocket } from '../../../contexts/websocket-context';
import { useNavigate } from 'react-router-dom';

export interface INotificationsContext {
  state: INotificationsState;
  refresh: () => void;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<INotificationsContext>();

export const NotificationsProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const [state, dispatch] = React.useReducer(notificationsReducer, {
    notifications: [],
    hasUnreadNotifications: false,
    lastNotificationTime: 0,
  });
  const { websocket: wsClient } = useWebsocket();

  const [notificationsRes, refetch] = useNotificationsQuery({
    variables: {
      take: 100,
    },
  });
  React.useEffect(() => {
    if (notificationsRes.data) {
      dispatch({
        type: 'UPDATE_NOTIFICATIONS',
        notifications: notificationsRes.data.notifications,
      });
    }
  }, [notificationsRes?.data]);

  React.useEffect(() => {
    if (state.lastNotificationTime > 0 && state.hasUnreadNotifications) {
      toast((t) => (
        <div className="flex gap-4">
          <div>Er zijn nieuwe notificaties</div>
          <div>
            <button
              onClick={() => {
                navigate('/internal/notifications');
                toast.dismiss(t.id);
              }}
            >
              Bekijk
            </button>
          </div>
        </div>
      ));
    }
  }, [state.lastNotificationTime]);

  React.useEffect(() => {
    wsClient.send({
      ref: createId(),
      method: 'notifications/subscribe',
      data: {},
    });

    const disposable = wsClient.onMessage((msg) => {
      if (msg.method === 'notifications/new-notification') {
        dispatch({
          type: 'NEW_NOTIFICATION',
          notification: msg.data.notification,
        });
      }
    });
    return () => disposable.dispose();
  }, [true]);

  return (
    <ReactProvider
      value={{
        state,
        refresh: () => {
          refetch({
            requestPolicy: 'network-only',
          });
        },
      }}
    >
      {children}
    </ReactProvider>
  );
};

export const useNotificationsCtx = useContext;
export const Consumer = ReactConsumer;
