import { createId } from '@paralleldrive/cuid2';
import { useEffect, useMemo, useReducer, useState } from 'react';

export interface IPaginationVariables {
  // TODO: Type this thing somehow dynamically
  cursor: any;
  take: number;
}

export interface IPaginationReturnValue<T> {
  variables: IPaginationVariables;
  hasNext: boolean;
  next: () => void;
  hasPrevious: boolean;
  previous: () => void;
  data: T[];
  error?: Error;
  isFetching: boolean;
  reset: () => void;
}

export type PageDataFetcherFn<T> = (variables: IPaginationVariables) => Promise<T[]>;

export interface IUsePaginationProps<T> {
  key: string;
  fetcher: PageDataFetcherFn<T>;
  pageSize: number;
  initialCursor: any;
  refreshToken?: any;
}

interface IPaginationState {
  isFetching: boolean;
  fetchToken: string;
  data: any[];
  error?: Error;
}

type IPaginationAction =
  | {
      type: 'fetchStarted';
      token: string;
    }
  | {
      type: 'fetchCompleted';
      token: string;
      data: any[];
    }
  | {
      type: 'fetchFailed';
      token: string;
      error: Error;
    };

function paginationReducer<T>(state: IPaginationState, action: IPaginationAction): IPaginationState {
  switch (action.type) {
    case 'fetchStarted': {
      return {
        ...state,
        fetchToken: action.token,
        isFetching: true,
      };
    }
    case 'fetchCompleted': {
      if (action.token !== state.fetchToken) {
        return state;
      }

      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case 'fetchFailed': {
      if (action.token !== state.fetchToken) {
        return state;
      }

      return {
        ...state,
        data: [],
        error: action.error,
        isFetching: false,
      };
    }
  }
}

export function usePagination<T>(props: IUsePaginationProps<T>): IPaginationReturnValue<T> {
  const { key, fetcher, pageSize, initialCursor, refreshToken } = props;
  const [variables, setVariables] = useState<IPaginationVariables>({ cursor: initialCursor, take: pageSize + 1 });
  const [state, dispatch] = useReducer(paginationReducer, {
    isFetching: false,
    fetchToken: '',
    data: [],
    error: undefined,
  });

  const entries = useMemo(() => {
    const cloned = [...state.data];
    if (state.data.length > pageSize) {
      cloned.pop();

      if (cloned.length > pageSize) {
        cloned.shift();
      }
    }
    return cloned;
  }, [state.data]);

  useEffect(() => {
    const token = createId();

    dispatch({
      type: 'fetchStarted',
      token,
    });

    fetcher(variables)
      .then((val) => {
        dispatch({
          type: 'fetchCompleted',
          data: val,
          token,
        });
      })
      .catch((err) => {
        dispatch({
          type: 'fetchFailed',
          error: err,
          token,
        });
      });
  }, [variables, refreshToken, fetcher]);

  const cursor = variables.cursor;
  const hasNext = state.data.length >= pageSize;
  const next = () => {
    if (!hasNext) return;

    const lastEntry = state.data[state.data.length - 1];
    if (!lastEntry) return;
    // @ts-ignore
    const nextCursor = lastEntry[key];
    setVariables({
      cursor: nextCursor,
      take: pageSize + 1,
    });
  };

  const hasPrevious = useMemo(() => {
    if (!cursor) {
      return false;
    }

    if (state.data[0]?.[key] === cursor) {
      return true;
    }

    if (state.data[state.data.length - 1]?.[key] === cursor) {
      return state.data.length === pageSize + 2;
    }

    return false;
  }, [cursor, state.data]);
  const previous = () => {
    if (!hasPrevious) return;

    const firstEntry = state.data[0]?.[key] === cursor ? state.data[0] : state.data[1];
    if (!firstEntry) return;
    // @ts-ignore
    const nextCursor = firstEntry[key];
    setVariables({
      cursor: nextCursor,
      take: -(pageSize + 2),
    });
  };

  return {
    variables,
    hasNext,
    next,
    hasPrevious,
    previous,
    data: entries,
    isFetching: state.isFetching,
    reset: () => {
      setVariables({
        cursor: null,
        take: pageSize + 1,
      });
    },
  };
}
