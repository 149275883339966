import { ISimpleSelectItem } from '../../../../components/select/SimpleSelect';
import { MailboxType } from '../../../../generated/graphql';

export const MailboxTypeToText: Record<MailboxType, string> = {
  [MailboxType.IncomingDocuments]: 'Inkomende Documenten',
  [MailboxType.OrderEntry]: 'Inkomende Orders',
};

export const MailboxTypeSelectOptions: ISimpleSelectItem[] = [
  {
    key: MailboxType.IncomingDocuments,
    name: MailboxTypeToText[MailboxType.IncomingDocuments],
  },
  {
    key: MailboxType.OrderEntry,
    name: MailboxTypeToText[MailboxType.OrderEntry],
  },
];
