import * as React from 'react';
import { urlJoin } from '../utils/url';

import { API_ROOT } from '../constants';
import { WebsocketClient } from '../websocket/WebsocketClient';
import createContext from './create-context';

const WS_URL = (function () {
  const wsUrl = new URL(urlJoin(API_ROOT, '/ws'), window.location.href);
  wsUrl.protocol = 'wss';
  return wsUrl.toString();
})();

interface ProviderValue {
  websocket: WebsocketClient;
}

interface WebsocketProviderProps {
  children?: React.ReactNode;
}

const [useContext, ReactProvider, _ReactConsumer] = createContext<ProviderValue>();

let _client: WebsocketClient | null = null;
function getClient(): WebsocketClient {
  if (!_client || _client.isDisposed) {
    _client = new WebsocketClient(WS_URL);
  }
  return _client;
}

export const WebsocketProvider: React.FC<WebsocketProviderProps> = (props) => {
  const { children } = props;
  const [client] = React.useState(getClient);

  return (
    <ReactProvider
      value={{
        websocket: client,
      }}
    >
      {children}
    </ReactProvider>
  );
};

export const useWebsocket = useContext;
