import { Plus } from '@phosphor-icons/react';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { IterableElement } from 'type-fest';

import { Button } from '../../../../components/button/Button';
import { ConfirmDialog } from '../../../../components/dialog/ConfirmDialog';
import { ITableCallbacks, ITableHeader, Table } from '../../../../components/table/Table';
import { TableActionBar } from '../../../../components/table/TableActionBar';
import { useDeleteLocationContactsMutation } from '../../../../generated/graphql';
import { getDisplayError } from '../../../../utils/get-display-error';
import { formatPhoneNumber } from '../../../../utils/phonenumber';
import { Location } from '../Location';
import { useLocation } from '../Location/locationContext';

export type LocationContact = IterableElement<Location['contacts']>;

const CONTACT_HEADERS: ITableHeader[] = [
  {
    id: 'name',
    name: 'Naam',
  },
  {
    id: 'email',
    name: 'Email',
  },
  {
    id: 'phone',
    name: 'Telefoon',
  },
  {
    id: 'function',
    name: 'Functie',
  },
  {
    id: 'language',
    name: 'Taal',
  },
];

interface IContactActionsProps {
  selections: LocationContact[];
  refresh: () => void;
}

const ContactSelectionActions: React.FC<IContactActionsProps> = (props) => {
  const { selections, refresh } = props;
  const [deleteState, executeDelete] = useDeleteLocationContactsMutation();

  return (
    <div>
      <ConfirmDialog
        triggerText="Verwijder"
        title="Verwijder contacten"
        submitText="Verwijder"
        description={
          <div>
            <div>Ben je zeker dat je volgende contacten wilt wissen?</div>
            <ul>
              {selections.map((s) => (
                <li key={s.id}>{s.name}</li>
              ))}
            </ul>
          </div>
        }
        onSubmit={async () => {
          try {
            const res = await executeDelete({
              ids: selections.map((s) => s.id),
            });
            refresh();
            if (res.error) {
              throw res.error;
            }
          } catch (err) {
            toast.error('Kon contacten niet verwijderen: ' + getDisplayError(err));
          }
        }}
      />
    </div>
  );
};

export const LocationContacts = () => {
  const navigate = useNavigate();
  const { location, refreshData } = useLocation();
  const [selections, setSelections] = useState<LocationContact[]>([]);
  const [tableCallbacks, setTableCallbacks] = useState<ITableCallbacks | null>(null);

  const refresh = useCallback(() => {
    refreshData();
    tableCallbacks?.resetSelections();
  }, [refreshData, tableCallbacks?.resetSelections]);

  return (
    <div>
      <TableActionBar
        selections={selections}
        cancelSelection={tableCallbacks?.resetSelections}
        renderSelectionBar={(selections) => {
          return <ContactSelectionActions selections={selections} refresh={refresh} />;
        }}
      >
        <div className="flex justify-end">
          <div className="flex">
            <div>
              <Button color="primary" onTrigger={() => navigate('new')} iconLeft={<Plus className="button-icon" />}>
                Nieuw contact
              </Button>
            </div>
          </div>
        </div>
      </TableActionBar>
      <Table
        idKey="id"
        headers={CONTACT_HEADERS}
        data={location.contacts}
        onSelect={setSelections}
        registerCallbacks={setTableCallbacks}
        mapData={(data) => {
          return [
            <Link to={`${data.id}`} className="link-text">
              {data.name}
            </Link>,
            data.email,
            formatPhoneNumber(data.phone, true),
            data.function,
            data.language,
          ];
        }}
        isMultiSelect
      />
    </div>
  );
};
