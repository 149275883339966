import { Plus } from '@phosphor-icons/react';
import { Button } from 'components/button/Button';
import { InputField } from 'components/input/InputField';
import { Formik } from 'formik';
import { useCreateProductTypeMutation } from 'generated/graphql';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { getDisplayError } from '../../../utils/get-display-error';

const createProductTypeSchema = Yup.object().shape({
  id: Yup.string().required('Vereist'),
  nameNl: Yup.string().required('Vereist'),
  nameFr: Yup.string().required('Vereist'),
  nameEn: Yup.string().required('Vereist'),
  nameDe: Yup.string().required('Vereist'),
});

interface IProductTypeValues {
  id: string;
  nameNl: string;
  nameFr: string;
  nameEn: string;
  nameDe: string;
}

const initialValues: IProductTypeValues = {
  id: '',
  nameNl: '',
  nameFr: '',
  nameEn: '',
  nameDe: '',
};

interface ICreateProductTypePageProps {
  onComplete?: (data: IProductTypeValues) => void;
  onCancel?: () => void;
}

export const CreateProductTypeForm: React.FC<ICreateProductTypePageProps> = (props) => {
  const { onComplete, onCancel } = props;
  const [, createProductTypeMutation] = useCreateProductTypeMutation();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createProductTypeSchema}
      onSubmit={async (values) => {
        try {
          const result = await createProductTypeMutation({
            data: values,
          });
          if (result.error) {
            throw result.error;
          }
          if (result.data) {
            onComplete?.(values);
          }
          toast.success('Verpakking type aangemaakt');
        } catch (err: any) {
          toast.error('Kon product type niet aanmaken: ' + getDisplayError(err));
        }
      }}
    >
      {({ handleSubmit, isSubmitting, errors, submitForm }) => {
        return (
          <form onSubmit={handleSubmit}>
            <InputField labelText="Code" type="text" name="id" />
            <InputField labelText="Naam NL" type="text" name="nameNl" />
            <InputField labelText="Naam EN" type="text" name="nameEn" />
            <InputField labelText="Naam FR" type="text" name="nameFr" />
            <InputField labelText="Naam DE" type="text" name="nameDe" />

            <div className="flex justify-between">
              {onCancel && (
                <div>
                  <Button isDisabled={isSubmitting} isLoading={isSubmitting} onTrigger={onCancel}>
                    Annuleer
                  </Button>
                </div>
              )}
              <div>
                <Button
                  type="submit"
                  color="primary"
                  iconLeft={<Plus className="button-icon" />}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                  onTrigger={submitForm}
                >
                  Voeg product type toe
                </Button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
