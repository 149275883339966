import * as Dialog from '@radix-ui/react-dialog';
import React from 'react';
import toast from 'react-hot-toast';

import {
  CustomerPortalCreateLocationMutation,
  useCustomerPortalCreateLocationMutation,
} from '../../../generated/graphql';
import { CustomerPortalCreateLocationForm } from './CustomerPortalCreateLocationForm';
import { nullthrows } from '../../../utils/invariant';
import { getDisplayError } from '../../../utils/get-display-error';
import { useTranslation } from '../../../contexts/translation-context';

export interface ILocationPreviewDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onCreated: (data: CustomerPortalCreateLocationMutation['customerPortalCreateLocation']) => void;
}

export const CustomerPortalCreateLocationDialog: React.FC<ILocationPreviewDialogProps> = (props) => {
  const { isOpen, setIsOpen, onCreated } = props;
  const [, createLocationMutation] = useCustomerPortalCreateLocationMutation();
  const { i18n } = useTranslation();

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="dialog-overlay" />
        <Dialog.Content className="dialog-content">
          <Dialog.Title className="heading-one mb-4">{i18n('customerPortal.locations.newLocation')}</Dialog.Title>

          <CustomerPortalCreateLocationForm
            onCancel={() => setIsOpen(false)}
            onSubmit={async (values) => {
              try {
                const result = await createLocationMutation({
                  data: values,
                });
                if (result.error) {
                  throw result.error;
                }
                const locationData = nullthrows(result.data?.customerPortalCreateLocation, 'Location not created');
                onCreated(locationData);
                toast.success(i18n('customerPortal.locations.locationCreated'));
              } catch (err: any) {
                toast.error(
                  i18n('customerPortal.locations.locationCreated', {
                    error: getDisplayError(err),
                  }),
                );
              }
            }}
          />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
