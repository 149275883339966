import { MagnifyingGlass, Plus } from '@phosphor-icons/react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';

import { Input } from '../../../components/input/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import { GetLocationsDocument, GetLocationsQuery, GetLocationsQueryVariables } from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { LinkButton } from '../../../components/button/ButtonLink';
import { Table } from 'components/table/Table';
import { PageHeading } from 'components/PageHeading';
import { Breadcrumb } from 'components/Breadcrumb';

const TABLE_HEADERS = [
  {
    id: 'name',
    name: 'Naam',
  },
  {
    id: 'address',
    name: 'Adres',
  },
  {
    id: 'postalCode',
    name: 'Postcode',
  },
  {
    id: 'country',
    name: 'Land',
  },
  {
    id: 'isActive',
    name: 'Is actief?',
  },
];

const LocationsPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetLocationsQuery, GetLocationsQueryVariables>(
          GetLocationsDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters: {
              search: searchValue,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.locations ?? [];
    },
    [client, searchValue],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 20,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  useEffect(() => {
    page.reset();
  }, [searchValue]);

  return (
    <>
      <PageHeader title="Locaties" />

      <PageHeading
        leftSide={<Breadcrumb currentItem="Locaties" />}
        rightSide={
          <div>
            <LinkButton to="new" color="primary" iconLeft={<Plus className="button-icon" />}>
              Nieuwe locatie
            </LinkButton>
          </div>
        }
      />

      <div className="px-4">
        <div className="flex w-full mb-8">
          <Input
            type="text"
            placeholder="Zoek een locatie..."
            value={searchValue}
            onChange={setSearchValue}
            iconLeft={<MagnifyingGlass className="input-icon" />}
          />
        </div>
      </div>

      <Table
        idKey="id"
        headers={TABLE_HEADERS}
        data={page.data}
        mapData={(location) => {
          return [
            <Link to={`${location.id}/general`} className="link-text">
              {location.name}
            </Link>,
            `${location.street} ${location.streetNumber}`,
            location.postalCode,
            location.country,
            location.deactivationReason ? 'Niet actief' : 'Actief',
          ];
        }}
      />

      <div className="my-4">
        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};

export default LocationsPage;
