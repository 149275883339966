import { MagnifyingGlass, Plus } from '@phosphor-icons/react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';
import ExcelJS from 'exceljs';
import toast from 'react-hot-toast';

import { Input } from '../../../components/input/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import {
  GetSuppliersDocument,
  GetSuppliersForExportDocument,
  GetSuppliersForExportQuery,
  GetSuppliersForExportQueryVariables,
  GetSuppliersQuery,
  GetSuppliersQueryVariables,
  SupplierDocumentType,
  UserRole,
} from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { LinkButton } from '../../../components/button/ButtonLink';
import { Button } from 'components/button/Button';
import { roleToNumber } from 'src/app/auth/roles.client';
import { useAuth } from 'contexts/auth-context';
import { Table } from 'components/table/Table';
import { PageHeading } from 'components/PageHeading';
import { Breadcrumb } from 'components/Breadcrumb';

const TABLE_HEADERS = [
  {
    id: 'name',
    name: 'Naam',
  },
  {
    id: 'vatNumber',
    name: 'BTW nummer',
  },
  {
    id: 'address',
    name: 'Adres',
  },
  {
    id: 'postalCode',
    name: 'Postcode',
  },
  {
    id: 'country',
    name: 'Land',
  },
];

const SuppliersPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const [isExporting, setIsExporting] = useState(false);
  const client = useClient();
  const { me } = useAuth();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetSuppliersQuery, GetSuppliersQueryVariables>(
          GetSuppliersDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters: {
              search: searchValue,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.suppliers ?? [];
    },
    [client, searchValue],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 20,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  useEffect(() => {
    page.reset();
  }, [searchValue]);

  const isAdmin = roleToNumber(me.role) >= roleToNumber(UserRole.Admin);
  return (
    <>
      <PageHeader title="Vervoerders" />

      <PageHeading
        leftSide={<Breadcrumb currentItem="Vervoerders" />}
        rightSide={
          <div className="flex gap-4">
            {isAdmin && (
              <div>
                <Button
                  isLoading={isExporting}
                  isDisabled={isExporting}
                  onTrigger={async () => {
                    if (isExporting) {
                      return;
                    }

                    setIsExporting(true);
                    try {
                      const suppliersDict: Record<string, NonNullable<GetSuppliersForExportQuery['suppliers'][0]>> = {};

                      let cursor = null;
                      while (true) {
                        const result = await client
                          .query<GetSuppliersForExportQuery, GetSuppliersForExportQueryVariables>(
                            GetSuppliersForExportDocument,
                            {
                              id: cursor,
                              take: 100,
                              filters: {
                                isActive: true,
                              },
                            },
                            {
                              requestPolicy: 'cache-and-network',
                            },
                          )
                          .toPromise();

                        if (result.error) {
                          throw result.error;
                        }

                        if (!result.data) {
                          throw new Error('No data');
                        }

                        let hasNewData = false;
                        for (const supplier of result.data.suppliers) {
                          if (!suppliersDict[supplier.id]) {
                            suppliersDict[supplier.id] = supplier;
                            hasNewData = true;
                          }

                          cursor = supplier.id;
                        }

                        if (!hasNewData) {
                          break;
                        }
                      }

                      const suppliers = Object.values(suppliersDict);
                      const workbook = new ExcelJS.Workbook();
                      workbook.creator = 'TDMS';
                      const worksheet = workbook.addWorksheet('Suppliers');

                      worksheet.columns = [
                        { header: 'Referentie', key: 'ref', width: 10 },
                        { header: 'Naam', key: 'name', width: 32 },
                        { header: 'Bedrijfsnummer', key: 'companyNumber', width: 32 },
                        { header: 'BTW Nummer', key: 'vatNumber', width: 32 },
                        { header: 'Straat', key: 'street', width: 16 },
                        { header: 'Huisnummer', key: 'streetNumber', width: 16 },
                        { header: 'Land', key: 'country', width: 16 },
                        { header: 'Postcode', key: 'postalCode', width: 32 },
                        { header: 'Stad', key: 'city', width: 32 },
                        { header: 'Betaaltermijn', key: 'paymentTerm', width: 32 },
                        { header: 'Aansprakelijkheidsverzekering', key: 'civilLiabilityDoc', width: 32 },
                        { header: 'CMR Verzekering', key: 'cmrInsuranceDoc', width: 32 },
                        { header: 'Transport verzekering', key: 'transportInsuranceDoc', width: 32 },
                        { header: 'Vragenlijst', key: 'surveyDoc', width: 32 },
                        { header: 'Factuur e-mail', key: 'invoiceEmail', width: 32 },
                        { header: 'Factuur telefoon', key: 'invoicePhone', width: 32 },
                        { header: 'Offert e-mail', key: 'quotationsEmail', width: 32 },
                        { header: 'Offert telefoon', key: 'quotationsPhone', width: 32 },
                        { header: 'CMR e-mail', key: 'cmrEmail', width: 32 },
                        { header: 'CMR telefoon', key: 'cmrPhone', width: 32 },
                        { header: 'TRO e-mail', key: 'troEmail', width: 32 },
                        { header: 'TRO telefoon', key: 'troPhone', width: 32 },
                      ];

                      for (const supplier of suppliers) {
                        worksheet.addRow({
                          ref: supplier.id,
                          name: supplier.name,
                          companyNumber: supplier.companyNumber,
                          vatNumber: supplier.vatNumber,
                          street: supplier.street,
                          streetNumber: supplier.streetNumber,
                          country: supplier.country,
                          postalCode: supplier.postalCode,
                          city: supplier.city,
                          paymentTerm: supplier.paymentTerm,
                          civilLiabilityDoc: supplier.documents.find(
                            (v) => v.type === SupplierDocumentType.CivilLiabilityInsurance,
                          )
                            ? 'Yes'
                            : 'No',
                          cmrInsuranceDoc: supplier.documents.find((v) => v.type === SupplierDocumentType.CmrInsurance)
                            ? 'Yes'
                            : 'No',
                          transportInsuranceDoc: supplier.documents.find(
                            (v) => v.type === SupplierDocumentType.TransportInsurance,
                          )
                            ? 'Yes'
                            : 'No',
                          surveyDoc: supplier.documents.find((v) => v.type === SupplierDocumentType.Survey)
                            ? 'Yes'
                            : 'No',
                          selfBillingsEmail: supplier.contacts.find((v) => v.shouldReceiveSelfBillings)?.email || '',
                          selfBillingsPhone: supplier.contacts.find((v) => v.shouldReceiveSelfBillings)?.phone || '',
                          troEmail: supplier.contacts.find((v) => v.shouldReceiveTransportOrders)?.email || '',
                          troPhone: supplier.contacts.find((v) => v.shouldReceiveTransportOrders)?.phone || '',
                        });
                      }

                      const arr = (await workbook.xlsx.writeBuffer()) as Buffer;
                      const blob = new Blob([arr], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                      });
                      const url = URL.createObjectURL(blob);
                      const a = document.createElement('a');
                      a.href = url;
                      a.download = 'vervoerders.xlsx';
                      a.click();
                      URL.revokeObjectURL(url);

                      toast.success('Export voltooid');
                    } catch (err) {
                      console.error(err);
                      toast.error('Vervoerders export mislukt');
                    }
                    setIsExporting(false);
                  }}
                >
                  Exporteer vervoerders
                </Button>
              </div>
            )}
            <div>
              <LinkButton to="new" color="primary" iconLeft={<Plus className="button-icon" />}>
                Nieuwe vervoerder
              </LinkButton>
            </div>
          </div>
        }
      />

      <div className="flex w-full px-4 mb-8">
        <Input
          type="text"
          placeholder="Zoek een vervoerder..."
          value={searchValue}
          onChange={setSearchValue}
          iconLeft={<MagnifyingGlass className="input-icon" />}
        />
      </div>

      <Table
        idKey="id"
        headers={TABLE_HEADERS}
        data={page.data}
        mapData={(supplier) => {
          return [
            <Link to={`${supplier.id}/general`} className="link-text">
              {supplier.name}
              {!!supplier.deactivationReason && ` - gedeactiveerd`}
            </Link>,
            supplier.vatNumber,
            `${supplier.street} ${supplier.streetNumber}`,
            supplier.postalCode,
            supplier.country,
          ];
        }}
      />

      <div className="my-4">
        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};

export default SuppliersPage;
