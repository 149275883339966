import { Plus, Trash } from '@phosphor-icons/react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { IterableElement } from 'type-fest';

import { ConfirmDialog } from '../../../../components/dialog/ConfirmDialog';
import { Customer } from '../Customer';
import { useCustomer } from '../Customer/customerContext';
import { useDeleteCustomerUserInviteMutation, useDeactivateCustomerUserMutation } from '../../../../generated/graphql';
import { getDisplayError } from '../../../../utils/get-display-error';
import { LinkButton } from '../../../../components/button/ButtonLink';
import { formatDateTime } from '../../../../utils/date';

export type CustomerUser = IterableElement<Customer['users']>;
export type CustomerUserInvite = IterableElement<Customer['invitedUsers']>;

export const CustomerUsersPage = () => {
  const navigate = useNavigate();
  const { customer, refreshData } = useCustomer();
  const [, deactivateCustomerUser] = useDeactivateCustomerUserMutation();
  const [, deleteInvite] = useDeleteCustomerUserInviteMutation();

  return (
    <div>
      <div className="flex justify-end mb-4">
        <LinkButton to="invite" color="primary" iconLeft={<Plus className="button-icon" />}>
          Nodig gebruiker uit
        </LinkButton>
      </div>

      <h2 className="heading-two mb-2">Uitgenodigde gebruikers</h2>

      {!customer.invitedUsers.length && <div>Geen uitnodigingen gevonden</div>}

      <div className="grid gap-4 grid-cols-2">
        {customer.invitedUsers.map((u) => {
          return (
            <div className="card p-4" key={u.id}>
              <div className="flex justify-between items-center">
                <div className="card-heading-text">{u.email}</div>

                <ConfirmDialog
                  triggerColor="danger"
                  triggerText={<Trash className="button-icon" />}
                  title="Uitnodiging verwijderen?"
                  submitText="Verwijder uitnodiging"
                  description={
                    <div>
                      {`Ben je zeker dat je de uitnodiging voor ${u.email} wilt verwijderen van klant ${customer.name}?`}
                    </div>
                  }
                  onSubmit={async () => {
                    try {
                      const result = await deleteInvite({
                        inviteId: u.id,
                        customerId: customer.id,
                      });
                      if (result.error) {
                        throw result.error;
                      }
                      toast.success('Uitnodiging verwijderd');
                    } catch (err) {
                      toast.error(`Kon uitnodiging niet verwijderen: ${getDisplayError(err)}`);
                    }
                  }}
                />
              </div>

              <div className="card-subtle-text">{formatDateTime(u.createdAt)}</div>
            </div>
          );
        })}
      </div>

      <h2 className="heading-two mb-2 mt-8">Gebruikers</h2>

      {!customer.users.length && <div>Geen gebruikers gevonden</div>}

      <div className="grid grid-cols-3 gap-4">
        {customer.users.map((u) => {
          return (
            <div className="card" key={u.id}>
              <div className="flex justify-between">
                <div className="card-heading-text">
                  <div>{u.name}</div>
                </div>

                <ConfirmDialog
                  triggerColor="danger"
                  triggerText={<Trash className="button-icon" />}
                  title="Gebruiker verwijderen?"
                  submitText="Verwijder gebruiker"
                  description={
                    <div>
                      {`Ben je zeker dat je de gebruiker ${u.name} wilt verwijderen van klant ${customer.name}?`}
                    </div>
                  }
                  onSubmit={async () => {
                    try {
                      const result = await deactivateCustomerUser({
                        userId: u.id,
                        customerId: customer.id,
                      });
                      if (result.error) {
                        throw result.error;
                      }
                      toast.success('Gebruiker verwijderd');
                    } catch (err) {
                      toast.error(`Kon gebruiker niet verwijderen: ${getDisplayError(err)}`);
                    }
                  }}
                />
              </div>
              <div className="card-subtle-text">{u.email}</div>
              <div className="card-subtle-text">{u.isActive ? 'Actief' : 'Niet actief'}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
