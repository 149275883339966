import { useField } from 'formik';

import { InputWrapper } from '../InputWrapper';
import { ISimpleComboboxItem, SimpleCombobox } from './SimpleCombobox';

export interface ISimpleComboboxFieldProps {
  labelText: string;
  name: string;
  helperText?: string;
  isDisabled?: boolean;
  items: ISimpleComboboxItem[];
  isNullable?: boolean;
}

export const SimpleComboboxField: React.FC<ISimpleComboboxFieldProps> = (props) => {
  const { name, labelText, helperText, isDisabled, items, isNullable } = props;
  const [field, meta, helpers] = useField({ name });

  return (
    <InputWrapper labelText={labelText} invalidText={meta.touched ? meta.error : undefined} helperText={helperText}>
      <SimpleCombobox
        items={items}
        selectedItem={field.value}
        onSelect={(val) => {
          helpers.setValue(val);
        }}
        onBlur={() => helpers.setTouched(true)}
        isDisabled={isDisabled}
        isInvalid={Boolean(meta.touched && meta.error)}
        isNullable={isNullable}
      />
    </InputWrapper>
  );
};
