import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Button } from '../../../components/button/Button';
import { InputField } from '../../../components/input/InputField';
import { PageHeader } from '../../../components/PageHeader';
import { useInternalCreateUserMutation } from '../../../generated/graphql';
import { getDisplayError } from '../../../utils/get-display-error';
import { Plus } from '@phosphor-icons/react';

const createUserSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Vereist').required('Vereist'),
  email: Yup.string().email('Ongeldig email').required('Vereist'),
});

const CreateUserPage = () => {
  const navigate = useNavigate();
  const [, createUserMutation] = useInternalCreateUserMutation();

  return (
    <>
      <PageHeader title="Nieuwe gebruiker" />

      <div>
        <div className="settings-page-heading">
          <h2 className="heading-two">Nieuwe gebruiker</h2>
        </div>

        <Formik
          initialValues={{ name: '', email: '' }}
          validationSchema={createUserSchema}
          onSubmit={async (values) => {
            try {
              const result = await createUserMutation({ data: values });
              if (result.error) {
                throw result.error;
              }
              if (result.data) {
                navigate(`../${result.data.internalCreateUser.id}`);
              }
              toast.success('Gebruiker aangemaakt');
            } catch (err: any) {
              toast.error('Kon gebruiker niet aanmaken: ' + getDisplayError(err));
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <InputField labelText="Naam" type="text" name="name" />
              <InputField labelText="Email" type="email" name="email" />
              <Button
                type="submit"
                color="primary"
                iconLeft={<Plus className="button-icon" />}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Maak gebruiker aan
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CreateUserPage;
