import { Plus } from '@phosphor-icons/react';
import { Formik } from 'formik';
import diff from 'object-diff';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useClient } from 'urql';
import * as Yup from 'yup';

import { Button } from '../../../../components/button/Button';
import { CheckboxField } from '../../../../components/checkbox/CheckboxField';
import { InputField } from '../../../../components/input/InputField';
import { TextAreaField } from '../../../../components/textarea/TextAreaField';
import {
  OrderStatus,
  GetHistoricalOrderLinesForSuggestionsDocument,
  GetHistoricalOrderLinesForSuggestionsQuery,
  GetHistoricalOrderLinesForSuggestionsQueryVariables,
  Supplier,
  useUpdateOrderMutation,
} from '../../../../generated/graphql';
import { getDisplayError } from '../../../../utils/get-display-error';
import { CustomerComboboxField } from '../../../customer/CustomerComboboxField';
import { SupplierComboboxField } from '../../../supplier/SupplierComboboxField';
import { useOrder } from './orderContext';
import { TrailerTypes } from './TrailerTypes';
import { formatNumber } from '../../../../utils/number';
import { calculateLinesTotalExclVat } from '../../utils/price';
import { Card } from 'components/Card';

const updateOrderSchema = Yup.object().shape({
  customer: Yup.mixed().nullable().required('Vereist'),
  customerRef: Yup.string(),
  internalNotes: Yup.string(),
  externalNotes: Yup.string(),
  requiresAnonymousCMR: Yup.boolean().required('Vereist'),
});

function getLineMatchingKey(stops: Array<{ location: { country: string; postalCode: string } }>): string {
  return stops
    .map((stop) => (stop.location.country + stop.location.postalCode).toUpperCase())
    .sort()
    .join(',');
}

export const UpdateOrder = () => {
  const client = useClient();
  const { order } = useOrder();
  const [, updateOrderMutation] = useUpdateOrderMutation();
  const [historicalOrderLines, setHistoricalOrderLines] = useState<
    GetHistoricalOrderLinesForSuggestionsQuery['historicalOrderLinesForSuggestions']
  >([]);

  useEffect(() => {
    if (order && !order.supplier) {
      const run = async () => {
        const operation = client.query<
          GetHistoricalOrderLinesForSuggestionsQuery,
          GetHistoricalOrderLinesForSuggestionsQueryVariables
        >(GetHistoricalOrderLinesForSuggestionsDocument, {
          orderId: order.id,
        });
        const result = await operation.toPromise();
        if (result.data) {
          setHistoricalOrderLines(result.data.historicalOrderLinesForSuggestions);
        }
        if (result.error) {
          console.error('Failed to fetch supplier suggestions', result.error);
        }
      };
      run().catch(console.error);
    }
  }, [order]);

  const initialValues = useMemo(() => {
    return {
      supplier: (order.supplier as Supplier) ?? null,
      customer: order.customer,
      noPurchase: order.noPurchase,
      customerRef: order.customerRef,
      internalNotes: order.internalNotes,
      externalNotes: order.externalNotes,
      requiresAnonymousCMR: order.requiresAnonymousCMR,
      allowedTrailerTypes: order.allowedTrailerTypes,
    };
  }, [order]);

  const suggestedSuppliers = useMemo(() => {
    const suppliers = new Map<number, any>();
    const supplierOrderLines = new Map<number, typeof historicalOrderLines>();

    const lineKeys = order.lines.map((line) => getLineMatchingKey(line.stops));

    for (const orderLine of historicalOrderLines) {
      const supplierId = orderLine.order.supplier?.id;
      if (supplierId) {
        const existingOrderLines = supplierOrderLines.get(supplierId) ?? [];
        suppliers.set(supplierId, orderLine.order.supplier);
        existingOrderLines.push(orderLine);
        supplierOrderLines.set(supplierId, existingOrderLines);
      }
    }

    const avgPerSupplier = new Map<number, number>();
    for (const [supplierId, supplierOrders] of supplierOrderLines) {
      const pricesPerKey = new Map<string, number[]>();

      for (const orderLine of supplierOrders) {
        const lineKey = getLineMatchingKey(orderLine.stops);
        const price = calculateLinesTotalExclVat(orderLine.purchases);
        const existingPrices = pricesPerKey.get(lineKey) ?? [];
        existingPrices.push(price);
        pricesPerKey.set(lineKey, existingPrices);
      }

      let hasAllStops = true;
      const averagePricePerKey = [];
      for (const lineKey of lineKeys) {
        const prices = pricesPerKey.get(lineKey);
        if (!prices?.length) {
          hasAllStops = false;
          continue;
        }
        averagePricePerKey.push(prices.reduce((a, b) => a + b, 0) / prices.length);
      }

      if (!hasAllStops) {
        continue;
      }

      avgPerSupplier.set(
        supplierId,
        averagePricePerKey.reduce((a, b) => a + b, 0),
      );
    }

    let suppliersResultValue = Array.from(suppliers.values());
    suppliersResultValue = suppliersResultValue.sort((a, b) => {
      return (
        (avgPerSupplier.get(a.id) ?? Number.MAX_SAFE_INTEGER) - (avgPerSupplier.get(b.id) ?? Number.MAX_SAFE_INTEGER)
      );
    });

    return {
      suppliers: suppliersResultValue,
      avgPerSupplier,
    };
  }, [historicalOrderLines]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={updateOrderSchema}
      onSubmit={async (values) => {
        try {
          const patch: Partial<typeof values> = diff(initialValues, values);
          const { customer, supplier, ...otherValues } = patch;
          const customerId = customer?.id;
          const supplierId = supplier === undefined ? undefined : (supplier?.id ?? null);
          const result = await updateOrderMutation({
            id: order.id,
            data: {
              customerId,
              supplierId,
              ...otherValues,
            },
          });
          if (result.error) {
            throw result.error;
          }
          toast.success('Order aangepast');
        } catch (err: any) {
          toast.error('Kon order niet aanpassen: ' + getDisplayError(err));
        }
      }}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="grid gap-4">
          <Card title="Algemene informatie">
            {!order.customer && order.originalCustomerName && (
              <div className="text-sm">{`Gedetecteerde klant niet gevonden: ${order.originalCustomerName}`}</div>
            )}
            <CustomerComboboxField name="customer" isDisabled={isSubmitting} />

            <SupplierComboboxField
              name="supplier"
              isDisabled={isSubmitting}
              isNullable={order.status === OrderStatus.Draft || order.status === OrderStatus.Created}
            />

            {suggestedSuppliers.suppliers.length > 0 && !order.supplier && (
              <div className="flex flex-wrap gap-2 mb-4">
                <div className="text-dark-03">Voorgestelde vervoerders:</div>
                {suggestedSuppliers.suppliers.map((suggestedSupplier) => {
                  const avgPrice = suggestedSuppliers.avgPerSupplier.get(suggestedSupplier.id);
                  let txtValue = suggestedSupplier.name;

                  if (avgPrice) {
                    txtValue += ` (€ ${formatNumber(avgPrice, 2, { decimalSeperator: ',', removeTrailingZeros: true })})`;
                  }

                  return (
                    <div
                      key={`suggested-supplier-${suggestedSupplier.id}`}
                      className="cursor-pointer hover:text-orange-01 text-dark-01 whitespace-nowrap"
                      onClick={() => {
                        setFieldValue('supplier', suggestedSupplier);
                      }}
                    >
                      {txtValue}
                    </div>
                  );
                })}
              </div>
            )}

            <InputField type="text" labelText="Factuurreferentie klant" name="customerRef" isDisabled={isSubmitting} />

            <div className="grid grid-cols-2">
              <CheckboxField labelText="Moet anonieme CMR aanmaken" name="requiresAnonymousCMR" />
              <CheckboxField labelText="Geen aankopen" name="noPurchase" />
            </div>
          </Card>

          <Card title="Notities">
            <TextAreaField
              labelText="Interne notities"
              name="internalNotes"
              isDisabled={isSubmitting}
              spellCheck={true}
            />
            <TextAreaField
              labelText="Externe notities (voor vervoerder)"
              name="externalNotes"
              isDisabled={isSubmitting}
              spellCheck={true}
            />
          </Card>

          <Card title="Trailer types">
            <TrailerTypes
              value={values.allowedTrailerTypes}
              onChange={(newTrailerTypes) => {
                setFieldValue('allowedTrailerTypes', newTrailerTypes);
              }}
              isMultiSelect={true}
            />
          </Card>

          <div>
            <Button
              type="submit"
              color="primary"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              iconLeft={<Plus className="button-icon" />}
            >
              Bewaar aanpassingen
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
