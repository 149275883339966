import { Star } from '@phosphor-icons/react';
import classNames from '@utils/classnames';

export interface IRatingInputProps {
  value: number;
  onChange: (newValue: number) => void;
}

export const RatingInput: React.FC<IRatingInputProps> = (props) => {
  const { value, onChange } = props;

  return (
    <div className="flex">
      {new Array(5).fill('').map((_v, idx) => {
        return (
          <div
            className="cursor-pointer"
            onClick={() => {
              onChange(idx + 1);
            }}
          >
            <Star
              className={classNames('w-10 h-10', {
                'text-orange-02 fill-orange-02': idx < value,
                'text-dark-04': idx >= value,
              })}
              key={idx}
            />
          </div>
        );
      })}
    </div>
  );
};
