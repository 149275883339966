import { FieldArray, Formik } from 'formik';
import toast from 'react-hot-toast';
import { Plus, Trash } from '@phosphor-icons/react';
import * as Yup from 'yup';

import { Breadcrumb } from '../../../../../components/Breadcrumb';
import { PageHeader } from '../../../../../components/PageHeader';
import { getDisplayError } from '../../../../../utils/get-display-error';
import { InputField } from '../../../../../components/input/InputField';
import { Button } from '../../../../../components/button/Button';
import { formatInputDate } from '../../../../../utils/date';
import { MinimalGeneralSettings, useMinimalGeneralSettings } from '../../../../../contexts/minimal-settings-context';
import {
  IMinimalVatRate,
  VatRateComboboxField,
  vatRateToComboboxItem,
} from '../../../../order/vatRate/VatRateComboboxField';
import { ISimpleComboboxItem } from '../../../../../components/combobox/SimpleCombobox';
import { Customer, CustomerComboboxField } from '../../../../customer/CustomerComboboxField';
import { SuspenseSpinner } from '../../../../../components/SuspenseSpinner';
import { useCreateInvoiceMutation } from '../../../../../generated/graphql';
import { nullthrows } from '../../../../../utils/invariant';
import { parseNumberInput } from '../../../../../utils/number';
import { PageHeading } from 'components/PageHeading';

const createInvoiceSchema = Yup.object().shape({
  invoiceDate: Yup.string().min(1, 'Vereist').required('Vereist'),
  customerRef: Yup.string(),
});

interface IManualInvoiceLineValues {
  description: string;
  amount: string;
  unitPrice: string;
  vatRate: ISimpleComboboxItem | null;
}

export const getInitialLineValues = (
  settings: MinimalGeneralSettings,
  customer?: { defaultVatRate?: IMinimalVatRate | null } | null,
): IManualInvoiceLineValues => {
  const vatRate = customer?.defaultVatRate ?? settings.defaultVatRate;
  return {
    description: '',
    vatRate: vatRate ? vatRateToComboboxItem(vatRate) : null,
    amount: '1',
    unitPrice: '0',
  };
};

export const CreateManualSaleInvoicePage = () => {
  const { settings } = useMinimalGeneralSettings();
  const [_createResult, createInvoiceMutation] = useCreateInvoiceMutation();

  return (
    <>
      <PageHeader title="Nieuwe verkoop" />

      <PageHeading
        leftSide={
          <Breadcrumb
            parentItem={{
              name: 'Verkopen',
              to: '/internal/finance/sales',
            }}
            currentItem="Nieuwe manuele verkoop"
          />
        }
      />

      <div className="px-4">
        <Formik
          initialValues={{
            customerRef: '',
            invoiceDate: formatInputDate(new Date()),
            customer: null as null | Customer,
            lines: [getInitialLineValues(settings, null)],
          }}
          validationSchema={createInvoiceSchema}
          onSubmit={async (values) => {
            try {
              const customer = nullthrows(values.customer, 'No customer selected');
              const result = await createInvoiceMutation({
                data: {
                  date: values.invoiceDate,
                  customerRef: values.customerRef,
                  orderIds: [],
                  customerId: customer.id,
                  manualInvoiceLines: values.lines.map((line) => ({
                    description: line.description,
                    amount: parseNumberInput(line.amount, 2),
                    unitPrice: parseNumberInput(line.unitPrice, 2),
                    vatRateId: nullthrows(line.vatRate, 'vat rate not selected').key,
                  })),
                },
              });
              if (result.error) {
                throw result.error;
              }
              toast.success('Factuur aangemaakt');
              // navigate('..');
            } catch (err) {
              console.error(err);
              toast.error('Kon factuur niet aanmaken: ' + getDisplayError(err));
            }
          }}
        >
          {({ handleSubmit, isSubmitting, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <InputField labelText="Factuurdatum" type="date" name="invoiceDate" />
                <InputField labelText="Ref klant" type="text" name="customerRef" />

                <CustomerComboboxField name="customer" isDisabled={isSubmitting} showDeactivated={true} />

                <div className="my-4">
                  <FieldArray
                    name="lines"
                    render={(arrayHelpers) => (
                      <div className="my-4">
                        <div className="flex justify-between items-end">
                          <div className="heading-two mb-2">Lijnen</div>
                          <Button
                            onTrigger={() => {
                              arrayHelpers.push({
                                ...getInitialLineValues(settings, values.customer),
                              });
                            }}
                            iconLeft={<Plus className="button-icon" />}
                          >
                            Voeg lijn toe
                          </Button>
                        </div>

                        {values.lines.length > 0 ? (
                          <div>
                            {values.lines.map((line, index) => {
                              return (
                                <SuspenseSpinner key={`creditnote-line-${index}`}>
                                  <div className="mt-4">
                                    <div className="flex gap-2 items-center">
                                      <div className="flex-1">
                                        <InputField
                                          labelText="Beschrijving"
                                          type="text"
                                          name={`lines[${index}].description`}
                                        />
                                      </div>

                                      <Button
                                        color="danger"
                                        onTrigger={() => {
                                          arrayHelpers.remove(index);
                                        }}
                                        iconLeft={<Trash className="button-icon" />}
                                      >
                                        Verwijder
                                      </Button>
                                    </div>

                                    <div className="flex gap-2">
                                      <div style={{ flex: 2 }}>
                                        <InputField
                                          labelText="Aantal"
                                          type="number"
                                          name={`lines[${index}].amount`}
                                          step="0.01"
                                        />
                                      </div>
                                      <div style={{ flex: 2 }}>
                                        <InputField
                                          labelText="Eenheidsprijs"
                                          type="number"
                                          name={`lines[${index}].unitPrice`}
                                          step="0.01"
                                        />
                                      </div>
                                      <div style={{ flex: 3 }}>
                                        <VatRateComboboxField
                                          labelText="Standaard BTW Tarief"
                                          name={`lines[${index}].vatRate`}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </SuspenseSpinner>
                              );
                            })}
                          </div>
                        ) : (
                          <div>Geen verkopen</div>
                        )}
                      </div>
                    )}
                  />
                </div>

                <div className="mt-4">
                  <Button
                    type="submit"
                    color="primary"
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                    iconLeft={<Plus className="button-icon" />}
                  >
                    Maak factuurontwerp aan
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};
