import { Plus, Trash } from '@phosphor-icons/react';
import { Breadcrumb } from 'components/Breadcrumb';
import { Button } from 'components/button/Button';
import { PageHeader } from 'components/PageHeader';
import { TextAreaField } from 'components/textarea/TextAreaField';
import { FieldArray, Formik, FormikProvider, useFormik } from 'formik';
import { useCreateOrderMutation, useGetOrderByIdQuery } from 'generated/graphql';
import { Suspense, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { nullthrows } from '@utils/invariant';
import * as Yup from 'yup';
import dayjs from 'dayjs';

import { InputField } from '../../../../components/input/InputField';
import { parseInputTime } from '../../../../utils/date';
import { getDisplayError } from '../../../../utils/get-display-error';
import { isRecent, validateLoadTime } from '../orderLineStop/utils';
import { STOP_TYPE_ITEMS } from '../orderLineStop/constants';
import { DataField } from '../../../../components/DataField';
import {
  MinimalOrder,
  MinimalOrderComboboxField,
} from '../../../incoming-document/components/MinimalOrderComboboxField';
import { PageHeading } from 'components/PageHeading';

const createStopSchema = Yup.object().shape({
  location: Yup.mixed().required('Vereist'),
  date: Yup.string()
    .required('Vereist')
    .test('isRecent', 'Datum ligt te ver in het verleden', function () {
      const value = this.parent[this.path];
      return isRecent(value);
    }),
  timeStart: Yup.string()
    .required('Vereist')
    .test('isBefore', 'Start tijd moet voor stop tijd vallen', function () {
      return validateLoadTime(this.parent);
    }),
  timeEnd: Yup.string()
    .required('Vereist')
    .test('isBefore', 'Start tijd moet voor stop tijd vallen', function () {
      return validateLoadTime(this.parent);
    }),
  reference: Yup.string().required('Vereist'),
  notes: Yup.string(),
});

const createOrderSchema = Yup.object().shape({
  customerRef: Yup.string(),
  internalNotes: Yup.string(),
  externalNotes: Yup.string(),
  stops: Yup.array(createStopSchema).required('Vereist'),
});

const createOrdersSchema = Yup.object().shape({
  orders: Yup.array(createOrderSchema).required('Vereist'),
});

interface ICreateOrdersFormProps {
  orderId: string;
}

const CreateOrdersForm: React.FC<ICreateOrdersFormProps> = (props) => {
  const { orderId } = props;
  const navigate = useNavigate();
  const [, createOrderMutation] = useCreateOrderMutation();

  const [{ data }] = useGetOrderByIdQuery({
    variables: {
      id: orderId,
    },
  });

  const originalOrder = nullthrows(data?.order, 'Order not found');
  const originalOrderLine = nullthrows(originalOrder.lines[0]!, 'Order has no order lines');
  const customer = nullthrows(originalOrder.customer, 'Order has no customer');
  const orderEntryInitialData = useMemo(() => {
    return {
      customerRef: '',
      internalNotes: originalOrder.internalNotes,
      externalNotes: originalOrder.externalNotes,
      stops: originalOrderLine.stops
        .sort((a, b) => a.sequenceIndex - b.sequenceIndex)
        .map((v) => {
          return {
            type: v.type,
            location: v.location,
            date: '',
            timeStart: '',
            timeEnd: '',
            reference: '',
            notes: v.notes,
          };
        }),
    };
  }, []);

  const initialValues = useMemo(() => {
    return {
      orders: [structuredClone(orderEntryInitialData)],
    };
  }, []);

  const formikbag = useFormik({
    initialValues,
    validationSchema: createOrdersSchema,
    onSubmit: async (values) => {
      try {
        const { orders } = values;

        for (const order of orders) {
          const result = await createOrderMutation({
            data: {
              noPurchase: originalOrder.noPurchase,
              customerId: customer.id,
              customerRef: order.customerRef,
              internalNotes: order.internalNotes,
              externalNotes: order.externalNotes,
              requiresAnonymousCMR: originalOrder.requiresAnonymousCMR,
              allowedTrailerTypes: originalOrder.allowedTrailerTypes,
              lines: [
                {
                  stops: order.stops.map((stop, i) => {
                    const { type: stopType, location, date, timeStart, timeEnd, reference, notes } = stop;

                    return {
                      sequenceIndex: i * 10,
                      type: stopType,
                      locationId: location.id,
                      date,
                      timeStart: parseInputTime(timeStart),
                      timeEnd: parseInputTime(timeEnd),
                      reference,
                      notes,
                    };
                  }),
                  contents: originalOrderLine.contents.map((content) => {
                    return {
                      cargoTypeId: content.cargoType.id,
                      packageTypeId: content.packageType.id,
                      packages: content.packages,
                      weight: content.weight,
                      volume: content.volume,
                      loadingMeters: content.loadingMeters,
                    };
                  }),
                  purchases: [],
                  sales: originalOrderLine.sales.map((sale) => {
                    return {
                      productTypeId: sale.productType.id,
                      vatRateId: sale.vatRate.id,
                      amount: sale.amount,
                      unitPrice: sale.unitPrice,
                      externalNote: sale.externalNote ?? '',
                    };
                  }),
                },
              ],
            },
          });
          if (result.error) {
            throw result.error;
          }
          toast.success('Order aangemaakt');
        }

        navigate('/internal/orders');

        toast.success('Alle orders zijn aangemaakt');
      } catch (err: any) {
        toast.error('Kon orders niet aanmaken: ' + getDisplayError(err));
      }
    },
  });

  return (
    <FormikProvider value={formikbag}>
      <form onSubmit={formikbag.handleSubmit}>
        <div className="flex gap-2 flex-wrap mb-4">
          <DataField title="Order nr">{originalOrder.orderNumber ?? 'DRAFT'}</DataField>
          <DataField title="Klant">{customer.name}</DataField>
        </div>

        <FieldArray
          name="orders"
          render={(arrayHelpers) => {
            return (
              <div>
                <div className="mb-4">
                  <Button
                    onTrigger={() => {
                      arrayHelpers.push(structuredClone(orderEntryInitialData));
                    }}
                  >
                    Add Order
                  </Button>
                </div>

                <div className="grid gap-4">
                  {formikbag.values.orders.map((order, oIdx) => {
                    return (
                      <div key={`order-input-${oIdx}`} className="card p-4">
                        <div className="flex justify-between items-center mb-2">
                          <div className="font-medium">{`Order #${oIdx + 1}`}</div>
                          <div>
                            <Button
                              color="danger-outline"
                              onTrigger={() => {
                                arrayHelpers.remove(oIdx);
                              }}
                              iconLeft={<Trash className="button-icon" />}
                            >
                              Verwijder
                            </Button>
                          </div>
                        </div>
                        <div>
                          <InputField
                            labelText="Referentie"
                            name={`orders[${oIdx}].customerRef`}
                            isDisabled={formikbag.isSubmitting}
                          />
                          <div className="flex gap-4">
                            <TextAreaField
                              labelText="Interne notities"
                              name={`orders[${oIdx}].internalNotes`}
                              isDisabled={formikbag.isSubmitting}
                            />
                            <TextAreaField
                              labelText="Externe notities"
                              name={`orders[${oIdx}].externalNotes`}
                              isDisabled={formikbag.isSubmitting}
                            />
                          </div>
                        </div>

                        <div>
                          <div className="font-medium mb-2">Stops</div>

                          {order.stops.map((s, sIdx) => {
                            const isDateInWeekend = s.date
                              ? dayjs(s.date).day() === 0 || dayjs(s.date).day() === 6
                              : false;

                            return (
                              <div key={`order-input-${oIdx}-stop-${sIdx}`}>
                                <div className="mb-2">{`${
                                  STOP_TYPE_ITEMS.find((v) => v.key === s.type)?.name ?? s.type
                                }: ${s.location.name}, ${s.location.street} ${s.location.streetNumber}, ${
                                  s.location.city
                                } ${s.location.country}${s.location.postalCode}`}</div>
                                {isDateInWeekend && (
                                  <div className="font-medium text-orange-01">
                                    Waarschuwing: datum valt in een weekend.
                                  </div>
                                )}
                                <div
                                  className="grid gap-2 w-full"
                                  style={{ gridTemplateColumns: '1fr 12rem 8rem 8rem' }}
                                >
                                  <InputField
                                    labelText="Referentie"
                                    type="text"
                                    name={`orders[${oIdx}].stops[${sIdx}].reference`}
                                    isDisabled={formikbag.isSubmitting}
                                  />
                                  <InputField
                                    labelText="Datum"
                                    type="date"
                                    name={`orders[${oIdx}].stops[${sIdx}].date`}
                                    isDisabled={formikbag.isSubmitting}
                                  />
                                  <InputField
                                    labelText="Start"
                                    type="time"
                                    name={`orders[${oIdx}].stops[${sIdx}].timeStart`}
                                    isDisabled={formikbag.isSubmitting}
                                  />
                                  <InputField
                                    labelText="Stop"
                                    type="time"
                                    name={`orders[${oIdx}].stops[${sIdx}].timeEnd`}
                                    isDisabled={formikbag.isSubmitting}
                                  />
                                </div>
                                <TextAreaField
                                  labelText="Notities"
                                  name={`orders[${oIdx}].stops[${sIdx}].notes`}
                                  isDisabled={formikbag.isSubmitting}
                                  spellCheck={true}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          }}
        />

        <div className="mt-8">
          <Button
            type="submit"
            color="primary"
            isDisabled={formikbag.isSubmitting}
            isLoading={formikbag.isSubmitting}
            iconLeft={<Plus className="button-icon" />}
          >
            Maak orders aan
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
};

export const CreateMultiOrdersFromOrderPage = () => {
  const [orderId, setOrderId] = useState('');

  return (
    <>
      <PageHeader title="Nieuwe orders van template" />

      <div>
        <PageHeading
          leftSide={
            <Breadcrumb
              parentItem={{
                name: 'Orders',
                to: '/internal/orders',
              }}
              currentItem="Nieuwe orders van template"
            />
          }
        />

        <div className="px-4">
          <Formik
            initialValues={{
              order: null as MinimalOrder | null,
            }}
            onSubmit={async (values) => {
              const { order } = values;

              if (!order?.customer) {
                toast.error('Kan order zonder klant niet kopieren');
                return;
              }

              setOrderId(order?.id ?? '');
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <div className="flex gap-2 items-center">
                  <MinimalOrderComboboxField name="order" />

                  <div>
                    <Button type="submit" color="primary" isDisabled={isSubmitting} isLoading={isSubmitting}>
                      Selecteer order om te kopieren
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>

          {!!orderId && (
            <Suspense>
              <CreateOrdersForm orderId={orderId} key={orderId} />
            </Suspense>
          )}
        </div>
      </div>
    </>
  );
};
