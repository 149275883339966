import { Plus, ArrowDown, ArrowUp } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { IterableElement } from 'type-fest';

import { ITableHeader, Table } from '../../../../components/table/Table';
import { Supplier } from '../Supplier';
import { useSupplier } from '../Supplier/supplierContext';
import { formatDate } from '../../../../utils/date';
import { SUPPLIER_TRUCK_DOCUMENT_MAP } from './constants';
import { LinkButton } from '../../../../components/button/ButtonLink';
import { Button } from 'components/button/Button';
import { moveArrayElement } from '@utils/array';
import { useUpdateSupplierTruckIndexesMutation, useUpdateSupplierTruckMutation } from 'generated/graphql';

export type SupplierTruck = IterableElement<Supplier['trucks']>;

const CONTACT_HEADERS: ITableHeader[] = [
  {
    id: 'licensePlate',
    name: 'Nummerplaat',
  },
  {
    id: 'name',
    name: 'Codenaam',
  },
  {
    id: 'planner',
    name: 'Planner',
  },
  {
    id: 'isActive',
    name: 'Actief',
  },
  {
    id: 'insuranceExpiresAt',
    name: 'Verzekering vervalt op',
  },
  {
    id: 'examinationExpiresAt',
    name: 'Keuring vervalt op',
  },
  {
    id: 'documents',
    name: 'Documenten',
  },
];

export const SupplierTrucksPage = () => {
  const { supplier } = useSupplier();
  const [updateState, updateTruckIndexes] = useUpdateSupplierTruckIndexesMutation();

  const moveTruckIdx = async (truckId: string, offset: number) => {
    const truckIdx = supplier.trucks.findIndex((truck) => truck.id === truckId);
    const trucksCopy = moveArrayElement(supplier.trucks, truckIdx, truckIdx + offset);
    await updateTruckIndexes({
      supplierId: supplier.id,
      truckIds: trucksCopy.map((truck) => truck.id),
      indexes: trucksCopy.map((_truck, idx) => idx),
    });
  };

  return (
    <div>
      <div className="flex justify-end">
        <div className="flex">
          <div>
            <LinkButton to="new" color="primary" iconLeft={<Plus className="button-icon" />}>
              Nieuw trekker
            </LinkButton>
          </div>
        </div>
      </div>
      <Table
        idKey="id"
        headers={CONTACT_HEADERS}
        data={supplier.trucks.sort((a, b) => a.sortIdx - b.sortIdx)}
        mapData={(data) => {
          return [
            <Link to={`${data.id}`} className="link-text">
              {data.licensePlate}
            </Link>,
            data.name,
            data.planner?.name ?? '-',
            data.isActive ? 'Ja' : 'Nee',
            formatDate(data.insuranceExpiresAt),
            formatDate(data.examinationExpiresAt),
            data.documents.map((v) => SUPPLIER_TRUCK_DOCUMENT_MAP[v.type]).join(', '),
            <div className="flex gap-1">
              <Button
                onTrigger={() => {
                  moveTruckIdx(data.id, 1);
                }}
                isLoading={updateState.fetching}
                isDisabled={data.sortIdx === supplier.trucks.length - 1}
              >
                <ArrowDown className="button-icon" />
              </Button>
              <Button
                onTrigger={() => {
                  moveTruckIdx(data.id, -1);
                }}
                isLoading={updateState.fetching}
                isDisabled={data.sortIdx === 0}
              >
                <ArrowUp className="button-icon" />
              </Button>
            </div>,
          ];
        }}
        isMultiSelect
      />
    </div>
  );
};
