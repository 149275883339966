import { MagnifyingGlass, Plus } from '@phosphor-icons/react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';

import { Input } from '../../../components/input/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import { ITableHeader, Table } from '../../../components/table/Table';
import { GetPurchasesDocument, GetPurchasesQuery, GetPurchasesQueryVariables } from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { formatNumber } from '../../../utils/number';
import { formatDate } from '../../../utils/date';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { LinkButton } from '../../../components/button/ButtonLink';
import { PageHeading } from 'components/PageHeading';

const PURCHASES_HEADERS: ITableHeader[] = [
  {
    id: 'id',
    name: 'Factuur Nummer',
  },
  {
    id: 'date',
    name: 'Factuur Datum',
  },
  {
    id: 'supplier',
    name: 'Leverancier',
  },
  {
    id: 'total',
    name: 'Totaal',
  },
];

const PurchasesPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetPurchasesQuery, GetPurchasesQueryVariables>(
          GetPurchasesDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters: {
              search: searchValue,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.purchases ?? [];
    },
    [client, searchValue],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 50,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  useEffect(() => {
    page.reset();
  }, [searchValue]);

  const title = 'Aankopen';

  return (
    <>
      <PageHeader title={title} />

      <PageHeading
        leftSide={
          <Breadcrumb
            parentItem={{
              name: 'Financieel',
              to: '/internal/finance',
            }}
            currentItem={title}
          />
        }
        rightSide={
          <div>
            <LinkButton to="new" color="primary" iconLeft={<Plus className="button-icon" />}>
              Nieuwe aankoop
            </LinkButton>
          </div>
        }
      />

      <div className="flex w-full mb-8">
        <Input
          type="text"
          placeholder="Zoek in aankopen..."
          value={searchValue}
          onChange={setSearchValue}
          iconLeft={<MagnifyingGlass className="input-icon" />}
        />
      </div>

      <Table
        idKey="id"
        headers={PURCHASES_HEADERS}
        data={page.data}
        mapData={(purchase) => {
          return [
            <Link to={`${purchase.id}`} className="link-text">
              {purchase.invoiceNumber || 'geen factuur nummer'}
            </Link>,
            formatDate(purchase.date),
            purchase.supplier.name,
            <div className="whitespace-nowrap">{`€ ${formatNumber(purchase.totalInclVat, 2, {
              decimalSeperator: ',',
            })}`}</div>,
          ];
        }}
      />

      <div className="my-4">
        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};

export default PurchasesPage;
