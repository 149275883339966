import { Customer, Order } from './types';

export type ICreateInvoiceState = {
  activeWindow: 'SELECT_CUSTOMER' | 'SELECT_ORDERS' | 'REVIEW';
  selectedCustomer: Customer | null;
  orders: Order[];
};

export type CreateInvoiceAction =
  | {
      type: 'SELECT_CUSTOMER';
      customer: Customer | null;
    }
  | {
      type: 'SELECT_ORDER';
      order: Order;
    }
  | {
      type: 'GO_BACK';
    }
  | {
      type: 'GO_FORWARD';
    };

export function createInvoiceReducer(state: ICreateInvoiceState, action: CreateInvoiceAction): ICreateInvoiceState {
  switch (action.type) {
    case 'SELECT_CUSTOMER': {
      return {
        ...state,
        selectedCustomer: action.customer,
        orders: [],
        activeWindow: action.customer ? 'SELECT_ORDERS' : 'SELECT_CUSTOMER',
      };
    }
    case 'SELECT_ORDER': {
      const ordersClone = [...state.orders];
      const foundIndex = ordersClone.findIndex((o) => o.id === action.order.id);
      if (foundIndex >= 0) {
        ordersClone.splice(foundIndex, 1);
      } else {
        ordersClone.push(action.order);
      }
      return {
        ...state,
        orders: ordersClone,
      };
    }
    case 'GO_BACK': {
      let newWindow = state.activeWindow;
      if (state.activeWindow === 'SELECT_ORDERS') {
        newWindow = 'SELECT_CUSTOMER';
      } else if (state.activeWindow === 'REVIEW') {
        newWindow = 'SELECT_ORDERS';
      }

      return {
        ...state,
        selectedCustomer: newWindow === 'SELECT_CUSTOMER' ? null : state.selectedCustomer,
        orders: newWindow === 'SELECT_CUSTOMER' ? [] : state.orders,
        activeWindow: newWindow,
      };
    }
    case 'GO_FORWARD': {
      let newWindow = state.activeWindow;
      if (state.activeWindow === 'SELECT_CUSTOMER') {
        newWindow = 'SELECT_ORDERS';
      } else if (state.activeWindow === 'SELECT_ORDERS') {
        newWindow = 'REVIEW';
      }

      return {
        ...state,
        activeWindow: newWindow,
      };
    }
  }

  return state;
}
