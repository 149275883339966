import { Link } from 'react-router-dom';

import { PageHeader } from '../../../components/PageHeader';
import { PageHeading } from 'components/PageHeading';
import { Breadcrumb } from 'components/Breadcrumb';

export interface ILinkCardProps {
  title: string;
  to: string;
}

const LinkCard: React.FC<ILinkCardProps> = (props) => {
  const { title, to } = props;

  return (
    <Link to={to}>
      <div className="card p-4" data-clickable="true">
        <div className="card-heading-text">{title}</div>
      </div>
    </Link>
  );
};

export const FinancePage = () => {
  return (
    <>
      <PageHeader title="Financieel" />

      <PageHeading leftSide={<Breadcrumb currentItem="Financieel" />} />

      <div className="px-4">
        <div className="grid lg:grid-cols-3 gap-4">
          <LinkCard title="Verkopen" to="sales" />
          <LinkCard title="Offertes" to="quotations" />
          <LinkCard title="Offerte aanvragen" to="quotation-requests" />
          <LinkCard title="Credit Notas" to="creditnotes" />
          <LinkCard title="Aankopen" to="purchases" />
          <LinkCard title="Self Billing" to="self-billing" />
          <LinkCard title="Debiteurenbeheer" to="payment-reminder" />
        </div>
      </div>
    </>
  );
};
