import { Plus } from '@phosphor-icons/react';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { getDisplayError } from '@utils/get-display-error';
import { Breadcrumb } from 'components/Breadcrumb';
import { Button } from 'components/button/Button';
import { Card } from 'components/Card';
import { InputField } from 'components/input/InputField';
import { PageHeader } from 'components/PageHeader';
import { PageHeading } from 'components/PageHeading';
import { CreateTrailerInputData, useCreateTrailerMutation } from 'generated/graphql';
import { useAuth } from 'contexts/auth-context';
import { InternalUser, UserComboboxField } from 'src/app/user/components/UserCombobox';

const createTrailerSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Vereist').required('Vereist'),
  licensePlate: Yup.string().min(1, 'Vereist').required('Vereist'),
  brand: Yup.string().min(1, 'Vereist').required('Vereist'),
  serialNumber: Yup.string().min(1, 'Vereist').required('Vereist'),
  registrationDate: Yup.string().required('Vereist'),
  insuranceExpirationDate: Yup.string().required('Vereist'),
  inspectionExpirationDate: Yup.string().required('Vereist'),
});

export const CreateTrailerPage = () => {
  const navigate = useNavigate();
  const { me } = useAuth();
  const [, createTrailerMutate] = useCreateTrailerMutation();

  const initialValues = {
    name: '',
    licensePlate: '',
    brand: '',
    serialNumber: '',
    registrationDate: '',
    insuranceExpirationDate: '',
    inspectionExpirationDate: '',
    responsible: me as InternalUser,
  };

  return (
    <>
      <PageHeader title="Nieuwe trailer" />

      <PageHeading
        leftSide={
          <Breadcrumb
            parentItem={{
              name: 'Trailers',
              to: '/internal/trailers',
            }}
            currentItem="Nieuwe trailer"
          />
        }
      />

      <div className="px-4">
        <Formik
          initialValues={initialValues}
          validationSchema={createTrailerSchema}
          onSubmit={async (values) => {
            try {
              const inputData: CreateTrailerInputData = {
                responsibleId: values.responsible?.id,
                name: values.name,
                licensePlate: values.licensePlate,
                brand: values.brand,
                serialNumber: values.serialNumber,
                registrationDate: values.registrationDate,
                insuranceExpirationDate: values.insuranceExpirationDate,
                inspectionExpirationDate: values.inspectionExpirationDate,
              };

              const result = await createTrailerMutate({
                data: inputData,
              });
              if (result.error) {
                throw result.error;
              }
              toast.success('Trailer aangemaakt');
              navigate('..');
            } catch (err: any) {
              toast.error('Kon trailer niet aanmaken: ' + getDisplayError(err));
            }
          }}
        >
          {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
            <form onSubmit={handleSubmit} className="grid gap-4">
              <Card title="Identificatie">
                <UserComboboxField labelText="Verantwoordelijke" name="responsible" />
                <InputField labelText="ID Code" type="text" name="name" />
                <InputField labelText="Nummerplaat" type="text" name="licensePlate" />
                <InputField labelText="Merk" type="text" name="brand" />
                <InputField labelText="Serienummer" type="text" name="serialNumber" />
                <InputField labelText="Datum eerste registratie" type="date" name="registrationDate" />
              </Card>

              <Card title="Vervaldagen">
                <InputField labelText="Vervaldag verzekering" type="date" name="insuranceExpirationDate" />
                <InputField labelText="Vervaldag keuring" type="date" name="inspectionExpirationDate" />
              </Card>

              <div>
                <Button
                  type="submit"
                  color="primary"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                  iconLeft={<Plus className="button-icon" />}
                >
                  Maak trailer aan
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};
