import { useField } from 'formik';
import { useId } from 'react';

import { InputWrapper } from '../InputWrapper';
import { Input } from './Input';

export interface IInputFieldProps {
  labelText: React.ReactNode;
  name: string;
  helperText?: string;
  type?: 'email' | 'text' | 'password' | 'number' | 'date' | 'time' | 'tel';
  isDisabled?: boolean;
  step?: string;
  onFocus?: (e: React.FocusEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
  spellCheck?: boolean;
}

export const InputField: React.FC<IInputFieldProps> = (props) => {
  const { name, type = 'text', labelText, helperText, isDisabled, step, onFocus, onBlur, spellCheck } = props;
  const id = useId();
  const [field, meta, helpers] = useField({ name });

  return (
    <InputWrapper labelText={labelText} invalidText={meta.touched ? meta.error : undefined} helperText={helperText}>
      <Input
        value={field.value}
        onChange={(val) => {
          helpers.setValue(val);
        }}
        onBlur={(evt) => {
          helpers.setTouched(true);
          field.onBlur(evt);
          onBlur?.(evt);
        }}
        onFocus={(evt) => {
          onFocus?.(evt);
        }}
        id={id}
        type={type}
        step={step}
        isInvalid={Boolean(meta.touched && meta.error)}
        isDisabled={isDisabled}
        spellCheck={spellCheck}
      />
    </InputWrapper>
  );
};
