import { LineVertical } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';

export interface IBreadcrumbItem {
  name: string;
  to: string;
}

export interface IBreadcrumbProps {
  parentItem?: IBreadcrumbItem | null;
  currentItem: string;
}

export const Breadcrumb: React.FC<IBreadcrumbProps> = (props) => {
  const { parentItem, currentItem } = props;

  return (
    <div className="flex items-center uppercase text-dark-02 gap-2">
      {parentItem && (
        <Link to={parentItem.to} className="font-light">
          {parentItem.name}
        </Link>
      )}

      {parentItem && <LineVertical className="h-4 w-4" />}

      <div className="font-medium">{currentItem}</div>
    </div>
  );
};
