import dayjs from 'dayjs';
import { Warning } from '@phosphor-icons/react';
import { useMemo } from 'react';

export interface IMinimalStop {
  date: string;
}

export interface IStopWarningsProps {
  stops: IMinimalStop[];
}

// Verifies the stops and shows warnings:
// if dates are in the weekend
// is dates between stops is more than 2 days apart
export const StopWarnings: React.FC<IStopWarningsProps> = (props) => {
  const { stops } = props;

  const warnings = useMemo(() => {
    const parsedStopDates = stops.filter((s) => !!s.date).map((stop) => dayjs(stop.date));
    const hasWeekendDays = parsedStopDates.some((date) => date.day() === 0 || date.day() === 6);
    const minDate = parsedStopDates.sort((a, b) => a.diff(b))[0];
    const maxDate = parsedStopDates.sort((a, b) => b.diff(a))[0];

    const warnings = [];
    if (hasWeekendDays) {
      warnings.push('Een of meerdere stops vallen in het weekend');
    }
    if (maxDate && minDate && maxDate.diff(minDate, 'days') > 2) {
      warnings.push('Er zit meer dan 2 dagen tussen stops');
    }

    return warnings;
  }, [stops]);

  if (!warnings.length) {
    return null;
  }

  return (
    <div>
      <div className="font-medium text-orange-01 flex items-center gap-2">
        <Warning className="button-icon" /> Waarschuwingen
      </div>
      {warnings.map((v, idx) => {
        return <div key={idx}>{v}</div>;
      })}
    </div>
  );
};
