import dayjs from 'dayjs';
import { isDateTimeBeforeOrEqual, isTimeBeforeOrEqual } from '../../../../utils/date';

export function validateUnloadTime(val: { unloadTimeStart: string; unloadTimeEnd: string }) {
  return val.unloadTimeStart === val.unloadTimeEnd || isTimeBeforeOrEqual(val.unloadTimeStart, val.unloadTimeEnd);
}

export function validateLoadTime(val: { loadTimeStart: string; loadTimeEnd: string }) {
  return val.loadTimeStart === val.loadTimeEnd || isTimeBeforeOrEqual(val.loadTimeStart, val.loadTimeEnd);
}

export function validateLoadDate(val: {
  loadDate: string;
  loadTimeStart: string;
  unloadDate: string;
  unloadTimeStart: string;
}) {
  return isDateTimeBeforeOrEqual(val.loadDate, val.loadTimeStart, val.unloadDate, val.unloadTimeStart);
}

export function isRecent(val: string) {
  return dayjs(val).isAfter(dayjs().subtract(3, 'month'));
}

export function isTomorrowOrAfter(val: string) {
  const parsed = dayjs(val);
  const startOfTomorrow = dayjs().add(1, 'day').startOf('day');
  return !parsed.isBefore(startOfTomorrow);
}
