import createContext from 'contexts/create-context';
import * as React from 'react';

import { Supplier } from '.';

interface ProviderValue {
  supplier: Supplier;
  refreshData: () => void;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface ISupplierProviderProps {
  supplier: Supplier;
  refreshData: () => void;
  children?: React.ReactNode;
}

export const SupplierProvider: React.FC<ISupplierProviderProps> = (props) => {
  const { supplier, refreshData, children } = props;

  return <ReactProvider value={{ supplier, refreshData }}>{children}</ReactProvider>;
};

export const useSupplier = useContext;
export const Consumer = ReactConsumer;
