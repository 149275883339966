import * as Dialog from '@radix-ui/react-dialog';
import React, { useMemo } from 'react';
import * as Yup from 'yup';

import { BaseButtonProps } from '../button/BaseButton';
import { FormDialog } from './FormDialog';
import { InlineSelectField } from '../select/InlineSelectField';
import { IInlineSelectItem } from '../select/InlineSelect';

const schema = Yup.object().shape({
  contacts: Yup.array().of(Yup.object()),
});

export interface IContactEntry {
  id: string;
  name: string;
  email: string;
}

export interface IContactsDialogProps {
  triggerText: React.ReactNode;
  triggerColor?: BaseButtonProps['color'];
  isDisabled?: boolean;
  title: string;
  description: string;
  contacts: IContactEntry[];
  validation?: Yup.StringSchema;
  cancelText?: string;
  submitText: string;
  onSubmit: (value: string[]) => void | Promise<void>;
  initialValue?: string[];
}

interface ContactsDialogValues {
  contacts: IInlineSelectItem[];
}

export const ContactsDialog: React.FC<IContactsDialogProps> = (props) => {
  const { onSubmit, initialValue, description, validation, contacts, ...otherProps } = props;

  const handleSubmit = async (values: ContactsDialogValues) => {
    await onSubmit(values.contacts.map((v) => v.key));
  };

  const items = useMemo(() => {
    return contacts.map((c) => {
      return {
        key: c.id,
        name: `${c.name} - ${c.email}`,
      };
    });
  }, [contacts]);

  const initialValues: ContactsDialogValues = useMemo(() => {
    return {
      contacts: initialValue?.length
        ? initialValue
            .map((v) => {
              return items.find((c) => c.key === v)!;
            })
            .filter(Boolean)
        : [],
    };
  }, [initialValue]);

  return (
    <FormDialog {...otherProps} initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
      <Dialog.Description className="mb-4">{description}</Dialog.Description>

      <InlineSelectField labelText="Contacten" name="contacts" items={items} />
    </FormDialog>
  );
};
