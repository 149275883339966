import { Notification } from './types';

export type INotificationsState = {
  notifications: Notification[];
  hasUnreadNotifications: boolean;
  lastNotificationTime: number;
};

export type NotificationAction =
  | {
      type: 'UPDATE_NOTIFICATIONS';
      notifications: Notification[];
    }
  | {
      type: 'NEW_NOTIFICATION';
      notification: Notification;
    };

export function notificationsReducer(state: INotificationsState, action: NotificationAction): INotificationsState {
  switch (action.type) {
    case 'UPDATE_NOTIFICATIONS': {
      return {
        ...state,
        hasUnreadNotifications: action.notifications.some((n) => !n.isRead),
        notifications: action.notifications,
        lastNotificationTime: Math.max(
          new Date(action.notifications[0]?.createdAt).getTime(),
          state.lastNotificationTime,
        ),
      };
    }
    case 'NEW_NOTIFICATION': {
      if (state.notifications.find((n) => n.id === action.notification.id)) {
        return state;
      }

      const notificationsClone = [action.notification, ...state.notifications];
      return {
        ...state,
        hasUnreadNotifications: true,
        notifications: notificationsClone,
        lastNotificationTime: Math.max(new Date(action.notification.createdAt).getTime(), state.lastNotificationTime),
      };
    }
  }

  return state;
}
