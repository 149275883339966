import { File, Plus, X } from '@phosphor-icons/react';
import classNames from '@utils/classnames';
import React, { useRef, useState } from 'react';
import { Button } from './button/Button';

export interface IDropzoneProps {
  files: File[];
  onUpload: (files: File[]) => any;
  disabled?: boolean;
  accept?: string;
  allowMultiple?: boolean;
}

export const Dropzone = (props: IDropzoneProps) => {
  let { files, onUpload, disabled, accept, allowMultiple } = props;
  let inputEl = useRef<HTMLInputElement | null>(null);
  let [dragging, setDragging] = useState<boolean>(false);

  const handleUpload = (inputFiles: File[]) => {
    if (allowMultiple) {
      onUpload([...files, ...inputFiles]);
    } else {
      onUpload(inputFiles[0] ? [inputFiles[0]] : []);
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    let inputFiles = e.target.files;
    if (!inputFiles) {
      return;
    }

    handleUpload([...inputFiles]);
  };

  const handleClick = () => {
    if (disabled) return;

    if (inputEl.current) {
      // @ts-ignore
      inputEl.current.dispatchEvent(new MouseEvent('click'));
    }
  };

  const dragEnter = (e: React.DragEvent<HTMLInputElement>) => {
    if (disabled) return;
    e.stopPropagation();
    e.preventDefault();

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const dragOver = (e: React.DragEvent<HTMLInputElement>) => {
    if (disabled) return;
    e.stopPropagation();
    e.preventDefault();
  };

  const dragLeave = (e: React.DragEvent<HTMLInputElement>) => {
    if (disabled) return;
    e.stopPropagation();
    e.preventDefault();

    setDragging(false);
  };

  const drop = (e: React.DragEvent<HTMLInputElement>) => {
    if (disabled) return;
    e.stopPropagation();
    e.preventDefault();

    handleUpload([...e.dataTransfer.files]);
  };

  return (
    <div
      className={classNames('w-full border-dashed border-gray-400 text-gray-400 border-2 rounded', {
        'cursor-pointer hover:border-gray-500': !disabled,
        'border-gray-500': dragging,
      })}
      onClick={handleClick}
    >
      <div
        className="w-full overflow-y-auto"
        style={{ minHeight: '10rem', maxHeight: '50vh' }}
        onDragEnter={dragEnter}
        onDragOver={dragOver}
        onDragLeave={dragLeave}
        onDrop={drop}
      >
        {files.length ? (
          <div className="w-full h-full">
            {files.map((file, idx) => {
              return (
                <div
                  className={classNames('flex items-center py-2', {
                    'border-b border-dark-05': idx !== files.length - 1,
                  })}
                  key={`file-${file.name}-${idx}`}
                >
                  <div className="px-2">
                    <File className="w-8 h-8" />
                  </div>
                  <div className="flex-1">{file.name}</div>
                  <div className="px-2">
                    <Button
                      onTrigger={(evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();

                        const clonedArr = [...files];
                        clonedArr.splice(idx, 1);
                        onUpload(clonedArr);
                      }}
                    >
                      <X className="button-icon" />
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="w-full mt-10 flex items-center justify-center">
            <div className="flex flex-col items-center">
              <div className="mb-4">{<Plus className="w-8 h-8" />}</div>
              <div>Klik hier of bestand(en) in het vak om bestand(en) op te laden</div>
            </div>
          </div>
        )}
      </div>
      <input
        type="file"
        multiple={Boolean(allowMultiple)}
        accept={accept}
        onChange={handleFileUpload}
        className="hidden"
        ref={inputEl}
      ></input>
    </div>
  );
};
