import { useField } from 'formik';
import { useClient } from 'urql';
import { useEffect, useState } from 'react';
import { IterableElement } from 'type-fest';

import { InputField } from '../../../components/input/InputField';
import { SimpleSelectField } from '../../../components/select/SimpleSelectField';
import { COUNTRY_VALUES } from '../../../utils/address';
import {
  FindPostalCodesDocument,
  FindPostalCodesQuery,
  FindPostalCodesQueryVariables,
} from '../../../generated/graphql';
import { useTranslation } from '../../../contexts/translation-context';

type PostalCodeSuggestion = IterableElement<NonNullable<NonNullable<FindPostalCodesQuery>['findPostalCodes']>>;

export interface IAutocompletePostalcodeValues {
  postalCodeName?: string;
  cityName?: string;
  countryName?: string;
  onAutocomplete?: (postalCode: string, city: string, longitude: number, latitude: number) => void;
}

export const AutocompletePostalcode: React.FC<IAutocompletePostalcodeValues> = (props) => {
  const { postalCodeName = 'postalCode', cityName = 'city', countryName = 'country', onAutocomplete } = props;
  const client = useClient();
  const [isFocused, setIsFocused] = useState<number>(0);
  const [state, setState] = useState<{
    requestKey: number;
    suggestions: PostalCodeSuggestion[];
  }>({ requestKey: 0, suggestions: [] });
  const { i18n } = useTranslation();

  const postalCodeField = useField({ name: postalCodeName });
  const countryField = useField({ name: countryName });
  const cityField = useField({ name: cityName });

  const postalCode = postalCodeField[0].value;
  const country = countryField[0].value;
  useEffect(() => {
    const query = postalCode;
    const countryCode = country?.key;
    if (query && countryCode) {
      const requestKey = state.requestKey + 1;
      setState((val) => {
        return {
          ...val,
          requestKey,
        };
      });

      client
        .query<FindPostalCodesQuery, FindPostalCodesQueryVariables>(FindPostalCodesDocument, {
          query,
          country: countryCode,
        })
        .toPromise()
        .then((val) => {
          const suggestions = val.data?.findPostalCodes;
          setState((prev) => {
            if (prev.requestKey === requestKey) {
              return {
                ...prev,
                suggestions: suggestions ?? [],
              };
            } else {
              return prev;
            }
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [postalCode, country]);

  return (
    <div className="flex gap-4">
      <div style={{ flex: 1 }}>
        <SimpleSelectField
          labelText={i18n('customerPortal.locations.countryCode')}
          items={COUNTRY_VALUES}
          name={countryName}
        />
      </div>
      <div className="relative" style={{ flex: 1 }}>
        <InputField
          labelText={i18n('customerPortal.locations.postalCode')}
          type="text"
          name={postalCodeName}
          onFocus={() => setIsFocused(Date.now())}
          onBlur={() => {
            const lastFocused = isFocused;
            setTimeout(() => {
              if (lastFocused === isFocused) {
                setIsFocused(0);
              }
            }, 100);
          }}
        />
        {isFocused > 0 && state.suggestions.length > 0 && (
          <div className="absolute z-dropdown bg-white rounded-md overflow-hidden w-full" style={{ marginTop: -18 }}>
            {state.suggestions.map((suggestion) => {
              const label = `${suggestion.postalCode} - ${suggestion.placeName}`;
              return (
                <div
                  key={label}
                  className="hover:bg-dark-05 px-4 py-2 cursor-pointer"
                  onClick={() => {
                    postalCodeField[2].setValue(suggestion.postalCode);
                    cityField[2].setValue(suggestion.placeName);

                    onAutocomplete?.(
                      suggestion.postalCode,
                      suggestion.placeName,
                      suggestion.longitude,
                      suggestion.latitude,
                    );
                  }}
                >
                  {label}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div style={{ flex: 2 }}>
        <InputField labelText={i18n('customerPortal.locations.city')} type="text" name={cityName} />
      </div>
    </div>
  );
};
