import { useReducer } from 'react';
import dayjs from 'dayjs';
import { createId } from '@paralleldrive/cuid2';

import { OrderSupplierType, OrderSupplierTruckType, OrderType, SupplierType } from './PlanningPage';
import { OrderStatus } from '../../../generated/graphql';

export interface IPlanningState {
  userId: string | null;
  date: Date;
  supplierOrders: Map<string, OrderType[]>;
  suppliers: SupplierType[];
  pendingOrders: OrderType[];
  allOrders: OrderType[];
}

export type PlanningAction =
  | {
      type: 'suppliers-fetched';
      suppliers: SupplierType[];
    }
  | {
      type: 'move-order';
      order: OrderType;
      supplier: OrderSupplierType | null;
      supplierTruck: OrderSupplierTruckType | null;
    }
  | {
      type: 'orders-fetched';
      orders: OrderType[];
    }
  | {
      type: 'sequence-changed';
      orderId: string;
      sequence: number;
      date: string;
    };

export function isTheSameDay(date1: Date, date2: Date) {
  return dayjs(date1).isSame(date2, 'day');
}

function getSupplierOrdersMap(date: Date, suppliers: SupplierType[], orders: OrderType[]): Map<string, OrderType[]> {
  const supplierOrders = new Map<string, OrderType[]>();
  for (const supplier of suppliers) {
    for (const truck of supplier.trucks) {
      const truckOrders = orders
        .filter((order) => order.supplierTruck?.id === truck.id)
        .sort((a, b) => {
          const aSorting = a.routeSortings.find((v) => isTheSameDay(v.date, date))?.sequence ?? 1;
          const bSorting = b.routeSortings.find((v) => isTheSameDay(v.date, date))?.sequence ?? 1;

          return aSorting - bSorting;
        });
      supplierOrders.set(truck.id, truckOrders);
    }
  }
  return supplierOrders;
}

function filterSuppliers(userId: string | null, suppliers: SupplierType[]): SupplierType[] {
  const res: SupplierType[] = [];
  for (const supplier of suppliers) {
    const trucks = supplier.trucks.filter((truck) => !userId || truck.planner?.id === userId);
    if (trucks.length) {
      res.push({
        ...supplier,
        trucks,
      });
    }
  }
  return res;
}

export function planningReducer(state: IPlanningState, action: PlanningAction) {
  switch (action.type) {
    case 'orders-fetched': {
      const orders = action.orders.filter((v) => v.status !== OrderStatus.Draft);

      return {
        ...state,
        supplierOrders: getSupplierOrdersMap(state.date, state.suppliers, orders),
        pendingOrders: orders.filter((order) => !order.supplier),
        allOrders: orders,
      };
    }
    case 'move-order': {
      const foundOrder = state.allOrders.find((order) => order.id === action.order.id);
      if (!foundOrder) {
        return state;
      }

      const supplierOrdersMap = new Map(state.supplierOrders);

      if (foundOrder.supplierTruck) {
        const supplierOrders = supplierOrdersMap.get(foundOrder.supplierTruck.id);
        if (supplierOrders) {
          supplierOrdersMap.set(
            foundOrder.supplierTruck.id,
            supplierOrders.filter((order) => order.id !== action.order.id),
          );
        }
      }

      foundOrder.supplier = action.supplier;
      foundOrder.supplierTruck = action.supplierTruck;

      if (action.supplierTruck) {
        const supplierOrders = supplierOrdersMap.get(action.supplierTruck.id);
        if (supplierOrders) {
          supplierOrders.push(action.order);
          supplierOrdersMap.set(action.supplierTruck.id, [...supplierOrders]);
        }
      }

      return {
        ...state,
        supplierOrders: supplierOrdersMap,
        pendingOrders: state.allOrders.filter((order) => !order.supplier),
      };
    }
    case 'sequence-changed': {
      const orders = state.allOrders;
      const order = orders.find((v) => v.id === action.orderId);
      if (!order) {
        return state;
      }

      const existingSorting = order.routeSortings.find((v) => isTheSameDay(v.date, new Date(action.date)));
      if (existingSorting) {
        existingSorting.sequence = action.sequence;
      } else {
        order.routeSortings.push({
          id: createId(),
          date: new Date(action.date),
          sequence: action.sequence,
        });
      }

      return {
        ...state,
        supplierOrders: getSupplierOrdersMap(state.date, state.suppliers, orders),
        pendingOrders: orders.filter((order) => !order.supplier),
        allOrders: orders,
      };
    }
    case 'suppliers-fetched': {
      const filteredSuppliers = filterSuppliers(state.userId, action.suppliers);

      return {
        ...state,
        supplierOrders: getSupplierOrdersMap(state.date, filteredSuppliers, state.allOrders),
        suppliers: filteredSuppliers,
      };
    }
  }

  return state;
}

export function usePlanningReducer(date: Date, userId: string | null) {
  return useReducer(planningReducer, {
    userId,
    date,
    supplierOrders: new Map(),
    suppliers: [],
    pendingOrders: [],
    allOrders: [],
  });
}
