import createContext from 'contexts/create-context';
import * as React from 'react';
import { IterableElement } from 'type-fest';

import { OrderStatsQuery } from '../../generated/graphql';

export type DashboardEntry = IterableElement<OrderStatsQuery['orderStats']>;

interface ProviderValue {
  entries: DashboardEntry[];
  refreshData: () => void;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface IDashboardProviderProps {
  entries: DashboardEntry[];
  refreshData: () => void;
  children?: React.ReactNode;
}

export const DashboardProvider: React.FC<IDashboardProviderProps> = (props) => {
  const { entries, children, refreshData } = props;
  // TODO: According to Gilles this shouldn't be filtered out
  // not sure why this was added in the first place
  // just double check with him if someone reports this as "broken"
  // const filteredEntries = React.useMemo(() => {
  //   return entries.filter((e) => !e.noPurchase);
  // }, [entries]);
  return <ReactProvider value={{ entries, refreshData }}>{children}</ReactProvider>;
};

export const useDashboardEntries = useContext;
export const DashboardConsumer = ReactConsumer;
