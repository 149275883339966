import { ISimpleSelectItem, SimpleSelect } from '../../../components/select/SimpleSelect';
import { UserRole } from '../../../generated/graphql';

const OPTIONS: ISimpleSelectItem[] = Object.entries(UserRole).map(([key, value]) => ({ key: value, name: key }));

export interface IRoleSelectProps {
  onSelect: (role: string) => void;
  value: string;
  isDisabled?: boolean;
}

export const RoleSelect: React.FC<IRoleSelectProps> = (props) => {
  const { onSelect, value, isDisabled } = props;

  return (
    <SimpleSelect
      isDisabled={isDisabled}
      items={[...OPTIONS]}
      onSelect={(val) => {
        if (val) {
          onSelect(val.key);
        }
      }}
      selectedItem={OPTIONS.find((v) => v.key === value) ?? null}
    />
  );
};
