import { MagnifyingGlass } from '@phosphor-icons/react';
import { useRef } from 'react';

import { SearchTypeSelect } from './SearchTypeSelect';

export interface SearchType {
  label: string;
  value: string;
}

export interface Props {
  value: string;
  onChange: (newValue: string) => void;
  types: SearchType[];
  selectedType: string;
  onTypeChange: (newType: string) => void;
}

export const SearchWithType: React.FC<Props> = (props) => {
  const { value, onChange, types, selectedType, onTypeChange } = props;
  const inputRef = useRef<null | HTMLInputElement>(null);

  return (
    <div className="flex items-center border rounded text-dark-04 overflow-hidden">
      <div className="px-2">
        <MagnifyingGlass className="input-icon" />
      </div>
      <input
        ref={inputRef}
        className="flex-1 bg-transparent py-2 focus:outline-none text-dark-01"
        type="text"
        onChange={(evt) => onChange(evt.target.value)}
        value={value}
        autoComplete="off"
      />
      <div className="border-l h-full">
        <SearchTypeSelect values={types} selectedValue={selectedType} onSelectValue={onTypeChange} />
      </div>
    </div>
  );
};
