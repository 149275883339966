import classNames from '@utils/classnames';
import { IterableElement } from 'type-fest';
import { CalendarDots, Check, Clock, X } from '@phosphor-icons/react';
import {
  usePendingCustomerOrdersQuery,
  PendingCustomerOrdersQuery,
  OrderLineStopType,
  OrderDocumentType,
} from 'generated/graphql';
import { formatDate, formatInputTime } from '../../../utils/date';

interface IOrderSelectProps {
  customerId: number;
  searchValue: string;
  selectedOrderIds: string[];
  onSelectOrder: (order: IterableElement<PendingCustomerOrdersQuery['pendingCustomerOrders']>) => void;
}

export const OrderSelect: React.FC<IOrderSelectProps> = (props) => {
  const { customerId, searchValue, selectedOrderIds, onSelectOrder } = props;
  const [data] = usePendingCustomerOrdersQuery({
    variables: {
      customerId,
      orderNumber: searchValue || undefined,
    },
    requestPolicy: 'network-only',
  });
  const orders = data.data?.pendingCustomerOrders ?? [];
  const selectedOrders = new Set(selectedOrderIds);

  return (
    <div className="grid gap-4 lg:grid-cols-2 2xl:grid-cols-3">
      {orders.map((o) => {
        const isSelected = selectedOrders.has(o.id);
        return (
          <div
            key={o.id}
            className={classNames('card p-4', {
              'selected-card': isSelected,
            })}
            data-clickable="true"
            onClick={() => {
              onSelectOrder(o);
            }}
          >
            <div className="card-heading-text flex justify-between">
              <div>{o.orderNumber}</div>
              <div>{o.customerRef}</div>
            </div>

            {o.lines.map((line) => {
              return (
                <div>
                  {line.stops
                    .sort((a, b) => a.sequenceIndex - b.sequenceIndex)
                    .map((stop) => {
                      return (
                        <div className="grid grid-cols-2 my-1">
                          <div className="font-medium">{stop.type === OrderLineStopType.Load ? 'Laden' : 'Lossen'}</div>
                          <div>
                            {stop.location.country}-{stop.location.name}
                          </div>

                          <div className="flex gap-2 items-center">
                            <CalendarDots className="w-4 h-4" />
                            {formatDate(stop.date)}
                          </div>
                          <div className="flex gap-2 items-center">
                            <Clock className="w-4 h-4" />
                            {`${formatInputTime(stop.timeStart)}-${formatInputTime(stop.timeEnd)}`}
                          </div>
                        </div>
                      );
                    })}
                </div>
              );
            })}

            <div className="flex font-medium items-center">
              CMR{' '}
              {!!o.documents.find((d) => d.type === OrderDocumentType.Cmr) ? (
                <Check className="w-6 h-6 text-feedback-positive" />
              ) : (
                <X className="w-6 h-6 text-feedback-negative-04" />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
