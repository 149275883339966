import { Plus } from '@phosphor-icons/react';
import { Button } from 'components/button/Button';
import { InputField } from 'components/input/InputField';
import { Formik } from 'formik';
import { Language, useCreateLocationContactMutation } from 'generated/graphql';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { SimpleSelectField } from '../../../../components/select/SimpleSelectField';
import { TextAreaField } from '../../../../components/textarea/TextAreaField';
import { getDisplayError } from '../../../../utils/get-display-error';
import { LANGUAGE_VALUES } from '../../../../utils/language';
import { formatPhoneNumber } from '../../../../utils/phonenumber';
import { sleep } from '../../../../utils/sleep';
import { useLocation } from '../Location/locationContext';

const createContactSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Moet ingevuld zijn').required('Moet ingevuld zijn'),
  email: Yup.string().email('Ongeldig email'),
  phone: Yup.string().phone(),
  function: Yup.string(),
});

const CreateLocationContactPage = () => {
  const navigate = useNavigate();
  const { location, refreshData: refetchLocationData } = useLocation();
  const [, createContactMutation] = useCreateLocationContactMutation();

  return (
    <div>
      <h1 className="heading-one mb-4">Nieuw contact</h1>

      <Formik
        initialValues={{
          name: '',
          email: '',
          phone: '',
          function: '',
          internalNotes: '',
          language: LANGUAGE_VALUES.find((v) => v.key === location.language)!,
        }}
        validationSchema={createContactSchema}
        onSubmit={async (values) => {
          try {
            const result = await createContactMutation({
              data: {
                ...values,
                language: values.language.key as Language,
                locationId: location.id,
                phone: formatPhoneNumber(values.phone, false),
              },
            });
            if (result.error) {
              throw result.error;
            }
            if (result.data) {
              refetchLocationData();
              await sleep(100);
              navigate('..');
            }
            toast.success('Contact aangemaakt');
          } catch (err: any) {
            toast.error('Kon contact niet aanmaken: ' + getDisplayError(err));
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <InputField labelText="Naam" type="text" name="name" />
            <InputField labelText="Email" type="text" name="email" />
            <InputField labelText="Telefoon" type="text" name="phone" />
            <InputField labelText="Functie" type="text" name="function" />
            <SimpleSelectField labelText="Taal" items={LANGUAGE_VALUES} name="language" />
            <TextAreaField labelText="Interne notities" name="internalNotes" spellCheck={true} />
            <Button
              type="submit"
              color="primary"
              iconLeft={<Plus className="button-icon" />}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Maak contact aan
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default CreateLocationContactPage;
