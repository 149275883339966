import classNames from '@utils/classnames';

import { StatusColor } from './StatusBadge';

export interface IStatusDotProps {
  color: StatusColor;
}

export const StatusDot: React.FC<IStatusDotProps> = (props) => {
  const { color } = props;

  return (
    <div
      className={classNames('status-dot', {
        'bg-dark-01': color === 'black',
        'bg-feedback-negative-04': color === 'red',
        'bg-orange-01': color === 'orange',
        'bg-green-01': color === 'green',
      })}
    ></div>
  );
};
