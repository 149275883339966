import { Suspense, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Truck, MapPin, Money, Gear, AddressBook, Users } from '@phosphor-icons/react';

import { useAuth } from '../../contexts/auth-context';
import { SpinnerBlock } from '../../components/Spinner';
import { useTranslation } from '../../contexts/translation-context';
import { Avatar } from '../../components/avatar/Avatar';
import { Sidebar } from '../../components/Sidebar';

export interface ICustomerPortalDashboardProps {
  children: React.ReactNode;
}

export const CustomerPortalDashboard: React.FC<ICustomerPortalDashboardProps> = (props) => {
  const { children } = props;
  const { me } = useAuth();
  const { i18n, lang } = useTranslation();

  const [navigationOne, navigationTwo] = useMemo(() => {
    return [
      [
        { name: i18n('customerPortal.orders.orders'), to: './orders', icon: Truck },
        { name: i18n('customerPortal.locations.locations'), to: './locations', icon: MapPin },
        { name: i18n('customerPortal.quotes.quotes'), to: './quotations', icon: Money },
        { name: i18n('customerPortal.invoices.invoices'), to: './invoices', icon: Money },
      ],
      [
        { name: i18n('customerPortal.contacts.contacts'), to: './contacts', icon: AddressBook },
        { name: i18n('customerPortal.users.users'), to: './users', icon: Users },
        {
          name: i18n('customerPortal.businessInformation.businessInformation'),
          to: './business-information',
          icon: Gear,
        },
      ],
    ];
  }, [i18n, lang]);

  return (
    <div className="flex min-h-screen w-full">
      <div className="flex-shrink-0">
        <Sidebar
          homeLink="/customer-portal"
          topItems={
            <div className="flex flex-1 flex-col">
              <nav className="flex-1">
                {navigationOne.map((item) => {
                  return (
                    <Link key={item.name} to={item.to} className="sidebar-item hoverable-sidebar-item">
                      <div className="sidebar-item-icon-container">
                        <item.icon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                      </div>
                      <div className="sidebar-item-text-container">{item.name}</div>
                    </Link>
                  );
                })}
              </nav>

              <div className="bg-dark-05 ml-4 mr-8 my-4" style={{ height: 1 }} />

              <nav className="flex-1">
                {navigationTwo.map((item) => {
                  return (
                    <Link key={item.name} to={item.to} className="sidebar-item hoverable-sidebar-item">
                      <div className="sidebar-item-icon-container">
                        <item.icon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                      </div>
                      <div className="sidebar-item-text-container">{item.name}</div>
                    </Link>
                  );
                })}
              </nav>
            </div>
          }
          bottomItems={
            <Link to="me" className="sidebar-item hoverable-sidebar-item">
              <div className="sidebar-item-icon-container">
                <Avatar name={me.name} url={me.image} />
              </div>
              <div className="sidebar-item-text-container">{me.name}</div>
            </Link>
          }
        />
      </div>

      <main className="pb-32 content-bg min-h-screen flex-1">
        <Suspense fallback={<SpinnerBlock message={i18n('customerPortal.loading')} />}>{children}</Suspense>
      </main>
    </div>
  );
};
