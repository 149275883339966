import { Supplier, Order } from './types';
import type { Document } from '../../../../document/types';

export type ICreatePurchaseState = {
  activeWindow: 'SELECT_SUPPLIER' | 'SELECT_ORDERS' | 'REVIEW';
  selectedSupplier: Supplier | null;
  orders: Order[];
  document: Document | null;
};

export type CreatePurchaseAction =
  | {
      type: 'SELECT_SUPPLIER';
      supplier: Supplier | null;
    }
  | {
      type: 'SELECT_ORDER';
      order: Order;
    }
  | {
      type: 'UPLOAD_DOC';
      document: Document;
    }
  | {
      type: 'GO_BACK';
    }
  | {
      type: 'GO_FORWARD';
    };

export function createPurchaseReducer(state: ICreatePurchaseState, action: CreatePurchaseAction): ICreatePurchaseState {
  switch (action.type) {
    case 'SELECT_SUPPLIER': {
      return {
        ...state,
        selectedSupplier: action.supplier,
        orders: [],
        activeWindow: action.supplier ? 'SELECT_ORDERS' : 'SELECT_SUPPLIER',
      };
    }
    case 'SELECT_ORDER': {
      const ordersClone = [...state.orders];
      const foundIndex = ordersClone.findIndex((o) => o.id === action.order.id);
      if (foundIndex >= 0) {
        ordersClone.splice(foundIndex, 1);
      } else {
        ordersClone.push(action.order);
      }
      return {
        ...state,
        orders: ordersClone,
      };
    }
    case 'GO_BACK': {
      let newWindow = state.activeWindow;
      if (state.activeWindow === 'SELECT_ORDERS') {
        newWindow = 'SELECT_SUPPLIER';
      } else if (state.activeWindow === 'REVIEW') {
        newWindow = 'SELECT_ORDERS';
      }

      return {
        ...state,
        selectedSupplier: newWindow === 'SELECT_SUPPLIER' ? null : state.selectedSupplier,
        orders: newWindow === 'SELECT_SUPPLIER' ? [] : state.orders,
        activeWindow: newWindow,
      };
    }
    case 'GO_FORWARD': {
      let newWindow = state.activeWindow;
      if (state.activeWindow === 'SELECT_SUPPLIER') {
        newWindow = 'SELECT_ORDERS';
      } else if (state.activeWindow === 'SELECT_ORDERS') {
        newWindow = 'REVIEW';
      }

      return {
        ...state,
        activeWindow: newWindow,
      };
    }
    case 'UPLOAD_DOC': {
      return {
        ...state,
        document: action.document,
      };
    }
  }

  return state;
}
