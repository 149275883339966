import { Button } from '../button/Button';

export interface ITableActionBarProps<V> {
  cancelSelection?: () => void;
  selections?: V[];
  renderSelectionBar?: (selections: V[]) => React.ReactNode;
  children: React.ReactNode;
}

export function TableActionBar<V>(props: ITableActionBarProps<V>) {
  const { selections, renderSelectionBar, children, cancelSelection } = props;

  return (
    <div className="relative h-12">
      {!!selections?.length && (
        <div className="absolute z-table-bar top-0 left-0 w-full bg-indigo-600 h-full flex justify-between text-white bg-offwhite">
          <div className="flex items-center pl-4">{selections.length} items selected</div>
          <div className="flex">
            <div>{renderSelectionBar?.(selections)}</div>
            {!!cancelSelection && (
              <div className="flex ml-4">
                <Button color="primary" onTrigger={cancelSelection}>
                  Annuleer
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
      <div>{children}</div>
    </div>
  );
}
