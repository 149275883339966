import { useGetCustomersQuery } from '../../../../../generated/graphql';
import { CUSTOMER_TYPE_VALUES } from '../../../../customer/select-values';
import { useCreateCreditNoteCtx } from './context';

export interface Props {
  searchValue: string;
}

export const CustomerSelect: React.FC<Props> = (props) => {
  const { searchValue } = props;
  const { dispatch } = useCreateCreditNoteCtx();
  const [data] = useGetCustomersQuery({
    variables: {
      filters: {
        search: searchValue || undefined,
      },
    },
  });

  const customers = data.data?.customers ?? [];

  if (!customers.length) {
    return <div>Er zijn geen klanten.</div>;
  }

  return (
    <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-3">
      {customers.map((customer) => {
        return (
          <div
            key={customer.id}
            className="card p-4"
            data-clickable="true"
            onClick={() => {
              dispatch({
                type: 'SELECT_CUSTOMER',
                customer,
              });
            }}
          >
            <div className="flex justify-between">
              <div className="card-heading-text">{customer.name}</div>
              <div className="card-subtle-text">{customer.vatNumber}</div>
            </div>

            <div className="card-subtle-text mb-4">
              {CUSTOMER_TYPE_VALUES.find((v) => v.key === customer.type)?.name}
            </div>

            <div>{`${customer.street} ${customer.streetNumber}`}</div>
            <div>{`${customer.postalCode} ${customer.city}, ${customer.country}`}</div>
          </div>
        );
      })}
    </div>
  );
};
