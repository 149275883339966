import { Breadcrumb } from 'components/Breadcrumb';
import { PageHeader } from 'components/PageHeader';
import { PaymentReminderInvoiceSelect } from './InvoiceSelect';
import { PageHeading } from 'components/PageHeading';

export const CreatePaymentReminderPage = () => {
  return (
    <div>
      <PageHeader title="Nieuwe betaalherinnering" />

      <PageHeading
        leftSide={
          <Breadcrumb
            parentItem={{
              name: 'Betaalherinneringen',
              to: '/internal/finance/payment-reminder',
            }}
            currentItem="Nieuwe betaal herinnering"
          />
        }
      />

      <div className="px-4">
        <PaymentReminderInvoiceSelect />
      </div>
    </div>
  );
};
