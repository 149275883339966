import { useDashboardEntries } from '../context';
import { ChartWithTable } from '../../../components/graphs/chart-with-table';

export const OrdersOverviewStats: React.FC = () => {
  const { entries } = useDashboardEntries();

  // const entries = _entries.filter((v) => !v.noPurchase);

  const groupedByCustomer: Map<
    number,
    {
      customerId: number;
      customerName: string;
      count: number;
      saleAmount: number;
      purchaseAmount: number;
    }
  > = new Map();

  for (const entry of entries) {
    if (entry.customerId == null) continue;

    const existing = groupedByCustomer.get(entry.customerId);
    if (existing) {
      existing.count++;
      existing.saleAmount += entry.saleAmount;
      existing.purchaseAmount += entry.purchaseAmount;
    } else {
      groupedByCustomer.set(entry.customerId, {
        customerId: entry.customerId,
        customerName: entry.customerName ?? '',
        count: 1,
        saleAmount: entry.saleAmount,
        purchaseAmount: entry.purchaseAmount,
      });
    }
  }

  const groupedByOrigin: Map<string, number> = new Map();
  for (const entry of entries) {
    groupedByOrigin.set(entry.origin, (groupedByOrigin.get(entry.origin) ?? 0) + 1);
  }

  const groupedByCustomerType: Map<string, number> = new Map();
  for (const entry of entries) {
    if (!entry.customerType) {
      continue;
    }

    groupedByCustomerType.set(entry.customerType, (groupedByCustomerType.get(entry.customerType) ?? 0) + 1);
  }

  const aggregatedValues = Array.from(groupedByCustomer.values());
  const revenueStats = aggregatedValues
    .sort((a, b) => b.saleAmount - a.saleAmount)
    .map((s) => {
      return {
        id: s.customerId,
        name: s.customerName,
        value: s.saleAmount / 100,
        link: `/dashboard/customers/${s.customerId}/general`,
      };
    });
  const marginStats = aggregatedValues
    .sort((a, b) => b.saleAmount - a.saleAmount)
    .map((s) => {
      return {
        id: s.customerId,
        name: s.customerName,
        value: (s.saleAmount - s.purchaseAmount) / 100,
        link: `/dashboard/customers/${s.customerId}/general`,
      };
    });
  const countByCustomerStats = aggregatedValues
    .sort((a, b) => b.count - a.count)
    .map((s) => {
      return {
        id: s.customerId,
        name: s.customerName,
        value: s.count,
        link: `/dashboard/customers/${s.customerId}/general`,
      };
    });
  const countByOriginStats = Array.from(groupedByOrigin.entries())
    .sort((a, b) => b[1] - a[1])
    .map((s, idx) => {
      return {
        id: s[0],
        name: s[0],
        value: s[1],
      };
    });
  const countByCustomerType = Array.from(groupedByCustomerType.entries())
    .sort((a, b) => b[1] - a[1])
    .map((s, idx) => {
      return {
        id: s[0],
        name: s[0],
        value: s[1],
      };
    });

  const spotOrderCount = entries.filter((e) => e.supplierId && !e.regularCarrier).length;
  const regularCarrierOrderCount = entries.filter((e) => e.supplierId && !!e.regularCarrier).length;
  const orderCountPerType = [
    {
      id: 1,
      name: 'Spot Orders',
      value: spotOrderCount,
      link: '/dashboard/orders/spot',
    },
    {
      id: 2,
      name: 'Vaste vervoerder Orders',
      value: regularCarrierOrderCount,
      link: '/dashboard/orders/regular',
    },
  ];

  return (
    <div>
      <div className="heading-two mb-4">Overzicht Orders</div>
      <div className="2xl:grid grid-cols-2 gap-x-8">
        <div className="mb-4">
          <ChartWithTable
            title="Omzet"
            nameTitle="Klant"
            data={revenueStats}
            dataType="currency"
            showPercentage={true}
          />
        </div>

        <div className="mb-4">
          <ChartWithTable
            title="Bruto Marge"
            nameTitle="Klant"
            data={marginStats}
            dataType="currency"
            showPercentage={true}
          />
        </div>

        <div className="mb-4">
          <ChartWithTable
            title="Aantal Orders per klant"
            nameTitle="Klant"
            data={countByCustomerStats}
            dataType="value"
            showPercentage={true}
            precision={0}
          />
        </div>

        <div className="mb-4">
          <ChartWithTable
            title="Aantal Orders per klant type"
            nameTitle="Klant"
            data={countByCustomerType}
            dataType="value"
            showPercentage={true}
            precision={0}
          />
        </div>

        <div className="mb-4">
          <ChartWithTable
            title="Aantal Orders per entry type"
            nameTitle="Entry type"
            data={countByOriginStats}
            dataType="value"
            showPercentage={true}
            precision={0}
          />
        </div>

        <div className="mb-4">
          <ChartWithTable
            title="Verhouding Spot Orders"
            nameTitle="Type"
            data={orderCountPerType}
            dataType="value"
            showPercentage={true}
            precision={0}
          />
        </div>
      </div>
    </div>
  );
};
