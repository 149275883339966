export interface ILine {
  amount: number;
  unitPrice: number;
  vatRatePercentage: number;
}

export const calculateLineTotal = (line: ILine) => {
  return (line.amount / 100) * line.unitPrice;
};

export const calculateLinesTotalExclVat = (lines: ILine[]) => {
  return lines.reduce((acc, line) => acc + calculateLineTotal(line), 0);
};

export const calculateLinesTotalVat = (lines: ILine[]) => {
  return lines.reduce((acc, line) => acc + calculateLineTotal(line) * (line.vatRatePercentage / 10000), 0);
};

export function calculateProfitPercentage(purchaseTotal: number, saleTotal: number): number {
  if (purchaseTotal <= 0) {
    if (saleTotal <= 0) {
      return 0;
    } else {
      return 10000;
    }
  }

  const profit = saleTotal - purchaseTotal;
  return (profit / saleTotal) * 10000;
}
