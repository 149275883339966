import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useCreateCreditNoteCtx } from './context';
import * as Yup from 'yup';
import { useMemo } from 'react';
import { CalendarDots, Clock, Plus, Trash } from '@phosphor-icons/react';
import { toast } from 'react-hot-toast';

import { Button } from '../../../../../components/button/Button';
import { getDisplayError } from '../../../../../utils/get-display-error';
import { SuspenseSpinner } from '../../../../../components/SuspenseSpinner';
import {
  ProductTypeComboboxField,
  productTypeToComboboxItem,
} from '../../../../order/productType/ProductTypeComboboxField';
import { InputField } from '../../../../../components/input/InputField';
import { useMinimalGeneralSettings } from '../../../../../contexts/minimal-settings-context';
import { getInitialValues as getInitialOrderLineSaleValues } from '../../../../order/pages/orderLineSale/CreateOrderLineSale';
import { formatNumber, parseNumberInput } from '../../../../../utils/number';
import { OrderLineStopType, useCreateCreditNoteMutation } from '../../../../../generated/graphql';
import { formatDate, formatInputDate, formatInputTime } from '../../../../../utils/date';
import { nullthrows } from '../../../../../utils/invariant';
import { useNavigate } from 'react-router-dom';
import { VatRateComboboxField, vatRateToComboboxItem } from '../../../../order/vatRate/VatRateComboboxField';
import { TextAreaField } from '../../../../../components/textarea/TextAreaField';

const CreditNoteLinesSchema = Yup.object().shape({
  orders: Yup.array().of(
    Yup.object().shape({
      orderId: Yup.string().required('Vereist'),
      lines: Yup.array().of(
        Yup.object().shape({
          productType: Yup.mixed().nullable().required('Vereist'),
          vatRate: Yup.mixed().nullable().required('Vereist'),
          amount: Yup.number().required('Vereist'),
          unitPrice: Yup.number().required('Vereist'),
        }),
      ),
    }),
  ),
});

export const CreditNoteLines: React.FC<{}> = (props) => {
  const { state } = useCreateCreditNoteCtx();
  const { settings } = useMinimalGeneralSettings();
  const navigate = useNavigate();
  const orders = state.orders;
  const customer = nullthrows(state.selectedCustomer, 'No customer selected');
  const invoice = nullthrows(state.selectedInvoice, 'No invoice selected');
  const [_createCreditNoteRes, createCreditNote] = useCreateCreditNoteMutation();

  const initialValues = useMemo(() => {
    return {
      date: formatInputDate(new Date()),
      internalNotes: '',
      externalNotes: '',
      orders: orders.map((o) => {
        return {
          orderId: o.id,
          lines: o.lines
            .map((l) => {
              return l.sales.flat();
            })
            .flat()
            .map((s) => {
              return {
                vatRate: vatRateToComboboxItem(s.vatRate),
                amount: formatNumber(s.amount, 2),
                unitPrice: formatNumber(s.unitPrice, 2),
                productType: productTypeToComboboxItem(s.productType),
                notes: '',
              };
            }),
        };
      }),
    };
  }, []);

  const formikbag = useFormik({
    initialValues,
    validationSchema: CreditNoteLinesSchema,
    onSubmit: async (values) => {
      try {
        const res = await createCreditNote({
          data: {
            date: values.date,
            internalNotes: values.internalNotes ?? '',
            externalNotes: values.externalNotes ?? '',
            invoiceId: invoice.id,
            orders: values.orders.map((o) => {
              return {
                orderId: o.orderId,
                lines: o.lines.map((l) => {
                  return {
                    amount: parseNumberInput(l.amount, 2),
                    unitPrice: parseNumberInput(l.unitPrice, 2),
                    vatRate: l.vatRate.key,
                    productTypeId: l.productType.key,
                    notes: l.notes ?? '',
                  };
                }),
              };
            }),
          },
        });

        if (res.error) {
          throw res.error;
        }

        toast.success('Creditnota aangemaakt');
        navigate(`../${res.data?.createCreditNote.id}`);
      } catch (err: any) {
        toast.error('Kon creditnota niet aanmaken: ' + getDisplayError(err));
      }
    },
  });

  const { handleSubmit, isSubmitting, values } = formikbag;

  if (!orders.length) {
    return <div>Er zijn geen orders geselecteerd.</div>;
  }

  return (
    <FormikProvider value={formikbag}>
      <form onSubmit={handleSubmit}>
        <div>
          <div>
            <InputField labelText="Datum" name="date" type="date" />
          </div>
          <div>
            <TextAreaField labelText="Interne Notities" name="internalNotes" spellCheck={true} />
          </div>
          <div>
            <TextAreaField labelText="Externe Notities" name="externalNotes" spellCheck={true} />
          </div>
        </div>

        <div className="grid gap-4 2xl:grid-cols-2">
          {orders.map((o, i) => {
            const linesValues = values.orders[i].lines;
            const lineName = `orders[${i}].lines`;
            return (
              <div key={o.id} data-clickable="true">
                <div className="font-medium text-lg mb-2">Order {o.orderNumber ?? o.id}</div>
                <div>
                  {o.lines
                    .map((l) => l.stops)
                    .flat()
                    .map((stop) => {
                      return (
                        <div className="flex" key={stop.id}>
                          <div className="font-medium">{stop.type === OrderLineStopType.Load ? 'Laden' : 'Lossen'}</div>
                          <div className="ml-4">
                            {stop.location.country}-{stop.location.name}
                          </div>
                          <div className="flex items-center ml-4">
                            <CalendarDots className="w-4 h-4 mr-2" />
                            <div>{formatDate(stop.date)}</div>
                          </div>
                          <div className="flex items-center ml-4">
                            <Clock className="w-4 h-4 mr-2" />
                            <div>
                              {formatInputTime(stop.timeStart)} - {formatInputTime(stop.timeEnd)}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>

                <FieldArray
                  name={lineName}
                  render={(arrayHelpers) => (
                    <div className="my-4">
                      <div className="flex justify-between items-end">
                        <h2 className="font-medium text-lg mb-2">Crediteringen</h2>
                        <Button
                          onTrigger={() => {
                            arrayHelpers.push({
                              ...getInitialOrderLineSaleValues(settings, linesValues.length, customer),
                            });
                          }}
                          iconLeft={<Plus className="button-icon" />}
                        >
                          Voeg creditering toe
                        </Button>
                      </div>

                      {linesValues.length > 0 ? (
                        <div>
                          {linesValues.map((line, index) => {
                            return (
                              <SuspenseSpinner key={`creditnote-line-${index}`}>
                                <div className="mt-4">
                                  <div className="flex gap-2 items-center">
                                    <div className="flex-1">
                                      <ProductTypeComboboxField
                                        labelText="Product"
                                        name={`${lineName}[${index}].productType`}
                                      />
                                    </div>

                                    <Button
                                      color="danger"
                                      onTrigger={() => {
                                        arrayHelpers.remove(index);
                                      }}
                                      iconLeft={<Trash className="button-icon" />}
                                    >
                                      Verwijder
                                    </Button>
                                  </div>

                                  <div className="flex gap-2">
                                    <div style={{ flex: 2 }}>
                                      <InputField
                                        labelText="Aantal"
                                        type="number"
                                        name={`${lineName}[${index}].amount`}
                                        step="0.01"
                                      />
                                    </div>
                                    <div style={{ flex: 2 }}>
                                      <InputField
                                        labelText="Eenheidsprijs"
                                        type="number"
                                        name={`${lineName}[${index}].unitPrice`}
                                        step="0.01"
                                      />
                                    </div>
                                    <div style={{ flex: 3 }}>
                                      <VatRateComboboxField
                                        labelText="Standaard BTW Tarief"
                                        name={`${lineName}[${index}].vatRate`}
                                      />
                                    </div>
                                  </div>

                                  <InputField
                                    labelText="Extra info"
                                    type="text"
                                    name={`${lineName}[${index}].notes`}
                                    spellCheck={true}
                                  />
                                </div>
                              </SuspenseSpinner>
                            );
                          })}
                        </div>
                      ) : (
                        <div>Geen verkopen</div>
                      )}
                    </div>
                  )}
                />
              </div>
            );
          })}
        </div>

        <Button
          type="submit"
          color="primary"
          iconLeft={<Plus className="button-icon" />}
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        >
          Maak creditnotaontwerp aan
        </Button>
      </form>
    </FormikProvider>
  );
};
