import { Eye } from '@phosphor-icons/react';
import * as Dialog from '@radix-ui/react-dialog';
import { useState } from 'react';
import { IterableElement } from 'type-fest';

import { BaseButton } from '../../../../components/button/BaseButton';
import { GetOrderByIdQuery } from '../../../../generated/graphql';
import { DocumentPreview } from '../../../document/components/DocumentPreview';
import { Button } from '../../../../components/button/Button';

export type IncomingOrderEmail = IterableElement<NonNullable<GetOrderByIdQuery['order']>['incomingOrderEmail']>;

interface IFileUploaderButtonProps {
  email: IncomingOrderEmail;
}

export const IncomingOrderEmailButton: React.FC<IFileUploaderButtonProps> = (props) => {
  const { email } = props;
  const [open, setOpen] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState<
    IterableElement<typeof email.attachments> | null | undefined
  >(email.attachments[0]);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <BaseButton color="default" iconLeft={<Eye className="button-icon" />}>
          Order Email
        </BaseButton>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay className="dialog-overlay" />
        <Dialog.Content className="dialog-content" style={{ maxWidth: '100vw' }}>
          <Dialog.Title className="heading-one mb-4">Order Email</Dialog.Title>

          <div className="grid grid-cols-2">
            <div className="overflow-y-auto" style={{ maxHeight: '75vh' }}>
              <div>Van: {email.from}</div>
              <div>Onderwerp: {email.subject}</div>
              <div className="mt-2 whitespace-pre-line">{email.content}</div>

              <div className="heading-two mt-8 mb-2">Bijlagen</div>
              {email.attachments.map((attachment) => {
                return (
                  <div key={attachment.id}>
                    <Button
                      isDisabled={selectedAttachment?.id === attachment.id}
                      onTrigger={() => {
                        setSelectedAttachment(attachment);
                      }}
                    >
                      {attachment.document.name}
                    </Button>
                  </div>
                );
              })}
            </div>
            <div style={{ maxHeight: '75vh' }}>
              {selectedAttachment && <DocumentPreview document={selectedAttachment.document} />}
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
