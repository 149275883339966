import createContext from 'contexts/create-context';
import * as React from 'react';

import { CreateInvoiceAction, ICreateInvoiceState, createInvoiceReducer } from './reducer';

interface ProviderValue {
  state: ICreateInvoiceState;
  dispatch: React.Dispatch<CreateInvoiceAction>;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

export const CreateInvoiceProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
  const { children } = props;
  const [state, dispatch] = React.useReducer(createInvoiceReducer, {
    activeWindow: 'SELECT_CUSTOMER',
    selectedCustomer: null,
    orders: [],
  });

  return <ReactProvider value={{ state, dispatch }}>{children}</ReactProvider>;
};

export const useCreateInvoiceCtx = useContext;
export const Consumer = ReactConsumer;
