import React from 'react';

export interface IPageHeadingProps {
  leftSide?: React.ReactNode;
  rightSide?: React.ReactNode;
}

export const PageHeading: React.FC<IPageHeadingProps> = (props) => {
  const { leftSide, rightSide } = props;

  return (
    <div className="flex mb-8 h-24">
      <div className="flex-shrink-0 flex items-center border-b border-b-orange-00 px-8">{leftSide}</div>

      <div className="w-full flex justify-end items-center border-b border-b-dark-05 px-8">{rightSide}</div>
    </div>
  );
};
