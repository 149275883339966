import { DownloadSimple } from '@phosphor-icons/react';
import { Button, ButtonProps } from '../../../components/button/Button';
import { downloadUrl } from '../../../utils/download';

export interface IDownloadButtonProps {
  children: React.ReactNode;
  downloadLink: string | undefined | null;
  color?: ButtonProps['color'];
}

export const DownloadButton: React.FC<IDownloadButtonProps> = (props) => {
  const { children, downloadLink, color } = props;

  return (
    <Button
      iconLeft={<DownloadSimple className="button-icon" />}
      isDisabled={!downloadLink}
      onTrigger={() => {
        if (downloadLink) {
          downloadUrl(downloadLink, {
            openInNewTab: true,
          });
        }
      }}
      color={color}
    >
      {children}
    </Button>
  );
};
