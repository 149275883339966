import { Plus } from '@phosphor-icons/react';
import { Button } from 'components/button/Button';
import { InputField } from 'components/input/InputField';
import { Formik } from 'formik';
import { Language, useCreateCustomerContactMutation } from 'generated/graphql';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { CheckboxField } from '../../../../components/checkbox/CheckboxField';
import { SimpleSelectField } from '../../../../components/select/SimpleSelectField';
import { getDisplayError } from '../../../../utils/get-display-error';
import { LANGUAGE_VALUES } from '../../../../utils/language';
import { formatPhoneNumber } from '../../../../utils/phonenumber';
import { sleep } from '../../../../utils/sleep';
import { useCustomer } from '../Customer/customerContext';
import { PageHeading } from 'components/PageHeading';
import { Breadcrumb } from 'components/Breadcrumb';

const createContactSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Vereist').required('Vereist'),
  email: Yup.string().email('Ongeldig email'),
  phone: Yup.string().phone(),
  language: Yup.mixed().nullable().required('Vereist'),
  shouldReceiveInvoices: Yup.boolean(),
  shouldReceiveOrderConfirmations: Yup.boolean(),
  shouldReceiveCMR: Yup.boolean(),
  shouldReceiveQuotations: Yup.boolean(),
  shouldReceiveDieselSurcharge: Yup.boolean(),
});

const CreateCustomerContactPage = () => {
  const navigate = useNavigate();
  const { customer, refreshData: refetchCustomerData } = useCustomer();
  const [, createContactMutation] = useCreateCustomerContactMutation();

  return (
    <div>
      <h2 className="heading-three mb-4">Maak contact aan</h2>

      <Formik
        initialValues={{
          name: '',
          email: '',
          phone: '',
          function: '',
          language: LANGUAGE_VALUES.find((v) => v.key === customer.language)!,
          shouldReceiveInvoices: false,
          shouldReceiveOrderConfirmations: false,
          shouldReceiveCMR: false,
          shouldReceiveQuotations: false,
          shouldReceiveDieselSurcharge: false,
        }}
        validationSchema={createContactSchema}
        onSubmit={async (values) => {
          try {
            const result = await createContactMutation({
              data: {
                ...values,
                phone: formatPhoneNumber(values.phone, false),
                language: values.language.key as Language,
                customerId: customer.id,
              },
            });
            if (result.error) {
              throw result.error;
            }
            if (result.data) {
              refetchCustomerData();
              await sleep(100);
              navigate('..');
            }
            toast.success('Contact aangemaakt');
          } catch (err: any) {
            toast.error('Kon contact niet aanmaken: ' + getDisplayError(err));
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <InputField labelText="Naam" type="text" name="name" />
            <InputField labelText="Email" type="text" name="email" />
            <InputField labelText="Telefoon" type="text" name="phone" />
            <InputField labelText="Functie" type="text" name="function" />
            <SimpleSelectField labelText="Taal" items={LANGUAGE_VALUES} name="language" />

            <h2 className="heading-two mb-2">Contact geschikt voor</h2>
            <div className="grid grid-cols-4 gap-x-4 mb-2">
              <CheckboxField labelText="Facturatie" name="shouldReceiveInvoices" />
              <CheckboxField labelText="Order bevestiging" name="shouldReceiveOrderConfirmations" />
              <CheckboxField labelText="CMR" name="shouldReceiveCMR" />
              <CheckboxField labelText="Offertes" name="shouldReceiveQuotations" />
              <CheckboxField labelText="Dieseltoeslag" name="shouldReceiveDieselSurcharge" />
            </div>

            <Button
              type="submit"
              color="primary"
              iconLeft={<Plus className="button-icon" />}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Maak contact aan
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default CreateCustomerContactPage;
