import { Plus } from '@phosphor-icons/react';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';

import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import { TrailersDocument, TrailersQuery, TrailersQueryVariables } from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { LinkButton } from '../../../components/button/ButtonLink';
import { useAuth } from 'contexts/auth-context';
import { Table } from 'components/table/Table';
import { PageHeading } from 'components/PageHeading';
import { Breadcrumb } from 'components/Breadcrumb';
import { formatDate } from '@utils/date';

const TABLE_HEADERS = [
  {
    id: 'id',
    name: 'ID',
  },
  {
    id: 'licensePlate',
    name: 'Nummerplaat',
  },
  {
    id: 'brand',
    name: 'Merk',
  },
  {
    id: 'registrationDate',
    name: 'Registratie',
  },
  {
    id: 'insuranceExpirationDate',
    name: 'Verzekering',
  },
  {
    id: 'inspectionExpirationDate',
    name: 'Keuring',
  },
  {
    id: 'isActive',
    name: 'Actief',
  },
];

export const TrailersPage = () => {
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<TrailersQuery, TrailersQueryVariables>(
          TrailersDocument,
          {
            id: variables.cursor,
            take: variables.take,
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.trailers ?? [];
    },
    [client],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 20,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  return (
    <>
      <PageHeader title="Trailers" />

      <div>
        <PageHeading
          leftSide={<Breadcrumb currentItem="Trailers" />}
          rightSide={
            <div className="flex gap-4">
              <div>
                <LinkButton to="new" color="primary" iconLeft={<Plus className="button-icon" />}>
                  Nieuwe trailer
                </LinkButton>
              </div>
            </div>
          }
        />

        <Table
          idKey="id"
          headers={TABLE_HEADERS}
          data={page.data}
          mapData={(trailer) => {
            return [
              <Link to={`${trailer.id}`} className="link-text">
                {trailer.name ?? trailer.id}
              </Link>,
              trailer.licensePlate,
              trailer.brand,
              formatDate(trailer.registrationDate),
              formatDate(trailer.insuranceExpirationDate),
              formatDate(trailer.inspectionExpirationDate),
              trailer.isActive ? 'Ja' : 'Nee',
            ];
          }}
        />

        <div className="my-4">
          <Pagination
            hasPrevious={page.hasPrevious}
            previous={page.previous}
            hasNext={page.hasNext}
            next={page.next}
            isFetching={page.isFetching}
          />
        </div>
      </div>
    </>
  );
};
