import { Warning, CalendarDots, Clock, Plus, Trash } from '@phosphor-icons/react';
import { PageHeader } from 'components/PageHeader';
import {
  GetInvoiceQuery,
  InvoiceStatus,
  OrderLineStopType,
  useApproveInvoiceMutation,
  useDeleteInvoiceMutation,
  useGenerateInvoiceMutation,
  useGetInvoiceQuery,
  useMarkSalesInvoiceAsPaidMutation,
  useRemoveOrdersFromInvoiceMutation,
  useSendInvoiceMutation,
  useUpdateInvoiceMutation,
} from 'generated/graphql';
import React, { useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { invariant, nullthrows } from '@utils/invariant';
import classNames from '@utils/classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Breadcrumb } from '../../../../components/Breadcrumb';
import { DataField } from '../../../../components/DataField';
import { ConfirmDialog } from '../../../../components/dialog/ConfirmDialog';
import { formatDate, formatDateTime, formatInputDate, formatInputTime } from '../../../../utils/date';
import { getDisplayError } from '../../../../utils/get-display-error';
import { formatNumber } from '../../../../utils/number';
import { FileUploaderButton } from '../../../document/components/FileUploaderButton';
import { calculateInvoiceTotals } from '../../utils/calculate';
import { StatusText } from '../../../../components/StatusText';
import { InvoiceStatusToColor, InvoiceStatusToText } from '../../utils/status';
import { FormDialog } from '../../../../components/dialog/FormDialog';
import { InputField } from '../../../../components/input/InputField';
import { useAuth } from '../../../../contexts/auth-context';
import { AddOrdersToInvoiceDialog } from '../../components/OrderSelectDialog';
import { Button } from '../../../../components/button/Button';
import { PageHeading } from 'components/PageHeading';

const updateInvoiceSchema = Yup.object().shape({
  invoiceDate: Yup.string().min(1, 'Vereist').required('Vereist'),
  customerRef: Yup.string(),
});

export type Invoice = NonNullable<GetInvoiceQuery['invoice']>;

const InvoicePage = () => {
  const { me } = useAuth();
  const navigate = useNavigate();
  const { saleId } = useParams<{ saleId: string }>();
  invariant(saleId);
  const [{ data, error }, refetch] = useGetInvoiceQuery({
    variables: {
      id: saleId,
    },
  });
  const [_sendInvoiceState, sendInvoice] = useSendInvoiceMutation();
  const [_genInvoiceState, generateInvoice] = useGenerateInvoiceMutation();
  const [_approveInvoiceState, approveInvoice] = useApproveInvoiceMutation();
  const [_deleteInvoiceState, deleteInvoice] = useDeleteInvoiceMutation();
  const [_removeOrdersFromInvoiceState, removeOrdersFromInvoice] = useRemoveOrdersFromInvoiceMutation();
  const [_updateInvoiceState, updateInvoice] = useUpdateInvoiceMutation();
  const [_markAsPaidState, markAsPaid] = useMarkSalesInvoiceAsPaidMutation();

  const refreshData = useCallback(() => {
    return refetch({
      requestPolicy: 'network-only',
    });
  }, [refetch]);

  const invoice = nullthrows(data?.invoice, 'Factuur niet gevonden');
  const totals = calculateInvoiceTotals([
    ...invoice.orders
      .map((o) =>
        o.lines
          .map((l) =>
            l.sales.map((s) => {
              return {
                ...s,
                vatRateId: s.vatRate.id,
              };
            }),
          )
          .flat(),
      )
      .flat(),
    ...invoice.manualInvoiceLines.map((v) => {
      return {
        ...v,
        vatRateId: v.vatRate.id,
      };
    }),
  ]);

  const totalsMismatch =
    invoice.status !== InvoiceStatus.Draft &&
    (invoice.totalExclVat !== totals.totalExclVat || invoice.totalInclVat !== totals.totalInclVat);
  const title = `Factuur ${invoice.invoiceNumber ?? 'DRAFT'}`;
  return (
    <>
      <PageHeader title={title} />

      <PageHeading
        leftSide={
          <Breadcrumb
            parentItem={{
              name: 'Verkopen',
              to: '/internal/finance/sales',
            }}
            currentItem={title}
          />
        }
        rightSide={
          <div className="flex gap-2">
            {invoice.status === InvoiceStatus.Draft && (
              <FormDialog
                triggerText="Factuur goedkeuren"
                title="Ben je zeker dat je de factuur wilt goedkeuren?"
                submitText="Factuur goedkeuren"
                initialValues={{ invoiceNumber: '' }}
                onSubmit={async (values) => {
                  const invoiceNumber = values.invoiceNumber.trim();

                  try {
                    const result = await approveInvoice({
                      invoiceId: invoice.id,
                      invoiceNumber: invoiceNumber ? +invoiceNumber : undefined,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                    refreshData();
                    toast.success('Factuur goedgekeurd');
                  } catch (err) {
                    toast.error(`Kon factuur niet goedkeuren: ${getDisplayError(err)}`);
                  }
                }}
              >
                <div>Ben je zeker dat je de factuur wilt goedkeuren?</div>

                <div>
                  Vul het factuur nummer alleen in als je manueel een ander factuurnummer wilt gebruiken (uit het
                  verleden), anders zal het de automatische nummering de mist in laten gaan.
                </div>

                <InputField labelText="Factuur nummer" name="invoiceNumber" type="number" />
              </FormDialog>
            )}

            {invoice.status !== InvoiceStatus.Draft && invoice.status !== InvoiceStatus.Paid && (
              <FormDialog
                triggerText="Markeer als betaald"
                title="Ben je zeker dat je de factuur wilt markeren als betaald?"
                submitText="Markeer als betaald"
                initialValues={{ paidAt: formatInputDate(new Date()) }}
                onSubmit={async (values) => {
                  const paidAt = values.paidAt;

                  try {
                    const result = await markAsPaid({
                      invoiceId: invoice.id,
                      paidAt,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                    toast.success('Factuur gemarkeerd als betaald');
                  } catch (err) {
                    toast.error(`Kon factuur niet markeren als betaald: ${getDisplayError(err)}`);
                  }
                }}
              >
                <InputField labelText="Betaald op" name="paidAt" type="date" />
              </FormDialog>
            )}

            {invoice.status === InvoiceStatus.Draft && <AddOrdersToInvoiceDialog invoice={invoice} />}

            {invoice.status !== InvoiceStatus.Draft && invoice.status !== InvoiceStatus.Paid && (
              <ConfirmDialog
                triggerText="Verzend factuur"
                title="Ben je zeker dat je de factuur wilt versturen?"
                submitText="Verzend factuur"
                description={
                  <div>
                    <div>Ben je zeker dat je de factuur wilt versturen naar de volgende contacten?</div>
                    <ul>
                      {invoice.customer.contacts
                        .filter((c) => c.shouldReceiveInvoices)
                        .map((contact) => {
                          return (
                            <li key={contact.id}>
                              {contact.name} - {contact.email}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                }
                onSubmit={async () => {
                  try {
                    const result = await sendInvoice({
                      invoiceId: invoice.id,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                    toast.success('Factuur verstuurd');
                  } catch (err) {
                    toast.error(`Kon factuur niet versturen: ${getDisplayError(err)}`);
                  }
                }}
              />
            )}

            <div>
              <FileUploaderButton
                buttonText="VKF"
                title="Verkoopfactuur"
                file={invoice.document}
                generateDocument={async () => {
                  const res = await generateInvoice({
                    invoiceId: invoice.id,
                  });
                  refreshData();
                  if (res.error) {
                    throw res.error;
                  }
                }}
                disableUpload
              />
            </div>

            {me.role === 'Admin' && (
              <ConfirmDialog
                triggerColor="danger"
                triggerText={<Trash className="button-icon" />}
                title="Factuur verwijderen?"
                submitText="Verwijder factuur"
                description={
                  <div>
                    Ben je zeker dat je deze factuur wilt verwijderen? Dit kan ervoor zorgen dat de facturen niet meer
                    opeenlopend zijn qua nummering.
                  </div>
                }
                onSubmit={async () => {
                  try {
                    const result = await deleteInvoice({
                      invoiceId: invoice.id,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                    toast.success('Factuur verwijderd');
                    navigate('..');
                  } catch (err) {
                    toast.error(`Kon factuur niet verwijderen: ${getDisplayError(err)}`);
                  }
                }}
              />
            )}
          </div>
        }
      />

      <div className="px-4">
        {invoice.status === InvoiceStatus.Draft && (
          <div>
            <Formik
              initialValues={{
                customerRef: invoice.customerRef ?? '',
                invoiceDate: formatInputDate(invoice.date ?? new Date()),
              }}
              validationSchema={updateInvoiceSchema}
              onSubmit={async (values) => {
                try {
                  const result = await updateInvoice({
                    invoiceId: invoice.id,
                    data: {
                      date: values.invoiceDate,
                      customerRef: values.customerRef,
                    },
                  });
                  if (result.error) {
                    throw result.error;
                  }
                  toast.success('Factuur aangepast');
                } catch (err) {
                  console.error(err);
                  toast.error('Kon factuur niet aanpassen: ' + getDisplayError(err));
                }
              }}
            >
              {({ handleSubmit, isSubmitting, dirty }) => {
                return (
                  <form onSubmit={handleSubmit} className="flex gap-4">
                    <InputField labelText="Factuurdatum" type="date" name="invoiceDate" />
                    <InputField labelText="Ref klant" type="text" name="customerRef" />

                    <div className="mt-4 flex items-center mb-4">
                      <Button
                        type="submit"
                        color="primary"
                        isDisabled={!dirty || isSubmitting}
                        isLoading={isSubmitting}
                        iconLeft={<Plus className="button-icon" />}
                      >
                        Pas aan
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        )}

        <div>
          {totalsMismatch && (
            <div className="warning-bar mb-4">
              Totaal op de factuur komt niet overeen met het totaal van de orders, dit wil zeggen dat er na het
              goedkeuren van de factuur nog orders aangepast geweest zijn.
            </div>
          )}
        </div>

        <div className="flex flex-wrap gap-4 mb-4">
          <DataField title="Klant">
            {invoice.customer.name} ({invoice.customer.accountingId ?? invoice.customer.id})
          </DataField>
          <DataField title="Gestructureerde mededeling">{invoice.structuredComment}</DataField>
          <DataField title="Status">
            <StatusText color={InvoiceStatusToColor[invoice.status]}>{InvoiceStatusToText[invoice.status]}</StatusText>
          </DataField>
          <DataField title="Factuur datum">{formatDate(invoice.date)}</DataField>
          {!!invoice.customerRef && <DataField title="Referentie klant">{invoice.customerRef}</DataField>}
          {invoice.sentAt && <DataField title="Verstuurd op">{formatDate(invoice.sentAt)}</DataField>}
          {invoice.sentAt && <DataField title="Vervalt op">{formatDate(invoice.expiresAt)}</DataField>}
          {invoice.paidAt && <DataField title="Betaald op">{formatDate(invoice.paidAt)}</DataField>}
          {invoice.status !== InvoiceStatus.Draft && (
            <DataField title="Totaal excl btw">
              <div
                className={classNames('whitespace-nowrap flex items-center gap-2', {
                  'text-feedback-negative-04': totalsMismatch,
                })}
              >
                {totalsMismatch && <Warning className="w-4 h-4" />}
                {`€ ${formatNumber(totals.totalExclVat, 2, {
                  decimalSeperator: ',',
                })}`}
              </div>
            </DataField>
          )}
          {invoice.status !== InvoiceStatus.Draft && (
            <DataField title="Totaal incl btw">
              <div
                className={classNames('whitespace-nowrap flex items-center gap-2', {
                  'text-feedback-negative-04': totalsMismatch,
                })}
              >
                {totalsMismatch && <Warning className="w-4 h-4" />}
                {`€ ${formatNumber(totals.totalInclVat, 2, {
                  decimalSeperator: ',',
                })}`}
              </div>
            </DataField>
          )}
        </div>

        <div>
          {invoice.orders
            .sort((a, b) => a.customerRef.localeCompare(b.customerRef))
            .map((order) => {
              const orderTotals = calculateInvoiceTotals(
                order.lines
                  .map((l) =>
                    l.sales.map((s) => {
                      return {
                        ...s,
                        vatRateId: s.vatRate.id,
                      };
                    }),
                  )
                  .flat(),
              );

              return (
                <div key={`order-${order.orderNumber}`}>
                  <div className="flex justify-between">
                    <Link to={`/internal/orders/${order.id}/general`} className="heading-two mb-4">
                      Order {order.orderNumber} - {order.customerRef}
                    </Link>

                    <div>
                      {invoice.status === InvoiceStatus.Draft && (
                        <ConfirmDialog
                          triggerColor="danger"
                          triggerText={<Trash className="button-icon" />}
                          title={`Ben je zeker dat je order ${order.orderNumber} uit de factuur wilt verwijderen?`}
                          submitText="Verwijder order uit factuur"
                          description={
                            <div>Ben je zeker dat je order {order.orderNumber} uit de factuur wilt verwijderen?</div>
                          }
                          onSubmit={async () => {
                            try {
                              const result = await removeOrdersFromInvoice({
                                invoiceId: invoice.id,
                                orderIds: [order.id],
                              });
                              if (result.error) {
                                throw result.error;
                              }
                              toast.success('Order uit factuur verwijderd');
                            } catch (err) {
                              toast.error(`Kon order niet uit de factuur verwijderen: ${getDisplayError(err)}`);
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div>
                    {order.lines.map((line) => {
                      return (
                        <div key={`line-${line.id}`}>
                          <div>
                            {line.stops
                              .sort((a, b) => a.sequenceIndex - b.sequenceIndex)
                              .map((stop) => {
                                return (
                                  <div className="grid grid-cols-4" key={`line-${line.id}-${stop.id}`}>
                                    <div className="font-medium">
                                      {stop.type === OrderLineStopType.Load ? 'Laden' : 'Lossen'}
                                    </div>
                                    <div>
                                      {stop.location.country}-{stop.location.name}
                                    </div>

                                    <div className="flex gap-2 items-center">
                                      <CalendarDots className="w-4 h-4" />
                                      {formatDate(stop.date)}
                                    </div>
                                    <div className="flex gap-2 items-center">
                                      <Clock className="w-4 h-4" />
                                      {`${formatInputTime(stop.timeStart)}-${formatInputTime(stop.timeEnd)}`}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>

                          <div className="border-t py-4 my-4 border-dark-04">
                            <div className="grid grid-cols-5 gap-x-4 mb-1">
                              <div className="label-text">Beschrijving</div>
                              <div className="label-text">Aantal</div>
                              <div className="label-text">Eenheidsprijs</div>
                              <div className="label-text">Totaal</div>
                              <div className="label-text">Btw</div>
                            </div>

                            <div className="grid grid-cols-5 gap-x-4 gap-y-1">
                              {line.sales.map((sale) => {
                                return (
                                  <React.Fragment key={`sale-${sale.id}`}>
                                    <div>{sale.productType.nameNl}</div>
                                    <div className="whitespace-nowrap">
                                      {formatNumber(sale.amount, 2, {
                                        decimalSeperator: ',',
                                      })}
                                    </div>
                                    <div className="whitespace-nowrap">
                                      €{' '}
                                      {formatNumber(sale.unitPrice, 2, {
                                        decimalSeperator: ',',
                                      })}
                                    </div>
                                    <div className="whitespace-nowrap">
                                      €{' '}
                                      {formatNumber((sale.amount * sale.unitPrice) / 100, 2, {
                                        decimalSeperator: ',',
                                      })}
                                    </div>
                                    <div
                                      className={classNames('whitespace-nowrap', {
                                        'text-feedback-negative-04':
                                          sale.vatRate.id !== invoice.customer.defaultVatRate.id,
                                      })}
                                    >
                                      €{' '}
                                      {formatNumber(
                                        (sale.amount * sale.unitPrice * sale.vatRatePercentage) / 1000000,
                                        2,
                                        {
                                          decimalSeperator: ',',
                                        },
                                      )}{' '}
                                      -{' '}
                                      {formatNumber(sale.vatRatePercentage, 2, {
                                        decimalSeperator: ',',
                                      })}
                                      %
                                    </div>

                                    {!!sale.externalNote && (
                                      <div className="col-span-5">
                                        <div className="text-sm">Extra informatie: {sale.externalNote}</div>
                                      </div>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <div className="label-text border-b border-dark-04 pb-4 my-4 whitespace-nowrap">
                      Order totaal: €{' '}
                      {formatNumber(orderTotals.totalExclVat, 2, {
                        decimalSeperator: ',',
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        {invoice.manualInvoiceLines.length > 0 && (
          <div className="border-dark-04 my-8">
            <div className="grid grid-cols-5 gap-x-4 mb-1">
              <div className="label-text">Beschrijving</div>
              <div className="label-text">Aantal</div>
              <div className="label-text">Eenheidsprijs</div>
              <div className="label-text">Totaal</div>
              <div className="label-text">Btw</div>
            </div>

            <div className="grid grid-cols-5 gap-x-4 gap-y-1">
              {invoice.manualInvoiceLines.map((line) => {
                return (
                  <React.Fragment key={`manual-invoice-line-${line.id}`}>
                    <div>{line.description}</div>
                    <div className="whitespace-nowrap">
                      {formatNumber(line.amount, 2, {
                        decimalSeperator: ',',
                      })}
                    </div>
                    <div className="whitespace-nowrap">
                      €{' '}
                      {formatNumber(line.unitPrice, 2, {
                        decimalSeperator: ',',
                      })}
                    </div>
                    <div className="whitespace-nowrap">
                      €{' '}
                      {formatNumber((line.amount * line.unitPrice) / 100, 2, {
                        decimalSeperator: ',',
                      })}
                    </div>
                    <div
                      className={classNames('whitespace-nowrap', {
                        'text-feedback-negative-04': line.vatRate.id !== invoice.customer.defaultVatRate.id,
                      })}
                    >
                      €{' '}
                      {formatNumber((line.amount * line.unitPrice * line.vatRatePercentage) / 1000000, 2, {
                        decimalSeperator: ',',
                      })}{' '}
                      -{' '}
                      {formatNumber(line.vatRatePercentage, 2, {
                        decimalSeperator: ',',
                      })}
                      %
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        )}

        <div className="grid grid-cols-2">
          <div></div>
          <div
            className={classNames('grid grid-cols-2 text-md gap-x-4', {
              'text-feedback-negative-04': totalsMismatch,
            })}
          >
            <div className="label-text">Totaal excl. btw:</div>
            <div className="whitespace-nowrap flex items-center gap-2">
              {totalsMismatch && <Warning className="w-4 h-4" />}
              {`€ ${formatNumber(totals.totalExclVat, 2, {
                decimalSeperator: ',',
              })}`}
            </div>
            <div className="label-text">Totaal btw:</div>
            <div className="whitespace-nowrap flex items-center gap-2">
              {totalsMismatch && <Warning className="w-4 h-4" />}
              {`€ ${formatNumber(totals.totalVat, 2, {
                decimalSeperator: ',',
              })}`}
            </div>
            <div className="label-text">Totaal incl. btw:</div>
            <div className="whitespace-nowrap flex items-center gap-2">
              {totalsMismatch && <Warning className="w-4 h-4" />}
              {`€ ${formatNumber(totals.totalInclVat, 2, {
                decimalSeperator: ',',
              })}`}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoicePage;
