import * as Dialog from '@radix-ui/react-dialog';
import React from 'react';

import { IMinimalLocation } from './CustomerPortalLocationCombobox';
import { Button } from '../../../components/button/Button';
import { openTab } from '../../../utils/windows';
import { useTranslation } from '../../../contexts/translation-context';

export interface ILocationPreviewDialogProps {
  location: IMinimalLocation;
  locationLink?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const LocationPreviewDialog: React.FC<ILocationPreviewDialogProps> = (props) => {
  const { location, isOpen, setIsOpen, locationLink } = props;
  const { i18n } = useTranslation();

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="dialog-overlay" />
        <Dialog.Content className="dialog-content">
          <Dialog.Title className="heading-one mb-4">{location.name}</Dialog.Title>

          <Dialog.Description className="mb-4">
            <div className="heading-three">{i18n('customerPortal.locations.address')}</div>
            <div>{`${location.street} ${location.streetNumber}`}</div>
            <div>{`${location.country}${location.postalCode} ${location.city}`}</div>
          </Dialog.Description>

          <div className="flex justify-between">
            <Button onTrigger={() => setIsOpen(false)}>{i18n('close')}</Button>
            {!!locationLink && (
              <Button color="primary" onTrigger={() => openTab(locationLink)}>
                {i18n('moreInfo')}
              </Button>
            )}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
