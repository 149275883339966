import { useMemo, useState } from 'react';
import { distance } from 'fastest-levenshtein';

import { OrderLineStopType, useGetOrderLocationsQuery } from '../../../generated/graphql';
import { InputWrapper } from '../../../components/InputWrapper';
import { Combobox } from '../../../components/combobox/Combobox';

export type ComboValue = {
  postalCode: string;
  city: string;
  country: string;
};

export interface IOrderLocationComboboxProps {
  value: ComboValue | null;
  onChange: (val: ComboValue | null) => void;
  orderLineStopType: OrderLineStopType;
  isNullable?: boolean;
  invalidText?: string;
  labelText?: string;
  helperText?: string;
  isDisabled?: boolean;
  hideInvalid?: boolean;
  onBlur?: () => void;
}

const requestContext = {
  suspense: false,
};

const displayLocation = (value: ComboValue) => {
  return `${value.country}-${value.postalCode} ${value.city}`;
};

const normalizeSearchValue = (value: string): string => {
  return value
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-z0-9]+/g, '');
};

export const OrderLocationCombobox: React.FC<IOrderLocationComboboxProps> = (props) => {
  const {
    value,
    onChange,
    orderLineStopType,
    labelText,
    isDisabled,
    isNullable = true,
    helperText,
    invalidText,
    hideInvalid,
    onBlur,
  } = props;
  const [searchValue, setSearchValue] = useState('');
  const [{ data }] = useGetOrderLocationsQuery({
    variables: {
      type: orderLineStopType,
    },
    context: requestContext,
  });

  const rawLocations = useMemo(() => {
    return (
      data?.orderLocations?.map((v) => {
        const [country, postalCode, city] = v.split('::');

        return {
          id: v,
          country,
          postalCode,
          city,
          postalCodeSearchValue: normalizeSearchValue(`${country}${postalCode}`),
          citySearchValue: normalizeSearchValue(city),
        };
      }) || []
    );
  }, [data?.orderLocations]);

  const locations: ComboValue[] = useMemo(() => {
    const normalizedSearchValue = normalizeSearchValue(searchValue);
    const hasPostalCode = /\d+/.test(searchValue);
    let filtered = rawLocations
      .filter((v) => {
        if (hasPostalCode) {
          return v.postalCodeSearchValue.includes(normalizedSearchValue);
        } else {
          return true;
        }
      })
      .map((v) => {
        return {
          ...v,
          distance: hasPostalCode
            ? distance(v.postalCodeSearchValue, normalizedSearchValue)
            : distance(v.citySearchValue, normalizedSearchValue),
        };
      })
      .sort((a, b) => a.distance - b.distance);

    return filtered.slice(0, 25).map((v) => {
      return {
        id: v.id,
        country: v.country,
        postalCode: v.postalCode,
        city: v.city,
      };
    });
  }, [rawLocations, searchValue]);

  return (
    <div className="flex gap-2">
      <InputWrapper
        labelText={labelText ?? 'Locatie'}
        invalidText={invalidText}
        helperText={helperText}
        hideInvalid={hideInvalid}
      >
        <Combobox
          keyName="id"
          placeholder="Zoek een locatie..."
          onQueryChange={setSearchValue}
          display={displayLocation}
          items={locations}
          selectedItem={value}
          onSelect={(val) => {
            onChange(val);
          }}
          onBlur={onBlur}
          isDisabled={isDisabled}
          isInvalid={!!invalidText}
          isNullable={isNullable}
        />
      </InputWrapper>
    </div>
  );
};
