import { CaretLeft, CaretRight, MagnifyingGlass } from '@phosphor-icons/react';
import { Suspense, useEffect, useState } from 'react';

import { Breadcrumb } from '../../../../../components/Breadcrumb';
import { Button } from '../../../../../components/button/Button';
import { PageHeader } from '../../../../../components/PageHeader';
import { useCreatePurchaseCtx, CreatePurchaseProvider } from './context';
import { OrderSelect } from './OrderSelect';
import { SupplierSelect } from './SupplierSelect';
import { PurchaseReview } from './Review';
import { Input } from '../../../../../components/input/Input';
import { PageHeading } from 'components/PageHeading';

const CreatePurchaseComponent = () => {
  const { state, dispatch } = useCreatePurchaseCtx();
  const [searchValue, setSearchValue] = useState('');

  let title = '';
  switch (state.activeWindow) {
    case 'SELECT_SUPPLIER':
      title = 'Selecteer een vervoerder';
      break;
    case 'SELECT_ORDERS':
      title = 'Selecteer order(s)';
      break;
    case 'REVIEW':
      title = 'Review';
      break;
  }

  let canGoForward = false;
  if (state.activeWindow === 'SELECT_SUPPLIER') {
    canGoForward = state.selectedSupplier !== null;
  } else if (state.activeWindow === 'SELECT_ORDERS') {
    canGoForward = state.orders.length > 0;
  }

  let searchText = 'search';
  switch (state.activeWindow) {
    case 'SELECT_SUPPLIER':
      searchText = 'Zoek een vervoerder';
      break;
    case 'SELECT_ORDERS':
      searchText = 'Zoek order(s)';
      break;
  }

  useEffect(() => {
    setSearchValue('');
  }, [state.activeWindow]);

  return (
    <>
      <PageHeader title="Nieuwe aankoop" />

      <PageHeading
        leftSide={
          <Breadcrumb
            parentItem={{
              name: 'Aankopen',
              to: '/internal/finance/purchases',
            }}
            currentItem="Nieuwe aankoop"
          />
        }
      />

      <div className="px-4">
        <div className="flex justify-between mb-4">
          <h2 className="heading-two self-start">{title}</h2>

          <div className="flex gap-4">
            <div>
              <Button
                iconLeft={<CaretLeft className="button-icon" />}
                isDisabled={state.activeWindow === 'SELECT_SUPPLIER'}
                onTrigger={() => {
                  dispatch({
                    type: 'GO_BACK',
                  });
                }}
              >
                Keer terug
              </Button>
            </div>

            <div>
              <Button
                color="primary"
                iconRight={<CaretRight className="button-icon" />}
                isDisabled={!canGoForward}
                onTrigger={() => {
                  dispatch({
                    type: 'GO_FORWARD',
                  });
                }}
              >
                Ga verder
              </Button>
            </div>
          </div>
        </div>

        {state.activeWindow !== 'REVIEW' && (
          <div className="mb-4">
            <Input
              type="text"
              placeholder={searchText}
              value={searchValue}
              onChange={setSearchValue}
              iconLeft={<MagnifyingGlass className="input-icon" />}
            />
          </div>
        )}

        <Suspense fallback="loading...">
          {state.activeWindow === 'SELECT_SUPPLIER' && <SupplierSelect searchValue={searchValue} />}
          {state.activeWindow === 'SELECT_ORDERS' && <OrderSelect searchValue={searchValue} />}
          {state.activeWindow === 'REVIEW' && <PurchaseReview />}
        </Suspense>
      </div>
    </>
  );
};

const CreatePurchasePage = () => {
  return (
    <CreatePurchaseProvider>
      <CreatePurchaseComponent />
    </CreatePurchaseProvider>
  );
};

export default CreatePurchasePage;
