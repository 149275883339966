import { Pencil, Plus } from '@phosphor-icons/react';
import { useField } from 'formik';
import { useCallback, useMemo } from 'react';

import { ISimpleComboboxItem } from '../../../components/combobox/SimpleCombobox';
import { SimpleComboboxField } from '../../../components/combobox/SimpleComboboxField';
import { useVatRatesQuery } from '../../../generated/graphql';
import { CreateVatRateDialog } from './CreateVatRateDialog';
import { UpdateVatRateDialog } from './UpdateVatRateDialog';
import { formatNumber } from '../../../utils/number';

export interface IMinimalVatRate {
  id: string;
  amount: number;
}

export interface IVatRateComboboxProps {
  name: string;
  labelText?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  onBlur?: () => void;
  isNullable?: boolean;
}

export const vatRateToComboboxItem = (val: IMinimalVatRate) => {
  return {
    key: val.id,
    name: `${val.id} - ${formatNumber(val.amount, 2, {
      decimalSeperator: ',',
    })}%`,
  };
};

export const VatRateComboboxField: React.FC<IVatRateComboboxProps> = (props) => {
  const { labelText, name, ...otherProps } = props;
  const [{ data }, reexecuteQuery] = useVatRatesQuery();
  const [field, _meta, helpers] = useField({ name });

  const vatRates = data?.vatRates ?? [];

  const items: ISimpleComboboxItem[] = useMemo(() => {
    return data ? data.vatRates.map(vatRateToComboboxItem) : [];
  }, [vatRates]);

  const refetch = useCallback(() => {
    reexecuteQuery({
      requestPolicy: 'network-only',
    });
  }, [reexecuteQuery]);

  const value: ISimpleComboboxItem | null = field.value;
  return (
    <div>
      <div className="flex items-center">
        <SimpleComboboxField labelText={labelText ?? 'Aard'} items={items} name={name} {...otherProps} />
        <div className="flex-end flex justify-end gap-2 ml-2">
          {value && (
            <UpdateVatRateDialog
              id={value.key}
              onSubmit={(data) => {
                helpers.setValue(vatRateToComboboxItem(data));
                refetch();
              }}
              triggerText={<Pencil className="button-icon" />}
            />
          )}

          <CreateVatRateDialog
            onSubmit={(data) => {
              helpers.setValue(vatRateToComboboxItem(data));
              refetch();
            }}
            triggerText={<Plus className="button-icon" />}
          />
        </div>
      </div>
    </div>
  );
};
