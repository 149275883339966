import createContext from 'contexts/create-context';
import * as React from 'react';

import { Location } from '.';

interface ProviderValue {
  location: Location;
  refreshData: () => void;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface ILocationProviderProps {
  location: Location;
  refreshData: () => void;
  children?: React.ReactNode;
}

export const LocationProvider: React.FC<ILocationProviderProps> = (props) => {
  const { location, children, refreshData } = props;

  return <ReactProvider value={{ location, refreshData }}>{children}</ReactProvider>;
};

export const useLocation = useContext;
export const Consumer = ReactConsumer;
