import createContext from 'contexts/create-context';
import * as React from 'react';

import { OrderLine } from './OrderLines';

interface ProviderValue {
  orderLine: OrderLine;
  refreshData: () => void;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface IOrderLineProviderProps {
  orderLine: OrderLine;
  refreshData: () => void;
  children?: React.ReactNode;
}

export const OrderLineProvider: React.FC<IOrderLineProviderProps> = (props) => {
  const { orderLine, children, refreshData } = props;

  return <ReactProvider value={{ orderLine, refreshData }}>{children}</ReactProvider>;
};

export const useOrderLine = useContext;
export const Consumer = ReactConsumer;
