import { useMemo, useState } from 'react';

import { Combobox } from './Combobox';

export interface ISimpleComboboxItem {
  key: string;
  name: string;
}

export interface ISimpleComboboxProps {
  items: ISimpleComboboxItem[];
  selectedItem: ISimpleComboboxItem;
  onSelect: (item: ISimpleComboboxItem | null) => void;
  isDisabled?: boolean;
  isInvalid?: boolean;
  onBlur?: () => void;
  isNullable?: boolean;
}

const display = (val: ISimpleComboboxItem) => val.name;

export const SimpleCombobox: React.FC<ISimpleComboboxProps> = (props) => {
  const { items, selectedItem, onSelect, isDisabled, isInvalid, isNullable } = props;
  const [query, setQuery] = useState('');

  const filteredItems = useMemo(() => {
    if (!query) {
      return items;
    } else {
      const lowerCaseQuery = query.toLowerCase();
      return items.filter((i) => i.name.toLowerCase().includes(lowerCaseQuery));
    }
  }, [items, query]);

  return (
    <Combobox
      keyName="key"
      items={filteredItems}
      selectedItem={selectedItem}
      onQueryChange={setQuery}
      onSelect={onSelect}
      display={display}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      isNullable={isNullable}
    />
  );
};
