import { Check, CaretDown, CaretUp } from '@phosphor-icons/react';
import * as Select from '@radix-ui/react-select';
import classnames from '@utils/classnames';
import React from 'react';

import { SearchType } from './SearchWithType';

export interface SearchTypeSelectProps {
  values: SearchType[];
  selectedValue: string;
  onSelectValue: (val: string) => void;
}

export const SearchTypeSelect: React.FC<SearchTypeSelectProps> = (props) => {
  const { values, selectedValue, onSelectValue } = props;

  return (
    <Select.Root value={selectedValue} onValueChange={onSelectValue}>
      <Select.Trigger className="search-type-select-trigger-button" aria-label="Search Type">
        <Select.Value placeholder="..." />
        <Select.Icon>
          <CaretDown className="w-4 h-4" />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content className="search-type-select-content" position="popper">
          <Select.ScrollUpButton className="search-type-select-scroll-button">
            <CaretUp className="w-4 h-4" />
          </Select.ScrollUpButton>
          <Select.Viewport className="search-type-select-viewport">
            {values.map((v) => {
              return (
                <SelectItem value={v.value} key={v.label}>
                  <span className="whitespace-nowrap">{v.label}</span>
                </SelectItem>
              );
            })}
          </Select.Viewport>
          <Select.ScrollDownButton className="search-type-select-scroll-button">
            <CaretDown />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

const SelectItem = React.forwardRef<any, any>(({ children, className, value, ...props }, forwardedRef) => {
  return (
    <Select.Item
      className={classnames('search-type-select-item', className)}
      value={value}
      {...props}
      ref={forwardedRef}
    >
      <Select.ItemText>{children}</Select.ItemText>
      <Select.ItemIndicator className="search-type-select-item-indicator">
        <Check className="w-4 h-4" />
      </Select.ItemIndicator>
    </Select.Item>
  );
});
