import { useState } from 'react';
import toast from 'react-hot-toast';
import { useClient } from 'urql';

import {
  GetDocumentLinkDocument,
  GetDocumentLinkQuery,
  GetDocumentLinkQueryVariables,
} from '../../../generated/graphql';
import { nullthrows } from '../../../utils/invariant';
import { downloadUrl } from '../../../utils/download';

export function useDownloadDocument() {
  const client = useClient();
  const [downloadingFiles, setDownloadingFiles] = useState<string[]>([]);

  const downloadFile = async (file: { id: string; name: string }) => {
    setDownloadingFiles((files) => [...files, file.id]);

    try {
      const result = await client
        .query<GetDocumentLinkQuery, GetDocumentLinkQueryVariables>(GetDocumentLinkDocument, {
          id: file.id,
        })
        .toPromise();
      if (result.error) {
        throw result.error;
      }

      const url = nullthrows(result.data?.documentLink, 'Document link not returned');
      downloadUrl(url, {
        openInNewTab: true,
      });

      toast.success('Download gestart');
    } catch (err: any) {
      toast.error('Download is mislukt: ' + err.message);
    }

    setDownloadingFiles((files) => files.filter((id) => id !== file.id));
  };

  return {
    downloadFile,
    downloadingFiles,
  };
}
