import createContext from 'contexts/create-context';
import * as React from 'react';

import { GetCustomerPortalCustomerByIdQuery, useGetCustomerPortalCustomerByIdQuery } from 'generated/graphql';
import { useParams } from 'react-router-dom';
import { nullthrows } from '../../utils/invariant';

export type Customer = NonNullable<GetCustomerPortalCustomerByIdQuery['customer']>;

interface ProviderValue {
  customer: Customer;
  refreshData: () => void;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface ICustomerPortalCustomerProviderProps {
  children?: React.ReactNode;
}

export const CustomerPortalCustomerProvider: React.FC<ICustomerPortalCustomerProviderProps> = (props) => {
  const { children } = props;
  const { customerId: _customerId } = useParams<{ customerId: string }>();
  const customerId = nullthrows(_customerId, 'customer id not defined');
  const [{ data }, _refreshData] = useGetCustomerPortalCustomerByIdQuery({
    variables: {
      id: parseInt(customerId, 10),
    },
  });

  const refreshData = React.useCallback(() => {
    _refreshData({
      requestPolicy: 'network-only',
    });
  }, [_refreshData]);

  const customer = nullthrows(data?.customer, 'customer not found');
  return <ReactProvider value={{ customer, refreshData }}>{children}</ReactProvider>;
};

export const useCustomerPortalCustomer = useContext;
export const Consumer = ReactConsumer;
