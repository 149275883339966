import classNames from '@utils/classnames';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { formatNumber, multiplyPrecision } from '../../utils/number';
import { ChartPie } from './pie/PieChart';

export interface ChartWithTableEntry {
  id: string | number;
  name: string;
  value: number;
  link?: string;
}

export interface ChartWithTableProps {
  title: string;
  dataType: 'currency' | 'value';
  data: ChartWithTableEntry[];
  precision?: number;
  showPercentage: boolean;
  nameTitle?: string;
}

export const ChartWithTable: React.FC<ChartWithTableProps> = (props) => {
  const { title, dataType, showPercentage, precision = 2, data: _data, nameTitle } = props;
  const [hoveredValue, setHoveredValue] = useState<string | number | null>(null);

  const clonedData = structuredClone(_data);
  const totalValue = clonedData.reduce((acc, v) => acc + Math.max(v.value, 0), 0);
  const sortedData = clonedData.sort((a, b) => b.value - a.value);

  return (
    <div className="w-full">
      <div className="self-start heading-three mb-4">{title}</div>

      <div
        className="border border-dark-04 items-center justify-center rounded-md overflow-hidden grid"
        style={{
          gridTemplateColumns: '300px 2fr',
        }}
      >
        <div className="h-full flex justify-center p-4 border-r border-dark-04">
          <ChartPie
            data={sortedData}
            dataType={showPercentage ? 'percentage' : dataType}
            showLabels={true}
            hoveredId={hoveredValue}
            onHover={setHoveredValue}
          />
        </div>

        <table className="dashboard-chart-table">
          <thead>
            <tr>
              <th>{nameTitle ?? 'Naam'}</th>
              <th>Waarde</th>
              <th>%</th>
            </tr>
          </thead>
          <tbody>
            <tr className="font-medium">
              <td>Totaal</td>
              <td className="whitespace-nowrap">{`${dataType === 'currency' ? '€ ' : ''}${formatNumber(
                multiplyPrecision(totalValue, precision),
                precision,
                {
                  decimalSeperator: ',',
                },
              )}`}</td>
              <td>100%</td>
            </tr>

            {sortedData.map((entry) => {
              let formatted = formatNumber(multiplyPrecision(entry.value, precision), precision, {
                decimalSeperator: ',',
              });
              let percentage = totalValue > 0 ? Math.round((entry.value / totalValue) * 100) : 0;
              switch (dataType) {
                case 'currency':
                  formatted = '€ ' + formatted;
                  break;
              }
              return (
                <tr
                  className={classNames({
                    'highlighted-row': hoveredValue === entry.id,
                  })}
                  onMouseEnter={() => {
                    if (percentage) {
                      setHoveredValue(entry.id);
                    }
                  }}
                  onMouseLeave={() => {
                    setHoveredValue(null);
                  }}
                  key={entry.id}
                >
                  {entry.name ? (
                    <>
                      <td>{entry.link ? <Link to={entry.link}>{entry.name}</Link> : entry.name}</td>
                      <td>{formatted}</td>
                      <td>{`${percentage}%`}</td>
                    </>
                  ) : (
                    <>
                      <td>-</td>
                      <td></td>
                      <td></td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
