import { OrderLineStopType } from '../../../../generated/graphql';

export const STOP_TYPE_ITEMS = [
  {
    key: OrderLineStopType.Load,
    name: 'Laden',
  },
  {
    key: OrderLineStopType.Unload,
    name: 'Lossen',
  },
];
