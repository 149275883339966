import { Warning, Plus, Trash } from '@phosphor-icons/react';
import { useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { IterableElement } from 'type-fest';

import { ConfirmDialog } from '../../../../components/dialog/ConfirmDialog';
import { ITableHeader, Table } from '../../../../components/table/Table';
import { TableActionBar } from '../../../../components/table/TableActionBar';
import {
  SaleStatus,
  useChangeOrderSaleStatusMutation,
  useDeleteOrderLineSaleMutation,
} from '../../../../generated/graphql';
import { useUniqueArrayReducer } from '../../../../hooks/useUniqueArrayReducer';
import { getDisplayError } from '../../../../utils/get-display-error';
import { formatNumber } from '../../../../utils/number';
import { calculateLineTotal } from '../../utils/price';
import { Order } from '../order/Order';
import { useOrderLine } from '../orderLine/orderLineContext';
import { useOrder } from '../order/orderContext';
import { LinkButton } from '../../../../components/button/ButtonLink';
import { useMinimalGeneralSettings } from '../../../../contexts/minimal-settings-context';

export type OrderLineSale = IterableElement<IterableElement<Order['lines']>['sales']>;

const ORDERLINE_HEADERS: ITableHeader[] = [
  {
    id: 'product',
    name: 'Artikel',
  },
  {
    id: 'amount',
    name: 'Hoeveelheid',
  },
  {
    id: 'unitPrice',
    name: 'Prijs',
  },
  {
    id: 'vatRate',
    name: 'Btw',
  },
  {
    id: 'total',
    name: 'Totaal',
  },
  {
    id: 'delete',
    name: '',
  },
];

export const OrderLineSalesPage = () => {
  const { settings } = useMinimalGeneralSettings();
  const { order } = useOrder();
  const { orderLine } = useOrderLine();
  const [_res, deleteOrderLineSale] = useDeleteOrderLineSaleMutation();
  const [pendingDeletions, mutatePendingDeletion] = useUniqueArrayReducer<number>();
  const [_changeOrderRes, changeOrderSaleStatus] = useChangeOrderSaleStatusMutation();

  const sales: OrderLineSale[] = orderLine.sales;

  const lines: Array<OrderLineSale | 'total'> = useMemo(() => {
    return [...sales, 'total'];
  }, [sales]);

  const total = useMemo(() => {
    return sales.reduce((acc, item) => {
      return acc + calculateLineTotal(item);
    }, 0);
  }, [sales]);

  const hasDieselSurcharge = sales.some((p) => p.productType.id === settings.dieselSurchargeProductType?.id);
  return (
    <div>
      <TableActionBar>
        <div className="flex justify-end">
          <div className="flex gap-2">
            {order.saleStatus === SaleStatus.Created && (
              <div>
                <ConfirmDialog
                  triggerText="Markeer als onvolledig voor facturatie"
                  title="Markeer als onvolledig?"
                  submitText="Markeer als onvolledig"
                  description={<div>Ben je zeker dat je dit order wilt markeren als onvolledig voor facturatie?</div>}
                  onSubmit={async () => {
                    try {
                      const res = await changeOrderSaleStatus({
                        orderId: order.id,
                        newStatus: SaleStatus.Incomplete,
                      });
                      if (res.error) {
                        throw res.error;
                      }
                      toast.success('Order verkoopstatus aangepast');
                    } catch (err) {
                      toast.error('Kon order verkoopstatus niet aanpassen: ' + getDisplayError(err));
                    }
                  }}
                />
              </div>
            )}
            {order.saleStatus === SaleStatus.Incomplete && (
              <div>
                <ConfirmDialog
                  triggerText="Markeer als klaar voor facturatie"
                  title="Markeer als klaar?"
                  submitText="Markeer als klaar"
                  description={<div>Ben je zeker dat je dit order wilt markeren als klaar voor facturatie?</div>}
                  onSubmit={async () => {
                    try {
                      const res = await changeOrderSaleStatus({
                        orderId: order.id,
                        newStatus: SaleStatus.Created,
                      });
                      if (res.error) {
                        throw res.error;
                      }
                      toast.success('Order verkoopstatus aangepast');
                    } catch (err) {
                      toast.error('Kon order verkoopstatus niet aanpassen: ' + getDisplayError(err));
                    }
                  }}
                />
              </div>
            )}
            <div>
              <LinkButton to="new" color="primary" iconLeft={<Plus className="button-icon" />}>
                Voeg verkoop toe
              </LinkButton>
            </div>
          </div>
        </div>
      </TableActionBar>
      <Table
        idKey="id"
        headers={ORDERLINE_HEADERS}
        data={lines}
        mapData={(data) => {
          if (data === 'total') {
            return [
              <span className="font-medium">Totaal</span>,
              '',
              '',
              '',
              <span className="font-medium whitespace-nowrap">{`€ ${formatNumber(total, 2, {
                decimalSeperator: ',',
              })}`}</span>,
              '',
            ];
          } else {
            const deleteLine = async () => {
              mutatePendingDeletion({
                type: 'add',
                value: data.id,
              });
              try {
                const result = await deleteOrderLineSale({
                  id: data.id,
                });
                if (result.error) {
                  throw result.error;
                }
                toast.success('Order verkoop lijn gewist');
              } catch (err) {
                toast.error('Kon order verkoop lijn niet wissen: ' + getDisplayError(err));
              }
              mutatePendingDeletion({
                type: 'delete',
                value: data.id,
              });
            };

            return [
              <Link to={`${data.id}`} className="link-text">
                {data.productType.nameNl}
              </Link>,
              <div className="whitespace-nowrap">
                {`${formatNumber(data.amount, 2, {
                  decimalSeperator: ',',
                })}`}
              </div>,
              <div className="whitespace-nowrap">{`€ ${formatNumber(data.unitPrice, 2, {
                decimalSeperator: ',',
              })}`}</div>,
              <div className="whitespace-nowrap">{`${formatNumber(data.vatRatePercentage, 2, {
                decimalSeperator: ',',
              })}%`}</div>,
              <div className="whitespace-nowrap">
                {`€ ${formatNumber(calculateLineTotal(data), 2, {
                  decimalSeperator: ',',
                })}`}
              </div>,
              <ConfirmDialog
                triggerColor="danger"
                triggerText={<Trash className="button-icon" />}
                title="Verwijder verkoop"
                submitText="Verwijder"
                description={<div>{`Ben je zeker dat je deze verkoop ${data.productType.nameNl} wilt wissen?`}</div>}
                onSubmit={deleteLine}
              />,
            ];
          }
        }}
        isMultiSelect
      />

      {order.customer?.dieselSurchargeEnabled && !hasDieselSurcharge && (
        <div className="flex items-center gap-2 text-orange-00">
          <Warning className="h-4 w-4" />
          <div>Diesel toeslag is nog niet toegekend.</div>
        </div>
      )}
    </div>
  );
};
