import { useField } from 'formik';
import { useMemo, useState } from 'react';

import { useGetInternalUsersQuery } from '../../../generated/graphql';
import { InputWrapper } from '../../../components/InputWrapper';
import { Combobox } from '../../../components/combobox/Combobox';

export type InternalUser = {
  id: string;
  name: string;
};

type ComboValue = InternalUser;

const displayUser = (user: ComboValue) => {
  return user.name;
};

const userQueryContext = {
  suspense: false,
};

export interface IUserComboboxProps {
  placeholder?: string;
  isDisabled?: boolean;
  showInactive?: boolean;
  value?: ComboValue | null;
  onChange?: (value: ComboValue | null) => void;
  onBlur?: () => void;
  isInvalid?: boolean;
}

export const UserCombobox: React.FC<IUserComboboxProps> = (props) => {
  const { placeholder = 'Zoek een werknemer...', isDisabled, showInactive, value, onChange, onBlur, isInvalid } = props;
  const [searchValue, setSearchValue] = useState('');
  const [{ data }] = useGetInternalUsersQuery({
    variables: {
      filters: {
        search: searchValue,
        isActive: !showInactive,
      },
    },
    context: userQueryContext,
  });

  const users: ComboValue[] = useMemo(() => {
    return data?.internalUsers ? [...data.internalUsers] : [];
  }, [data?.internalUsers]);

  return (
    <Combobox
      keyName="id"
      placeholder={placeholder}
      onQueryChange={setSearchValue}
      display={displayUser}
      items={users}
      selectedItem={value}
      onSelect={(val) => {
        onChange?.(val);
      }}
      onBlur={onBlur}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      isNullable={true}
    />
  );
};

export interface IUserComboboxFieldProps {
  name: string;
  labelText?: string;
  placeholder?: string;
  helperText?: string;
  isDisabled?: boolean;
  showInactive?: boolean;
}

export const UserComboboxField: React.FC<IUserComboboxFieldProps> = (props) => {
  const {
    name,
    labelText = 'Werknemer',
    placeholder = 'Zoek een werknemer...',
    helperText,
    isDisabled,
    showInactive,
  } = props;

  const [field, meta, helpers] = useField({ name });
  const selectedItem = field.value ?? null;
  return (
    <InputWrapper labelText={labelText} invalidText={meta.touched ? meta.error : undefined} helperText={helperText}>
      <UserCombobox
        placeholder={placeholder}
        value={selectedItem}
        onChange={helpers.setValue}
        onBlur={() => helpers.setTouched(true)}
        isDisabled={isDisabled}
        isInvalid={Boolean(meta.touched && meta.error)}
        showInactive={showInactive}
      />
    </InputWrapper>
  );
};
