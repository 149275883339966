import { Plus } from '@phosphor-icons/react';
import { Button } from 'components/button/Button';
import { Formik } from 'formik';
import diff from 'object-diff';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { InputField } from '../../../components/input/InputField';
import { Country, useUpdateGeneralSettingsMutation } from '../../../generated/graphql';
import { getDisplayError } from '../../../utils/get-display-error';
import { useAllGeneralSettings } from '../all-settings-context';
import { CheckboxField } from '../../../components/checkbox/CheckboxField';

const transporeonSettingsSchema = Yup.object().shape({
  transporeonImportEnabled: Yup.bool().required('Vereist'),
  transporeonUsername: Yup.string().required('Vereist'),
  transporeonCompanyId: Yup.string().required('Vereist'),
  transporeonPassword: Yup.string().required('Vereist'),
});

interface ITransporeonSettingsValues {
  transporeonImportEnabled: boolean;
  transporeonUsername: string;
  transporeonCompanyId: string;
  transporeonPassword: string;
}

export const TransporeonSettingsPage = () => {
  const { settings } = useAllGeneralSettings();
  const [_updateState, updateGeneralSettings] = useUpdateGeneralSettingsMutation();

  const initialValues: ITransporeonSettingsValues = useMemo(() => {
    return {
      transporeonImportEnabled: settings.transporeonImportEnabled ?? false,
      transporeonUsername: settings.transporeonUsername ?? '',
      transporeonCompanyId: settings.transporeonCompanyId ?? '',
      transporeonPassword: settings.transporeonPassword ?? '',
    };
  }, [settings]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={transporeonSettingsSchema}
      onSubmit={async (values) => {
        try {
          const patch: Partial<typeof values> = diff(initialValues, values);
          const result = await updateGeneralSettings({
            data: patch,
          });
          if (result.error) {
            throw result.error;
          }
          toast.success('Transporeon instellingen aangepast');
        } catch (err: any) {
          toast.error('Kon transporeon instellingen niet aanpassen: ' + getDisplayError(err));
        }
      }}
    >
      {({ handleSubmit, isSubmitting, errors }) => {
        return (
          <form onSubmit={handleSubmit}>
            <CheckboxField labelText="Transporeon sync actief" name="transporeonImportEnabled" />

            <InputField labelText="Transporeon gebruikersnaam" type="text" name="transporeonUsername" />
            <InputField labelText="Transporeon company id" type="text" name="transporeonCompanyId" />
            <InputField labelText="Transporeon wachtwoord" type="password" name="transporeonPassword" />

            <Button
              type="submit"
              color="primary"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              iconLeft={<Plus className="button-icon" />}
            >
              Pas instellingen aan
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};
