import { ArrowClockwise } from '@phosphor-icons/react';
import { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useParams, useResolvedPath } from 'react-router-dom';
import { invariant } from '@utils/invariant';
import * as Yup from 'yup';

import { Breadcrumb } from '../../../../components/Breadcrumb';
import { Button } from '../../../../components/button/Button';
import { DataField } from '../../../../components/DataField';
import { ConfirmDialog } from '../../../../components/dialog/ConfirmDialog';
import { InputDialog } from '../../../../components/dialog/InputDialog';
import { PageHeader } from '../../../../components/PageHeader';
import { ITabItem, Tabs } from '../../../../components/tabs/Tabs';
import {
  GetSupplierByIdQuery,
  SupplierDocumentType,
  UserRole,
  useGetSupplierByIdQuery,
  useLinkSupplierDocumentMutation,
  useUnlinkSupplierDocumentMutation,
  useUpdateSupplierMutation,
} from '../../../../generated/graphql';
import { getDisplayError } from '../../../../utils/get-display-error';
import { FileUploaderButton } from '../../../document/components/FileUploaderButton';
import { MultiFileUploaderButton } from '../../../document/components/MultiFileUploaderButton';
import { SupplierProvider } from './supplierContext';
import { useAuth } from '../../../../contexts/auth-context';
import { PageHeading } from 'components/PageHeading';

export type Supplier = NonNullable<GetSupplierByIdQuery['supplier']>;

const SupplierPage = () => {
  const { me } = useAuth();
  const [, linkDocument] = useLinkSupplierDocumentMutation();
  const [, unlinkDocument] = useUnlinkSupplierDocumentMutation();
  const { supplierId } = useParams<{ supplierId: string }>();
  invariant(supplierId);
  const [{ data, error }, reexecuteQuery] = useGetSupplierByIdQuery({
    variables: {
      id: +supplierId,
    },
  });
  const [, updateSupplier] = useUpdateSupplierMutation();

  const generalRoute = useResolvedPath('general');
  const contactsRoute = useResolvedPath('contacts');
  const trucksRoute = useResolvedPath('trucks');
  const auditLogsRoute = useResolvedPath('audit-logs');
  const tabItems = useMemo<ITabItem[]>(() => {
    const res = [
      {
        title: 'Algemeen',
        path: generalRoute.pathname,
      },
      {
        title: 'Contacten',
        path: contactsRoute.pathname,
      },
      {
        title: 'Trekkers',
        path: trucksRoute.pathname,
      },
    ];

    if (me.role === UserRole.Admin) {
      res.push({
        title: 'Audit logs',
        path: auditLogsRoute.pathname,
      });
    }

    return res;
  }, []);

  const refreshData = useCallback(() => {
    reexecuteQuery({
      requestPolicy: 'network-only',
    });
  }, []);

  const supplier = data?.supplier;
  if (!supplier) {
    if (error) {
      return <div>Kon vervoerder niet laden: {error.message}</div>;
    } else {
      return <div>vervoerder niet gevonden</div>;
    }
  } else {
    return (
      <>
        <SupplierProvider supplier={supplier} refreshData={refreshData}>
          <PageHeader title={supplier.name} />

          <PageHeading
            leftSide={
              <Breadcrumb
                parentItem={{
                  name: 'Vervoerders',
                  to: '/internal/suppliers',
                }}
                currentItem={supplier.name}
              />
            }
            rightSide={
              <div className="flex gap-2">
                {supplier.deactivationReason ? (
                  <ConfirmDialog
                    triggerText="Heractiveer vervoerder"
                    title="Heractiveer vervoerder"
                    submitText="Heractiveer"
                    description="Ben je zeker dat je de vervoerder wilt heractiveren?"
                    onSubmit={async () => {
                      try {
                        const result = await updateSupplier({
                          id: supplier.id,
                          data: {
                            deactivationReason: null,
                          },
                        });
                        if (result.error) {
                          throw result.error;
                        }
                        toast.success('Vervoerder is geheractiveerd');
                      } catch (err) {
                        toast.error('Vervoerder heractiveren mislukt: ' + getDisplayError(err));
                      }
                    }}
                  />
                ) : (
                  <InputDialog
                    triggerText="Deactiveer vervoerder"
                    title="Deactiveer vervoerder"
                    submitText="Deactiveer"
                    description="Ben je zeker dat je de vervoerder wilt deactiveren?"
                    labelText="Reden"
                    validation={Yup.string().min(5, 'Minimum 5 karakters')}
                    onSubmit={async (value: string) => {
                      try {
                        const result = await updateSupplier({
                          id: supplier.id,
                          data: {
                            deactivationReason: value,
                          },
                        });
                        if (result.error) {
                          throw result.error;
                        }
                        toast.success('Vervoerder is gedeactiveerd');
                      } catch (err) {
                        toast.error('Vervoerder deactiveren mislukt: ' + getDisplayError(err));
                        throw err;
                      }
                    }}
                  />
                )}

                <Button color="default" onTrigger={refreshData}>
                  <ArrowClockwise className="button-icon" />
                </Button>
              </div>
            }
          />

          <div className="px-4">
            <div className="flex justify-between items-center my-4">
              {!!supplier.deactivationReason && (
                <div className="w-full flex mt-2">
                  <DataField title="Deactivatie reden">{supplier.deactivationReason}</DataField>
                </div>
              )}

              <div className="flex gap-2 self-end">
                <FileUploaderButton
                  buttonText="BA Verzekering"
                  title="BA Verzekering"
                  initialName={`BA Verzekering - ${supplier.id}`}
                  file={
                    supplier.documents.find((d) => d.type === SupplierDocumentType.CivilLiabilityInsurance)?.document
                  }
                  onSubmit={async (document) => {
                    const result = await linkDocument({
                      supplierId: supplier.id,
                      documentId: document.id,
                      type: SupplierDocumentType.CivilLiabilityInsurance,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                    toast.success('AB Verzekering opgeladen');
                  }}
                />

                <FileUploaderButton
                  buttonText="CMR Verzekering"
                  title="CMR Verzekering"
                  initialName={'CMR Verzekering'}
                  file={supplier.documents.find((d) => d.type === SupplierDocumentType.CmrInsurance)?.document}
                  onSubmit={async (document) => {
                    const result = await linkDocument({
                      supplierId: supplier.id,
                      documentId: document.id,
                      type: SupplierDocumentType.CmrInsurance,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                  }}
                />

                <FileUploaderButton
                  buttonText="Enquete"
                  title="Enquete"
                  initialName={'Enquete'}
                  file={supplier.documents.find((d) => d.type === SupplierDocumentType.Survey)?.document}
                  onSubmit={async (document) => {
                    const result = await linkDocument({
                      supplierId: supplier.id,
                      documentId: document.id,
                      type: SupplierDocumentType.Survey,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                  }}
                />

                <FileUploaderButton
                  buttonText="Transport vergunning"
                  title="Transport vergunning"
                  initialName="Transport vergunning"
                  file={supplier.documents.find((d) => d.type === SupplierDocumentType.TransportInsurance)?.document}
                  onSubmit={async (document) => {
                    const result = await linkDocument({
                      supplierId: supplier.id,
                      documentId: document.id,
                      type: SupplierDocumentType.TransportInsurance,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                  }}
                />

                <MultiFileUploaderButton
                  buttonText="Andere"
                  title="Andere"
                  files={supplier.documents.filter((d) => d.type === SupplierDocumentType.Other).map((v) => v.document)}
                  onSubmit={async (documents) => {
                    let count = 0;
                    await Promise.allSettled(
                      documents.map(async (doc) => {
                        try {
                          const result = await linkDocument({
                            supplierId: supplier.id,
                            documentId: doc.id,
                            type: SupplierDocumentType.Other,
                          });
                          if (result.error) {
                            throw result.error;
                          }
                          count += 1;
                          toast.success(`Bestand: ${doc.name}, ${count}/${documents.length} opgeladen`);
                        } catch (err) {
                          toast.error(`Kon bestand ${doc.name} niet opladen: ${getDisplayError(err)}`);
                        }
                      }),
                    );
                  }}
                  unlinkDocument={async (docId: string) => {
                    try {
                      const result = await unlinkDocument({
                        supplierId: supplier.id,
                        documentId: docId,
                        type: SupplierDocumentType.Other,
                      });
                      if (result.error) {
                        throw result.error;
                      }
                      toast.success('Bestand gewist');
                    } catch (err) {
                      toast.error(`Kon bestand niet wissen: ${getDisplayError(err)}`);
                    }
                  }}
                />
              </div>
            </div>

            <Tabs items={tabItems} />
          </div>
        </SupplierProvider>
      </>
    );
  }
};

export default SupplierPage;
