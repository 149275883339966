import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from '../../../components/button/Button';
import { PageHeader } from '../../../components/PageHeader';
import { useAcceptCustomerUserInviteMutation, useGetCustomerNameByInviteLinkQuery } from '../../../generated/graphql';
import { getDisplayError } from '../../../utils/get-display-error';
import { nullthrows } from '../../../utils/invariant';
import { useTranslation } from '../../../contexts/translation-context';
import { InputField } from '../../../components/input/InputField';
import { Formik } from 'formik';
import { Key } from '@phosphor-icons/react';

const setPasswordSchema = Yup.object().shape({
  password: Yup.string().min(12, 'errors.passwordTooShort').required('errors.required'),
  repeatPassword: Yup.string()
    .min(12, 'errors.passwordTooShort')
    .required('errors.required')
    .test('passwordsAreEqual', 'errors.passwordsDontMatch', function () {
      return this.parent.password === this.parent.repeatPassword;
    }),
});

export const CustomerUserInvitePage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = nullthrows(searchParams.get('email'), 'Email not found');
  const customerId = parseInt(nullthrows(searchParams.get('customerId'), 'Customer ID not found'), 10);
  const token = nullthrows(searchParams.get('token'), 'Token not found');
  const { i18n } = useTranslation();
  const [{ data, error }] = useGetCustomerNameByInviteLinkQuery({
    variables: {
      email,
      customerId,
      token,
    },
  });
  const [, acceptCustomerUserInvite] = useAcceptCustomerUserInviteMutation();

  if (error) {
    throw error;
  }

  const customerName = data?.customerNameByInviteLink ?? 'unknown';
  return (
    <div className="h-screen">
      <PageHeader
        title={i18n('customerPortal.invite.title', {
          customerName: customerName,
        })}
      />

      <main className="bg-dark-01 p-8 h-full">
        <div className="flex flex-col justify-center h-full items-center max-w-md mx-auto">
          <h1 className="heading-one mb-8 text-white">
            {i18n('customerPortal.invite.title', {
              customerName: customerName,
            })}
          </h1>

          <div className="text-white mb-4">
            {i18n('customerPortal.invite.details', {
              customerName: customerName,
            })}
          </div>

          <div className="w-full">
            <Formik
              initialValues={{
                password: '',
                repeatPassword: '',
              }}
              validationSchema={setPasswordSchema}
              onSubmit={async (values) => {
                try {
                  const result = await acceptCustomerUserInvite({
                    email,
                    customerId,
                    token,
                    password: values.password,
                  });

                  if (result.error) {
                    throw result.error;
                  }

                  toast(i18n('customerPortal.invite.accepted'));

                  navigate('/login');
                } catch (err) {
                  toast.error(
                    i18n('customerPortal.invite.acceptError', {
                      error: getDisplayError(err),
                    }),
                  );
                }
              }}
            >
              {({ handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                  <InputField labelText={i18n('customerPortal.invite.password')} type="password" name="password" />
                  <InputField
                    labelText={i18n('customerPortal.invite.repeatPassword')}
                    type="password"
                    name="repeatPassword"
                  />

                  <div className="flex justify-between">
                    <Button
                      type="submit"
                      color="primary"
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                      iconLeft={<Key className="button-icon" />}
                    >
                      {i18n('customerPortal.invite.acceptButtonText')}
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </main>
    </div>
  );
};
