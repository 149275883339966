import { DocumentType } from '../../../../generated/graphql';

export const DocumentTypeNames = {
  [DocumentType.Tro]: 'TRO',
  [DocumentType.Planning]: 'Planning',
  [DocumentType.Quotation]: 'Offerte',
  [DocumentType.PayReminderOnePrefix]: 'Betaalherinnering 1 prefix',
  [DocumentType.PayReminderOneSuffix]: 'Betaalherinnering 1 suffix',
  [DocumentType.PayReminderTwoPrefix]: 'Betaalherinnering 2 prefix',
  [DocumentType.PayReminderTwoSuffix]: 'Betaalherinnering 2 suffix',
  [DocumentType.PayReminderThreePrefix]: 'Betaalherinnering 3 prefix',
  [DocumentType.PayReminderThreeSuffix]: 'Betaalherinnering 3 suffix',
  [DocumentType.PayReminderFourPrefix]: 'Betaalherinnering 4 prefix',
  [DocumentType.PayReminderFourSuffix]: 'Betaalherinnering 4 suffix',
};
