import classNames from '@utils/classnames';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { Spinner } from '../Spinner';
import { buttonVariants, BaseButtonProps } from './BaseButton';

export interface LinkButtonProps extends LinkProps {
  color?: BaseButtonProps['color'];
  isLoading?: boolean;
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
  isDisabled?: boolean;
}
export type Ref = HTMLButtonElement;

const DisabledLink: React.FC<LinkProps> = (props) => {
  const { children, className, style } = props;

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

export const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const {
    isLoading,
    isDisabled: _disabled,
    color = 'default',
    children,
    className,
    iconLeft,
    iconRight,
    ...otherProps
  } = props;

  const isDisabled = _disabled || !otherProps.to;
  const style = buttonVariants({
    variant: color,
  });

  const ComponentClass = isDisabled || isLoading ? DisabledLink : Link;
  return (
    <ComponentClass {...otherProps} className={style} style={{ height: '2.25rem' }}>
      <div className="w-full flex items-center whitespace-nowrap">
        {iconLeft != null && <div className="py-4 pl-4">{iconLeft}</div>}
        <div className="px-3 w-full">{children}</div>
        {iconRight != null && <div className="py-4 pr-4">{iconRight}</div>}
      </div>
      {isLoading && (
        <div className={classNames('absolute flex items-center justify-center top-0 left-0 w-full h-full')}>
          <Spinner size={4} color={color === 'primary' ? 'primary' : 'default'} />
        </div>
      )}
    </ComponentClass>
  );
};
