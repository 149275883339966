import * as Dialog from '@radix-ui/react-dialog';
import React, { useMemo } from 'react';
import * as Yup from 'yup';

import { BaseButtonProps } from '../button/BaseButton';
import { FormDialog } from './FormDialog';
import { InputField } from '../input/InputField';

export interface IInputDialogProps {
  triggerText: string;
  triggerColor?: BaseButtonProps['color'];
  title: string;
  description: string;
  labelText: string;
  validation?: Yup.StringSchema;
  cancelText?: string;
  submitText: string;
  onSubmit: (value: string) => void | Promise<void>;
  initialValue?: string;
}

interface InputDialogValues {
  value: string;
}

export const InputDialog: React.FC<IInputDialogProps> = (props) => {
  const { onSubmit, initialValue, labelText, description, validation, ...otherProps } = props;

  const initialValues: InputDialogValues = useMemo(() => {
    return {
      value: initialValue ?? '',
    };
  }, [initialValue]);

  const schema = useMemo(() => {
    return Yup.object().shape({
      value: validation ?? Yup.string(),
    });
  }, [validation]);

  const handleSubmit = async (values: InputDialogValues) => {
    await onSubmit(values.value);
  };

  return (
    <FormDialog {...otherProps} initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
      <Dialog.Description className="mb-4">{description}</Dialog.Description>

      <InputField labelText={labelText} name="value" />
    </FormDialog>
  );
};
