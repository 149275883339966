import { ISimpleSelectItem } from '../../components/select/SimpleSelect';
import { IncomingDocumentStatus } from '../../generated/graphql';

export const INCOMING_DOCUMENT_STATUS_OPTIONS: ISimpleSelectItem[] = [
  {
    key: IncomingDocumentStatus.New,
    name: 'Nieuw',
  },
  {
    key: IncomingDocumentStatus.Approved,
    name: 'Goedgekeurd',
  },
  {
    key: IncomingDocumentStatus.Rejected,
    name: 'Afgekeurd',
  },
];
