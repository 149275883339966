import React, { useId } from 'react';
import { Checkbox } from './Checkbox';

export interface ICheckboxProps {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  isDisabled?: boolean;
  isInvalid?: boolean;
  labelText: string;
}

export const CheckboxWithLabel: React.FC<ICheckboxProps> = (props) => {
  const { onChange, isChecked, isDisabled, isInvalid, labelText } = props;
  const id = useId();

  return (
    <label htmlFor={id} className="flex">
      <Checkbox isChecked={isChecked} onChange={onChange} id={id} isInvalid={isInvalid} isDisabled={isDisabled} />
      <div className="ml-2 text-dark-01">{labelText}</div>
    </label>
  );
};
