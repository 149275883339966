import classNames from '@utils/classnames';
import { Suspense } from 'react';
import { Link, Outlet, useLocation, useMatch } from 'react-router-dom';

import { SpinnerBlock } from '../Spinner';

export interface ITabItem {
  title: string;
  path: string;
}

export interface ITabsProps {
  items: ITabItem[];
  actions?: React.ReactNode;
}

export const Tabs: React.FC<ITabsProps> = (props) => {
  const { items, actions } = props;
  const location = useLocation();
  const { search } = useLocation();

  return (
    <div>
      <div className="flex justify-between items-center border-b border-t border-dark-04">
        <div className="flex flex-1 overflow-x-scroll">
          {items.map((item, idx) => {
            const isActive = location.pathname.startsWith(item.path);
            const to = item.path + search;
            return (
              <Link to={to} key={`tab-${to}`}>
                <div
                  className={classNames('whitespace-nowrap py-4 pr-4 font-medium text-md', {
                    'text-dark-01': isActive,
                    'text-dark-04 hover:text-dark-03': !isActive,
                    'ml-6': idx > 0,
                  })}
                  key={`tab-header-${idx}`}
                >
                  {item.title}
                </div>
              </Link>
            );
          })}
        </div>
        <div>{actions}</div>
      </div>

      <div className="pt-4">
        <Suspense fallback={<SpinnerBlock message="Loading..." />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
};
