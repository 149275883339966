import { IterableElement } from 'type-fest';
import classNames from '@utils/classnames';
import { useEffect } from 'react';
import { captureException } from '@sentry/react';
import { Trash } from '@phosphor-icons/react';
import { toast } from 'react-hot-toast';

import { PageHeader } from '../../../components/PageHeader';
import {
  GetCustomersQuery,
  useDeleteNotificationMutation,
  useMarkNotificationsAsReadMutation,
} from '../../../generated/graphql';
import { useNotificationsCtx } from './context';
import { Notification } from './types';
import { Button } from '../../../components/button/Button';
import { getDisplayError } from '../../../utils/get-display-error';
import { PageHeading } from 'components/PageHeading';
import { Breadcrumb } from 'components/Breadcrumb';

export type Customer = NonNullable<IterableElement<GetCustomersQuery['customers']>>;

interface INotificationComponentProps {
  notification: Notification;
  refresh: () => void;
}

const NotificationComponent: React.FC<INotificationComponentProps> = (props) => {
  const { notification, refresh } = props;
  const [deleteRes, deleteNotification] = useDeleteNotificationMutation();

  return (
    <div className="flex gap-4">
      <div
        onClick={() => {
          if (notification.link) {
            location.href = new URL(notification.link, location.origin).href;
          }
        }}
        className={classNames('p-4 bg-dark-05 rounded break-words w-full', {
          'hover:bg-orange-04 cursor-pointer': !!notification.link,
        })}
      >
        {notification.content}
      </div>
      <div className="flex items-center">
        <Button
          color="danger-outline"
          onTrigger={async () => {
            try {
              const res = await deleteNotification({
                notificationId: notification.id,
              });
              if (res.error) {
                throw res.error;
              }
              refresh();
            } catch (err) {
              toast.error('Kon notificatie niet wissen: ' + getDisplayError(err));
            }
          }}
          isLoading={deleteRes.fetching}
        >
          <Trash className="w-4 h-4" />
        </Button>
      </div>
    </div>
  );
};

const NotificationsPage = () => {
  const {
    state: { notifications },
    refresh: refreshNotifications,
  } = useNotificationsCtx();
  const [_markAsReadResult, markNotificationsAsRead] = useMarkNotificationsAsReadMutation();

  useEffect(() => {
    markNotificationsAsRead({
      lastNotificationTimestamp: notifications[0]?.createdAt ?? new Date(0).toISOString(),
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        refreshNotifications();
      })
      .catch((err) => {
        captureException(err);
      });
  }, [true]);

  return (
    <>
      <PageHeader title="Notificaties" />

      <PageHeading leftSide={<Breadcrumb currentItem="Notificaties" />} />

      <div className="px-4">
        <div className="grid gap-4">
          {notifications.map((notification) => {
            return (
              <NotificationComponent notification={notification} refresh={refreshNotifications} key={notification.id} />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default NotificationsPage;
