import { PageHeader } from '../../../components/PageHeader';
import { LinkButton } from '../../../components/button/ButtonLink';
import { useTranslation } from '../../../contexts/translation-context';

export const UserNotFoundPage = () => {
  const { i18n } = useTranslation();

  return (
    <div className="h-screen">
      <PageHeader title={i18n('auth.userNotFound.title')} />

      <main
        className="p-8 min-h-screen w-full"
        style={{
          background: "url('/static/graphic-elements/element-one.svg') no-repeat center right fixed",
        }}
      >
        <div className="flex flex-col justify-center h-full items-center mx-auto max-w-lg">
          <h1 className="heading-one mt-32">{i18n('auth.userNotFound.title')}</h1>

          <div className="text-center my-8">{i18n('auth.userNotFound.description')}</div>

          <div className="flex justify-center w-full">
            <LinkButton color="default" to="/customer-portal/login">
              {i18n('auth.userNotFound.goToLoginPage')}
            </LinkButton>
          </div>
        </div>
      </main>
    </div>
  );
};
