import { ISimpleSelectItem } from '../../../components/select/SimpleSelect';
import { Country, TrailerType } from '../../../generated/graphql';
import { COUNTRY_VALUES } from '../../../utils/address';

export interface ILineState {
  departure?: [number, number];
  arrival?: [number, number];
  fetchKey?: string;
}

export interface IQuotationLineValues {
  departurePostalCode: string;
  departureCountry: ISimpleSelectItem;
  departureCity: string;
  arrivalPostalCode: string;
  arrivalCountry: ISimpleSelectItem;
  arrivalCity: string;
  distance: string;
  price: string;
  trailerTypes: TrailerType[];
}

export const initialLineValues: IQuotationLineValues = {
  departurePostalCode: '',
  departureCountry: COUNTRY_VALUES.find((v) => v.key === Country.Be)!,
  departureCity: '',
  arrivalPostalCode: '',
  arrivalCountry: COUNTRY_VALUES.find((v) => v.key === Country.Be)!,
  arrivalCity: '',
  distance: '',
  price: '',
  trailerTypes: [TrailerType.Tautliner],
};
