import { DownloadSimple, File, Paperclip, Trash, X } from '@phosphor-icons/react';
import * as Dialog from '@radix-ui/react-dialog';
import { Document } from '@prisma/client';
import { useState } from 'react';

import { BaseButton } from '../../../components/button/BaseButton';
import { useDownloadDocument } from '../hooks/useDownloadDocument';
import { MultiUploadForm } from './MultiUploadForm';
import { Button } from '../../../components/button/Button';
import classNames from '@utils/classnames';
import { ConfirmDialog } from '../../../components/dialog/ConfirmDialog';

interface IMinimalFile {
  id: string;
  name: string;
}

interface IMultiFileUploaderButtonProps {
  buttonText: string;
  title: string;
  files: IMinimalFile[];
  disableUpload?: boolean;
  unlinkDocument?: (id: string) => Promise<void>;
  onSubmit?: (documents: Document[]) => Promise<void>;
}

export const MultiFileUploaderButton: React.FC<IMultiFileUploaderButtonProps> = (props) => {
  const { buttonText, title, files, disableUpload, unlinkDocument, onSubmit } = props;
  const [open, setOpen] = useState(false);
  const { downloadFile, downloadingFiles } = useDownloadDocument();

  const handleSubmit = async (documents: Document[]) => {
    if (onSubmit) {
      await onSubmit(documents);
      // setOpen(false);
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <BaseButton
          color={files.length ? 'primary' : 'default-outline'}
          iconLeft={<Paperclip className="button-icon" />}
        >
          {buttonText}
        </BaseButton>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay className="dialog-overlay" />
        <Dialog.Content className="dialog-content">
          <Dialog.Title className="heading-one mb-4">{title}</Dialog.Title>

          <div className="w-full h-full mb-4 overflow-y-auto" style={{ maxHeight: '25vh' }}>
            {files.map((file, idx) => {
              return (
                <div
                  className={classNames('flex items-center border-dark-05 py-1', {
                    'border-b': idx !== files.length - 1,
                  })}
                  key={`file-${file.name}-${idx}`}
                >
                  <div className="pr-1 text-dark-03">
                    <File className="w-4 h-4" />
                  </div>
                  <div className="flex-1">{file.name}</div>
                  <div>
                    <Button
                      color="default"
                      onTrigger={() => downloadFile(file)}
                      isLoading={downloadingFiles.includes(file.id)}
                    >
                      <DownloadSimple className="button-icon" />
                    </Button>
                  </div>
                  {unlinkDocument && (
                    <div className="pl-2">
                      <ConfirmDialog
                        triggerColor="danger-outline"
                        triggerText={<Trash className="button-icon" />}
                        title="Ben je zeker dat je dit document wilt verwijderen?"
                        submitText="Verwijder document"
                        description="Ben je zeker dat je dit document wilt verwijderen?"
                        onSubmit={async () => {
                          await unlinkDocument(file.id);
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {!files.length && disableUpload && <div>Geen documenten gevonden</div>}

          {disableUpload ? (
            <div className="flex justify-end">
              <Dialog.Close asChild>
                <BaseButton>Sluit</BaseButton>
              </Dialog.Close>
            </div>
          ) : (
            <div>
              <h2 className="heading-two mb-2">Upload</h2>

              <MultiUploadForm
                onSubmit={handleSubmit}
                onCancel={() => {
                  setOpen(false);
                }}
              />
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
