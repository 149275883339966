import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import { formatDate } from '@utils/date';
import { invariant, nullthrows } from '@utils/invariant';
import { Breadcrumb } from 'components/Breadcrumb';
import { DataField } from 'components/DataField';
import { PageHeader } from 'components/PageHeader';
import {
  GetPaymentReminderQuery,
  useGeneratePaymentReminderMutation,
  useGetPaymentReminderQuery,
  useSendPaymentReminderMutation,
} from 'generated/graphql';
import { formatNumber } from '@utils/number';
import { getDisplayError } from '@utils/get-display-error';
import { FileUploaderButton } from 'src/app/document/components/FileUploaderButton';
import { ContactsDialog } from 'src/components/dialog/ContactsDialog';
import { PageHeading } from 'components/PageHeading';

export type PaymentReminder = NonNullable<GetPaymentReminderQuery['paymentReminder']>;

export const PaymentReminderPage = () => {
  const navigate = useNavigate();
  const { paymentReminderId } = useParams<{ paymentReminderId: string }>();
  invariant(paymentReminderId);
  const [{ data, error }, refetch] = useGetPaymentReminderQuery({
    variables: {
      id: paymentReminderId,
    },
  });
  const [, sendPaymentReminder] = useSendPaymentReminderMutation();
  const [, generationPdf] = useGeneratePaymentReminderMutation();

  const refreshData = useCallback(() => {
    return refetch({
      requestPolicy: 'network-only',
    });
  }, [refetch]);

  const paymentReminder = nullthrows(data?.paymentReminder, 'Betaalherinnering niet gevonden');
  const total = paymentReminder.invoices.reduce((acc, invoice) => acc + invoice.amount, 0);
  return (
    <>
      <PageHeader
        title={`Betaal herinnering ${paymentReminder.customer.name} - ${formatDate(paymentReminder.createdAt)}`}
      />

      <PageHeading
        leftSide={
          <Breadcrumb
            parentItem={{
              name: 'Betaalherinnering',
              to: '/internal/finance/payment-reminder',
            }}
            currentItem="Betaal herinnering"
          />
        }
        rightSide={
          <div className="flex gap-2">
            <div>
              <FileUploaderButton
                buttonText="RPL"
                title="Document"
                file={paymentReminder.document}
                generateDocument={async () => {
                  const res = await generationPdf({
                    paymentReminderId: paymentReminder.id,
                  });
                  if (res.error) {
                    throw res.error;
                  }
                }}
                disableUpload
              />
            </div>

            <ContactsDialog
              triggerText="Verzend herinnering"
              title="Ben je zeker dat je de herinnering wilt versturen?"
              submitText="Verzend herinnering"
              triggerColor={paymentReminder.sentAt ? 'default' : 'primary'}
              contacts={paymentReminder.customer.contacts.map((c) => {
                return {
                  id: '' + c.id,
                  name: c.name,
                  email: c.email,
                };
              })}
              initialValue={paymentReminder.customer.contacts
                .filter((c) => c.shouldReceiveInvoices)
                .map((v) => '' + v.id)}
              description={`Ben je zeker dat je de betaal herinnering wilt sturen?`}
              onSubmit={async (value) => {
                try {
                  if (!value.length) {
                    throw new Error('Geen contacten geselecteerd');
                  }

                  const result = await sendPaymentReminder({
                    paymentReminderId: paymentReminder.id,
                    contactIds: value.map((v) => +v),
                  });
                  if (result.error) {
                    throw result.error;
                  }
                  toast.success('Betaalherinnering verstuurd');
                } catch (err) {
                  toast.error(`Kon betaalherinnering niet versturen: ${getDisplayError(err)}`);
                  throw err;
                }
              }}
            />
          </div>
        }
      />

      <div className="px-4">
        <div className="flex gap-4">
          <DataField title="Klant">{paymentReminder.customer.name}</DataField>
          <DataField title="Type">{paymentReminder.reminderType}</DataField>
          <DataField title="Aangemaakt op">{formatDate(paymentReminder.createdAt)}</DataField>
          <DataField title="Verstuurd op">
            {paymentReminder.sentAt ? formatDate(paymentReminder.sentAt) : '-'}
          </DataField>
        </div>

        <div className="my-4">
          <div className="grid grid-cols-2 gap-2 font-medium border-b">
            <div>Factuurnummer</div>
            <div>Bedrag</div>
          </div>

          {paymentReminder.invoices.map((invoice) => {
            return (
              <div className="grid grid-cols-2 gap-2">
                <div>{invoice.invoice.invoiceNumber}</div>
                <div>{`€ ${formatNumber(invoice.amount, 2, {
                  decimalSeperator: ',',
                })}`}</div>
              </div>
            );
          })}

          <div className="grid grid-cols-2 gap-2">
            <div>Totaal</div>
            <div>{`€ ${formatNumber(total, 2, {
              decimalSeperator: ',',
            })}`}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentReminderPage;
