import { Plus, MagnifyingGlass } from '@phosphor-icons/react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';

import { Input } from '../../../components/input/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import { ITableHeader, Table } from '../../../components/table/Table';
import {
  GetCustomerPortalQuotationsDocument,
  GetCustomerPortalQuotationsQuery,
  GetCustomerPortalQuotationsQueryVariables,
} from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { formatNumber } from '../../../utils/number';
import { formatDate } from '../../../utils/date';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { useTranslation } from '../../../contexts/translation-context';
import { LinkButton } from '../../../components/button/ButtonLink';
import { PageHeading } from 'components/PageHeading';

export const CustomerPortalQuotationsPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const { i18n } = useTranslation();
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetCustomerPortalQuotationsQuery, GetCustomerPortalQuotationsQueryVariables>(
          GetCustomerPortalQuotationsDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters: {
              search: searchValue,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.customerPortalQuotations ?? [];
    },
    [client, searchValue],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 50,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  useEffect(() => {
    page.reset();
  }, [searchValue]);

  const tableHeaders: ITableHeader[] = [
    {
      id: 'reference',
      name: i18n('customerPortal.quotes.quoteReference'),
    },
    {
      id: 'date',
      name: i18n('customerPortal.quotes.quoteDate'),
    },
    {
      id: 'expiresAt',
      name: i18n('customerPortal.quotes.expirationDate'),
    },
    {
      id: 'total',
      name: i18n('customerPortal.quotes.total'),
    },
  ];

  const title = i18n('customerPortal.quotes.quotes');
  return (
    <>
      <PageHeader title={title} />

      <div>
        <PageHeading
          leftSide={<Breadcrumb currentItem={title} />}
          rightSide={
            <div className="flex gap-2">
              <LinkButton to="requests">{i18n('customerPortal.quotes.requests')}</LinkButton>
              <LinkButton color="primary" iconLeft={<Plus className="button-icon" />} to="requests/new">
                {i18n('customerPortal.quotes.request')}
              </LinkButton>
            </div>
          }
        />

        <div className="flex w-full px-4 mb-8">
          <Input
            type="text"
            placeholder={i18n('customerPortal.quotes.search')}
            value={searchValue}
            onChange={setSearchValue}
            iconLeft={<MagnifyingGlass className="input-icon" />}
          />
        </div>

        <Table
          idKey="id"
          headers={tableHeaders}
          data={page.data}
          mapData={(quotation) => {
            const total = quotation.lines.reduce((acc, line) => {
              return acc + line.price;
            }, 0);

            return [
              <Link to={`${quotation.id}`} className="link-text">
                {quotation.reference ?? 'DRAFT'}
              </Link>,
              formatDate(quotation.quotationDate),
              formatDate(quotation.expiresAt),
              <div className="whitespace-nowrap">{`€ ${formatNumber(total, 2, {
                decimalSeperator: ',',
              })}`}</div>,
            ];
          }}
        />

        <div className="my-4">
          <Pagination
            hasPrevious={page.hasPrevious}
            previous={page.previous}
            hasNext={page.hasNext}
            next={page.next}
            isFetching={page.isFetching}
          />
        </div>
      </div>
    </>
  );
};
