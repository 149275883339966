import { Pencil, Plus } from '@phosphor-icons/react';
import { useField } from 'formik';
import { useCallback, useMemo } from 'react';

import { ISimpleComboboxItem } from '../../../components/combobox/SimpleCombobox';
import { SimpleComboboxField } from '../../../components/combobox/SimpleComboboxField';
import { useProductTypesQuery } from '../../../generated/graphql';
import { CreateProductTypeDialog } from './CreateProductTypeDialog';
import { UpdateProductTypeDialog } from './UpdateProductTypeDialog';

export interface IMinimalProductType {
  id: string;
  nameNl: string;
}

export const productTypeToComboboxItem = (val: IMinimalProductType): ISimpleComboboxItem => {
  return {
    key: val.id,
    name: `${val.id} - ${val.nameNl}`,
  };
};

export interface IProductTypeComboboxProps {
  name: string;
  labelText?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  onBlur?: () => void;
  isNullable?: boolean;
}

export const ProductTypeComboboxField: React.FC<IProductTypeComboboxProps> = (props) => {
  const { labelText, name, ...otherProps } = props;
  const [{ data }, reexecuteQuery] = useProductTypesQuery();
  const [field, _meta, helpers] = useField({ name });

  const productTypes = data?.productTypes ?? [];

  const items: ISimpleComboboxItem[] = useMemo(() => {
    return data ? data.productTypes.map(productTypeToComboboxItem) : [];
  }, [productTypes]);

  const refetch = useCallback(() => {
    reexecuteQuery({
      requestPolicy: 'network-only',
    });
  }, [reexecuteQuery]);

  const value: ISimpleComboboxItem | null = field.value;
  return (
    <div>
      <div className="flex items-center">
        <SimpleComboboxField labelText={labelText ?? 'Aard'} items={items} name={name} {...otherProps} />

        <div className="flex-end flex justify-end gap-2 ml-2">
          {value && (
            <UpdateProductTypeDialog
              id={value.key}
              onSubmit={(data) => {
                helpers.setValue(productTypeToComboboxItem(data));
                refetch();
              }}
              triggerText={<Pencil className="button-icon" />}
            />
          )}

          <CreateProductTypeDialog
            onSubmit={(data) => {
              helpers.setValue(productTypeToComboboxItem(data));
              refetch();
            }}
            triggerText={<Plus className="button-icon" />}
          />
        </div>
      </div>
    </div>
  );
};
