import { useField } from 'formik';
import { useEffect } from 'react';

import { CheckboxWithLabel } from './CheckboxWithLabel';

export interface ICheckboxFieldProps {
  labelText: string;
  name: string;
  helperText?: string;
  isDisabled?: boolean;
}

export const CheckboxField: React.FC<ICheckboxFieldProps> = (props) => {
  const { name, labelText, helperText, isDisabled } = props;
  const [field, meta, helpers] = useField({ name });

  useEffect(() => {
    helpers.setTouched(true);
  }, []);

  const invalidText = meta.touched ? meta.error : undefined;
  return (
    <div>
      <CheckboxWithLabel
        isChecked={field.value}
        onChange={(val) => {
          helpers.setValue(val);
        }}
        isInvalid={Boolean(meta.touched && meta.error)}
        isDisabled={isDisabled}
        labelText={labelText}
      />

      <div className="text-sm pb-1 w-full">
        {invalidText ? (
          <span className="text-red-600">{invalidText}</span>
        ) : (
          <span className="text-gray-600">{helperText ?? <>&zwnj;</>}</span>
        )}
      </div>
    </div>
  );
};
