import classNames from '@utils/classnames';

import { StatusColor } from './StatusBadge';
import { StatusDot } from './StatusDot';

export interface IStatusTextProps {
  color: StatusColor;
  children: React.ReactNode;
}

export const StatusText: React.FC<IStatusTextProps> = (props) => {
  const { color, children } = props;

  return (
    <div
      className={classNames('flex gap-2 items-center', {
        'text-dark-01': color === 'black',
        'text-feedback-negative-04': color === 'red',
        'text-orange-01': color === 'orange',
        'text-green-01': color === 'green',
      })}
    >
      <StatusDot color={color} />
      {children}
    </div>
  );
};
