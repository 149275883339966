export interface IRoute {
  distance: number;
  duration: number;
  geometry: {
    coordinates: [number, number][];
    type: 'LineString';
  };
}

export function serializeCoordinates(coordinates: [number, number]) {
  return coordinates.map((c) => c.toFixed(3)).join(',');
}

/**
 * Create a route between multiple points
 * @param token MAPBOX API token
 * @param points Array of [longitude, latitude] pairs
 * @returns The route or null if no route was found
 */
export async function createRoute(token: string, points: Array<[number, number]>): Promise<IRoute | null> {
  const serializedPoints = points.map((p) => serializeCoordinates(p)).join(';');
  const searchParams = new URLSearchParams();
  searchParams.set('access_token', token);
  searchParams.set('language', 'nl');
  searchParams.set('geometries', 'geojson');
  const result = await fetch(
    `https://api.mapbox.com/directions/v5/mapbox/driving/${serializedPoints}.json?${searchParams.toString()}`,
    {
      headers: {
        accept: 'application/json',
      },
    },
  );
  const json = await result.json();
  if (!result.ok) {
    throw new Error('No results found');
  }
  return json.routes[0] ?? null;
}
