import { Plus } from '@phosphor-icons/react';
import { Button } from 'components/button/Button';
import { InputField } from 'components/input/InputField';
import { Formik } from 'formik';
import { Language, useUpdateSupplierContactMutation } from 'generated/graphql';
import diff from 'object-diff';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { invariant } from '@utils/invariant';
import * as Yup from 'yup';

import { CheckboxField } from '../../../../components/checkbox/CheckboxField';
import { SimpleSelectField } from '../../../../components/select/SimpleSelectField';
import { TextAreaField } from '../../../../components/textarea/TextAreaField';
import { getDisplayError } from '../../../../utils/get-display-error';
import { LANGUAGE_VALUES } from '../../../../utils/language';
import { formatPhoneNumber } from '../../../../utils/phonenumber';
import { useSupplier } from '../Supplier/supplierContext';

const createContactSchema = Yup.object().shape({
  name: Yup.string().required('Vereist'),
  email: Yup.string().email('Ongeldig email'),
  phone: Yup.string().phone(),
  function: Yup.string(),
  language: Yup.mixed().nullable().required('Vereist'),
  shouldReceiveTransportOrders: Yup.boolean().required('Vereist'),
  shouldReceiveSelfBillings: Yup.boolean().required('Vereist'),
});

const UpdateSupplierContactPage = () => {
  const navigate = useNavigate();
  const { supplier } = useSupplier();
  const { supplierContactId } = useParams<{ supplierContactId: string }>();
  invariant(supplierContactId);
  const [, updateContactMutation] = useUpdateSupplierContactMutation();

  const contact = supplier.contacts.find((c) => c.id === +supplierContactId);

  if (!contact) {
    return <div>Contact niet gevonden</div>;
  } else {
    const initialValues = {
      name: contact.name,
      email: contact.email,
      phone: contact.phone,
      function: contact.function,
      language: LANGUAGE_VALUES.find((v) => v.key === contact.language),
      internalNotes: contact.internalNotes,
      shouldReceiveTransportOrders: contact.shouldReceiveTransportOrders,
      shouldReceiveSelfBillings: contact.shouldReceiveSelfBillings,
    };

    return (
      <div>
        <h1 className="heading-one mb-4">Pas contact aan</h1>

        <Formik
          initialValues={initialValues}
          validationSchema={createContactSchema}
          onSubmit={async (values) => {
            try {
              const patch: Partial<typeof values> = diff(initialValues, values);
              const updateData = {
                ...patch,
                language: patch.language ? (patch.language.key as Language) : undefined,
                phone: patch.phone ? formatPhoneNumber(patch.phone, false) : undefined,
              };
              const result = await updateContactMutation({
                id: contact.id,
                data: updateData,
              });
              if (result.error) {
                throw result.error;
              }
              if (result.data) {
                navigate('..');
              }
              toast.success('Contact aangepast');
            } catch (err: any) {
              toast.error('Kon contact niet aanpassen: ' + getDisplayError(err));
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <InputField labelText="Naam" type="text" name="name" />
              <InputField labelText="Email" type="text" name="email" />
              <InputField labelText="Telefoon" type="text" name="phone" />
              <InputField labelText="Functie" type="text" name="function" />
              <SimpleSelectField labelText="Taal" items={LANGUAGE_VALUES} name="language" />
              <TextAreaField labelText="Interne notities" name="internalNotes" spellCheck={true} />
              <h2 className="heading-two mb-2">Contact geschikt voor</h2>
              <div className="grid grid-cols-2 gap-4">
                <CheckboxField labelText="Transport opdracht" name="shouldReceiveTransportOrders" />
                <CheckboxField labelText="Self billings" name="shouldReceiveSelfBillings" />
              </div>
              <Button
                type="submit"
                color="primary"
                iconLeft={<Plus className="button-icon" />}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Bewaar aanpassingen
              </Button>
            </form>
          )}
        </Formik>
      </div>
    );
  }
};

export default UpdateSupplierContactPage;
