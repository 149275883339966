import { Plus } from '@phosphor-icons/react';
import { Formik } from 'formik';
import { Document } from '@prisma/client';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { Button } from '../../../components/button/Button';
import { Dropzone } from '../../../components/Dropzone';
import { InputField } from '../../../components/input/InputField';
import { getDisplayError } from '../../../utils/get-display-error';
import { IUploadResponse } from '../types';

const createDocumentSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Vereist').required('Vereist'),
  file: Yup.mixed().nullable().required('Vereist'),
});

export interface IUploadFormProps {
  onSubmit: (values: Document) => Promise<void>;
  onCancel: () => void;
  initialName?: string;
}

const UploadForm: React.FC<IUploadFormProps> = (props) => {
  const { onSubmit, initialName, onCancel } = props;

  return (
    <Formik
      initialValues={{
        name: initialName ?? '',
        file: null,
      }}
      validationSchema={createDocumentSchema}
      onSubmit={async (values) => {
        try {
          if (!values.file) {
            throw new Error('Moet een bestand selecteren');
          }

          const formData = new FormData();
          formData.set('name', values.name);
          formData.set('file', values.file);

          const result = await fetch('/api/document/upload', {
            body: formData,
            method: 'post',
          });

          const text = await result.text();
          if (!result.ok) {
            console.error(text);
            throw new Error('Upload mislukt');
          }

          const createdDoc: IUploadResponse = JSON.parse(text);
          await onSubmit(createdDoc.data);
        } catch (err: any) {
          toast.error('Kon document niet uploaden: ' + getDisplayError(err));
        }
      }}
    >
      {({ handleSubmit, isSubmitting, getFieldHelpers, values, errors }) => {
        return (
          <form onSubmit={handleSubmit}>
            <InputField labelText="Naam" type="text" name="name" />

            <div className="mb-8">
              <Dropzone
                files={values['file'] ? [values['file']] : []}
                onUpload={(files) => {
                  getFieldHelpers('file').setValue(files[0]);
                  if (!values['name'] && files[0]) {
                    getFieldHelpers('name').setValue(files[0].name);
                  }
                }}
                accept="application/pdf,image/*"
              />
            </div>

            <div className="flex justify-between">
              <Button onTrigger={onCancel}>Annuleer</Button>

              <Button
                type="submit"
                color="primary"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                iconLeft={<Plus className="button-icon" />}
              >
                Upload document
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default UploadForm;
