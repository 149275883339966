import React from 'react';

import { GoogleIcon } from 'components/icons/GoogleIcon';
import { useTranslation } from 'contexts/translation-context';

export interface IGoogleAuthButtonProps {
  redirectUri: string;
}

export const GoogleAuthButton: React.FC<IGoogleAuthButtonProps> = (props) => {
  const { redirectUri } = props;
  const { i18n } = useTranslation();

  return (
    <button
      className="bg-offwhite rounded border w-full text-dark-02 border-dark-04 hover:border-orange-00 hover:text-orange-00 cursor-pointer"
      style={{ height: '2.75rem' }}
      onClick={() => {
        window.location.href = `/api/auth/google/redirect?redirectUri=${encodeURI(redirectUri)}`;
      }}
    >
      <div className="flex h-full gap-2 items-center justify-center">
        <GoogleIcon className="w-4 h-4" />
        {i18n('auth.login.loginWithGoogle')}
      </div>
    </button>
  );
};
