import classNames from '@utils/classnames';
import React from 'react';
import { cva } from 'class-variance-authority';

import { Spinner } from '../Spinner';

export const buttonVariants = cva(
  'border overflow-hidden relative flex items-center justify-center text-sm outline-none rounded cursor-pointer',
  {
    variants: {
      variant: {
        default: 'bg-dark-01 border-dark-01 hover:bg-dark-02 hover:border-dark-02 text-white',
        'default-outline': 'bg-transparent border-dark-01 hover:bg-dark-01 hover:text-white text-dark-01',
        primary: 'bg-orange-00 border-orange-00 hover:bg-orange-02 hover:border-orange-02 text-white',
        'primary-outline':
          'bg-transparent border-orange-00 text-orange-00 hover:bg-orange-00 hover:text-white focus:ring-2 focus:ring-orange-02',
        danger:
          'bg-feedback-negative-04 border-feedback-negative-04 hover:bg-feedback-negative-05 hover:border-feedback-negative-05 text-white',
        'danger-outline':
          'bg-transparent border-feedback-negative-04 text-feedback-negative-04 hover:text-white hover:bg-feedback-negative-04',
        subtle: 'bg-transparent border-transparent hover:bg-dark-05 hover:border-dark-05 text-dark-01',
        disabled: 'bg-dark-05 border-dark-05 text-dark-04 cursor-default',
        'disabled-outline': 'bg-offwhite border-dark-04 text-dark-04 cursor-default',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface BaseButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  color?: 'default' | 'default-outline' | 'primary' | 'primary-outline' | 'danger' | 'danger-outline' | 'subtle';
  isLoading?: boolean;
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
}
export type Ref = HTMLButtonElement;

export const BaseButton = React.forwardRef<Ref, BaseButtonProps>((props, ref) => {
  const { isLoading, disabled, color = 'default', children, className, iconLeft, iconRight, ...otherProps } = props;

  const variantStyle = buttonVariants({
    variant: disabled ? (color.endsWith('outline') ? 'disabled-outline' : 'disabled') : color,
  });

  return (
    <button
      {...otherProps}
      disabled={disabled}
      className={classNames(className, variantStyle)}
      style={{ height: '2.25rem' }}
      ref={ref}
    >
      <div className="w-full flex items-center justify-center whitespace-nowrap">
        {iconLeft != null && <div className="py-4 pl-4">{iconLeft}</div>}
        <div className="px-3">{children}</div>
        {iconRight != null && <div className="py-4 pr-4">{iconRight}</div>}
      </div>
      {isLoading && (
        <div className={classNames('absolute flex items-center justify-center top-0 left-0 w-full h-full')}>
          <Spinner size={4} color={color === 'primary' ? 'primary' : 'default'} />
        </div>
      )}
    </button>
  );
});
