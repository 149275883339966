import createContext from 'contexts/create-context';
import * as React from 'react';

import { AllGeneralSettingsQuery, useAllGeneralSettingsQuery } from '../../generated/graphql';
import { nullthrows } from '../../utils/invariant';
import { useMinimalGeneralSettings } from '../../contexts/minimal-settings-context';

export type AllGeneralSettings = NonNullable<AllGeneralSettingsQuery['generalSettings']>;

interface ProviderValue {
  settings: AllGeneralSettings;
  refreshData: () => void;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface IAllGeneralSettingsProviderProps {
  children?: React.ReactNode;
}

export const AllGeneralSettingsProvider: React.FC<IAllGeneralSettingsProviderProps> = (props) => {
  const { children } = props;
  const [data, refreshData] = useAllGeneralSettingsQuery();
  const { refreshData: refreshMinimalData } = useMinimalGeneralSettings();

  return (
    <ReactProvider
      value={{
        settings: nullthrows(data.data?.generalSettings, 'General settings not found'),
        refreshData: () => {
          refreshMinimalData();
          refreshData({
            requestPolicy: 'network-only',
          });
        },
      }}
    >
      {children}
    </ReactProvider>
  );
};

export const useAllGeneralSettings = useContext;
export const AllGeneralSettingsConsumer = ReactConsumer;
