import { Customer, Invoice, Order } from './types';

export type ICreateCreditNoteState = {
  activeWindow: 'SELECT_CUSTOMER' | 'SELECT_INVOICE' | 'SELECT_ORDERS' | 'ENTER_LINES';
  selectedCustomer: Customer | null;
  selectedInvoice: Invoice | null;
  orders: Order[];
};

export type CreateCreditNoteAction =
  | {
      type: 'SELECT_CUSTOMER';
      customer: Customer | null;
    }
  | {
      type: 'SELECT_INVOICE';
      invoice: Invoice;
    }
  | {
      type: 'SELECT_ORDER';
      order: Order;
    }
  | {
      type: 'GO_BACK';
    }
  | {
      type: 'GO_FORWARD';
    };

export function createCreditNoteReducer(
  state: ICreateCreditNoteState,
  action: CreateCreditNoteAction,
): ICreateCreditNoteState {
  switch (action.type) {
    case 'SELECT_CUSTOMER': {
      return {
        ...state,
        selectedCustomer: action.customer,
        orders: [],
        activeWindow: action.customer ? 'SELECT_INVOICE' : 'SELECT_CUSTOMER',
      };
    }
    case 'SELECT_INVOICE': {
      return {
        ...state,
        selectedInvoice: action.invoice,
        orders: [],
        activeWindow: action.invoice ? 'SELECT_ORDERS' : 'SELECT_INVOICE',
      };
    }
    case 'SELECT_ORDER': {
      const ordersClone = [...state.orders];
      const foundIndex = ordersClone.findIndex((o) => o.id === action.order.id);
      if (foundIndex >= 0) {
        ordersClone.splice(foundIndex, 1);
      } else {
        ordersClone.push(action.order);
      }
      return {
        ...state,
        orders: ordersClone,
      };
    }
    case 'GO_BACK': {
      let newWindow = state.activeWindow;
      if (state.activeWindow === 'ENTER_LINES') {
        newWindow = 'SELECT_ORDERS';
      } else if (state.activeWindow === 'SELECT_ORDERS') {
        newWindow = 'SELECT_INVOICE';
      } else if (state.activeWindow === 'SELECT_INVOICE') {
        newWindow = 'SELECT_CUSTOMER';
      }

      return {
        ...state,
        selectedCustomer: newWindow === 'SELECT_CUSTOMER' ? null : state.selectedCustomer,
        selectedInvoice: newWindow === 'SELECT_INVOICE' ? null : state.selectedInvoice,
        orders: newWindow === 'SELECT_CUSTOMER' ? [] : state.orders,
        activeWindow: newWindow,
      };
    }
    case 'GO_FORWARD': {
      let newWindow = state.activeWindow;
      if (state.activeWindow === 'SELECT_CUSTOMER') {
        newWindow = 'SELECT_INVOICE';
      } else if (state.activeWindow === 'SELECT_INVOICE') {
        newWindow = 'SELECT_ORDERS';
      } else if (state.activeWindow === 'SELECT_ORDERS') {
        newWindow = 'ENTER_LINES';
      }

      return {
        ...state,
        activeWindow: newWindow,
      };
    }
  }

  return state;
}
