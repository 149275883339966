import classNames from '@utils/classnames';
import React from 'react';

export interface TextAreaProps {
  id?: string;
  value: string;
  onBlur?: (e: React.FocusEvent<any>) => void;
  onFocus?: () => void;
  onChange: (newValue: string) => void;
  isInvalid?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  rows?: number;
  cols?: number;
  spellCheck?: boolean;
}

export const TextArea: React.FC<TextAreaProps> = (props) => {
  const { id, value, onBlur, onFocus, onChange, isInvalid, isDisabled, placeholder, rows, cols, spellCheck } = props;

  return (
    <textarea
      id={id}
      rows={rows}
      cols={cols}
      value={value}
      onBlur={onBlur}
      onFocus={onFocus}
      placeholder={placeholder}
      onChange={(evt) => onChange(evt.target.value)}
      className={classNames('py-2 px-3 block w-full border rounded focus:outline-none bg-offwhite', {
        'focus:border-indigo-500 border-feedback-negative-04 ': isInvalid,
        'focus:border-indigo-500 border-dark-04 focus:border-dark-01': !isInvalid,
      })}
      disabled={isDisabled}
      autoComplete="off"
      spellCheck={spellCheck}
    />
  );
};
