import { StatusBadge } from '../../../components/StatusBadge';
import { useTranslation } from '../../../contexts/translation-context';
import { OrderStatus } from '../../../generated/graphql';
import { ORDER_STATUS_COLOR } from '../constants';

export interface IOrderStatusTagProps {
  status: OrderStatus;
}

export const OrderStatusTag: React.FC<IOrderStatusTagProps> = (props) => {
  const { status } = props;
  const { i18n } = useTranslation();

  const orderStatusName = i18n(`orderStatus.${status}`);
  return <StatusBadge color={ORDER_STATUS_COLOR[status]}>{orderStatusName}</StatusBadge>;
};
