import { useReducer } from 'react';

export type UniqueArrayAction<T> =
  | {
      type: 'add';
      value: T;
    }
  | {
      type: 'delete';
      value: T;
    };

function reducer<T>(state: Set<T>, action: UniqueArrayAction<T>): Set<T> {
  switch (action.type) {
    case 'add': {
      const v = new Set(state);
      v.add(action.value);
      return v;
    }
    case 'delete': {
      const v = new Set(state);
      v.delete(action.value);
      return v;
    }
  }
}

export function useUniqueArrayReducer<T>(): [Set<T>, (action: UniqueArrayAction<T>) => void] {
  // @ts-ignore
  return useReducer(reducer, new Set<T>());
}
