import { Suspense } from 'react';
import { Spinner } from './Spinner';
import { Delay } from './Delay';

export const SuspenseSpinner: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Suspense
      fallback={
        <Delay
          ms={500}
          after={
            <div className="w-full flex justify-center items-center">
              <Spinner />
            </div>
          }
        />
      }
    >
      {children}
    </Suspense>
  );
};
