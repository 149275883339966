import { IncomingDocumentType, IncomingDocumentStatus } from '../../../generated/graphql';

import { StatusColor } from '../../../components/StatusBadge';

export const IncomingDocumentStatusToText: Record<IncomingDocumentStatus, string> = {
  [IncomingDocumentStatus.New]: 'Nieuw',
  [IncomingDocumentStatus.Approved]: 'Goedgekeurd',
  [IncomingDocumentStatus.Rejected]: 'Afgekeurd',
};

export const IncomingDocumentStatusToColor: Record<IncomingDocumentStatus, StatusColor> = {
  [IncomingDocumentStatus.New]: 'green',
  [IncomingDocumentStatus.Approved]: 'black',
  [IncomingDocumentStatus.Rejected]: 'black',
};

export const IncomingDocumentTypeToText: Record<IncomingDocumentType, string> = {
  [IncomingDocumentType.Cmr]: 'CMR',
  [IncomingDocumentType.Invoice]: 'Factuur',
  [IncomingDocumentType.Other]: 'Andere',
  [IncomingDocumentType.TransportOrder]: 'Transport Order',
};
