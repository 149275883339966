import { Link, Navigate } from 'react-router-dom';
import { IterableElement } from 'type-fest';

import { ITableHeader, Table } from '../../../../components/table/Table';
import { OrderLineStopType } from '../../../../generated/graphql';
import { Order } from '../order/Order';
import { useOrder } from '../order/orderContext';

export type OrderLine = IterableElement<Order['lines']>;

const ORDERLINE_HEADERS: ITableHeader[] = [
  {
    id: 'load',
    name: 'Laden',
  },
  {
    id: 'unload',
    name: 'Lossen',
  },
];

const OrderLinesPage = () => {
  const { order } = useOrder();

  return (
    <div>
      {!order.lines.length ? (
        <Navigate to="new" />
      ) : (
        <Table
          idKey="id"
          headers={ORDERLINE_HEADERS}
          data={order.lines}
          mapData={(data) => {
            return [
              <Link to={`${data.id}/contents`} className="link-text">
                {data.stops
                  .filter((s) => s.type === OrderLineStopType.Load)
                  .map((s) => `${s.location.name} ${s.location.country}-${s.location.city}`)
                  .join(', ') || '-'}
              </Link>,
              data.stops
                .filter((s) => s.type === OrderLineStopType.Unload)
                .map((s) => `${s.location.name} ${s.location.country}-${s.location.city}`)
                .join(', ') || '-',
            ];
          }}
          isMultiSelect
        />
      )}
    </div>
  );
};

export default OrderLinesPage;
