import createContext from 'contexts/create-context';
import * as React from 'react';

import { Trailer } from './TrailerPage';

interface ProviderValue {
  trailer: Trailer;
  refreshData: () => void;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface ITrailerProviderProps {
  trailer: Trailer;
  refreshData: () => void;
  children?: React.ReactNode;
}

export const TrailerProvider: React.FC<ITrailerProviderProps> = (props) => {
  const { trailer, children, refreshData } = props;

  return <ReactProvider value={{ trailer, refreshData }}>{children}</ReactProvider>;
};

export const useTrailer = useContext;
export const Consumer = ReactConsumer;
