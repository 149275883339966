import * as RadixTooltip from '@radix-ui/react-tooltip';
import React from 'react';

export interface ITooltipProps {
  children: React.ReactElement;
  content: string;
}

export const Tooltip: React.FC<ITooltipProps> = (props) => {
  const { children, content } = props;

  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>
          <div tabIndex={0}>{children}</div>
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content className="tooltip-content" sideOffset={5}>
            {content}
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};
