export function parseURLSearchParams(params: URLSearchParams): {
  orderId?: string;
  tokenId?: string;
  supplierId?: number;
  token: string;
  planningDate?: string;
} {
  const result = {
    orderId: params.get('orderId')!,
    tokenId: params.get('tokenId')!,
    supplierId: parseInt(params.get('supplierId') ?? '', 10),
    token: params.get('token') ?? '',
    planningDate: params.get('planningDate') ?? '',
  };
  if ((!result.orderId && !result.tokenId) || !result.token) {
    throw new Error('Invalid URL parameters');
  }
  return result;
}
