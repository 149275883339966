import { CalendarDots, Clock } from '@phosphor-icons/react';
import { IterableElement } from 'type-fest';

import { GetOrdersByTokenQuery, OrderLineStopType } from '../../../../generated/graphql';
import { formatDate, formatInputTime } from '../../../../utils/date';
import { formatNumber } from '../../../../utils/number';
import { calculateLinesTotalExclVat } from '../../utils/price';
import { useTranslation } from '../../../../contexts/translation-context';

export type OrderSummaryData = NonNullable<IterableElement<GetOrdersByTokenQuery['ordersByToken']>>;

export interface IOrderSummaryProps {
  order: OrderSummaryData;
}

export const OrderSummary: React.FC<IOrderSummaryProps> = (props) => {
  const { order } = props;
  const { i18n } = useTranslation();

  return (
    <>
      {order.lines.map((line) => {
        return (
          <div className="mb-8" key={line.id}>
            <h2 className="heading-two mb-4">{i18n('pages.orderSummary.loadAndUnloadInformation')}</h2>

            <div className="rounded bg-dark-05 grid grid-cols-2">
              {line.stops
                .sort((a, b) => a.sequenceIndex - b.sequenceIndex)
                .map((stop) => {
                  return (
                    <div className="p-4" key={stop.id}>
                      <div className="font-medium mb-2">
                        {stop.type === OrderLineStopType.Load
                          ? i18n('pages.orderSummary.load')
                          : i18n('pages.orderSummary.unload')}
                      </div>
                      <div>
                        {stop.location.country}-{stop.location.name}
                      </div>
                      <div className="flex items-center">
                        <CalendarDots className="w-4 h-4 mr-2" />
                        <div>{formatDate(stop.date)}</div>
                      </div>
                      <div className="flex items-center">
                        <Clock className="w-4 h-4 mr-2" />
                        <div>
                          {formatInputTime(stop.timeStart)} - {formatInputTime(stop.timeEnd)}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            <h2 className="heading-two my-4">{i18n('pages.orderSummary.freightRate')}</h2>
            {line.purchases.map((purchase) => {
              return (
                <div key={purchase.id} className="rounded bg-offwhite border border-dark-04 grid grid-cols-4 p-2 mb-2">
                  <div className="col-span-3 whitespace-nowrap">
                    {formatNumber(purchase.amount, 2)} x {purchase.productType.nameNl}
                  </div>
                  <div className="whitespace-nowrap">
                    €
                    {formatNumber((purchase.amount * purchase.unitPrice) / 100, 2, {
                      decimalSeperator: ',',
                    })}
                  </div>
                </div>
              );
            })}
            <div className="rounded bg-dark-05 grid grid-cols-4 p-2 mb-2 font-medium">
              <div className="col-span-3">{i18n('pages.orderSummary.total')}</div>
              <div className="whitespace-nowrap">
                €
                {formatNumber(calculateLinesTotalExclVat(line.purchases), 2, {
                  decimalSeperator: ',',
                })}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
