import {
  Money,
  Bell,
  BellRinging,
  Gear,
  ChartPie,
  Tray,
  MapPin,
  Truck,
  TruckTrailer,
  Users,
  File,
  Eye,
  Calendar,
} from '@phosphor-icons/react';
import { Suspense, useMemo } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

import { useAuth } from '../../contexts/auth-context';
import { SpinnerBlock } from '../Spinner';
import { useNotificationsCtx } from '../../app/notification/pages/context';
import { useMinimalGeneralSettings } from '../../contexts/minimal-settings-context';
import { Avatar } from '../avatar/Avatar';
import { roleToNumber } from '../../app/auth/roles.client';
import { UserRole } from '../../generated/graphql';
import { Sidebar } from 'components/Sidebar';

export interface IDashboardProps {
  children: React.ReactNode;
}

export const Dashboard: React.FC<IDashboardProps> = (props) => {
  const { children } = props;
  const { me } = useAuth();
  const location = useLocation();
  const [params] = useSearchParams();
  const {
    state: { hasUnreadNotifications },
  } = useNotificationsCtx();
  const { incomingDocCount } = useMinimalGeneralSettings();

  const [topItems, bottomItems] = useMemo(() => {
    const topRoutes = [];
    const bottomRoutes = [];

    if (roleToNumber(me.role) >= roleToNumber(UserRole.Admin)) {
      topRoutes.push({ name: 'Statistieken', to: '/internal/stats', icon: ChartPie });
    }

    topRoutes.push(
      { name: 'Orders', to: '/internal/orders', icon: Tray },
      { name: 'Planning', to: '/internal/planning', icon: Calendar },
      { name: 'Klanten', to: '/internal/customers', icon: Users },
      { name: 'Vervoerders', to: '/internal/suppliers', icon: Truck },
      { name: 'Trailers', to: '/internal/trailers', icon: TruckTrailer },
      { name: 'Locaties', to: '/internal/locations', icon: MapPin },
      { name: `Inkomende docs (${incomingDocCount})`, to: '/internal/incoming-docs', icon: File },
    );

    if (roleToNumber(me.role) >= roleToNumber(UserRole.EmployeePlus)) {
      topRoutes.push({ name: 'Financieel', to: '/internal/finance', icon: Money });
    }

    if (roleToNumber(me.role) >= roleToNumber(UserRole.Admin)) {
      bottomRoutes.push(
        { name: 'Audit Logs', to: '/internal/audit-logs', icon: Eye },
        { name: 'Instellingen', to: '/internal/settings', icon: Gear },
      );
    }

    return [topRoutes, bottomRoutes];
  }, [incomingDocCount]);

  const hideBars = params.get('minimal') === '1';
  if (hideBars) {
    return (
      <main className="p-6">
        <Suspense fallback={<SpinnerBlock message="Loading..." />}>{children}</Suspense>
      </main>
    );
  } else {
    return (
      <div className="flex min-h-screen w-full">
        <div className="flex-shrink-0">
          <Sidebar
            homeLink="/internal"
            topItems={
              <div className="flex flex-col">
                <nav className="flex-1">
                  {topItems.map((item) => {
                    return (
                      <Link key={item.name} to={item.to} className="sidebar-item hoverable-sidebar-item">
                        <div className="sidebar-item-icon-container">
                          <item.icon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                        </div>
                        <div className="sidebar-item-text-container">{item.name}</div>
                      </Link>
                    );
                  })}
                </nav>

                <div className="bg-dark-05 ml-4 mr-8 my-4" style={{ height: 1 }} />

                <nav className="flex-1">
                  {bottomItems.map((item) => {
                    return (
                      <Link key={item.name} to={item.to} className="sidebar-item hoverable-sidebar-item">
                        <div className="sidebar-item-icon-container">
                          <item.icon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                        </div>
                        <div className="sidebar-item-text-container">{item.name}</div>
                      </Link>
                    );
                  })}
                </nav>
              </div>
            }
            bottomItems={
              <div>
                <Link to="/internal/notifications" className="sidebar-item hoverable-sidebar-item">
                  <div className="sidebar-item-icon-container">
                    {hasUnreadNotifications ? (
                      <BellRinging className="h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bell className="h-6 w-6" aria-hidden="true" />
                    )}
                  </div>
                  <div className="sidebar-item-text-container">Notificaties</div>
                </Link>

                <Link to="/internal/me" className="sidebar-item hoverable-sidebar-item">
                  <div className="sidebar-item-icon-container">
                    <Avatar name={me.name} url={me.image} />
                  </div>
                  <div className="sidebar-item-text-container">{me.name}</div>
                </Link>
              </div>
            }
          />
        </div>

        <div className="pb-32 content-bg min-h-screen flex-1">
          <Suspense fallback={<SpinnerBlock message="Loading..." />}>{children}</Suspense>
        </div>
      </div>
    );
  }
};
