import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';
import { MagnifyingGlass } from '@phosphor-icons/react';
import toast from 'react-hot-toast';

import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import { ITableHeader, Table } from '../../../components/table/Table';
import {
  GetSupplierSelfBillsDocument,
  GetSupplierSelfBillsQuery,
  GetSupplierSelfBillsQueryVariables,
  useGenerateSelfBillingsMutation,
} from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { formatDateTime } from '../../../utils/date';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { Input } from '../../../components/input/Input';
import { ConfirmDialog } from 'components/dialog/ConfirmDialog';
import { getDisplayError } from '@utils/get-display-error';
import { PageHeading } from 'components/PageHeading';

const SELF_BILL_HEADERS: ITableHeader[] = [
  {
    id: 'entryNumber',
    name: 'ID',
  },
  {
    id: 'supplier',
    name: 'Vervoerder',
  },
  {
    id: 'emailSentAt',
    name: 'Email verzonden op',
  },
  {
    id: 'createdAt',
    name: 'Datum',
  },
  {
    id: 'dueDate',
    name: 'Vervaldatum',
  },
];

const SelfBillingsPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetSupplierSelfBillsQuery, GetSupplierSelfBillsQueryVariables>(
          GetSupplierSelfBillsDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters: {
              search: searchValue,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.supplierSelfBills ?? [];
    },
    [client, searchValue],
  );
  const [, generateSelfBillings] = useGenerateSelfBillingsMutation();

  const page = usePagination({
    key: 'id',
    pageSize: 50,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  const title = 'Self Billing';

  return (
    <>
      <PageHeader title={title} />

      <PageHeading
        leftSide={
          <Breadcrumb
            parentItem={{
              name: 'Financieel',
              to: '/internal/finance',
            }}
            currentItem={title}
          />
        }
        rightSide={
          <div>
            <ConfirmDialog
              triggerText="Genereer self billings"
              title="Ben je zeker dat je de creditnota wilt versturen?"
              submitText="Genereer self billings"
              description="Ben je zeker dat je de self billings wilt genereren?"
              onSubmit={async () => {
                try {
                  const result = await generateSelfBillings({});
                  if (result.error) {
                    throw result.error;
                  }
                  toast.success('Self billings gegenereerd');
                } catch (err) {
                  toast.error(`Kon self billings niet genereren: ${getDisplayError(err)}`);
                }
              }}
            />
          </div>
        }
      />

      <div className="flex w-full px-4 mb-8">
        <Input
          type="text"
          placeholder="Zoek een self billing..."
          value={searchValue}
          onChange={setSearchValue}
          iconLeft={<MagnifyingGlass className="input-icon" />}
        />
      </div>

      <Table
        idKey="id"
        headers={SELF_BILL_HEADERS}
        data={page.data}
        mapData={(selfBill) => {
          return [
            <Link to={`${selfBill.id}`} className="link-text">
              {selfBill.entryNumber}
            </Link>,
            selfBill.supplier.name,
            selfBill.emailSentAt ? formatDateTime(selfBill.emailSentAt) : '-',
            formatDateTime(selfBill.createdAt),
            formatDateTime(selfBill.dueDate),
          ];
        }}
      />

      <div className="my-4">
        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};

export default SelfBillingsPage;
