import * as Dialog from '@radix-ui/react-dialog';
import React, { useState } from 'react';

import { getDisplayError } from '../../utils/get-display-error';
import { BaseButton, BaseButtonProps } from '../button/BaseButton';
import { Button } from '../button/Button';

export interface IConfirmDialogProps {
  triggerText: React.ReactNode;
  triggerColor?: BaseButtonProps['color'];
  title: string;
  description: React.ReactNode;
  cancelText?: string;
  submitText: string;
  onSubmit: () => void | Promise<void>;
}

export const ConfirmDialog: React.FC<IConfirmDialogProps> = (props) => {
  const { triggerText, triggerColor, title, cancelText = 'Annuleer', submitText, description, onSubmit } = props;
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await onSubmit();
      setOpen(false);
    } catch (err) {
      setError(getDisplayError(err));
    }
    setIsLoading(false);
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Button
        isLoading={isLoading}
        color={triggerColor}
        onTrigger={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();

          setOpen(true);
        }}
      >
        {triggerText}
      </Button>

      <Dialog.Portal>
        <Dialog.Overlay className="dialog-overlay" />
        <Dialog.Content className="dialog-content">
          <Dialog.Title className="heading-one mb-4">{title}</Dialog.Title>

          <Dialog.Description className="mb-4">{description}</Dialog.Description>

          <div className="text-sm mb-2 w-full">{error}</div>

          <div className="flex justify-between">
            <div>
              <BaseButton
                isLoading={isLoading}
                disabled={isLoading}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  setOpen(false);
                }}
              >
                {cancelText}
              </BaseButton>
            </div>
            <div>
              <BaseButton
                color="primary"
                isLoading={isLoading}
                disabled={isLoading}
                onClick={(event) => {
                  handleSubmit();

                  event.preventDefault();
                  event.stopPropagation();
                }}
              >
                {submitText}
              </BaseButton>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
