import { ArrowCircleDown, ArrowCircleUp, Package, Plus, Trash } from '@phosphor-icons/react';
import { FieldArray } from 'formik';

import { Button } from '../../../../components/button/Button';
import { InputField } from '../../../../components/input/InputField';
import { SimpleSelectField } from '../../../../components/select/SimpleSelectField';
import { TextAreaField } from '../../../../components/textarea/TextAreaField';
import { useMinimalGeneralSettings } from '../../../../contexts/minimal-settings-context';
import { LocationComboboxField } from '../../../location/LocationCombobox';
import { CargoTypeComboboxField, IMinimalCargoType } from '../../cargoType/CargoTypeComboboxField';
import { PackageTypeComboboxField } from '../../packageType/PackageTypeComboboxField';
import { ProductTypeComboboxField } from '../../productType/ProductTypeComboboxField';
import {
  getInitialValues as getInitialOrderLineContentValues,
  IOrderLineContentValues,
} from '../orderLineContent/CreateOrderLineContent';
import {
  getInitialValues as getInitialOrderLineSaleValues,
  IOrderLineSaleValues,
} from '../orderLineSale/CreateOrderLineSale';
import { STOP_TYPE_ITEMS } from '../orderLineStop/constants';
import { IOrderLineStopValues, initialValues as initialStopValues } from '../orderLineStop/CreateOrderLineStop';
import {
  getInitialValues as getInitialOrderLinePurchaseValues,
  IOrderPurchaseValues,
} from '../orderPurchase/CreateOrderPurchase';
import { OrderLineStopType } from '../../../../generated/graphql';
import { SuspenseSpinner } from '../../../../components/SuspenseSpinner';
import { IMinimalVatRate, VatRateComboboxField } from '../../vatRate/VatRateComboboxField';
import { StopWarnings } from '../../components/StopsVerification';
import { CreateOrderLineSale } from './CreateOrderLineSale';

export interface ICreateOrderLineFieldsProps {
  customer?: {
    defaultCargoType?: IMinimalCargoType | null;
    defaultVatRate?: IMinimalVatRate | null;
    dieselSurchargeEnabled: boolean;
    hasCustomDieselSurcharge: boolean;
    dieselSurchargePercentage: number;
  } | null;
  supplier?: { defaultVatRate?: IMinimalVatRate | null } | null;
  isSubmitting: boolean;
  values: ICreateOrderLineValues;
  noPurchase?: boolean;
}

export interface ICreateOrderLineValues {
  stops: IOrderLineStopValues[];
  contents: IOrderLineContentValues[];
  purchases: IOrderPurchaseValues[];
  sales: IOrderLineSaleValues[];
}

export const CreateOrderLineFields: React.FC<ICreateOrderLineFieldsProps> = (props) => {
  const { customer, supplier, isSubmitting, values, noPurchase } = props;
  const { settings } = useMinimalGeneralSettings();

  return (
    <>
      <FieldArray
        name="stops"
        render={(arrayHelpers) => (
          <div className="my-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="heading-three">Stops</h2>
              <Button
                onTrigger={() => {
                  const previousStop = values['stops'][values['stops'].length - 1];
                  const itemToAdd = { ...initialStopValues };
                  if (previousStop) {
                    if (values.stops.length === 1) {
                      itemToAdd.type = STOP_TYPE_ITEMS.find((v) => v.key === OrderLineStopType.Unload)!;
                    }
                    // itemToAdd.date = previousStop.date;
                  }
                  arrayHelpers.push(itemToAdd);
                }}
                iconLeft={<Plus className="button-icon" />}
              >
                Voeg stop toe
              </Button>
            </div>

            <StopWarnings stops={values.stops} />

            {values['stops'].length > 0 ? (
              <div className="grid grid-cols-2 gap-4">
                {values['stops'].map((stop, index) => {
                  return (
                    <div className="card p-4" key={`order-stop-${index}`}>
                      <div className="flex justify-between items-center mb-4">
                        <h3 className="flex gap-2 items-center">
                          {stop.type?.key === OrderLineStopType.Load ? (
                            <ArrowCircleUp className="w-6 h-6 text-orange-00" />
                          ) : (
                            <ArrowCircleDown className="w-6 h-6 text-orange-00" />
                          )}
                          <div className="heading-three">{`Stop ${index + 1} - ${stop.type?.name ?? ''}`}</div>
                        </h3>
                        <Button
                          color="danger-outline"
                          onTrigger={() => {
                            arrayHelpers.remove(index);
                          }}
                          iconLeft={<Trash className="button-icon" />}
                        >
                          Verwijder
                        </Button>
                      </div>

                      <SimpleSelectField labelText="Type stop" items={STOP_TYPE_ITEMS} name={`stops[${index}].type`} />

                      <LocationComboboxField
                        labelText="Locatie"
                        name={`stops[${index}].location`}
                        isDisabled={isSubmitting}
                      />
                      <div className="flex gap-2">
                        <div className="flex-1">
                          <InputField
                            labelText="Datum"
                            type="date"
                            name={`stops[${index}].date`}
                            isDisabled={isSubmitting}
                          />
                        </div>
                        <div className="flex gap-2 flex-1">
                          <InputField
                            labelText="Start"
                            type="time"
                            name={`stops[${index}].timeStart`}
                            isDisabled={isSubmitting}
                          />
                          <InputField
                            labelText="Stop"
                            type="time"
                            name={`stops[${index}].timeEnd`}
                            isDisabled={isSubmitting}
                          />
                        </div>
                      </div>
                      <InputField
                        labelText="Referentie"
                        type="text"
                        name={`stops[${index}].reference`}
                        isDisabled={isSubmitting}
                      />
                      <TextAreaField
                        labelText="Notities"
                        name={`stops[${index}].notes`}
                        isDisabled={isSubmitting}
                        spellCheck={true}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>Geen stops</div>
            )}
          </div>
        )}
      />

      <FieldArray
        name="contents"
        render={(arrayHelpers) => (
          <div className="my-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="heading-three">Inhoud</h2>
              <Button
                onTrigger={() => {
                  arrayHelpers.push({
                    ...getInitialOrderLineContentValues(settings, customer),
                  });
                }}
                iconLeft={<Plus className="button-icon" />}
              >
                Voeg inhoud toe
              </Button>
            </div>

            {values['contents'].length > 0 ? (
              <div className="grid gap-4">
                {values['contents'].map((_content, index) => {
                  return (
                    <SuspenseSpinner key={`order-content-${index}`}>
                      <div className="card p-4">
                        <div className="flex justify-between items-center  mb-4">
                          <h3 className="flex gap-2 items-center">
                            <Package className="w-6 h-6 text-orange-00" />
                            <div className="heading-three">{`Inhoud ${index + 1}`}</div>
                          </h3>
                          <Button
                            color="danger-outline"
                            onTrigger={() => {
                              arrayHelpers.remove(index);
                            }}
                            iconLeft={<Trash className="button-icon" />}
                          >
                            Verwijder
                          </Button>
                        </div>

                        <div className="flex items-center gap-4">
                          <div className="grid grid-cols-3 gap-2 flex-1">
                            <CargoTypeComboboxField labelText="Inhoud" name={`contents[${index}].cargoType`} />
                            <InputField
                              labelText="Coli"
                              type="number"
                              name={`contents[${index}].packages`}
                              step="0.01"
                            />
                            <PackageTypeComboboxField labelText="Verpakking" name={`contents[${index}].packageType`} />
                          </div>
                        </div>
                        <div className="flex gap-2">
                          <InputField
                            labelText="Gewicht (kg)"
                            type="number"
                            name={`contents[${index}].weight`}
                            step="0.01"
                          />
                          <InputField
                            labelText="Volume (m3)"
                            type="number"
                            name={`contents[${index}].volume`}
                            step="0.01"
                          />
                          <InputField
                            labelText="Laadmeter"
                            type="number"
                            name={`contents[${index}].loadingMeters`}
                            step="0.01"
                          />
                        </div>
                      </div>
                    </SuspenseSpinner>
                  );
                })}
              </div>
            ) : (
              <div>Geen inhoud</div>
            )}
          </div>
        )}
      />

      {!noPurchase && (
        <FieldArray
          name="purchases"
          render={(arrayHelpers) => (
            <div className="my-4">
              <div className="flex justify-between items-center mb-4">
                <h2 className="heading-two">Aankopen</h2>
                <Button
                  onTrigger={() => {
                    arrayHelpers.push({
                      ...getInitialOrderLinePurchaseValues(settings, values['purchases'].length, supplier),
                    });
                  }}
                  iconLeft={<Plus className="button-icon" />}
                >
                  Voeg aankoop toe
                </Button>
              </div>

              {values['purchases'].length > 0 ? (
                <div className="grid gap-4">
                  {values['purchases'].map((_purchase, index) => {
                    return (
                      <SuspenseSpinner key={`order-purchase-${index}`}>
                        <div className="card p-4">
                          <div className="flex justify-between items-center">
                            <h3 className="flex gap-2 items-center">
                              <ArrowCircleDown className="w-6 h-6 text-feedback-negative-04" />
                              <div className="heading-three">{`Aankoop ${index + 1}`}</div>
                            </h3>
                            <Button
                              color="danger-outline"
                              onTrigger={() => {
                                arrayHelpers.remove(index);
                              }}
                              iconLeft={<Trash className="button-icon" />}
                            >
                              Verwijder
                            </Button>
                          </div>

                          <div className="mt-4">
                            <ProductTypeComboboxField labelText="Product" name={`purchases[${index}].productType`} />

                            <div className="flex gap-2">
                              <div style={{ flex: 2 }}>
                                <InputField
                                  labelText="Aantal"
                                  type="number"
                                  name={`purchases[${index}].amount`}
                                  step="0.01"
                                />
                              </div>
                              <div style={{ flex: 2 }}>
                                <InputField
                                  labelText="Eenheidsprijs"
                                  type="number"
                                  name={`purchases[${index}].unitPrice`}
                                  step="0.01"
                                />
                              </div>
                              <div style={{ flex: 3 }}>
                                <VatRateComboboxField labelText="Btw Tarief" name={`purchases[${index}].vatRate`} />
                              </div>
                            </div>
                            <InputField labelText="Extra info" type="text" name={`purchases[${index}].externalNote`} />
                          </div>
                        </div>
                      </SuspenseSpinner>
                    );
                  })}
                </div>
              ) : (
                <div>Geen aankopen</div>
              )}
            </div>
          )}
        />
      )}

      <FieldArray
        name="sales"
        render={(arrayHelpers) => (
          <div className="my-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="heading-two">Verkopen</h2>
              <Button
                onTrigger={() => {
                  arrayHelpers.push({
                    ...getInitialOrderLineSaleValues(settings, values['sales'].length, customer),
                  });
                }}
                iconLeft={<Plus className="button-icon" />}
              >
                Voeg verkoop toe
              </Button>
            </div>

            {values['sales'].length > 0 ? (
              <div className="grid gap-4">
                {values['sales'].map((_sale, index) => {
                  return (
                    <SuspenseSpinner key={`order-sale-${index}`}>
                      <CreateOrderLineSale
                        index={index}
                        onRemove={arrayHelpers.remove}
                        sales={values['sales']}
                        customer={customer}
                      />
                    </SuspenseSpinner>
                  );
                })}
              </div>
            ) : (
              <div>Geen verkopen</div>
            )}
          </div>
        )}
      />
    </>
  );
};
