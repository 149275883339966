import classNames from '@utils/classnames';
import React, { useCallback, useEffect } from 'react';

import { Checkbox } from '../checkbox/Checkbox';
import { useSelectionReducer } from './selection-reducer';

export type TableRow = React.ReactNode[];

interface ITableRowProps<V> {
  index: number;
  mapData: (item: V) => TableRow;
  hasSelection?: boolean;
  selectedItems: number[];
  onSelect: (rowIdx: number) => void;
  data: V;
  isHovered?: boolean;
  onHover?: (id: string | number | null) => void;
}

function TableRow<V = any>(props: ITableRowProps<V>) {
  const { index: rowIdx, data, hasSelection, selectedItems, onSelect, mapData, isHovered, onHover } = props;

  const mapped = mapData(data);
  return (
    <tr
      key={`table-${rowIdx}`}
      className={classNames({
        'bg-dark-05': isHovered,
        'bg-dark-06': !isHovered && rowIdx % 2 === 0,
        'bg-offwhite': !isHovered && rowIdx % 2 !== 0,
      })}
      onMouseEnter={() => onHover?.((data as any).id)}
    >
      {hasSelection && (
        <td className="h-12">
          <label className="flex justify-center items-center cursor-pointer w-full h-full">
            <Checkbox onChange={() => onSelect(rowIdx)} isChecked={selectedItems.includes(rowIdx)} />
          </label>
        </td>
      )}

      {mapped.map((cell, cellIdx) => {
        return (
          <td key={`table-${rowIdx}-${cellIdx}`} className="px-4 h-12">
            <div className="flex flex-wrap justify-start items-center">{cell}</div>
          </td>
        );
      })}
    </tr>
  );
}

export interface ITableHeader {
  id: string;
  name: string;
  width?: string;
}

export interface ITableCallbacks {
  resetSelections: () => void;
}

export interface ITableProps<V> {
  idKey: string;
  refreshToken?: string;
  headers: ITableHeader[];
  mapData: (item: V) => TableRow;
  onSelect?: (selectedItems: V[]) => void;
  isMultiSelect?: boolean;
  data: V[];
  hoveredId?: string | number | null;
  onHover?: (id: null | string | number) => void;
  registerCallbacks?: (callbacks: ITableCallbacks) => void;
}

export function Table<V = any>(props: ITableProps<V>) {
  const { headers, data, mapData, onSelect, registerCallbacks, hoveredId, onHover, idKey } = props;
  const [{ selectedItems }, dispatchSelection] = useSelectionReducer(Boolean(props.isMultiSelect));

  const hasSelection = Boolean(onSelect);

  useEffect(() => {
    dispatchSelection({
      type: 'change_type',
      isMultiSelect: Boolean(props.isMultiSelect),
    });
  }, [props.isMultiSelect]);

  useEffect(() => {
    if (onSelect) {
      onSelect(selectedItems.map((v) => data[v]));
    }
  }, [selectedItems, Boolean(onSelect)]);

  const resetSelections = useCallback(() => {
    dispatchSelection({ type: 'reset' });
  }, [dispatchSelection]);

  useEffect(() => {
    registerCallbacks?.({
      resetSelections,
    });
  }, [registerCallbacks, resetSelections]);

  const handleSelect = useCallback(
    (index: number) => {
      dispatchSelection({
        type: 'select',
        index,
      });
    },
    [dispatchSelection],
  );

  return (
    <table className="simple-table w-full" onMouseLeave={() => onHover?.(null)}>
      <thead>
        <tr>
          {hasSelection && <th className="w-12"></th>}

          {headers.map((header, idx) => {
            return (
              <th
                className="px-4 font-medium border-t border-b border-dark-05 py-4"
                style={{
                  minWidth: header.width ?? undefined,
                }}
                key={`table-header-${idx}`}
              >
                <div className="text-nowrap flex justify-start">{header.name}</div>
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody className="w-full">
        {data.map((d, rowIdx) => {
          return (
            <TableRow
              // @ts-ignore
              key={`table-row-${d[idKey] ?? rowIdx}`}
              index={rowIdx}
              mapData={mapData}
              data={d}
              selectedItems={selectedItems}
              onSelect={handleSelect}
              hasSelection={hasSelection}
              onHover={onHover}
              isHovered={hoveredId === (d as any).id}
            />
          );
        })}
      </tbody>
    </table>
  );
}
