import createContext from 'contexts/create-context';
import * as React from 'react';

import { CreatePurchaseAction, ICreatePurchaseState, createPurchaseReducer } from './reducer';

interface ProviderValue {
  state: ICreatePurchaseState;
  dispatch: React.Dispatch<CreatePurchaseAction>;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

export const CreatePurchaseProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
  const { children } = props;
  const [state, dispatch] = React.useReducer(createPurchaseReducer, {
    activeWindow: 'SELECT_SUPPLIER',
    selectedSupplier: null,
    document: null,
    orders: [],
  });

  return <ReactProvider value={{ state, dispatch }}>{children}</ReactProvider>;
};

export const useCreatePurchaseCtx = useContext;
export const Consumer = ReactConsumer;
