import * as Dialog from '@radix-ui/react-dialog';
import React, { useState } from 'react';

import { BaseButton } from '../../../components/button/BaseButton';
import { CreateProductTypeForm } from './CreateProductType';
import { IMinimalProductType } from './ProductTypeComboboxField';

export interface ICreateProductTypeDialogProps {
  triggerText: React.ReactElement;
  onSubmit: (data: IMinimalProductType) => void | Promise<void>;
}

export const CreateProductTypeDialog: React.FC<ICreateProductTypeDialogProps> = (props) => {
  const { triggerText, onSubmit } = props;
  const [open, setOpen] = useState(false);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <BaseButton>{triggerText}</BaseButton>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay className="dialog-overlay" />
        <Dialog.Content className="dialog-content">
          <Dialog.Title className="heading-one mb-4">Maak product type aan</Dialog.Title>

          <div>
            <CreateProductTypeForm
              onCancel={() => setOpen(false)}
              onComplete={(data) => {
                onSubmit(data);
                setOpen(false);
              }}
            />
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
