import { Link } from 'react-router-dom';
import { Plus, Trash } from '@phosphor-icons/react';
import toast from 'react-hot-toast';

import { useAllGeneralSettings } from '../../all-settings-context';
import { MailboxTypeToText } from './constants';
import { ConfirmDialog } from '../../../../components/dialog/ConfirmDialog';
import { useDeleteMailboxMutation } from '../../../../generated/graphql';
import { getDisplayError } from '../../../../utils/get-display-error';
import { LinkButton } from '../../../../components/button/ButtonLink';
import { Table } from 'components/table/Table';

const TABLE_HEADERS = [
  {
    id: 'name',
    name: 'Naam',
  },
  {
    id: 'email',
    name: 'E-Mail',
  },
  {
    id: 'type',
    name: 'Type',
  },
  {
    id: 'server',
    name: 'Server',
  },
  {
    id: 'delete',
    name: 'Verwijder',
  },
];

export const MailboxesSettingsPage = () => {
  const { settings } = useAllGeneralSettings();
  const [, deleteMailbox] = useDeleteMailboxMutation();

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="heading-two">Mailboxen</h2>
        <div>
          <LinkButton to="configure" color="primary" iconLeft={<Plus className="button-icon" />}>
            Nieuwe Mailbox
          </LinkButton>
        </div>
      </div>
      <div>
        {!settings.mailboxes.length && 'Geen mailboxen geconfigureerd'}
        {!!settings.mailboxes.length && (
          <Table
            idKey="id"
            headers={TABLE_HEADERS}
            data={settings.mailboxes}
            mapData={(mailbox) => {
              return [
                <Link to={mailbox.id} className="link-text">
                  {mailbox.name}
                </Link>,
                mailbox.imapUser,
                MailboxTypeToText[mailbox.type] || 'onbekend',
                mailbox.imapHost,
                <ConfirmDialog
                  triggerColor="danger"
                  triggerText={<Trash className="button-icon" />}
                  title={`Ben je zeker dat je de mailbox ${mailbox.name} wilt verwijderen?`}
                  submitText="Mailbox verwijderen"
                  description={`Ben je zeker dat je de mailbox ${mailbox.name} wilt verwijderen? Om dit ongedaan te maken zal je de mailbox opnieuw moeten aanmaken.`}
                  onSubmit={async () => {
                    try {
                      const result = await deleteMailbox({
                        mailboxId: mailbox.id,
                      });
                      if (result.error) {
                        throw result.error;
                      }
                      toast.success('Mailbox verwijderd');
                    } catch (err) {
                      toast.error(`Kon mailbox niet verwijderen: ${getDisplayError(err)}`);
                    }
                  }}
                />,
              ];
            }}
          />
        )}
      </div>
    </div>
  );
};
