import { useState } from 'react';
import { IterableElement } from 'type-fest';
import { Link } from 'react-router-dom';

import { OrderStatsQuery, PurchaseStatus, SaleStatus } from '../../../generated/graphql';
import { ISimpleSelectItem, SimpleSelect } from '../../../components/select/SimpleSelect';
import { ITableHeader, Table } from '../../../components/table/Table';
import { formatNumber } from '../../../utils/number';
import { useDashboardEntries } from '../context';

export const CHART_TYPE_VALUES: ISimpleSelectItem[] = [
  {
    key: 'all',
    name: 'Alles',
  },
  {
    key: 'supplier_invoiced',
    name: 'Aankoop gefactureerd, verkoop niet',
  },
  {
    key: 'not_invoiced',
    name: 'Nog niet gefactureerd',
  },
  {
    key: 'all_invoiced',
    name: 'Volledig gefactureerd',
  },
  {
    key: 'customer_invoiced',
    name: 'Verkoop gefactureerd, aankoop niet',
  },
];

export const STAT_HEADERS: ITableHeader[] = [
  {
    id: 'id',
    name: 'Order ID',
  },
  {
    id: 'supplierName',
    name: 'Transporteur',
  },
  {
    id: 'purchaseAmount',
    name: 'Aankoop bedrag',
  },
  {
    id: 'customer',
    name: 'Klant',
  },
  {
    id: 'saleAmount',
    name: 'Verkoop bedrag',
  },
  {
    id: 'margin',
    name: 'Marge',
  },
];

type OrderStatEntry = IterableElement<OrderStatsQuery['orderStats']> & { type: 'entry' };

interface ITotalEntry {
  type: 'total';
  saleAmount: number;
  purchaseAmount: number;
}

export const AccountingStats: React.FC = () => {
  const { entries } = useDashboardEntries();
  const [selectedChartType, setSelectedChartType] = useState<ISimpleSelectItem>(CHART_TYPE_VALUES[0]);

  const stats: OrderStatEntry[] = entries.map((v) => {
    return {
      ...v,
      type: 'entry',
    };
  });

  // Of de klant nog geen factuur ontvangen heeft, maar waarvoor de leverancier wel al een factuur gestuurd heeft (hier kan de boekhouding sneller facturen sturen)
  const supplierInvoicedNotCustomer = stats
    .filter((v) => {
      return (
        v.saleStatus !== SaleStatus.Invoiced &&
        v.saleStatus !== SaleStatus.Credited &&
        v.purchaseStatus === PurchaseStatus.Invoiced
      );
    })
    .sort((a, b) => {
      return b.purchaseAmount - a.purchaseAmount;
    });

  // Of de klant nog geen factuur ontvangen heeft, en waarvoor de leverancier nog geen factuur gestuurd heeft (speciaal geval. Hier zouden we eventueel al kunnen factureren)
  const notInvoicedOrders = stats
    .filter((v) => {
      return (
        v.saleStatus !== SaleStatus.Invoiced &&
        v.saleStatus !== SaleStatus.Credited &&
        v.purchaseStatus !== PurchaseStatus.Invoiced
      );
    })
    .sort((a, b) => {
      return b.saleAmount - a.saleAmount;
    });

  // Of de klant wel al een factuur ontvangen heeft, en waarvoor de leverancier ook al een factuur gestuurd heeft (boekhoudkundige omzet – to be confirmed)
  const completedOrders = stats
    .filter((v) => {
      return (
        (v.saleStatus === SaleStatus.Invoiced || v.saleStatus === SaleStatus.Credited) &&
        v.purchaseStatus === PurchaseStatus.Invoiced
      );
    })
    .sort((a, b) => {
      return b.saleAmount - a.saleAmount;
    });

  // Of de klant wel al een factuur ontvangen heeft, maar waarvoor de leverancier nog geen factuur gestuurd heeft (boekhoudkundige omzet – maar zal niet of erg uitzonderlijk voorkomen)
  const noSupplierInvoice = stats
    .filter((v) => {
      return (
        (v.saleStatus === SaleStatus.Invoiced || v.saleStatus === SaleStatus.Credited) &&
        v.purchaseStatus !== PurchaseStatus.Invoiced
      );
    })
    .sort((a, b) => {
      return b.saleAmount - a.saleAmount;
    });

  // Alle orders
  const allOrders = stats.sort((a, b) => {
    return b.saleAmount - a.saleAmount;
  });

  const selectedStats: (OrderStatEntry | ITotalEntry)[] = [];
  switch (selectedChartType.key) {
    case 'supplier_invoiced':
      selectedStats.push(...supplierInvoicedNotCustomer);
      break;
    case 'not_invoiced':
      selectedStats.push(...notInvoicedOrders);
      break;
    case 'all_invoiced':
      selectedStats.push(...completedOrders);
      break;
    case 'customer_invoiced':
      selectedStats.push(...noSupplierInvoice);
      break;
    case 'all':
      selectedStats.push(...allOrders);
      break;
  }

  selectedStats.unshift({
    type: 'total',
    saleAmount: selectedStats.reduce((acc, curr) => {
      return acc + curr.saleAmount;
    }, 0),
    purchaseAmount: selectedStats.reduce((acc, curr) => {
      return acc + curr.purchaseAmount;
    }, 0),
  });

  const saleInvoiceTotal = noSupplierInvoice.reduce((acc, curr) => {
    return acc + curr.purchaseAmount;
  }, 0);
  const purchaseInvoiceTotal = supplierInvoicedNotCustomer.reduce((acc, curr) => {
    return acc + curr.saleAmount;
  }, 0);

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="heading-two mb-4">Boekhoudkundige Omzet</div>
      </div>

      <div className="grid grid-cols-2">
        <div className="border-b border-dark-03 px-4 pb-4">
          <div className="heading-four">Aankoop Gefactureerd, Verkoop niet</div>
          <div className="whitespace-nowrap">{`€ ${formatNumber(purchaseInvoiceTotal, 2, {
            decimalSeperator: ',',
          })}`}</div>
        </div>
        <div className="border-b border-dark-03 border-l px-4 pb-4">
          <div className="heading-four">Verkoop Gefactureerd, Aankoop niet</div>
          <div className="whitespace-nowrap">{`€ ${formatNumber(-saleInvoiceTotal, 2, {
            decimalSeperator: ',',
          })}`}</div>
        </div>
        <div></div>
        <div className="border-l border-dark-03 px-4 py-4">
          <div className="heading-four">Verschil</div>
          <div className="whitespace-nowrap">{`€ ${formatNumber(purchaseInvoiceTotal - saleInvoiceTotal, 2, {
            decimalSeperator: ',',
          })}`}</div>
        </div>
      </div>

      <div>
        <div>
          <div className="label-text">Type Rapport</div>
          <div className="my-2">
            <SimpleSelect
              items={CHART_TYPE_VALUES}
              selectedItem={selectedChartType}
              onSelect={(v) => {
                if (!v) return;

                setSelectedChartType(v);
              }}
            />
          </div>
        </div>

        <div>
          <Table
            idKey="id"
            headers={STAT_HEADERS}
            data={selectedStats}
            mapData={(statEntry) => {
              if (statEntry.type === 'total') {
                return [
                  'Totaal',
                  '-',
                  <div className="whitespace-nowrap">
                    {`€ ${formatNumber(statEntry.purchaseAmount, 2, {
                      decimalSeperator: ',',
                    })}`}
                  </div>,
                  '-',
                  <div className="whitespace-nowrap">
                    {`€ ${formatNumber(statEntry.saleAmount, 2, {
                      decimalSeperator: ',',
                    })}`}
                  </div>,
                  <div className="whitespace-nowrap">
                    {`€ ${formatNumber(statEntry.saleAmount - statEntry.purchaseAmount, 2, {
                      decimalSeperator: ',',
                    })}`}
                  </div>,
                ];
              } else {
                return [
                  <Link to={`/internal/orders/${statEntry.orderId}/general`} className="link-text">
                    {statEntry.orderNumber ?? 'DRAFT'}
                  </Link>,
                  <Link
                    to={`/internal/suppliers/${statEntry.supplierId}/general`}
                    className="link-text"
                  >{`${statEntry.supplierName} (${statEntry.supplierId})`}</Link>,
                  <div className="whitespace-nowrap">{`€ ${formatNumber(statEntry.purchaseAmount, 2, {
                    decimalSeperator: ',',
                  })}`}</div>,
                  <Link
                    to={`/internal/customers/${statEntry.customerName}/general`}
                    className="link-text"
                  >{`${statEntry.customerName} (${statEntry.customerId})`}</Link>,
                  <div className="whitespace-nowrap">{`€ ${formatNumber(statEntry.saleAmount, 2, {
                    decimalSeperator: ',',
                  })}`}</div>,
                  <div className="whitespace-nowrap">{`€ ${formatNumber(
                    statEntry.saleAmount - statEntry.purchaseAmount,
                    2,
                    {
                      decimalSeperator: ',',
                    },
                  )}`}</div>,
                ];
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
