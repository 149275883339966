import { useField } from 'formik';
import { useId } from 'react';

import { InputWrapper } from '../InputWrapper';
import { TextArea } from './TextArea';

export interface TextAreaFieldProps {
  labelText: string;
  name: string;
  helperText?: string;
  isDisabled?: boolean;
  rows?: number;
  cols?: number;
  spellCheck?: boolean;
}

export const TextAreaField: React.FC<TextAreaFieldProps> = (props) => {
  const { name, labelText, helperText, ...otherProps } = props;
  const id = useId();
  const [field, meta, helpers] = useField({ name });

  return (
    <InputWrapper labelText={labelText} invalidText={meta.touched ? meta.error : undefined} helperText={helperText}>
      <TextArea
        {...otherProps}
        id={id}
        value={field.value}
        onChange={(val) => {
          helpers.setValue(val);
        }}
        onBlur={(evt) => {
          helpers.setTouched(true);
          field.onBlur(evt);
        }}
        isInvalid={Boolean(meta.touched && meta.error)}
      />
    </InputWrapper>
  );
};
