import React from 'react';

export interface ICardProps {
  title: React.ReactNode;
  rightHeading?: React.ReactNode;
  children: React.ReactNode;
}

export const Card: React.FC<ICardProps> = (props) => {
  const { title, rightHeading, children } = props;

  return (
    <div className="card">
      <div className="card-heading flex justify-between items-center">
        <div>{title}</div>
        <div>{rightHeading}</div>
      </div>
      <div className="card-content">{children}</div>
    </div>
  );
};
