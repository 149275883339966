import * as Dialog from '@radix-ui/react-dialog';
import React, { Suspense, useState } from 'react';

import { BaseButton } from '../../../components/button/BaseButton';
import { SimpleErrorBoundary } from '../../../components/SimpleErrorBoundary';
import { IMinimalProductType } from './ProductTypeComboboxField';
import { UpdateProductTypeForm } from './UpdateProductType';

export interface IUpdateProductTypeDialogProps {
  id: string;
  triggerText: React.ReactElement;
  onSubmit: (data: IMinimalProductType) => void | Promise<void>;
}

export const UpdateProductTypeDialog: React.FC<IUpdateProductTypeDialogProps> = (props) => {
  const { id, triggerText, onSubmit } = props;
  const [open, setOpen] = useState(false);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <BaseButton>{triggerText}</BaseButton>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay className="dialog-overlay" />
        <Dialog.Content className="dialog-content">
          <Dialog.Title className="heading-one mb-4">Pas product type aan</Dialog.Title>

          <div>
            <SimpleErrorBoundary>
              <Suspense fallback="Loading...">
                <UpdateProductTypeForm
                  id={id}
                  onCancel={() => setOpen(false)}
                  onComplete={(data) => {
                    onSubmit(data);
                    setOpen(false);
                  }}
                />
              </Suspense>
            </SimpleErrorBoundary>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
